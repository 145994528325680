import React from "react";
import styled from "styled-components";

const ButtonContainer = styled.button<{ background?: string; color?: string }>`
  background: ${({ theme, background }) => background || theme.colors.primary[70]};
  padding: ${({ theme }) => theme.spacing.md} 0;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  color: ${({ theme, color }) => color || theme.colors.white[70]};
  cursor: pointer;
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
`;

const LogoImage = styled.img`
  width: 25px;
`;

type SignInButtonProps = {
  onLogin?: () => void;
  buttonName?: string;
  buttonLogo?: string;
  background?: string;
  color?: string;
};

function SignInButton({
  buttonName,
  buttonLogo,
  onLogin,
  background,
  color,
}: SignInButtonProps): JSX.Element {
  return (
    <ButtonContainer onClick={onLogin} background={background} color={color}>
      {buttonLogo && <LogoImage src={buttonLogo} alt={`${buttonName} 로그인 버튼`} />}
      {buttonName}
    </ButtonContainer>
  );
}

export default SignInButton;
