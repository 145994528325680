const borderRadius = {
  small: "4px",
  medium: "8px",
  large: "16px",
  full: "99px",
  circle: "99%",
};

const space = {
  xs: "4px",
  sm: "8px",
  md: "16px",
  lg: "32px",
  xl: "64px",
};

export const foundations = {
  borderRadius,
  space,
};
