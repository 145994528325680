import React, { useMemo } from "react";
import { Typography, TypographyProps } from "@presentation/components/common/atoms";

interface HighlightedTextProps extends TypographyProps {
  text: string;
  highlightText: string;
  highlightColor?: string;
  highlightVariant?: string;
  frontText?: string;
  backText?: string;
}

export default function HighlightedText({
  text,
  highlightText,
  highlightColor = "primary.70",
  highlightVariant,
  frontText,
  backText,
  fontSize,
  ...props
}: HighlightedTextProps): JSX.Element {
  const matchedList = useMemo(() => {
    if (!highlightText) return [text];
    return text.split(new RegExp(`(${highlightText})`, "gi")).filter(Boolean);
  }, [text, highlightText]);

  return (
    <Typography {...props} fontSize={fontSize}>
      {text && frontText}
      {matchedList.map((text, index) =>
        text.toLowerCase() === highlightText.toLowerCase() ? (
          <Typography
            key={index}
            color={highlightColor}
            as={"strong"}
            fontSize={fontSize}
            fontWeight={"bold"}
          >
            {text}
          </Typography>
        ) : (
          text
        )
      )}
      {text && backText}
    </Typography>
  );
}
