import { useQuery } from "@tanstack/react-query";
import { AcademyQueryKeys } from "@application/academy/queryKeys";
import { academyUseCase } from "@application/academy";

export const useAcademyLecturesRelated = (academyCode: number | string) => {
  return useQuery({
    queryKey: [AcademyQueryKeys.GET_ACADEMY_LECTURE_RELATED, academyCode],
    queryFn: () => {
      return academyUseCase.getAcademyLectureRelated(academyCode);
    },
    enabled: false,
  });
};
