import React from "react";
import HorizontalBlank from "@components/atoms/HorizontalBlank";
import hi from "@asset/Img/hi.png";
import { Outlet } from "react-router";
import {
  Description,
  DescriptionBox,
  LoginButtonContainer,
  LogoContainer,
  MainLogo,
  StyledImg,
} from "./signIn.styles";
import LoginButtonList from "@presentation/components/views/signIn/loginButtonList";
import { CONFIG } from "@config";
import { ACADEMY_METADATA } from "../../../common/constants";
import { BaseLayout } from "../../../common/layout";

type SignInViewProps = {
  onLogin: (loginType: string) => void;
};

function SignInView({ onLogin }: SignInViewProps): JSX.Element {
  const appName = ACADEMY_METADATA.APP_NAME;
  const mainLogoImageUri = `${CONFIG.API.EXTERNAL_URL.MEDIA_STORAGE_URL}/academy/logo/${appName}.png`;
  const isNotAcademyApp = CONFIG.APP_ENV.IS_NOT_ACADEMY;
  return (
    <BaseLayout>
      <LogoContainer>
        <MainLogo src={mainLogoImageUri} alt={`${appName}로고`} />
        {isNotAcademyApp && (
          <DescriptionBox>
            <Description>내 수업 학생들에게만 제공되는</Description>
            <HorizontalBlank height={8} />
            <Description>대화형 문제풀이 어플</Description>
            <StyledImg src={hi} alt="hi" />
          </DescriptionBox>
        )}
      </LogoContainer>
      <LoginButtonContainer>
        <LoginButtonList onLogin={onLogin} />
      </LoginButtonContainer>
      <Outlet />
    </BaseLayout>
  );
}

export default SignInView;
