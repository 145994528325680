class RandomUtil {
  private static surnames = ["김", "이", "박", "최", "정", "강", "조", "윤", "장", "임"];
  private static names = [
    "민수",
    "서연",
    "지후",
    "하은",
    "준서",
    "지민",
    "수아",
    "현우",
    "윤아",
    "은우",
  ];
  private static adjectivePrefix = [
    "최고의",
    "귀여운",
    "울트라",
    "행복한",
    "멋진",
    "사랑스러운",
    "용감한",
    "상냥한",
    "환상적인",
    "활기찬",
  ];
  private static animals = [
    "호랑이",
    "사자",
    "코끼리",
    "토끼",
    "여우",
    "다람쥐",
    "늑대",
    "펭귄",
    "고래",
    "독수리",
  ];

  static getRandomKoreanFullName(): string {
    const surname = this.surnames[Math.floor(Math.random() * this.surnames.length)];
    const name = this.names[Math.floor(Math.random() * this.names.length)];
    return `${surname}${name}`;
  }

  static getRandomKoreanNickname(): string {
    const adjectiveNickName =
      this.adjectivePrefix[Math.floor(Math.random() * this.adjectivePrefix.length)];
    const animalNickName = this.animals[Math.floor(Math.random() * this.animals.length)];
    return `${adjectiveNickName}${animalNickName}`;
  }

  static getRandomString(length: number): string {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }
  static getRandomEmail(): string {
    return `${this.getRandomString(7)}@${this.getRandomString(5)}.test.account`;
  }

  static getRandomPhoneNumber(): string {
    return `020${Math.floor(Math.random() * 100000000)
      .toString()
      .padStart(8, "0")}`;
  }
}

export default RandomUtil;
