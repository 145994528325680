import store from "asset/store";
import React, { useCallback, useContext, useMemo, useRef, useState } from "react";
import { VideoContext } from "..";
import styles from "./style.module.scss";

const ProgressBarControl = () => {
  const { isMobile } = store;
  const { player, playerState } = useContext(VideoContext);
  const [isDragging, setIsDragging] = useState(false);
  const [thumbnailPosition, setThumbnailPosition] = useState(0);
  const [thumbnailWidth, setThumbnailWidth] = useState(0);
  const [thumbnailHeight, setThumbnailHeight] = useState(0);

  const thumbnailRef = useRef<HTMLVideoElement>(null);
  const handleProgressClick = useCallback(
    (e) => {
      const { offsetX, target, changedTouches } = e.nativeEvent;
      var leftPosition: number;
      if (e.type === "touchmove" || e.type === "touchend") {
        const touch = changedTouches[0];
        leftPosition = touch.pageX - target.getBoundingClientRect().left;
      } else {
        leftPosition = offsetX;
      }
      const percent = leftPosition / target.offsetWidth;
      const duration = playerState.duration;
      const newSeekTime = percent * duration;

      if (isDragging) {
        player.currentTime(newSeekTime);
      } else {
        setThumbnailPosition((newSeekTime / thumbnailRef?.current?.duration) * 100);
      }
    },
    [isDragging, player, playerState, setThumbnailPosition]
  );

  const progressPosition = useMemo(() => {
    return player ? (playerState.currentTime / playerState?.duration) * 100 : 0;
  }, [playerState.currentTime, playerState?.duration, player]);

  const progressBarRef = useRef<HTMLDivElement>(null);
  const [thumbnailURL, setThumbnailURL] = useState(null);

  const handleMouseLeave = () => {
    setThumbnailURL(null);
  };

  // player?.duration(40);

  return (
    <div
      className={styles.progressPadding}
      onMouseDown={(e) => {
        setIsDragging(true);
      }}
      onMouseMove={(e) => {
        handleProgressClick(e);
      }}
      onMouseUp={(e) => {
        setIsDragging(false);
        handleProgressClick(e);
      }}
      onMouseLeave={handleMouseLeave}
      //
      onTouchStart={(e) => {
        setIsDragging(true);
      }}
      onTouchMove={(e) => {
        handleProgressClick(e);
      }}
      onTouchEnd={(e) => {
        setIsDragging(false);
        handleProgressClick(e);
      }}
    >
      {thumbnailURL && (
        <div
          className={styles.progressThumbnail}
          style={{
            position: "absolute",
            left: `${thumbnailPosition}%`,
            background: `url(${thumbnailURL})`,
            width: thumbnailWidth,
            height: thumbnailHeight,
          }}
        />
      )}
      <ProgressBarInput progressValue={progressPosition} />
    </div>
  );
};

interface IProgressBarInput {
  progressValue: number;
}

const ProgressBarInput = ({ progressValue }: IProgressBarInput) => {
  const gradient = `linear-gradient(to right, rgba(200,200,200,0.8) 0%, rgba(200,200,200,0.8) ${progressValue}%, rgba(130,130,130,0.5) ${progressValue}%, rgba(130,130,130,0.5)  100%)`;
  return (
    <div className={styles.progressBar}>
      <input
        type="range"
        className={styles.progressFill}
        value={progressValue}
        style={{ background: gradient }}
      />
    </div>
  );
};

export default ProgressBarControl;
