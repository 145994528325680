import { useMutation } from "@tanstack/react-query";
import { accountRepository } from "@infrastructure/repositories/account";
import { ExistingAccountLinkDto } from "@infrastructure/repositories/account/dto/link.dto";

const useExistingAccountLink = () => {
  return useMutation({
    mutationFn: async (data: ExistingAccountLinkDto) => {
      return await accountRepository.setExistingAccountLink(data);
    },
  });
};

export default useExistingAccountLink;
