import { React, useState, useEffect } from "react";
import { UiInput } from "../../uiComponents/UiInput/UiInput";
import face_student from "../../asset/Img/defaultpic_student.png";
import face_teacher from "../../asset/Img/defaultpic_teacher.png";
import { useNavigate, useParams } from "react-router";
import constants from "../../asset/constants";
import apiFn from "../../asset/apiClass";
import style from "../../asset/style";
import UiPeople_Style from "../../uiComponents/UiPeople/UiPeople_Style";
import UiMessage_Style from "../../uiComponents/UiMessage/UiMessage_Style";
import UiRadioList from "../../uiComponents/UiRadio/UiRadio";
import UiSearchBox from "../../uiComponents/UiSearch/UiSearchBox";

var fontStyle = {
  fontFamily: "Noto Sans",
  fontStyle: "normal",
};

const Modal_invite = ({ setIsModal, setQnaData, isModal, userInfo, qnaData, type = "msg" }) => {
  let params = useParams();
  let navigate = useNavigate();

  var [isAll, setIsAll] = useState(false);
  var [isLoading, setIsLoading] = useState(false);
  var [isInit, setIsInit] = useState(true);

  var [oriList, setOriList] = useState([]);
  var [list, setList] = useState([]);
  var [search, setSearch] = useState("");

  var [selectData, setSelectData] = useState({});

  var [type, setType] = useState("name");

  const filterToGetUninvited = (peopleList) => {
    return peopleList.filter(
      (people) => !people.suupList.some((lecture) => lecture.id === Number(params.id))
    );
  };
  const apiPro_teacher = async () => {
    try {
      let where_obj = {};
      if (type == "name") {
        where_obj = {
          "A.name": `= '${search}'`,
        };
      } else if (type == "nick") {
        where_obj = {
          "A.nick": `= '${search}'`,
        };
      }
      let list = await apiFn.comApi({
        state: {
          table: "user_credential",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          ref: ["A.*|HIQDB_user A on A.index=HIQDB_user_credential.user_id and A.user_type = 2"],
          where: where_obj,
        },
      });
      console.log(list);
      return filterToGetUninvited(list.list);
    } catch (e) {
      console.log("************");
      console.log(e);
      console.log("************");
    }
  };

  const apiPro_student = async () => {
    try {
      let where_obj = {};
      if (type == "name") {
        where_obj = {
          "A.name": `= '${search}'`,
        };
      } else if (type == "nick") {
        where_obj = {
          "A.nick": `= '${search}'`,
        };
      }
      let list = await apiFn.comApi({
        state: {
          table: "user_credential",
          page: 1,
          amount: 9999,
          ref: ["A.*|HIQDB_user A on A.index=HIQDB_user_credential.user_id and A.user_type = 1"],
          sort: "reg_date desc",
          where: where_obj,
        },
      });
      return filterToGetUninvited(list.list);
    } catch (e) {
      console.log("************");
      console.log(e);
      console.log("************");
    }
  };

  const apiPro_search = async () => {
    try {
      let promiseArray = [apiPro_student(), apiPro_teacher()];
      let data = await Promise.all(promiseArray);
      console.log(data);
      setList([...data[0], ...data[1]]);
      setIsInit(false);
      // let student = await apiPro_student()
      // let teacher = await apiPro_teacher();
    } catch (e) {
      console.log("************");
      console.log(e);
      console.log("************");
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        zIndex: 130002,
        backgroundColor: "rgba(150, 150, 150,0.5)",
        // backgroundColor:"red",
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={() => {
        setIsModal(false);
      }}
    >
      <div
        style={{
          width: "75%",
          // height : "70%",
          // minHeight:"30%",
          maxHeight: "70%",
          // maxHeight:"80%",
          backgroundColor: "white",
          padding: "15px 20px 30px 20px",
          borderRadius: 10,
          border: "1px solid rgb(200, 200, 200)",
          display: "flex",
          flexDirection: "column",
          // justifyContent:"space-between",
          filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.37))",
        }}
        onClick={(e) => {
          console.log("###");
          e.stopPropagation();
          // e.preventDefault();
        }}
      >
        <div
          style={{
            fontWeight: 700,
            display: "flex",
            fontSize: "18px",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          초대할 학생/선생님 찾기
        </div>
        <div>
          <UiRadioList
            inputName="search"
            list={[
              { value: "name", name: "이름" },
              { value: "nick", name: "닉네임" },
              // { value : "tel" , name : "전화번호"},
            ]}
            containerStyle={{
              display: "flex",
              justifyContent: "center",
              padding: "15px 0px 10px 0px",
            }}
            fnClick={(e) => {
              console.log(e.target.id);
              setType(e.target.id);
            }}
            initValue={type}
          />
          <UiSearchBox
            isTeacher={false}
            fnChange={(e) => {
              setSearch(e.target.value);
            }}
            fnClick={() => {
              apiPro_search();
            }}
          />
          {isInit ? (
            <div
              style={{
                color: "#B0B0B0",
                fontSize: "12px",
                fontWeight: 400,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "15px",
              }}
            >
              <div
                style={{
                  marginBottom: "4px",
                }}
              >
                이름, 전화번호 및 이메일 검색은
              </div>
              <div
                style={{
                  marginBottom: "4px",
                }}
              >
                개인정보 보호를 위해
              </div>
              <div
                style={{
                  marginBottom: "4px",
                }}
              >
                검색어와 완전 일치하는 대상만 검색됩니다.
              </div>
            </div>
          ) : list.length == 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                fontWeight: 600,
                color: style.common.t_color,
              }}
            >
              초대 가능한 사람이 없어요.
            </div>
          ) : (
            <>
              <div
                style={{
                  paddingTop: "5px",
                  overflowY: "scroll",
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                {list.map((v) => (
                  <Member
                    qnaData={qnaData}
                    selectData={selectData}
                    params={params}
                    data={v}
                    id={v["id"]}
                    userInfo={userInfo}
                    type={type}
                    setList={setList}
                    list={list}
                    // fnCheck={(e)=>{
                    //     if(selectData[e.target.id] == "Y"){
                    //         delete selectData[e.target.id]
                    //         setSelectData({
                    //             ...selectData,
                    //         })
                    //     }
                    //     else {
                    //         setSelectData({
                    //             ...selectData,
                    //             [e.target.id] : "Y"
                    //         })
                    //     }
                    // }}
                  />
                ))}
              </div>
            </>
          )}
        </div>
        {/* <div style={{
                    height:"40px",
                    display:"flex",
                    justifyContent:"center",
                }}>
                    <UiBtn
                        btnStyle={{
                            width:"90px",
                            height:"36px",
                            backgroundColor:style.common.t_color,
                            color : "white",
                            fontStyle:"12px",
                        }}
                        title={"닫기"}
                        fnClick={()=>{
                            setIsModal(false)
                        }}
                    />
                </div> */}
      </div>
    </div>
  );
};

const Member = ({
  id,
  isChk,
  selectData,
  isTeacher = true,
  fnCheck,
  data,
  params,
  userInfo,
  qnaData,
  type,
  setList,
  list,
}) => {
  let navigate = useNavigate();

  const apiPro_invite = async () => {
    try {
      if (type == "msg") {
      } else {
        let send_type = "suup_invite";
        if (data?.user_type === 2) {
        } else {
          send_type = "student_invite";
        }
        await apiFn.comApi({
          state: {
            table: "member_class",
            sendMsg: {
              type: send_type,
              targetMid: data["id"],
              orderMid: userInfo["id"],
              cid: params["id"],
              channel: constants.channelId,
            },
            ins: [
              {
                mid: data["id"],
                cid: params["id"],
                type: 9,
                status: 5,
                reg_date: 0,
              },
            ],
          },
        });
      }
      alert("초대 신청을 보냈어요.");
      const removeInvitePeople = list.filter((people) => data.id !== people.id);
      setList(removeInvitePeople);
    } catch (e) {
      console.log("************");
      console.log(e);
      console.log("************");
    }
  };

  useEffect(() => {
    console.log("qnaData : ", qnaData);
  }, [qnaData]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: style.common.maxWidth,
        // flex:1,
        // padding : "0px 10px",
      }}
    >
      <div
        style={{
          display: "flex",
          // margin : "5px 0px",
          padding: "7px 0px",
          width: "100%",
          alignItems: "center",
          // borderBottom : "1px solid #f6f6f6"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => {
            apiPro_invite();
          }}
        >
          <div
            style={{
              // width : '20%',
              display: "flex",
              alignItems: "center",
              marginRight: "10px",
            }}
            onClick={() => {}}
          >
            <div
              style={{
                ...UiPeople_Style.section_i_w,
                width: "40px",
                height: "40px",
              }}
            >
              <img
                style={UiMessage_Style.section_i}
                onError={(e) => {
                  // setIsLoad(false)
                  e.currentTarget.src = data?.user_type === 2 ? face_teacher : face_student;
                }}
                src={
                  data
                    ? data["img"]
                      ? constants.s3_url +
                        `${data?.user_type === 2 ? "/teacher/" : "/student/"}${data["img"]}` +
                        ".jpg?"
                      : constants.apiUrl +
                        `${data?.user_type === 2 ? "/teacher/" : "/student/"}${data["id"].normalize("NFD")}` +
                        ".jpg?"
                    : data?.user_type === 2
                      ? face_teacher
                      : face_student
                }
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "flex-end",
              // alignItems:"center",
              // width : '100%',
              // height : "100%",
            }}
            onClick={() => {}}
          >
            <div
              style={{
                // width:"100px",
                marginRight: "5px",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              {`${data["name"] ? data["name"] : "이름없음"} (${data["nick"] ? data["nick"] : ""})`}
            </div>
            <div
              style={{
                fontWeight: 300,
                fontSize: "12px",
              }}
            >
              {data?.user_type === 2 ? "선생님" : "학생"}
            </div>
          </div>
        </div>
      </div>
      {}
    </div>
  );
};

const SearchBox = ({ search, setSearch, apiPro_read, filter_ }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "28px",
        marginTop: "5px",
        // width:"230px",
      }}
    >
      <UiInput
        type="basic"
        basic_container_style={{
          height: "94%",
          padding: "0px 0px 0px 5px",
          // padding : "0px 10px"
        }}
        inputValue={search}
        fnChange={(e) => {
          setSearch(e.target.value);
          search = e.target.value;
          filter_("", e.target.value);
          // apiPro_read("",e.target.value)
        }}
        placeholder={"검색"}
        // fnChange={fnChange}
      />
    </div>
  );
};

export default Modal_invite;
