import { React, useEffect, useRef, useState } from "react";
import constants from "../../asset/constants";
import style from "../../asset/style";
import util from "../../asset/util";
import UiSearchSection_Style from "./UiSearchSection_Style";
import noLogo from "../../asset/Img/class1.png";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { useParams } from "react-router";
import { UiCheckBox } from "../UiCheckBox/UiCheckBox";

const UiSearchList = ({
  isImg = true,
  isTeacher,
  setIsSelect,
  form = 1,
  isAcademy,
  title = "'시대인재' 검색결과",
  title_add = "(3건)",
  isTitle = true,
  fnClick,
  data = [],
  isClassStat = true,
  isSearch = false,
  isLine = false,
  scrollKey = "",
  setScrolllKey,
  omitDefault = "",
}) => {
  let params = useParams();

  var [isOmit, setIsOmit] = useState(
    params["where"] == "academy"
      ? window.localStorage.getItem("academy_omit") === "true"
        ? true
        : false
      : params["where"] == "class"
        ? window.localStorage.getItem("class_omit") === "true"
          ? true
          : false
        : true
  );

  return (
    <div style={UiSearchSection_Style.f}>
      {isTitle && (
        <div style={UiSearchSection_Style.t_w}>
          {title}
          <div style={UiSearchSection_Style.t_w_add}>{!isAcademy && title_add}</div>
        </div>
      )}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <UiCheckBox
          value={"isImportant"}
          name="iI"
          className={params["who"] == "teacher" ? "t" : "s"}
          checked={isOmit}
          fnCheck={(e) => {
            setIsOmit(e.target.checked);
            if (params["where"] == "academy") {
              window.localStorage.setItem("academy_omit", e.target.checked);
            }
            if (params["where"] == "class") {
              window.localStorage.setItem("class_omit", e.target.checked);
            }
          }}
        />
        <div
          style={{
            fontSize: "12px",
            color: style.common.fontColor_1,
            marginLeft: "5px",
          }}
        >
          간략히 보기
        </div>
      </div>
      {data.map((v, i) => {
        if (form == 1) {
          return (
            <UiSearchSection
              key={i}
              isOmit={isOmit}
              isImg={isImg}
              scrollKey={scrollKey}
              setScrolllKey={setScrolllKey}
              isTeacher={isTeacher}
              isAcademy={isAcademy}
              setaIsSelect={setIsSelect}
              fnClick={fnClick}
              data={v}
              isClassStat={isClassStat}
              isSearch={isSearch}
            />
          );
        } else {
          return (
            <UiSearchSection_form2
              key={i}
              isOmit={isOmit}
              isImg={isImg}
              scrollKey={scrollKey}
              setScrolllKey={setScrolllKey}
              isTeacher={isTeacher}
              isAcademy={isAcademy}
              fnClick={fnClick}
              data={v}
              isClassStat={isClassStat}
              isSearch={isSearch}
              isLine={isLine}
            />
          );
        }
      })}
    </div>
  );
};

const UiSearchSection = ({
  isOmit,
  isImg,
  setIsSelect,
  isAcademy,
  isTeacher,
  fnClick,
  isBorder = true,
  data = {},
  isClassStat,
  isSearch,
  scrollKey,
  setScrolllKey,
}) => {
  var [isContents, setIsContents] = useState(true);

  useEffect(() => {
    // console.log("UiSearchSection data => ",data);
  }, [data]);

  let imgRef = useRef(null);

  var scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        // behavior: "smooth",
        block: "center",
      });
      // scrollKey = ""
      if (setScrolllKey) {
        setScrolllKey("");
      }
    }
  }, [scrollRef]);

  useEffect(() => {
    if (isOmit) {
      setIsContents(true);
    }
  }, [isOmit]);

  const IncludeMemberCountText = () => {
    if (data.cnt) {
      return (
        <div style={UiSearchSection_Style.section_t_b_text}>
          {`강사수 ${data["cnt"]["teacher"]} 명 | `}
          {`학생수 ${data["cnt"]["student"]} 명 `}
        </div>
      );
    } else if (!data["cnt"] && data.teachers && data.lcnt > -1) {
      return (
        <div style={UiSearchSection_Style.section_t_b_text}>
          {`강사수 ${data.teachers.length} 명 | `}
          {`학생수 ${data.lcnt} 명 `}
        </div>
      );
    } else if (
      (data.student_count || data.student_count === 0) &&
      (data.teacher_count || data.teacher_count === 0)
    ) {
      return (
        <div style={UiSearchSection_Style.section_t_b_text}>
          {`강사수 ${data?.teacher_count ?? 0} 명 | `}
          {`학생수 ${data?.student_count ?? 0} 명 `}
        </div>
      );
    } else {
      return "";
    }
  };

  return (
    <div
      style={{
        ...UiSearchSection_Style.section_c,
        backgroundColor: "white",
        border: isBorder ? "1px solid" : "none",
      }}
      ref={scrollKey == data["id"] ? scrollRef : null}
      onClick={() => {
        fnClick(data);
        if (setIsSelect) {
          setIsSelect(true);
        }
      }}
    >
      <div style={UiSearchSection_Style.section_w}>
        <div
          style={{
            ...UiSearchSection_Style.section_t_w,
            borderBottom: !isOmit ? (isContents ? "1px solid rgb(200,200,200)" : "none") : "none",
            borderColor: style.common.borderColor,
            minHeight: !isOmit ? "85px" : "0px",
          }}
        >
          <div style={UiSearchSection_Style.section_t_t_c}>
            {!isOmit && isImg && (
              <div style={UiSearchSection_Style.section_t_t_i_w}>
                <img
                  ref={imgRef}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  loading={"lazy"}
                  onError={(e) => {
                    e.currentTarget.src = noLogo;
                  }}
                  src={
                    data["img"]
                      ? constants.s3_url +
                        // '/public'
                        // +
                        `/class/${data["img"]}` +
                        ".jpg?"
                      : data["id"]
                        ? constants.s3_url +
                          // '/public'
                          // +
                          `/class/${data["id"]}` +
                          ".jpg?" +
                          util.getRandomInt(1000, 9999)
                        : data["cid"]
                          ? constants.s3_url +
                            // '/public'
                            // +
                            `/class/${data["cid"]}` +
                            ".jpg?" +
                            util.getRandomInt(1000, 9999)
                          : ""
                  }
                />
              </div>
            )}
            <div
              style={{
                ...UiSearchSection_Style.section_t_t_r_w,
                marginLeft: isOmit ? "0px" : isImg ? "17px" : "0px",
              }}
            >
              <div style={UiSearchSection_Style.section_t_t_r_w_text_1}>
                {/* 시대인재 (대치) */}
                {data.name ?? data.class_name ?? "알 수 없음"}
              </div>
              {!isOmit && (
                <div
                  style={{
                    ...UiSearchSection_Style.section_t_t_r_w_text_2,
                    display: "flex",
                  }}
                >
                  {/* 박근수 | 02-552-2373 */}
                  {data["owner"] ??
                    data?.reg_name ??
                    (data?.manager_name ? data?.manager_name : "이름 없는")}
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              ...UiSearchSection_Style.section_t_b_w,
              justifyContent: "flex-end",
              marginTop: isOmit ? "0px" : "15px",
              marginBottom: isOmit ? "0px" : "10px",
            }}
          >
            <div
              style={{
                ...UiSearchSection_Style.section_t_b_mark,
              }}
            >
              {/* {data["level"] == 0 ? "미인증" : "인증"} */}
            </div>
            <IncludeMemberCountText />
          </div>
        </div>
        {!isOmit && isContents && (
          <div style={UiSearchSection_Style.section_b_w}>
            <div style={UiSearchSection_Style.section_b_w_text}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                  // backgroundColor:"orange",
                }}
              >
                <KeyboardArrowUp
                  style={{
                    color: isTeacher ? style.common.t_color : style.common.s_color,
                    fontSize: "22px",
                    padding: 0,
                    margin: 0,
                  }}
                  onClick={(e) => {
                    setIsContents(false);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              </div>
              {data["intro"] &&
                (data["intro"].includes("\n")
                  ? util.line(data["intro"])
                  : // data["intro"].split("\n").map((v,i)=>
                    // <div>{v}</div>
                    // )
                    data["intro"])}
            </div>
          </div>
        )}
        {!isOmit && (isAcademy || isSearch) && (
          <div
            style={{
              ...UiSearchSection_Style.academyAdd_w,
              color: isTeacher ? style.common.t_color : style.common.s_color,
            }}
          >
            {isSearch ? (
              <div>
                {isTeacher &&
                  (data["joinStatus"] == "0"
                    ? "승인 대기중"
                    : data["joinStatus"] == "1"
                      ? data["joinType"] == "5"
                        ? "관리자"
                        : data["joinType"] == "6" && "일반 선생님"
                      : data["joinStatus"] == "2"
                        ? "승인 거절"
                        : data["joinStatus"] == "3" && "탈퇴 ?")}
              </div>
            ) : (
              <div>
                {isTeacher && data["status"] == "0"
                  ? "승인 대기중"
                  : data["status"] == "1"
                    ? data["type"] == "5"
                      ? "관리자"
                      : data["type"] == "6" && "일반 선생님"
                    : data["status"] == "2"
                      ? "승인 거절"
                      : data["status"] == "3" && "탈퇴 ?"}
              </div>
            )}
            {!isSearch && (
              <div>
                {data["classList"] &&
                  (data["classList"].length > 0
                    ? `${data["classList"].length}개 수업 강의중`
                    : "수업 없음")}
              </div>
            )}
          </div>
        )}
        {!isOmit && !isContents && (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              // backgroundColor:"orange",
            }}
          >
            <KeyboardArrowDown
              style={{
                color: isTeacher ? style.common.t_color : style.common.s_color,
                fontSize: "22px",
                padding: 0,
                margin: 0,
              }}
              onClick={(e) => {
                setIsContents(true);
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const UiSearchSection_form2 = ({
  isImg,
  isOmit,
  setIsSelect,
  isTeacher,
  isAcademy,
  fnClick,
  data = {},
  isClassStat,
  isBorder = true,
  isSearch,
  isLine,
  scrollKey,
  setScrolllKey,
}) => {
  // var [timeData,setTimeData] = useState([])
  var [time, setTime] = useState("");
  var [owner, setOwner] = useState("");

  var [isContents, setIsContents] = useState(true);

  const apiPro_read = async () => {
    try {
      let time_text = "";
      let time_obj = {};
      if (data.time) {
        data["time"].map((v, i) => {
          time_obj[v["start_time"]] = {
            ...time_obj[v["start_time"]],
            [v["start_day"]]: v["end_time"],
          };
        });
        Object.keys(time_obj).map((v, i) => {
          let text = "";
          Object.keys(time_obj[v]).map((g, j) => {
            if (j != 0) {
              text = g + "," + text;
            } else {
              text = text + g + " " + v + " ~ " + time_obj[v][g];
            }
            // // console.log(text)
          });
          time_text = time_text + text + " ";
        });
      }
      let array = [];
      if (data["teachers"]) {
        array = data["teachers"].reduce((a, v, i) => {
          //    // console.log(v["type"])
          if (v["type"] == 5) {
            //    // console.log("#")
            return [...a, v];
          } else {
            return [...a];
          }
        }, []);
        //    // console.log("owner => ",array);
        setOwner(array[0]["name"]);
      }
      setTime(util.textTrim(time_text, 45));
    } catch (e) {
      util.c_err_log(`apiPro Read Error => ${e}`);
    }
  };

  useEffect(() => {
    apiPro_read();
    // if(data["id"] || data["mid"]){
    //     imgRef.current.src = loading
    // }
    // console.log("UiSearchSection_form2 data => ",data)
  }, [data]);

  // let imgRef = null;
  let imgRef = useRef(null);
  var scrollRef = useRef(null);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        // behavior: "smooth",
        block: "center",
      });
      if (setScrolllKey) {
        setScrolllKey("");
      }
    }
  }, [scrollRef]);

  useEffect(() => {
    if (isOmit) {
      setIsContents(true);
    }
  }, [isOmit]);

  return (
    <div
      ref={scrollKey == data["id"] ? scrollRef : null}
      id={data["id"]}
      key={"search_" + data["id"]}
      style={{
        ...UiSearchSection_Style.section_c,
        backgroundColor: "white",
        // height :
        //     isLine ? "auto"
        //     :
        //     (
        //         isAcademy ? "160px"
        //         :
        //         isSearch ? "160px"
        //         :
        //         "140px"
        //     )
        // ,
        border: isBorder ? "1px solid" : "none",
      }}
      onClick={() => {
        fnClick(data);
        if (setIsSelect) setIsSelect(true);
      }}
    >
      <div
        style={{
          ...UiSearchSection_Style.section_w,
          padding: isOmit ? "5px 10px" : "10px",
        }}
      >
        <div
          style={{
            ...UiSearchSection_Style.section_t_w,
            borderColor: style.common.borderColor,
            borderBottom: !isOmit ? (isContents ? "1px solid rgb(200,200,200)" : "none") : "none",
            minHeight: !isOmit ? "85px" : "0px",
          }}
        >
          <div style={UiSearchSection_Style.section_t_t_c}>
            {isImg && (
              <div style={UiSearchSection_Style.section_t_t_i_w}>
                <img
                  ref={imgRef}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                  onError={(e) => {
                    e.currentTarget.src = noLogo;
                  }}
                  src={
                    data["img"]
                      ? constants.s3_url +
                        // '/public'
                        // +
                        `/class/${data["img"]}` +
                        ".jpg?"
                      : data["id"]
                        ? constants.s3_url +
                          // '/public'
                          // +
                          `/class/${data["id"]}` +
                          ".jpg?" +
                          util.getRandomInt(1000, 9999)
                        : // data["rand"]
                          data["cid"]
                          ? constants.s3_url +
                            // '/public'
                            // +
                            `/class/${data["cid"]}` +
                            ".jpg?" +
                            util.getRandomInt(1000, 9999)
                          : ""
                  }
                />
                {/* 1121 삭제요청 */}
                {/* <div style={{
                                ...UiSearchSection_Style.section_t_b_mark,
                                backgroundColor : style.common.s_color,
                                // backgroundColor : style.common.t_color,
                            }}> */}
                {/* {data["stype"] == 0 ? "실강" : "인강"} */}
                {/* </div> */}
              </div>
            )}
            <div
              style={{
                ...UiSearchSection_Style.section_t_t_r_w,
                marginLeft: isImg ? "17px" : "0px",
                // backgroundColor:"orange"
              }}
            >
              <div
                style={{
                  ...UiSearchSection_Style.section_t_t_r_w_text_1,
                  display: "flex",
                  marginBottom: "0px",
                  ...style.common.line_text,
                }}
              >
                {/* 수학 만점왕 | 시대인재(대치) */}
                {`${data["name"] ? data["name"] : data.class_name ?? "알 수 없음"} `}
                <div
                  style={{
                    marginLeft: "3px",
                    // fontWeight:"normal",
                    fontSize: "15px",
                    fontWeight: "lighter",
                    color: style.common.fontColor_6,
                    display: "inline",
                  }}
                >
                  {` ${data["academyName"] ? "| " + data["academyName"] : ""}`}
                  {data.classroom_name ? `| ${data.classroom_name}` : ""}
                  {/* {` | ${util.textTrim(data["academyName"],12)}`} */}
                </div>
              </div>
              {!isOmit && (
                <>
                  <div style={UiSearchSection_Style.section_t_t_r_w_text_2}>
                    {/* 김이박 선생님 외 2명 */}
                    {`${owner ? owner + " 선생님" : ""} `}
                    {`${data?.reg_name ?? data?.manager_name} 선생님`}
                    {data["teachers"] &&
                      data["teachers"].length > 1 &&
                      `외 ${data["teachers"].length - 1}명`}
                  </div>
                  <div style={UiSearchSection_Style.section_t_t_r_w_text_2}>{data["address"]}</div>
                  <div
                    style={{
                      ...UiSearchSection_Style.section_t_t_r_w_text_2,
                      display: "flex",
                      flexDirection: isLine ? "column" : "row",
                    }}
                  >
                    {/* 월수금 18:00 ~ 20:00 */}
                    {isLine
                      ? // "SDSDSDSD"
                        data["time"] &&
                        data["time"].map((v, i) => (
                          <div>
                            {v["start_day"] + " " + v["start_time"] + " ~ " + v["end_time"]}
                          </div>
                        ))
                      : time}
                    {/* {timeData.map((v,i)=>{
                                    return (
                                        <div>
                                            {v["start_day"] + " " + v["start_time"] + " ~ "+v["end_time"]}
                                        </div>
                                    )
                                })} */}
                  </div>
                </>
              )}
            </div>
          </div>
          <div
            style={{
              ...UiSearchSection_Style.section_t_b_w,
              // justifyContent:"space-between",
              marginTop: isOmit ? "5px" : "15px",
              marginBottom: isOmit ? "3px" : "10px",
              justifyContent: "flex-end",
            }}
          >
            <div style={UiSearchSection_Style.section_t_b_text}>
              {/* 수강인원 34명 */}
              {`수강인원 ${data["lcnt"] ? data["lcnt"] : data.student_count ?? 0}명`}
            </div>
          </div>
        </div>
        {!isOmit && isContents && (
          <div style={UiSearchSection_Style.section_b_w}>
            <div
              style={UiSearchSection_Style.section_b_w_text}
              // dangerouslySetInnerHTML={{__html : data["intro"]}}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-end",
                  // backgroundColor:"orange",
                }}
              >
                <KeyboardArrowUp
                  style={{
                    color: isTeacher ? style.common.t_color : style.common.s_color,
                    fontSize: "22px",
                    padding: 0,
                    margin: 0,
                  }}
                  onClick={(e) => {
                    setIsContents(false);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              </div>
              {/* 대치동에 이ㅜ치한 대학 입시 전문 클래스룸입니다.대치동에 이ㅜ치한 대학 입시 전문 클래스룸입니다 */}
              {data["intro"] &&
                (data["intro"].includes("\n")
                  ? util.line(data["intro"])
                  : // data["intro"].split("\n").map((v,i)=>
                    // <div>{v}</div>
                    // )
                    data["intro"])}
            </div>
          </div>
        )}
        {(isAcademy || isSearch) && isContents && !isOmit && (
          <div
            style={{
              ...UiSearchSection_Style.academyAdd_w,
              color: isTeacher ? style.common.t_color : style.common.s_color,
            }}
          >
            {isAcademy && (
              <>
                <div>
                  {
                    // isTeacher && "승인 대기중"
                    isTeacher &&
                      (data["joinStatus"] == "0"
                        ? "승인 대기중"
                        : data["joinStatus"] == "1" && "강의중")
                  }
                </div>
                <div>{isClassStat && "수업 없음"}</div>
              </>
            )}
            {isSearch &&
              (data["joinStatus"] == "0"
                ? "승인 대기중"
                : data["joinStatus"] == "1"
                  ? "수강중"
                  : data["joinStatus"] == "5" && "초대 승낙 대기중")}
          </div>
        )}
        {!isContents && (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              // backgroundColor:"orange",
            }}
          >
            <KeyboardArrowDown
              style={{
                color: isTeacher ? style.common.t_color : style.common.s_color,
                fontSize: "22px",
                padding: 0,
                margin: 0,
              }}
              onClick={(e) => {
                setIsContents(true);
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { UiSearchList, UiSearchSection, UiSearchSection_form2 };
