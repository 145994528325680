import { useQuery } from "@tanstack/react-query";
import { academyRepository } from "../repository/academy.repository";

export const useGetAcademyInfo = (academyCode?: string) => {
  return useQuery({
    queryKey: ["academy", "info", academyCode],
    queryFn: () => academyRepository.getAcademyInfo(academyCode),
    enabled: false,
    suspense: false,
  });
};
