import constants from "asset/constants";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import API from "../../api";
import UiBtn from "../../uiComponents/UiBtn/UiBtn";
import Academy_main_Style from "./Academy_main_Style";
import ClassroomListSection from "./ClassroomListSection";

const Academy_main = ({ isTeacher, userInfo, scrollKey, setScrolllKey }) => {
  const navigate = useNavigate();
  const params = useParams();

  const [classroomList, setClassroomList] = useState([]);

  useEffect(() => {
    const getUserJoinClassroomList = async () => {
      let queryString;
      if (constants.isAcademy) {
        queryString = `classId=${constants.classroomId}`;
      } else {
        queryString = "classType=0";
      }
      const userClassList = await API.getUserClassList(userInfo.id, queryString);
      setClassroomList(userClassList);
    };
    getUserJoinClassroomList();
  }, [userInfo]);

  return (
    <div style={Academy_main_Style.c}>
      <div style={Academy_main_Style.t_btn_c}>
        <div style={Academy_main_Style.t_btn_w}>
          {!isTeacher && (
            <UiBtn
              btnStyle={Academy_main_Style.t_btn}
              title={isTeacher ? "새 학원 생성" : "수업 가입 신청"}
              fnClick={() => {
                if (isTeacher) {
                  navigate("/academyregi/" + params["who"]);
                }
              }}
            />
          )}
        </div>
      </div>
      <div style={Academy_main_Style.searchList_w}>
        <ClassroomListSection
          data={classroomList}
          scrollKey={scrollKey}
          setScrolllKey={setScrolllKey}
        />
      </div>
    </div>
  );
};

export default Academy_main;
