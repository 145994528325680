import { ISignUpRepository } from "../interface/signUp-repository.interface";
import { IAccountJoinRequest } from "../../../../shared/api/api-gateway/account/join/type";

export class JoinUseCase {
  constructor(private readonly signUpRepository: ISignUpRepository) {}

  execute(accountJoinData: IAccountJoinRequest): Promise<any> {
    return this.signUpRepository.postJoin(accountJoinData);
  }
}
