import React from "react";
import { useLocation, useNavigate } from "react-router";
import LinkExistingAccountView from "@presentation/views/authViews/linkExistingAccountView";
import useExistingAccountLink from "@hooks/queries/account/useExistingAccountLink";

function LinkExistingAccountPage(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const { mutateAsync } = useExistingAccountLink();
  const hint = JSON.parse(location.state.hint);
  const oauthSessionKey = location.state.oauthSessionKey;

  const handleLinkExistingAccount = async () => {
    try {
      await mutateAsync({ email: hint.email, oauthSessionKey: oauthSessionKey });
      navigate("/auth?res=ok");
    } catch (e) {
      console.error(e);
    }
  };

  const handleNotMyAccount = () => {
    navigate(`/auth?res=notfound&oauth_session_key=${oauthSessionKey}&not_my_account=true`);
  };

  return (
    <LinkExistingAccountView
      existingAccount={{ ...hint, type: hint.type === "STUDENT" ? "학생" : "선생님" }}
      onLinkExistingAccount={handleLinkExistingAccount}
      onNotMyAccount={handleNotMyAccount}
    />
  );
}

export default LinkExistingAccountPage;
