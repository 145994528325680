import { IUrlManager } from "../url/interface/url-manager.interface";
import { urlManager } from "../url/url.manager";
import {
  AcademyMetaDataCodeType,
  AcademyMetaDataMapType,
  IAcademyMetaData,
} from "./interface/academyMetaDataInfo.interface";
import { ACADEMY_METADATA } from "./constants/academyMetaData";

class AcademyMetaDataManager {
  private readonly academyMetaDataMap: AcademyMetaDataMapType;
  private readonly urlManager: IUrlManager;
  private readonly academyCode: AcademyMetaDataCodeType;
  private readonly DEFAULT_ACADEMY_CODE = "default" as AcademyMetaDataCodeType;

  constructor(urlManager: IUrlManager, academyInfoMap: AcademyMetaDataMapType) {
    this.validateAcademyInfoMap(academyInfoMap);
    this.urlManager = urlManager;
    this.academyMetaDataMap = academyInfoMap;
    this.academyCode = this.getAcademyCodeInUrl();
  }

  private validateAcademyInfoMap(academyInfoMap: AcademyMetaDataMapType): void {
    if (!academyInfoMap || Object.keys(academyInfoMap).length === 0) {
      throw new Error("Academy info map cannot be empty");
    }
  }

  private showNotification(message: string): void {
    console.warn(message);
  }

  private getAcademyCodeInUrl(): AcademyMetaDataCodeType {
    const academyCode = this.urlManager.getFirstPathSegment();
    if (academyCode === "") {
      return this.DEFAULT_ACADEMY_CODE;
    }
    if (academyCode && !this.academyMetaDataMap[academyCode as AcademyMetaDataCodeType]) {
      this.showNotification(`'${academyCode}' 학원을 찾을 수 없습니다. 기본 페이지로 설정합니다.`);
      return this.DEFAULT_ACADEMY_CODE;
    }
    return academyCode as AcademyMetaDataCodeType;
  }

  getAcademyInfo(): IAcademyMetaData {
    return this.academyMetaDataMap[this.academyCode];
  }

  getBaseName(): string {
    const academyCode = this.urlManager.getFirstPathSegment();
    if (
      academyCode === "" ||
      (academyCode && !this.academyMetaDataMap[academyCode as AcademyMetaDataCodeType])
    ) {
      return "/";
    }
    return `/${academyCode}`;
  }
}

export const academyMetaDataManager = new AcademyMetaDataManager(urlManager, ACADEMY_METADATA);
