import Confirm, { ConfirmProps } from "components/templates/Confirm";
import React, { createContext, useContext, useState } from "react";

export const ConfirmContext = createContext<((confirm: ConfirmProps) => void) | null>(null);

export const ConfirmCloseContext = createContext<(() => void) | null>(null);

const ConfirmProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [confirm, setConfirm] = useState<ConfirmProps | null>(null);

  const confirmHandler = (confirm: ConfirmProps) => {
    setConfirm(confirm);
  };

  const confirmCloseHandler = () => {
    setConfirm(null);
  };

  return (
    <ConfirmContext.Provider value={confirmHandler}>
      {children}
      {confirm && <Confirm {...confirm} onClose={confirmCloseHandler} />}
    </ConfirmContext.Provider>
  );
};

export const useConfirm = () => {
  const confirmHandler = useContext(ConfirmContext);
  const showConfirm = (confirm: ConfirmProps) =>
    confirmHandler && confirmHandler({ ...confirm, type: "confirm" });
  const showAlert = (confirm: ConfirmProps) =>
    confirmHandler && confirmHandler({ ...confirm, type: "alert" });

  return {
    showConfirm,
    showAlert,
  };
};

export default ConfirmProvider;
