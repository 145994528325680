import teacherCharacter from "@asset/Img/aquery.png";
import studentCharacter from "@asset/Img/answer.png";
import { TypeDescription } from "@pages/signUp/types";
import { AccountEnum } from "../../shared/enum/account";

export const descriptions: Record<
  AccountEnum.TEACHER_LOWER | AccountEnum.STUDENT_LOWER,
  TypeDescription
> = {
  [AccountEnum.TEACHER_LOWER]: {
    mainDescription: "학생들의 어려운 문제를 해결해주는",
    typeText: "선생님",
    imageUrl: teacherCharacter,
    pointColor: "teacher",
  },
  [AccountEnum.STUDENT_LOWER]: {
    mainDescription: "모르는 문제의 해답을 찾아가는",
    typeText: "학생",
    imageUrl: studentCharacter,
    pointColor: "student",
  },
};
