import { useTheme } from "styled-components";
import { ThemeColorRepository } from "@infrastructure/theme/color.repository";
import { GetColorValueUseCase } from "@application/useCase/theme/getColorValue.useCase";

export const useGetColor = () => {
  const theme = useTheme();
  const colorRepository = new ThemeColorRepository(theme);
  const getColorByPalette = new GetColorValueUseCase(colorRepository);
  return {
    getColor: (colorInput: string | null) => getColorByPalette.execute({ colorInput }),
  };
};
