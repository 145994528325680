import Appbar from "@components/pages/signUp/appbar";
import StepIndicator from "@components/pages/signUp/stepIndicator";
import AccountFormTemplate from "@components/templates/accountFormTemplate";
import { AccountFieldType } from "@constants/accountForm/types";
import { AccountContextSection } from "@pages/signUp/accountInfo/styled";
import { SignUpContext } from "@providers/pages/signUp";
import React, { useContext, useState } from "react";
import { SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router";
import PageContainer from "@components/templates/PageContainer";
import { useGetProfile } from "@hooks/queries/account/useProfile";
import { IJoinData, useJoin } from "../../../page/account/sign-up/hook/useJoin";

export default function AccountInfoPage(): JSX.Element {
  const navigate = useNavigate();
  const { signUpFormData, onResetSignUpState, myNotAccount } = useContext(SignUpContext);
  const { mutateAsync } = useJoin();
  const { refetch: refetchUserProfile } = useGetProfile();
  const [accountFormFileds, setAccountFormFileds] = useState<(keyof AccountFieldType)[]>([
    "nickname",
    ...(myNotAccount.current ? (["email", "phoneNumber"] as (keyof AccountFieldType)[]) : []),
  ]);

  const handleAccountInfoSubmit: SubmitHandler<AccountFieldType> = async (data, event) => {
    event.stopPropagation();
    event.preventDefault();
    console.log("signUpFormData", signUpFormData);
    const joinData: IJoinData = {
      join: {
        accountParams: {
          userName: data.userName,
          email: data.email,
          phoneNumber: data.phoneNumber,
          nickname: data.nickname,
        },
        userType: signUpFormData.type,
        oauthSessionKey: signUpFormData.oauth_session_key,
      },
      marketingNotify: signUpFormData.marketingNotify,
    };

    try {
      await mutateAsync(joinData);
      alert("회원가입이 완료되었습니다.");
      await refetchUserProfile();
      navigate("/lectures/select", { replace: true });
    } catch (e: any) {
      const error = e?.response?.data;
      if ("fields" in error) {
        setAccountFormFileds((prev) => [...prev, ...error.fields]);
        alert(
          `회원가입에 필요한 데이터가 없어서 다시 입력해주세요.. ${e?.response?.data?.message}`
        );
      } else {
        console.error("d에러", e.response.data);
        alert(`회원가입에 실패했습니다. ${e?.response?.data?.message}`);
      }
    }
  };

  // useEffect(() => {
  //   if (!signUpFormData.type) {
  //     navigate("/", { replace: true });
  //   }
  // }, [signUpFormData.type]);
  //
  // if (!signUpFormData.type) {
  //   return <></>;
  // }

  return (
    <PageContainer>
      <Appbar />
      <StepIndicator />
      <AccountContextSection>
        <AccountFormTemplate
          onSubmit={handleAccountInfoSubmit}
          formFields={accountFormFileds}
          buttonBackgroundColor={signUpFormData.type}
        />
      </AccountContextSection>
    </PageContainer>
  );
}
