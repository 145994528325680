import colors from "@constants/theme/color";
import typography from "@constants/theme/typography";
import { foundations } from "@constants/theme/foundations";

const theme = {
  colors,
  ...typography,
  ...foundations,
};

export default theme;

export type Theme = typeof theme;
