import MessageMark from "@pages/MessageMark";
import MessageMarkList from "@pages/MessageMarkList";
import MyInfo_orderdetail from "@pages/MyInfo/MyInfo_orderdetail";
import MyInfo_orderlog from "@pages/MyInfo/MyInfo_orderlog";
import Profile from "@pages/Profile/Profile";
import { Profile_lecture_detail } from "@pages/Profile/Profile_lecture_detail";
import Profile_regi from "@pages/Profile/Profile_regi";
import { Reply } from "@pages/Reply/Reply";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { usePageVisibility } from "react-page-visibility";
import { Route, Routes, useLocation, useNavigate, useNavigationType } from "react-router";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { BookDetailPage } from "@pages/PdfDetail";

import { useLazyQuery } from "@apollo/client";
import LocalOauthPage from "@pages/auth/localOauth";
import TermsPage from "@pages/signUp/terms";
import { CommonContext, OpenMode, PaymentContext } from "@asset/context";
import ClassDetail from "@pages/Column/ClassDetail";
import LectureUpdate from "@pages/LectureRegist/LectureUpdate";
import LectureVideo from "@pages/LectureVideo";
import LectureVideoSearch from "@pages/LectureVideoSearch";
import MyInfo_infoModi from "@pages/MyInfo/MyInfo_infoModi";
import MyInfo_review from "@pages/MyInfo/MyInfo_review";
import MyInfo_ticketlog from "@pages/MyInfo/MyInfo_ticketlog";
import PDFdemo from "@pages/PDFdemo";
import Order from "@pages/Payment/Order";
import StudyBook from "@pages/StudyBook";
import StudyBookDetail from "@pages/StudyBookDetail";
import StudyBookFolder from "@pages/StudyBookFolder";
import DeactivateAccount from "@pages/before/register/DeactivateAccount";
import { CheckAcademyUsePaymentDocument } from "./types/graphql/graphql";
import { v4 as uuidv4 } from "uuid";
import "./App.css";
import Before from "./Before";
import type { BookDTO } from "./api/book/dtos";
import apiFn from "./asset/apiClass";
import constants from "./asset/constants";
import store from "./asset/store";
import util from "./asset/util";
import GlobalButtonClickListener from "./hooks/globalButtonClickListener";
import Board_write from "./pages/Board/Board_write";
import Book from "./pages/Book/Book";
import Index from "./pages/Column/Column_detail";
import Column_pick_list from "./pages/Column/Column_pick_list";
import Invite from "./pages/Invite/Invite";
import LectureRegist from "./pages/LectureRegist";
import LectureVideoList from "./pages/LectureVideoList";
import Message_book_list from "./pages/Message/Message_book_list";
import Message_talk from "./pages/Message/Message_talk";
import Message_teacher from "./pages/Message/Message_teacher";
import SelectLecture from "./pages/Message/SelectLecture";
import ViewQna from "./pages/Message/ViewQna";
import Myinfo_Main from "./pages/MyInfo/Main/Myinfo_Main";
import MyInfo_Profile from "./pages/MyInfo/MyInfo_Profile";
import Myinfo_Question from "./pages/MyInfo/Question/Myinfo_Question";
import PaymentMobileRedirect from "./pages/Payment/MobileRedirect";
import PdfView from "./pages/PdfView";
import People_Main from "./pages/People/People_Main";
import QNA from "./pages/QNA/QNA";
import QnaRegi from "./pages/QNA/QnaRegi";
import Post_Search from "./pages/Search/Post_Search";
import QuestionBookSearch from "./pages/Search/QuestionBookSearch";
import QuestionImageSearch from "./pages/Search/QuestionImageSearch";
import Search from "./pages/Search/Search";
import Tutorial from "./pages/Tutorial";
import Video from "./pages/Video/Video";
import Warn from "./pages/Warn/Warn";
import Youtube from "./pages/Youtube";
import AuthPage from "./pages/auth";
import ContactUs from "./pages/before/ContactUs";
import { Academy, Message } from "./pages/page_index";
import SignInPage from "./pages/signIn";
import SignUp from "./pages/signUp";
import SignUpAccountInfo from "./pages/signUp/accountInfo"; // TODO: Add SDKs for Firebase products that you want to use
import SignUpTypePage from "./pages/signUp/type";
import { SocketProvider } from "./providers/socket";
import "./stylesheet/latex.scss";
import Apps from "./uiComponents/UiCrop/App";
import UiLoading from "./uiComponents/UiLoading/UiLoading"; // Import the functions you need from the SDKs you need
import UiNoticeDetail from "./uiComponents/UiNotice/UiNoticeDetail";
import Lecture from "@pages/lecture";
import LectureSelectPage from "@pages/lecture/select";
import "react-loading-skeleton/dist/skeleton.css";
import { useMyDefaultAcademy } from "@api/academy/useMyDefaultAcademy";
import LinkExistingAccountPage from "@pages/auth/linkExistingAccount";
import { useGetProfile } from "@hooks/queries/account/useProfile";
import { ACCOUNT_TYPE_ENUM } from "@enum";
import { useCheckMainAccess } from "./page/account/checkMainAccess/hook/useCheckMainAccess";
import { tokenManager } from "./shared/api/api-gateway/token/token.manager";
import { TokenNameEnum } from "./shared/api/api-gateway/token/enum/token.enum";
import { ACADEMY_METADATA } from "./common/constants";

let SentryRoutes = Routes;

function App() {
  store.use("user", {});

  const useLoca = useLocation();

  const [isSide, setIsSide] = useState(false);

  const [isSR_detail, setIsSR_detail] = useState(false);
  const [solutionList, setSolutionList] = useState(null);
  const [ori_data, setOri_data] = useState({});

  // scroll key
  const [scrollKey, setScrolllKey] = useState("");

  const [isLoading, setIsLoading] = useState(false); // 0913 messageList 관련 임시 주석 true -> false
  const [isBlock, setIsBlock] = useState(false);
  const [isDetail, setIsDetail] = useState(false);

  // senderInfo
  const [senderInfo, setSenderInfo] = useState({});

  // video Info
  const [videoInfo, setVideoInfo] = useState({});

  //youtube url
  const [youtube_url, setYoutube_url] = useState("");

  // oriQnaRoom Data
  const [oriQnaRoom, setOriQnaRoom] = useState("");

  // create Data Obj
  const [createObj, setCreateObj] = useState({});

  const [userInfo, setUserInfo] = useState<Record<string, string>>({});

  // Qna Data
  const [qnaData, setQnaData] = useState({});

  // Add Qna Search Data
  const [addSearch, setAddSearch] = useState(null);

  // Home message
  const [messageList, setMessageList] = useState([]);

  // hiddenNav
  const [hiddenNav, setHiddenNav] = useState("");

  // hidden url
  const [hiddenUrl, setHiddenUrl] = useState("");

  // Board Write Object
  const [boardObj, setBoardObj] = useState({
    mosaic: "y",
  });

  const [clipData, setClipData] = useState({});

  // Board Write Cat Obj
  const [cat, setCat] = useState({
    id: "66", // notice Cat id
  });

  // Board Title Temp Data
  const [tempTitleData, setTempTitleData] = useState({
    전체: {
      name: "전체",
      id: 0,
    },
  });

  //
  const [explainData, setExplainData] = useState([]);
  const [explainPage, setExplainPage] = useState(1);

  // File Obj
  const [fileObj, setFileObj] = useState({});

  // 폴더 공유 교재 리스트
  const [s_bookList, setS_BookList] = useState([]);

  // message_home 교재 분류 아이디
  const [lectureId, setLectureId] = useState("");

  // List
  const [listStat, setListStat] = useState(null);

  // id 저장
  const [id_set, setId_set] = useState("");

  // 삭제된 질문 페이지 교재 id
  const [del_bookdId, setDel_bookId] = useState(null);

  const getStorage = () => {
    if (window.localStorage.getItem("user")) {
      let _userInfo = JSON.parse(window.localStorage.getItem("user"));
      setUserInfo({
        ..._userInfo,
      });
    }
  };

  const [isFull, setIsFull] = useState(false);

  const [isReconnect, setIsReconnect] = useState(false);

  // 질문권/결제 모달
  const [isPaymentModalOpen, setPaymentModalOpen] = useState(false);
  const [ticketInventories, setTicketInventories] = useState([]);
  const [updatedAt, setForceUpdate] = useState(new Date().getTime());
  const [tickets, setTickets] = useState([]);
  const [paymentOpen, setPaymentOpen] = useState<OpenMode>("none");
  const [pickResult, setPickResult] = useState<boolean | string>(false);
  const [isSelectTeacherModalOpen, setIsSelectTeacherModalOpen] = useState<boolean>(false);
  const [selectedLectureId, setSelectedLectureId] = useState(null);
  const [selectedTeacherId, setSelectedTeacherId] = useState("");
  const [defaultAcademyId, setDefaultAcademyId] = useState("");
  const [bookInQuestionList, setBookInQuestionList] = useState<BookDTO[]>(null);

  const requestPickTicket = useCallback(() => {
    if (paymentOpen != "none") {
      setPickResult(false);
      setPaymentOpen("select");
      setPaymentModalOpen(true);
    } else {
      // 학원에서 결제를 사용하지 않을 때, 기본 질문권을 사용
      setPickResult("BASIC");
    }
  }, [paymentOpen]);

  // 학원 id 저장
  const [academyId, setAcademyId] = useState<string>("");
  const { data: defaultAcademyIdData } = useMyDefaultAcademy();
  const classroomId = constants.classroomId;

  useEffect(() => {
    if (!classroomId) {
      if (!defaultAcademyIdData) {
        return;
      }
      setDefaultAcademyId(defaultAcademyIdData.getDefaultAcademy?.academy?.id);
      setAcademyId(defaultAcademyIdData.getDefaultAcademy?.academy?.id);
    } else {
      setAcademyId(String(classroomId));
    }
  }, [defaultAcademyIdData, userInfo]);

  const [checkAcademyUsePayment, { data: academyCheckData }] = useLazyQuery(
    CheckAcademyUsePaymentDocument
  );

  useEffect(() => {
    if (academyId) {
      checkAcademyUsePayment({
        variables: { academyId },
        fetchPolicy: "network-only",
      });
    }
  }, [academyId]);

  let ws = useRef(null);
  const webSocketUrl = useRef(null);
  const isVisible = usePageVisibility();

  const stack = store.use("stack", []);
  const { pathname } = useLocation();

  const windowAny = window as any;
  const documentAny = document as any;
  const type = useNavigationType();

  useEffect(() => {
    if (type === "POP") {
      store.set("stack", stack.slice(0, stack.length - 1));
    } else if (type === "PUSH") {
      store.set("stack", [...stack, pathname]);
      if (!pathname.includes("/talk")) {
        setT_page(1);
        setMessage_page(1);
      }
    } else {
      store.set("stack", [...stack.slice(0, stack.length - 1), pathname]);
    }
  }, [pathname, type]);

  const fn = async ({ api_ = async () => {} }) => {
    setIsBlock(true);
    try {
      await api_();
    } catch (e) {
    } finally {
      setIsBlock(false);
    }
  };

  useEffect(() => {
    try {
      if (isVisible) {
        connectWs();
      } else {
        setIsReconnect(false);
        ws.current.close();

        ws.current = null;
      }
    } catch (e) {}
  }, [isVisible]);

  const connectWs = () => {
    try {
      if (!userInfo["id"]) return;
      if (!webSocketUrl.current) webSocketUrl.current = constants.wsurl + "/" + userInfo["id"];
      if (ws.current && ws.current.readyState < 3) return;
      ws.current = new WebSocket(webSocketUrl.current);
      ws.current.onopen = () => {};
      ws.current.onclose = (error) => {
        if (isReconnect) {
          const reconnectDelay = Math.floor(Math.random() * 5000) + 1000; // 1 to 5 seconds
          setTimeout(() => connectWs(), reconnectDelay);
          connectWs();
        } else {
          ws.current = null;
        }
        if (!userInfo["id"]) {
          return;
        }
        if (error.code == 1006) {
          setTimeout(() => connectWs(), 1000);
        }
        setIsReconnect(false);
      };
      ws.current.onerror = (error) => {
        setIsReconnect(false);
      };
      ws.current.onmessage = (evt) => {
        if (!window.location.pathname.includes("talk")) {
          window.scrollTo(0, 0);
          let chk;
          if (window.localStorage.getItem("msg_home")) {
            chk = JSON.parse(window.localStorage.getItem("msg_home"));
          }
          if (window.location.pathname.includes("student")) {
            if (chk) {
              apiPro_counts("", 1, false, true);
            } else {
              apiPro_counts("", 1, false, false);
            }
          } else {
            if (chk) {
              apiPro_counts("Y", 1);
            } else {
              apiPro_counts("", 1);
            }
          }
        }
      };
    } catch (e) {
      console.error("connectFail", e);
    }
  };

  const [message_page, setMessage_page] = useState(1);
  const [t_page, setT_page] = useState(0);
  const [tcnt, setTcnt] = useState(0);
  const apiPro_counts = async (chk, page, isChk?, isConfirm?, cid?, listStat?) => {
    try {
      let amount = 20;

      let pass = true;
      if (messageList.length > 0) {
        if (page) {
          if (tcnt <= page * amount) {
            pass = false;
          } else {
            pass = true;
          }
        } else {
          if (message_page) {
            if (tcnt <= (message_page - 1) * amount) {
              pass = false;
            } else {
              pass = true;
            }
          }
        }
      } else {
        setIsLoading(true);
      }

      if (isChk) {
        pass = true;
        page = 1;
      }
      if (t_page > 0) {
        pass = true;
        setT_page(0);
      }

      if (!pass) return;

      let _obj = {
        cat: "hiq",
        cmd: "newMessageList",
        classroomId: constants.classroomId,
        id: JSON.parse(window.localStorage.getItem("user"))?.id,
        sort: "reg_date desc",
        type: JSON.parse(window.localStorage.getItem("user"))?.type,
        isConfirm: window.location.pathname.includes("student") ? (isConfirm ? "Y" : "") : "",
        stat: listStat ? listStat : window.localStorage.getItem("msg_home_section"),
        isLive: chk === "Y" ? "Y" : "",
        isHide: window.localStorage.getItem("msg_home_section")
          ? window.localStorage.getItem("msg_home_section") == "d"
            ? "Y"
            : ""
          : "",
        cid: cid
          ? cid === "all"
            ? ""
            : cid
          : window.localStorage.getItem("msg_home_lc")
            ? window.localStorage.getItem("msg_home_lc") === "all"
              ? ""
              : window.localStorage.getItem("msg_home_lc")
            : "",
        page: 1,
        amount: page ? Number(page) * amount : Number(message_page) * amount,
      };
      let { list, tcnt: newTcnt } = await store.axiosPost(_obj);
      setIsLoading(false);

      setTcnt(newTcnt);

      setMessage_page((prevState) => prevState + 1);
      setMessageList(list);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  let navigate = useNavigate();

  useEffect(() => {
    document.addEventListener("message", async (event) => {
      const e = event as any;
      if (e.data == "background") {
        setIsReconnect(false);
        ws.current?.close();

        ws.current = null;
      }
    });
    window.addEventListener("beforeunload", (e) => {
      setIsReconnect(false);
      ws.current?.close();
      ws.current = null;
    });
    if (windowAny.ReactNativeWebView) {
      if (util.chk_platform() == "ios") {
        window.addEventListener("message", async (e) => {
          try {
            let data = JSON.parse(e.data);

            let isOk = await apiFn.providerSignIn({
              state: {
                apple: {
                  id: data["user"],
                },
              },
            });
            if (isOk.status === -1) {
              //TODO:회원 가입 페이지로 이동
              // navigate("/regiIntro/apple");
            } else {
              try {
                let isOk_login = await apiFn.fnNewSignIn({
                  state: {
                    id: isOk["id"],
                    pw: data["user"],
                  },
                });
                setUserInfo({
                  ...isOk_login,
                });
                if (isOk_login["type"] == 1) {
                  navigate(`/message/student/home`);
                } else {
                  navigate(`/message/teacher/home`);
                }
              } catch (e) {
                console.error(e);
                alert("로그인 실패했습니다.");
                util.removeLocalStorage();
                navigate("/", { replace: true });
              }
            }
          } catch (e) {}
        });
      } else {
      }
    }
    // 웹으로 들어갔을 때, isDev=true 일때, 관리자 페이지
    // 가 아니라 hiqsum deeplink 시도, 안 열면 설치 페이지 유도
    else if (!constants.isDev) {
      const redirectApp = () => {
        console.log(useLoca.pathname);
        location.href = process.env.REACT_APP_DEEP_LINK + useLoca.pathname;
        checkInstallApp();
      };

      function checkInstallApp() {
        function clearTimers() {
          clearInterval(check);
          clearTimeout(timer);
        }

        function isHideWeb() {
          if (documentAny.webkitHidden || document.hidden) {
            clearTimers();
          }
        }

        const check = setInterval(isHideWeb, 2000);

        const timer = setTimeout(function () {
          redirectStore();
        }, 5000);
      }

      const redirectStore = () => {
        const ua = navigator.userAgent.toLowerCase();

        // 모바일 장치인지 확인
        if (ua.includes("mobile") || ua.includes("android") || ua.includes("iphone")) {
          if (window.confirm("스토어로 이동하시겠습니까?")) {
            location.href =
              ua.indexOf("android") > -1
                ? process.env.REACT_APP_PLAY_STORE
                : process.env.REACT_APP_APP_STORE;
          }
        } else {
          // PC 브라우저인 경우 실행할 코드
        }
      };

      redirectApp();
    }

    setUserInfo({});
    util.c_log("*** APP.js ***");

    getStorage();

    store.set("stack", []);
  }, []);

  useEffect(() => {
    try {
      if (useLoca["pathname"].includes("/message")) {
        if (useLoca["pathname"].includes("/mark")) {
          setIsLoading(false);
        }
      }
      windowAny.ReactNativeWebView.postMessage(useLoca["pathname"]);
      windowAny.ReactNativeWebView.postMessage(`id:${userInfo["id"]}&${useLoca["pathname"]}`);
    } catch (e) {}
  }, [useLoca, userInfo]);

  useEffect(() => {
    if (!ws.current) {
      connectWs();
    }
  }, [userInfo]);

  useEffect(() => {
    if (!userInfo) {
      navigate("/");
    }
  }, [userInfo]);
  const handleGlobalButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const uuid = uuidv4();
    const error = new Error();
    const stack = error.stack.split("\n").slice(1).join(";").trim();
    window.localStorage.setItem("clickContextId", JSON.stringify({ id: uuid, stack }));
  };
  const { refetch: refetchUserProfile } = useGetProfile();
  const { onCheckEnterMain } = useCheckMainAccess();

  useEffect(() => {
    const getAccount = async () => {
      const { data } = await refetchUserProfile();
      const enterApp = await onCheckEnterMain();
      if (enterApp) {
        const userType = data.type === ACCOUNT_TYPE_ENUM.STUDENT ? "student" : "teacher";
        navigate(`/message/${userType}/home`);
      } else {
        alert("접근 권한이 없습니다. 선생님께 문의해주세요.");
        navigate("/", { replace: true });
      }
    };
    //TODO 임시스~ Auth 더 나은 방법 찾기
    if (!pathname.includes("/auth")) {
      const hasRefreshToken = tokenManager.hasToken(TokenNameEnum.REFRESH_TOKEN_METADATA);
      if (!hasRefreshToken) {
        navigate("/", { replace: true });
        return;
      }
      getAccount();
    }
  }, []);

  useEffect(() => {
    document.title = ACADEMY_METADATA.ACADEMY_NAME;
  }, []);

  return (
    <PaymentContext.Provider
      value={{
        academyId: academyId,
        setAcademyId: setAcademyId,
        defaultAcademyId: defaultAcademyId,
        setDefaultAcademyId: setDefaultAcademyId,
        isPaymentModalOpen: isPaymentModalOpen,
        setPaymentModalOpen: setPaymentModalOpen,
        userInfo,
        inventories: ticketInventories,
        setInventories: setTicketInventories,
        tickets,
        setTickets,
        updatedAt,
        setForceUpdate,
        paymentOpen: paymentOpen,
        setPaymentOpen: setPaymentOpen,
        pickResult,
        setPickResult,
        requestPickTicket,
        isSelectTeacherModalOpen,
        setIsSelectTeacherModalOpen,
        selectedLectureId,
        setSelectedLectureId,
        selectedTeacherId,
        setSelectedTeacherId,
      }}
    >
      <SocketProvider userId={userInfo?.id}>
        <CommonContext.Provider
          value={{
            qnaData,
            setQnaData,
            fileObj,
            setFileObj,
            isFull,
            setIsFull,
            bookInQuestionList,
            setBookInQuestionList,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
            className={"mother_"}
          >
            <SentryRoutes>
              <Route path="/" element={<SignInPage />}>
                <Route path={"auth"} element={<AuthPage />} />
              </Route>
              <Route path={"/local-oauth"} element={<LocalOauthPage />} />
              <Route path={"/auth/link-existing-account"} element={<LinkExistingAccountPage />} />

              <Route path={"/sign-up"} element={<SignUp />}>
                <Route path={"type"} element={<SignUpTypePage />} />
                <Route path={"terms"} element={<TermsPage />} />
                <Route path={"account-info"} element={<SignUpAccountInfo />} />
              </Route>

              <Route path={"/lectures"} element={<Lecture />}>
                <Route path={"select"} element={<LectureSelectPage />} />
              </Route>

              <Route path="*" element={<Before setUserInfo={setUserInfo} />} />

              {/*학원 탭*/}
              <Route
                path="/people/:who/:where"
                element={
                  <People_Main
                    scrollKey={scrollKey}
                    setScrolllKey={setScrolllKey}
                    setIsLoading={setIsLoading}
                    setQnaData={setQnaData}
                    setHiddenUrl={setHiddenUrl}
                    setFileObj={setFileObj}
                  />
                }
              />
              {/*내 학원 관리 목록 페이지*/}
              <Route
                path="/academy/:who/:where"
                element={
                  <Academy
                    hiddenNav={hiddenNav}
                    setHiddenUrl={setHiddenUrl}
                    scrollKey={scrollKey}
                    setScrolllKey={setScrolllKey}
                    s_bookList={s_bookList}
                    setS_BookList={setS_BookList}
                    setCreateObj={setCreateObj}
                  />
                }
              />
              {/*어디서 들어가야하는지 찾지 못함*/}
              <Route
                path="/columndetail/:who/:id/:type"
                element={
                  <Index
                    qnaData={qnaData}
                    setQnaData={setQnaData}
                    setHiddenNav={setHiddenNav}
                    fileObj={fileObj}
                    setFileObj={setFileObj}
                  />
                }
              />
              {/*동영상 등 상세 페이지*/}
              <Route path="/classdetail/:who/:id/:type" element={<ClassDetail />} />
              {/*수업 영상에서 찜 하기 아이콘 클릭시 나오는 페이지*/}
              <Route path="/picklist/:who/:id" element={<Column_pick_list />} />

              {/* 메세지 상세 */}
              <Route
                path="/talk/:who/:where/:qid/:sid/:tid"
                element={
                  <Message_talk
                    clipData={clipData}
                    setClipData={setClipData}
                    isDetail={isDetail}
                    setIsDetail={setIsDetail}
                    isSide={isSide}
                    setIsSide={setIsSide}
                    messageList={messageList}
                    senderInfo={senderInfo}
                    setSenderInfo={setSenderInfo}
                    videoInfo={videoInfo}
                    setVideoInfo={setVideoInfo}
                    oriQnaRoom={oriQnaRoom}
                    setOriQnaRoom={setOriQnaRoom}
                    setQnaData_app={setQnaData}
                    setFileObj={setFileObj}
                    hiddenUrl={hiddenUrl}
                    setHiddenUrl={setHiddenUrl}
                    youtube_url={youtube_url}
                    setYoutube_url={setYoutube_url}
                    solutionList={solutionList}
                    setSolutionList={setSolutionList}
                    websocket={ws?.current}
                  />
                }
              />

              {/*이어 질문하기 클릭시 선생님 선택 화면*/}
              <Route
                path="/messageteacher/:who/:bid/:qid/:subs/:tid"
                element={<Message_teacher />}
              />
              {/*질문을 다른 교재로 옮길때 나오는 페이지*/}
              <Route path="/list/:who/:qid/:sid/:tid" element={<Message_book_list />} />
              {/*질문방 리스트 페이지*/}
              <Route
                path="/message/:who/:where"
                element={
                  <Message
                    hiddenUrl={undefined}
                    tcnt={tcnt}
                    setAddSearch={setAddSearch}
                    isSR_detail={isSR_detail}
                    setIsSR_detail={setIsSR_detail}
                    isDetail={isDetail}
                    setIsDetail={setIsDetail}
                    t_page={t_page}
                    setT_page={setT_page}
                    listStat={listStat}
                    setListStat={setListStat}
                    lectureId={lectureId}
                    setLectureId={setLectureId}
                    messageList={messageList}
                    setMessageList={setMessageList}
                    message_page={message_page}
                    setMessage_page={setMessage_page}
                    scrollKey={scrollKey}
                    setScrolllKey={setScrolllKey}
                    setQnaData={setQnaData}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                    createObj={createObj}
                    setCreateObj={setCreateObj}
                    apiPro_counts={apiPro_counts}
                    setFileObj={setFileObj}
                    fileObj={fileObj}
                    setHiddenUrl={setHiddenUrl}
                  />
                }
              />

              {/*오답 노트*/}
              <Route path="/oxNote" element={<MessageMark />} />
              {/*오답노트 폴터 상세*/}
              <Route path="/oxNote/:id" element={<MessageMarkList />} />
              {/*학원 상세 페이지*/}
              <Route
                path="/profile/:who/:id/:where"
                element={
                  <Profile
                    scrollKey={scrollKey}
                    setScrolllKey={setScrolllKey}
                    setFileObj={setFileObj}
                    fileObj={fileObj}
                    setHiddenUrl={setHiddenUrl}
                    setHiddenNav={setHiddenNav}
                    hiddenNav={hiddenNav}
                  />
                }
              />
              {/*학원 상세에서 학원 수정하는 페이지*/}
              <Route path="/profileregi/:who/:id/:form" element={<Profile_regi />} />
              {/*수업 상세페이지*/}
              <Route
                path="/lecture/detail/:who/:id/:where"
                element={
                  <Profile_lecture_detail
                    explainData={explainData}
                    setExplainData={setExplainData}
                    explainPage={explainPage}
                    setExplainPage={setExplainPage}
                    fn={fn}
                    hiddenUrl={hiddenUrl}
                    setHiddenNav={setHiddenNav}
                    setFileObj={setFileObj}
                    fileObj={fileObj}
                    hiddenNav={hiddenNav}
                    setQnaData={setQnaData}
                    setHiddenUrl={setHiddenUrl}
                    setAddSearch={setAddSearch}
                    setIsLoading={setIsLoading}
                    isLoading={isLoading}
                  />
                }
              />
              {/*수업 메임*/}
              <Route path="/lecture" element={<LectureVideo />} />
              {/*수업 메인에서 검색 아이콘 클릭*/}
              <Route path="/lecture/search" element={<LectureVideoSearch />} />
              {/*교재 상세 페이지*/}
              <Route
                path="/book/:who/:id/:cid/:where"
                element={
                  <Book
                    isFull={isFull}
                    setIsFull={setIsFull}
                    explainData={explainData}
                    setExplainData={setExplainData}
                    explainPage={explainPage}
                    setExplainPage={setExplainPage}
                    del_bookdId={del_bookdId}
                    setDel_bookId={setDel_bookId}
                    setFileObj={setFileObj}
                    fileObj={fileObj}
                    qnaData={qnaData}
                    setQnaData={setQnaData}
                    setHiddenUrl={setHiddenUrl}
                    setHiddenNav={setHiddenNav}
                    hiddenNav={hiddenNav}
                    setAddSearch={setAddSearch}
                  />
                }
              />
              {/*교재 메인 페이지*/}
              <Route path="/study-book" element={<StudyBook />} />
              {/*교재 메인 폴더 상세*/}
              <Route path="/study-book/folder/:folderId" element={<StudyBookFolder />} />
              {/*수업에 공유된 교재 클릭*/}
              <Route path="/study-book/:bookId" element={<StudyBookDetail />} />
              {/*교재 상세 아래쪽 버튼 들*/}
              <Route
                path="/explainmodel/:who/:id/:form/:type"
                element={
                  <QNA
                    explainPage={explainPage}
                    setExplainPage={setExplainPage}
                    qnaData={explainData}
                    setQnaData={setExplainData}
                    scrollKey={scrollKey}
                    setScrolllKey={setScrolllKey}
                    setHiddenUrl={setHiddenUrl}
                  />
                }
              />
              {/*벌크업 페이지*/}
              <Route
                path="/mobumregi/:who/:id/:form/:type"
                element={<QnaRegi setHiddenUrl={setHiddenUrl} />}
              />
              {/*동영상 댓글 수정 페이지*/}
              <Route path="/reply/:who/:id/:where" element={<Reply userInfo={userInfo} />} />
              {/*동영상 댓글 신고 페이지*/}
              <Route path="/warn/:who/:id/:where" element={<Warn userInfo={userInfo} />} />
              {/*공지사항, 등 board를 볼 수 있는 페이지*/}
              <Route
                path="/notice/:who/:id/:type"
                element={<UiNoticeDetail userInfo={userInfo} />}
              />
              {/*내정보 상세 페이지*/}
              <Route
                path="/myinfo/:who/:id"
                element={
                  <MyInfo_Profile
                    hiddenUrl={hiddenUrl}
                    setFileObj={setFileObj}
                    fileObj={fileObj}
                    hiddenNav={hiddenNav}
                    setHiddenUrl={setHiddenUrl}
                    setHiddenNav={setHiddenNav}
                    setQnaData={setQnaData}
                  />
                }
              />
              {/*리뷰 보는 페이지(내학원, 유저클릭, 리뷰보기)*/}
              <Route path="/myinforeview/:who" element={<MyInfo_review userInfo={userInfo} />} />
              {/*질문권 사용 내역 페이지*/}
              <Route
                path="/myinfo/ticketlog/:who/:id"
                element={<MyInfo_ticketlog userInfo={userInfo} />}
              />
              {/*질문권 주문 내역 페이지*/}
              <Route
                path="/myinfo/orders/:id"
                element={<MyInfo_orderlog userInfo={userInfo} isCancel={undefined} />}
              />
              {/*질문권 주문 내역 상세 페이지*/}
              <Route
                path="/myinfo/order/:id"
                element={<MyInfo_orderdetail userInfo={userInfo} />}
              />
              {/*내정보 메인 페이지*/}
              <Route path="/myinfo" element={<Myinfo_Main />} />
              {/*내정보 질문 답변 관리 페이지*/}
              <Route path="/myinfo/question" element={<Myinfo_Question />} />
              {/*회원 탈퇴 페이지*/}
              <Route path="/myinfom/:who/:form" element={<MyInfo_infoModi userInfo={userInfo} />} />
              {/*문제 검색 결과 페이지*/}
              <Route
                path="/searchresult/:who/:form/:type"
                element={
                  <Search
                    solutionList={solutionList}
                    setSolutionList={setSolutionList}
                    isSR_detail={isSR_detail}
                    setIsSR_detail={setIsSR_detail}
                    scrollKey={scrollKey}
                    setScrolllKey={setScrolllKey}
                    addSearch={addSearch}
                    qnaData={qnaData}
                    setQnaData={setQnaData}
                    fileObj={fileObj}
                    setFileobj={setFileObj}
                    hiddenUrl={hiddenUrl}
                    setHiddenUrl={setHiddenUrl}
                  />
                }
              />
              {/*과거 질문 검색 결과 페이지*/}
              <Route
                path="/postsearchresult/:who/:qid"
                element={
                  <Post_Search
                    ori_data={ori_data}
                    setOri_data={setOri_data}
                    isSR_detail={isSR_detail}
                    setIsSR_detail={setIsSR_detail}
                    solutionList={solutionList}
                    setSolutionList={setSolutionList}
                    setScrolllKey={setScrolllKey}
                    qnaData={qnaData}
                    setHiddenUrl={setHiddenUrl}
                    oriQnaRoom={oriQnaRoom}
                  />
                }
              />
              {/*내학원 관리-> 학원 -> 강사진 -> 선생님 초대하기 */}
              <Route
                path="/invite/:who/:form/:type/:key"
                element={
                  <Invite fn={fn} userInfo={userInfo} id_set={id_set} setId_set={setId_set} />
                }
              />
              {/*보드 작성 페이지*/}
              <Route
                path="/write/:who/:type/:id/:where/:modi"
                element={
                  <Board_write
                    userInfo={userInfo}
                    setFileObj={setFileObj}
                    fileObj={fileObj}
                    boardObj={boardObj}
                    setBoardObj={setBoardObj}
                    setHiddenUrl={setHiddenUrl}
                    tempTitleData={tempTitleData}
                    setTempTitleData={setTempTitleData}
                    cat={cat}
                    setCat={setCat}
                  />
                }
              />
              {/*영상 답변 등록 페이지*/}
              <Route
                path="/video/:who/:type"
                element={
                  <Video
                    userInfo={userInfo}
                    setFileObj={setFileObj}
                    fileObj={fileObj}
                    boardObj={boardObj}
                    setBoardObj={setBoardObj}
                    hiddenUrl={hiddenUrl}
                  />
                }
              />
              {/*문제 크롭 페이지*/}
              <Route
                path="/crop/:who/:type"
                element={
                  <Apps
                    setIsCrop={false}
                    setFileObj={setFileObj}
                    fileObj={fileObj}
                    qnaData={qnaData}
                    addSearch={addSearch}
                  />
                }
              />
              {/*문제, 답변 등 확대해서 보는 페이지*/}
              <Route
                path="/view/:who/:qid/:type/:mo/:tid"
                element={
                  <ViewQna
                    setHiddenUrl={setHiddenUrl}
                    senderInfo={senderInfo}
                    setFileObj={setFileObj}
                    setSenderInfo={setSenderInfo}
                    fileObj={fileObj}
                    userInfo={userInfo}
                    youtube_url={youtube_url}
                    videoInfo={videoInfo}
                    qnaData_app={qnaData}
                  />
                }
              />
              {/*유튜브 기출 페이지 (학생)*/}
              <Route
                path="/youtube/:who/:where"
                element={
                  <Youtube
                    userInfo={userInfo}
                    setScrolllKey={setScrolllKey}
                    scrollKey={scrollKey}
                  />
                }
              />
              {/*선생님일때 메세지 홈에서 물음표버튼 클릭시 이동하는 튜토리얼 페이지*/}
              <Route
                path="/tutorial"
                element={
                  <Tutorial
                    onClose={() => {
                      localStorage.setItem("showTutorial", JSON.stringify(false));
                      if (stack.length > 0) navigate(-1);
                      else navigate("/", { replace: true });
                    }}
                  />
                }
              />
              {/*수업 영상 전체보기 페이지*/}
              <Route path="/class/video/list" element={<LectureVideoList />} />
              {/*수업영상 업로드 페이지*/}
              <Route path="/class/:cid/video/regist" element={<LectureRegist />} />
              {/*수업 영상 수정 페이지*/}
              <Route path="/class/:cid/video/update/:boardId" element={<LectureUpdate />} />
              {/*문제 검색 pdf 교재 상세 페이지*/}
              <Route path={"/pdfView/:bookId"} element={<PdfView />} />
              {/*문의하기 안 회원탈퇴 페이지*/}
              <Route
                path="/deactivate-account"
                element={<DeactivateAccount setUserInfo={setUserInfo} />}
              />
              {/*문의하기 페이지*/}
              <Route path={"/contactUs"} element={<ContactUs />} />
              {/*결재 페이지*/}
              <Route path="/order" element={<Order />} />
              {/*결재 관련*/}
              <Route path="/payment/callback" element={<PaymentMobileRedirect />} />
              {/*이미지 검색 페이지*/}
              <Route
                path="/search/question/image"
                element={
                  <QuestionImageSearch
                    ori_data={ori_data}
                    setHiddenUrl={setHiddenUrl}
                    setIsSR_detail={setIsSR_detail}
                    setScrolllKey={setScrolllKey}
                  />
                }
              />
              {/*교재내 검색 페이지*/}
              <Route
                path={"/search/question/book/:bookId/:searchType"}
                element={
                  <QuestionBookSearch
                    ori_data={ori_data}
                    setHiddenUrl={setHiddenUrl}
                    setIsSR_detail={setIsSR_detail}
                  />
                }
              />
              {/*학원캡에서 선생님을 클릭후 질문하기 눌렀을때 나오는 페이지*/}
              <Route
                path="/searchresult/student/student/p/:teacherId/:academyId"
                element={<SelectLecture setFileObj={setFileObj} setQnaData={setQnaData} />}
              />
              {/*메인에서 질문하기를 클릭하면 나오는 페이지*/}
              <Route
                path="/searchresult/student/student/p"
                element={<SelectLecture setFileObj={setFileObj} setQnaData={setQnaData} />}
              />
              {/*pdf 교재 상세페이지*/}
              <Route path="/book/:bookId" element={<BookDetailPage />} />
              {/*pdf demo 페이지*/}
              <Route path="/bookDemo" element={<PDFdemo />} />
            </SentryRoutes>
            {isBlock && <UiLoading isTransparent={true} isText={false} isCircle={false} text="" />}
            {/* <CommonUploadInfo /> */}
          </div>
          <GlobalButtonClickListener onClick={handleGlobalButtonClick} />
        </CommonContext.Provider>
      </SocketProvider>
    </PaymentContext.Provider>
  );
}

export default App;
