import { IAcademyInfo } from "@entities";
import { IAcademyRepository } from "@interfaces";
import { axiosInstanceWithAuth } from "@infrastructure/api";
import { CONFIG } from "@config";

class AcademyRepositoryImpl implements IAcademyRepository {
  constructor() {}
  async getAcademyInfo(academyCode: number | string): Promise<IAcademyInfo> {
    try {
      const response = await axiosInstanceWithAuth.get(
        CONFIG.API.API_ENDPOINTS.ACADEMY.GET_ACADEMY_INFO(academyCode)
      );
      return response.data;
    } catch (e) {
      console.error(`Error in getAcademyInfo: ${e}`);
      throw new Error(`Error in getAcademyInfo: ${e}`);
    }
  }
  async setAcademyRequest(academyCode: number | string) {
    try {
      const response = await axiosInstanceWithAuth.post(
        CONFIG.API.API_ENDPOINTS.ACADEMY.SET_ACADEMY_REQUEST(academyCode),
        {}
      );
      return response.data;
    } catch (e) {
      console.error(`Error in setAcademyRequest: ${e}`);
      throw new Error(`Error in setAcademyRequest: ${e}`);
    }
  }
  async getAcademyLectureRelated(academyCode: number | string) {
    try {
      const response = await axiosInstanceWithAuth.get(
        CONFIG.API.API_ENDPOINTS.ACADEMY.GET_ACADEMY_LECTURE_RELATED(academyCode)
      );
      return response.data;
    } catch (e) {
      console.error(`Error in getAcademyLectureRelated: ${e}`);
      throw new Error(`Error in getAcademyLectureRelated: ${e}`);
    }
  }
}

export const academyRepository = new AcademyRepositoryImpl();
