import style from "../../asset/style";

const Academy_book_Style = {
  t_w: {
    display: "flex",
    paddingBottom: "17px",
    // justifyContent:"center",
  },
  edit_w: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    height: "50px",
    right: 10,
    top: 3,
    fontSize: "14px",
    color: style.common.t_color,
    fontWeight: "bold",
  },
  edit_section_w: {
    // ...Academy_book_Style.t_c,
    // ...Academy_book_Style.t_c_add,
    display: "flex",
    width: "100%",
    padding: "12px 0px 15px 0px",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: style.common.select_fontSize,
  },
  btn_w: {
    width: "140px",
    margin: "0px 5px",
  },
  btn: {
    borderRadius: 20,
    height: "30px",
    padding: "18px 10px",
    fontSize: "14px",
    // alignItems : 'center',
    // backgroundColor:"red",
  },
  btn_title: {
    // backgroundColor:"green",
    width: "100%",
    display: "flex",
    height: "100%",
    padding: 0,
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  btn_Add: {
    position: "absolute",
    left: 0,
    // marginRight : "20px"
  },
  textBtn_w: {
    display: "flex",
    justifyContent: "space-between",
    // backgroundColor : "red",
    paddingRight: "10px",
  },
  textBtn: {
    color: style.common.fontColor_3,
    fontSize: style.common.select_fontSize,
  },
  empty_w: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "14px",
    fontWeight: "bold",
    color: style.common.fontColor_1,
    //  width:"100%",
    height: "100%",
    marginTop: "30%",
  },
  empty_i: {
    // height : "60%",
    width: "60%",
  },
  empty_desc: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "10px",
  },
  list_folder_w: {
    display: "flex",
    paddingBottom: "17px",
    // justifyContent:"space-between",
    flexWrap: "wrap",
  },
  list_book_w: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",

    // justifyContent:"space-between",
  },
};

export default Academy_book_Style;
// 20
