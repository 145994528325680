import React from "react";
import { CONFIG } from "@config";
import SignInView from "@presentation/views/signInView";
import { useNavigate } from "react-router";
import { ILocalAuthItem } from "@entities";
import { useLocalAuthToResponse } from "../../page/auth/localAuth/hook/useLocalAuthToResponse";
import { urlManager } from "../../common/util/url/url.manager";

enum LoginType {
  KAKAO = "kakao",
  APPLE = "apple",
  NAVER = "naver",
  LOCAL_OAUTH = "localOauth",
}

export default function SignInPage() {
  const { Kakao, AppleID } = window;
  const currentUrl = urlManager.getOriginWithFirstPathSegment();
  const apiBaseUrl = CONFIG.API.EXTERNAL_URL.API_BASE_URL;
  const appleClientId = CONFIG.APP_ENV.APPLE_CLIENT_ID;
  const naverClientId = CONFIG.APP_ENV.NAVER_CLIENT_ID;
  const state = encodeURIComponent(
    `redirect_to=${currentUrl}/auth;request_at=${new Date().getTime()}`
  );
  const { mutateAsync } = useLocalAuthToResponse();
  let navigate = useNavigate();

  const handleKakaoLogin = () => {
    Kakao.Auth.authorize({
      redirectUri: `${apiBaseUrl}/auth/login/kakao_redirect`,
      nonce: Math.random().toString(36).substring(2, 15),
      state,
    });
  };

  const handleAppleLogin = () => {
    AppleID.auth.init({
      clientId: appleClientId,
      scope: "name email",
      redirectURI: `${apiBaseUrl}/auth/login/apple_redirect`,
      state,
      usePopup: false,
    });
    AppleID.auth.signIn();
  };

  const handleNaverLogin = () => {
    const redirectUri = `${apiBaseUrl}/auth/login/naver_redirect`;
    window.location.href = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${naverClientId}&redirect_uri=${redirectUri}&state=${state}`;
  };

  const handleTestAccountLogin = (account: string) => {
    const accountInfo: ILocalAuthItem = JSON.parse(account);
    mutateAsync({ email: accountInfo.email, identity: accountInfo.identity });
  };

  const handleLoginButton = (loginType: string) => {
    switch (loginType) {
      case LoginType.KAKAO:
        handleKakaoLogin();
        break;
      case LoginType.APPLE:
        handleAppleLogin();
        break;
      case LoginType.NAVER:
        handleNaverLogin();
        break;
      case LoginType.LOCAL_OAUTH:
        navigate("/local-oauth");
        break;
      default:
        handleTestAccountLogin(loginType);
        break;
    }
  };

  return <SignInView onLogin={handleLoginButton} />;
}
