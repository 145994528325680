import styled from "styled-components";

export const ContentSection = styled.section`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm};
  margin-top: ${({ theme }) => theme.spacing.md};
  overflow: scroll;
`;

export const ActionSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xs};
`;

export const ContentBox = styled.div`
  flex: 1;
  overflow: scroll;
`;

export const ButtonBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing.sm};
`;
