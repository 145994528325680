import { axiosInstanceWithOutAuth } from "@infrastructure/api";
import { ILocalAuthToResponseRequest } from "./type";

export const localAuthToResponseApi = async (
  localAuthRequest: ILocalAuthToResponseRequest
): Promise<any> => {
  try {
    const response = await axiosInstanceWithOutAuth.post(
      "/auth/login/local_auth_to_response",
      localAuthRequest,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error: any) {
    throw new Error(`Error in localAuthToResponseApi: ${error.message}`);
  }
};
