import { IAccessRule } from "../interface/academyEnter.interface";
import { CheckMainAccessUseCase } from "./checkMainAccess.useCase";

export class CheckMainAccessBuilderUseCase {
  private rules: IAccessRule[] = [];

  addRule(rule: IAccessRule): CheckMainAccessBuilderUseCase {
    this.rules.push(rule);
    return this;
  }
  build(): CheckMainAccessUseCase {
    return new CheckMainAccessUseCase(this.rules);
  }
}
