import type { IcDefatultProps } from "../types";
import React from "react";

export function IcArrowDown({ size = 24, color = "#434343" }: IcDefatultProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 -960 960 960"
      width={size}
      fill={color}
    >
      <path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z" />
    </svg>
  );
}
