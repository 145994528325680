import React from "react";
import { useGetColor } from "@application/hooks/theme/useGetColor";
import { ButtonProps } from "@presentation/components/common/atoms/button/button.types";
import { StyledButton } from "@presentation/components/common/atoms/button/button.styles";

export function Button({
  variant = "filled",
  size = "medium",
  disabled = false,
  fullWidth = true,
  children,
  onClick,
  backgroundColor = null,
  color = null,
  ...props
}: ButtonProps): JSX.Element {
  const { getColor } = useGetColor();

  const processedBackgroundColor = backgroundColor ? getColor(backgroundColor) : null;
  const processedColor = color ? getColor(color) : null;

  return (
    <StyledButton
      variant={variant}
      size={size}
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={onClick}
      $processedBackgroundColor={processedBackgroundColor}
      $processedColor={processedColor}
      {...props}
    >
      {children}
    </StyledButton>
  );
}
