import React from "react";
import styled, { css } from "styled-components";

export interface DividerProps {
  color?: string;
  thickness?: string;
  margin?: string;
  orientation?: "horizontal" | "vertical";
}

const StyledDivider = styled.hr<DividerProps>`
  background-color: ${({ theme, color }) => color || theme.colors[color] || "#ccc"};
  border: none;
  margin: ${({ margin }) => margin || "16px 0"};

  ${({ orientation, thickness }) =>
    orientation === "vertical"
      ? css`
          width: ${thickness || "1px"};
          height: 100%;
        `
      : css`
          height: ${thickness || "1px"};
          width: 100%;
        `}
`;

const Divider: React.FC<DividerProps> = ({
  color,
  thickness,
  margin,
  orientation = "horizontal",
}) => {
  return (
    <StyledDivider
      as={orientation === "vertical" ? "div" : "hr"}
      color={color}
      thickness={thickness}
      margin={margin}
      orientation={orientation}
    />
  );
};

export default Divider;
