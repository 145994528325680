import { IAcademyRepository } from "../interface/academy-repository.interface";
import { getAcademyInfoApi } from "../../../../shared/api/api-gateway/academy/academy_code/info";
import { getAcademyLecturesRelatedApi } from "../../../../shared/api/api-gateway/academy/academy_code/lectures/related";

class AcademyRepository implements IAcademyRepository {
  constructor() {}

  async getAcademyInfo(academyCode: string) {
    return await getAcademyInfoApi(academyCode);
  }
  async getLecturesRelatedToAcademy(academyCode: string, page?: number, limit?: number) {
    const academyLecturesParams = {
      academyCode,
      page: page ?? 1,
      limit: limit ?? 100,
    };
    return await getAcademyLecturesRelatedApi(academyLecturesParams);
  }
}

export const academyRepository = new AcademyRepository();
