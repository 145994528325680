import axios from "axios";
import deepmerge from "deepmerge";
import { useState } from "react";
import { createStore } from "redux";
import constants from "./constants";
// import AWS from 'aws-sdk'
import apiFn from "./apiClass";
import util from "./util";

// import XLSX from 'xlsx'
// import apiFn from './apiClass';
// import apiCheck from './apiCheck'
// import constants from './constants';

// var crypto = require('crypto')

var cr, us;
cr = createStore;
us = useState;

const ENCRYPTION_KEY = "FQCr89PwkJyejFVoYMUIHqqn7ehIY1zr";
const IV_LENGTH = 16;

var singletone;

function storeClass() {
  if (singletone != null) return singletone;
  let store = cr((prevState = {}, obj) => {
    if (obj.type == null) return prevState;
    else return Object.assign({}, prevState, obj.arg);
  });

  this.convertUrl = (text) => {
    try {
      return text.replace(/\?/gi, "%3F").replace(/[&]/gi, "%26").replace(/[/]/gi, "%2F");
    } catch (e) {}
  };
  this.invertUrl = (text) => {
    try {
      return text.replace(/\%3f/gi, "?").replace(/\%26/gi, "&").replace(/\%2F/gi, "/");
    } catch (e) {}
  };

  this.encryptData = (data) => {
    try {
      // return crypto.createHash('sha256').update(data).digest('base64')
      const iv = crypto.randomBytes(IV_LENGTH);
      const cipher = crypto.createCipheriv("aes-256-cbc", Buffer.from(ENCRYPTION_KEY), iv);
      const encrypted = cipher.update(data);

      return iv.toString("hex") + ":" + Buffer.concat([encrypted, cipher.final()]).toString("hex");
    } catch (e) {
      console.log("encryptData Error => ", e);
    }
  };
  this.decryptData = (data) => {
    try {
      const textParts = data.split(":");
      const iv = Buffer.from(textParts.shift(), "hex");
      const encryptedText = Buffer.from(textParts.join(":"), "hex");
      const decipher = crypto.createDecipheriv("aes-256-cbc", Buffer.from(ENCRYPTION_KEY), iv);
      const decrypted = decipher.update(encryptedText);

      return Buffer.concat([decrypted, decipher.final()]).toString();
    } catch (e) {
      console.log("decryptData Error => ", e);
    }
  };

  this.get = (key) => store.getState()[key];

  this.set = (key, val) => {
    const dic = {};
    dic[key] = val;
    store.dispatch({ type: key, arg: dic });
  };

  this.merge = (key, val) => {
    const dic = {};
    dic[key] = deepmerge(this.get(key), val);
    store.dispatch({ type: key, arg: dic });
  };

  this.use = (key, val) => {
    if (this.get(key) == null) {
      this.set(key, val == null ? 0 : val);
    }
    const [a, setA] = us(this.get(key));
    store.subscribe(() => setA(this.get(key)));
    return a;
  };
  this.getUrl = (url) =>
    new Promise((r, j) => {
      let xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== 4) return;
        if (xhr.status === 200) r(xhr.responseText);
        else j(new Error(xhr.statusText));
      };
      xhr.send();
    });
  this.postUrl = (url, data) =>
    new Promise((r, j) => {
      let xhr = new XMLHttpRequest();
      xhr.open("POST", url, true);
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== 4) return;
        if (xhr.status === 200) r(xhr.responseText);
        else j(new Error(xhr.statusText));
      };
      xhr.send(JSON.stringify(data));
    });

  this.priceComma = (price) => {
    try {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "원";
    } catch (e) {
      //console.log('Price Comma Error => ',e)
    }
  };

  this.priceComma_default = (price) => {
    try {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } catch (e) {
      //console.log('Price Comma Error => ',e)
    }
  };
  this.version = 31;
  this.buildNumber = 10;
  // this.s3_url = "http://dshiq.s3.ap-northeast-2.amazonaws.com";
  // this.s3_url = "http://dev.com:8060";
  this.S3_BUCKET = "dshiq";
  // this.S3_BUCKET_folder = "/test"
  // this.myBucket = AWS.S3({
  //   params: { Bucket: S3_BUCKET},
  //   region: REGION,
  // });
  // this.lambda = AWS.Lambda({region: REGION});
  this.keyUpdate = async () => {
    try {
      const result = await this.axiosPost({
        cat: "com",
        cmd: "getKey",
        page: 1,
        amount: 10,
      });
      // console.log(result, result.access, result.secret);
      // if (result.access == ACCESS_KEY &&result.secret == SECRET_ACCESS_KEY) alert('same ACCESS');
      // alert("%% "+JSON.stringify(result.access))
      // alert("!!! "+JSON.stringify(result.secret))
      // AWS.config.update({
      //     accessKeyId: result.access,
      //     secretAccessKey: result.secret
      // });

      // alert('keyUpdate');
    } catch (e) {
      console.log(e);
    }
  };
  this.putBucket = async (file, name, v_uuid, isRe, isQna, isMsg, isMsgImg) => {
    try {
      const arrName = name.split(".");

      var fname;

      if (name.includes("MP4")) {
        name = name.replace("MP4", "mp4");
      }
      let table = "";
      let name_split = name.split("/");
      // console.log("name_split => ",name_split)
      let db_id = name_split[name_split.length - 1];
      // console.log("db_id => ",db_id)
      let db_id_split = db_id.split(".");

      let uuid = util.getUUid();

      // await apiFn.comApi({
      //     state : {
      //         table : "warn",
      //         ins : [{
      //             content : "type V and G :",
      //             reg_date : 0,

      //         }]
      //     }
      // })

      if (["mov", "avi", "wmv"].includes(arrName[arrName.length - 1].toLowerCase())) {
        // fname = "up/" + db_id
        // fname = "up/"+db_id+"."+arrName[arrName.length-1]
        // fname = name;
        // fname = "up/"+v_uuid+"."+arrName[arrName.length-1]
        // fname = util.getS3Folder() + "/board/"+v_uuid+"."+arrName[arrName.length-1]
        // fname = util.getS3Folder() + "/board/"+v_uuid+".jpg"
        if (name.includes("qna")) {
          table = "online_qna";
        } else if (name.includes("board")) {
          table = "board";
        }
      } else {
        console.log("name => ", name);
        // console.log(v_uuid)
        // fname = util.getS3Folder() + name;

        if (name.includes("teacher") || name.includes("student") || name.includes("user")) {
          util.getS3Folder() + "/teacher/" + uuid + ".jpg";
          table = "user";
        } else if (name.includes("class")) {
          util.getS3Folder() + "class/" + uuid + ".jpg";
          table = "class";
        } else if (name.includes("book")) {
          util.getS3Folder() + "bookCover/" + uuid + ".jpg";
          table = "book";
        } else if (name.includes("subs_info")) {
          // fname = util.getS3Folder() + "/book_subs_info/"+uuid+".jpg"
          util.getS3Folder() + name;
          table = "book_subs_info";
        } else if (name.includes("qna")) {
          if (isMsg) {
            // console.log("this ? ? ? ?")
            util.getS3Folder() + name;
          } else {
            util.getS3Folder() + "qna/" + uuid + ".jpg";
            if (isQna) {
              util.getS3Folder() + name;
            }
            table = "online_qna";
          }
          if (isMsgImg) {
            util.getS3Folder() + name + ".jpg";
            table = "";
          }
        } else if (name.includes("board")) {
          // if(v_uuid){
          //     fname = util.getS3Folder() + "/board/"+v_uuid+".mp4"
          // }
          // else {
          // }
          util.getS3Folder() + name;
          // fname = util.getS3Folder() + "/board/"+uuid+".jpg"
          table = "board";
          // if (fname.includes("_t") || name.split("/").length > 2) {
          //     table = ""
          // }
        } else {
          // console.log("e : ",e)
          util.getS3Folder() + name;
        }
      }

      if (typeof file == "string") {
        file = util.dataURLtoFile(file, db_id_split);
      }

      console.log("put bucket file", file);

      // await apiFn.comApi({
      //     state : {
      //         table : "warn",
      //         ins : [{
      //             content : "type G :" + `file : ${file}`,
      //             reg_date : 0,

      //         }]
      //     }
      // })

      // console.log("name => ",name)

      // console.log("fname => ",fname);

      // let res = await this.axiosPost({
      //     cat:'com',
      //     cmd:'getSign',
      //     key:fname,
      //     type:file.type
      // })
      // console.log(asdfasdfvovovo)

      // 0727 img cache 관련 테스트 코드
      // let res = await this.axiosPost({
      //     cat:'com',
      //     cmd:'getSign2',
      //     key:fname.replace("//","/"),
      //     type:file.type
      // })

      // console.log("!####")

      // 0727 img cache 관련 테스트 코드
      // let name_split = name.split("/")
      // // console.log("name_split => ",name_split)
      // let db_id = name_split[name_split.length - 1]
      // // console.log("db_id => ",db_id)
      // let db_id_split = db_id.split(".")
      // console.log("db_id_split ==> ",db_id_split);
      let rand = db_id_split.reduce((a, v, i) => {
        if (i != db_id_split.length - 1) {
          if (i == 0) {
            return a + v;
          } else {
            return a + "." + v;
          }
        } else {
          return a;
        }
      }, "");

      // console.log("rand => ",rand);
      // console.log("uuid => ",uuid);
      // console.log("res => ",res);
      console.log("Table => ", table);

      if (isRe) {
        let base64 = await util.toBase64(file, true);
        // let res = await apiFn.fnPutQnaImage({
        //     state : {
        //         img : base64,
        //         name : uuid,
        //     }
        // })

        // console.log("###### ",res);
        await apiFn.comApi({
          state: {
            // table : "teacher",
            table: table,
            set: [
              {
                key: `id:${rand}`,
                value: {
                  // img : v_uuid ? v_uuid : uuid
                  img: rand,
                  content: res["content"],
                },
              },
            ],
          }.then(() => {
            console.log("yay!");
          }),
        });
      } else {
        if (table == "") {
          throw new Error("no table");
        } else {
          await apiFn.comApi({
            state: {
              // table : "teacher",
              table: table,
              set: [
                {
                  key: `id:${rand}`,
                  value: {
                    img: rand,
                  },
                },
              ],
            },
          });
        }
      }

      // return await axios.put(res.url, file, {
      //     headers:{
      //         'Content-Type' : _type,
      //     }
      // })
      let putResult;
      if (isRe) {
        putResult = true;
      } else {
        // putResult = await axios.put(res.url, file, {
        //     headers:{
        //         'Content-Type' : _type,
        //     }
        // })
        putResult = await apiFn.uploadFile(name, file);
      }
      console.log("putBucket => upload", name, file);
      console.log("putResult => ", putResult);
      return { name };
    } catch (e) {
      console.log(Object.keys(e));

      // "https://dshiq2.s3.ap-northeast-2.amazonaws.com//editor/1660882118552_911_pop_titlebar_close2_pressed.png?Content-Type=image%2Fjpeg&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJHP4A6PBDDEXI5IA%2F20220819%2Fap-northeast-2%2Fs3%2Faws4_request&X-Amz-Date=20220819T040840Z&X-Amz-Expires=900&X-Amz-Signature=e019e37bfa84821d582249445ea5d88ed929e20374b1278ea7ff31877c5ec1d1&X-Amz-SignedHeaders=host"
      alert("파일업로드가 일시적으로 원할하지 않습니다.");

      // await apiFn.comApi({
      //     state : {
      //         table : "warn",
      //         ins : [{
      //             content : "type B :" + JSON.stringify(e.message),
      //             reg_date : 0,

      //         }]
      //     }
      // })

      console.log(e);

      throw new Error(e);
    }
  };
  this.axiosPost = async (data) => {
    try {
      // console.log("@@@@@@@@@@")
      // this.set("isLoading",true)
      // alert("1")
      // console.log(data)
      // console.log('Axio => ',data["cmd"])
      // if(this.get("user")){
      let reqOption = { withCredentials: true };

      await new Promise((resolve) => setTimeout(resolve, 0));
      const { id: clickContextId, stack } = JSON.parse(
        window.localStorage.getItem("clickContextId") || "{}"
      );

      if (JSON.parse(window.localStorage.getItem("user"))) {
        const savedUser = JSON.parse(window.localStorage.getItem("user"));
        data["session"] = savedUser["session"];
        if (savedUser["jwt"]) {
          reqOption = {
            ...reqOption,
            headers: {
              Authorization: `Bearer ${savedUser["jwt"]}`,
            },
          };
        }
        // data["session"] = this.get("user")["session"]
      }
      // alert("2")

      // console.log("DATA =====> ",JSON.parse(window.localStorage.getItem("user")))

      if (!JSON.parse(window.localStorage.getItem("user"))) {
        console.log("Session => ", data["session"]);
        // alert("잘못된 접근입니다.")
        // history.push("/")
        // window.location.replace('/')
      }
      // alert("3")
      // data["version"] = this.version;

      if (clickContextId) {
        reqOption = {
          ...reqOption,
          headers: {
            ...reqOption.headers,
            "x-hiq-context": clickContextId,
            "x-hiq-trace": stack,
          },
        };
      }
      console.log("reqOption => ", reqOption);

      var result = await axios.post(
        constants.apiUrl,
        { channelId: constants.channelId, ...data },
        reqOption
      );

      // result.data.status = 4;
      // alert(JSON.stringify(result));
      if (result.data.status == 4) {
        if (window.ReactNativeWebView) {
          alert("서비스가 업데이트 되었습니다.");
          const ua = navigator.userAgent.toLowerCase();

          // mobile web...
          if (ua.includes("mobile") || ua.includes("android") || ua.includes("iphone")) {
            if (window.confirm("스토어로 이동하시겠습니까?")) {
              location.href =
                ua.indexOf("android") > -1
                  ? "https://play.google.com/store/apps/details?id=com.juho.HiQ"
                  : "https://apps.apple.com/kr/app/hiq/id1602520474";
            }
            window.ReactNativeWebView.postMessage("closeApp");
          }
          result.data.status = 3;
          return result.data;
        }
        // this.logout();
        // return;
      }
      // this.set("isLoading",false)
      // return null;
      return result.data;
    } catch (e) {
      // alert(JSON.stringify(e))
      console.log("axio error ", e);
      // alert(e.message);
      if (data["cmd"] == "signIn") {
        // alert('입력하신 회원 정보를 찾을 수 없습니다');
        return;
      } else {
        if (e.message != "Network Error") throw new Error(data["cmd"]);
        else {
          // alert("-1 Case ");
          if (util.chk_platform() === "ios") {
            return await this.axiosPost(data);
          }
        }
        // window.location.replace('/dsmain');
      }
    }
  };
  this.mov2mp4 = async (fname, v_uuid) => {
    // console.log(fname)
    const arrFname = fname.split("/");
    // console.log("mov2mp4 info => ",arrFname[arrFname.length-1], fname , arrFname);
    // console.log("up => ",arrFname[arrFname.length-1]);
    console.log("up => ", arrFname[arrFname.length - 1]);

    console.log(util.getS3Folder() + util.replace_mp4(fname));

    // try {
    // console.log("!")

    // console.log(util.getS3Folder()+util.replace_mp4(fname))
    // if (fname.toLowerCase().includes('.mp4')) {
    // return await this.axiosPost({
    //     cat: 'com',
    //     cmd: 'videoEncoder2',
    // cmd:'videoEncoder',
    // qid : "834",,
    // video: `${util.getS3Folder() + util.replace_mp4(fname)}`
    // video:`${util.getS3Folder()+fname.replace('.mov', '.mp4').replace('.avi', '.mp4').replace('.wmv','.mp4').replace('.MP4','.mp4')}`
    // video:`${util.getS3Folder()+fname.toLowerCase().replace('.mov', '.mp4').replace('.avi', '.mp4').replace('.wmv','.mp4')}`
    // })
    // }
    // else {
    //     return await this.axiosPost({
    //         cat: 'com',
    //         cmd: 'videoEncoder2',
    //         // cmd:'videoEncoder',
    //         up: `up/${arrFname[arrFname.length - 1]}`,
    //         down: `public${util.replace_mp4(fname)}`
    //         // down:`${util.getS3Folder()+fname.replㅕace('.mov', '.mp4').replace('.avi', '.mp4').replace('.wmv','.mp4')}`
    //         // down:`${util.getS3Folder()+fname.toLowerCase().replace('.mov', '.mp4').replace('.avi', '.mp4').replace('.wmv','.mp4')}`
    //     })
    // }
    // } catch (e) {
    //     console.log("mov2mp4 Error => ", e)
    // }
  };
  this.logout = (cb) => {
    try {
      window.localStorage.removeItem("user");
      window.localStorage.removeItem("pw");
      this.set("user", {});
      window.location.replace("/");
    } catch (e) {}
  };
  this.post = (data) =>
    new Promise((r, j) => {
      try {
        this.LogoutTimer();
        let f = data;

        // let session = JSON.parse(window.sessionStorage.getItem('session'));

        // if(session){
        //     f.session = session
        // }
        // else {
        //     f.asession = constants.asession
        // }

        // f.session = constants.session
        if (JSON.parse(window.sessionStorage.getItem("userInfo"))) {
          f["S_USER_ID"] = JSON.parse(window.sessionStorage.getItem("userInfo"))["S_USER_ID"];
        }
        // else {
        //     return;
        // }

        if (data["form"]) {
          f = new FormData();
          Object.keys(data).forEach((d) =>
            f.append(
              d,
              typeof data[d] == "boolean"
                ? data[d].toString()
                : Array.isArray(data[d])
                  ? data[d][0]
                  : data[d]
                    ? data[d]
                    : "null"
            )
          );
        }

        fetch(constants.apiUrl, {
          method: "POST",
          // credentials:"omit",
          // credentials:"same-origin",
          // credentials:'include',
          headers: data["form"]
            ? {}
            : {
                "Content-Type": "application/json",
              },
          // header : {"cookie" : this.boolCheckCookie("userInfo") ? JSON.parse(this.getCookie("userInfo")) : ""},
          body: JSON.stringify({ body: this.encryptData(JSON.stringify(f)) }),
          // body: this.encryptData(JSON.stringify(f))
          // body: data['form']?f:JSON.stringify(f)
        })
          .then((t) =>
            t
              .clone()
              .json()
              // .then(r=> {
              // })
              .then((r) => {
                if (r["Error"]) {
                  return r;
                } else {
                  return JSON.parse(this.decryptData(r["res"]));
                }
              })
              .catch((e) => {
                console.log(e);
                throw e;
              })
          )
          .then(r)
          .catch((e) => {
            console.log("FETCH ERROR => ", e);
          });
      } catch (e) {
        console.log("store Post Error => ", e);
      }
    });

  /**
   * Google Pub/Sub REST API를 사용하여 메시지를 발행하는 함수입니다.
   *
   * @param {string} topic - 발행할 토픽의 이름
   * @param {string} message - 발행할 메시지 내용
   */
  this.publishMessage = async (topic, message) => {
    const apiUrl = `${constants.apiUrl}/pubsub/publish`;
    try {
      const response = await axios.post(
        apiUrl,
        {
          topic,
          message: JSON.stringify(message),
        },
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      // 성공 시 응답 데이터 반환
      return response.data;
    } catch (error) {
      // 실패 시 오류 반환
      throw error;
    }
  };

  /**
   * Google Cloud Function을 호출하여 주어진 이미지 파일에서 텍스트를 추출하는 함수입니다.
   * 이 함수는 axios 라이브러리를 사용하여 HTTP POST 요청을 보내고,
   * Cloud Function에 파일 이름을 전달하여 Google Cloud Storage에서 해당 파일의 텍스트를 추출합니다.
   *
   * @param {object} data - 텍스트를 추출할 이미지 파일의 이름이 포함됨.
   *                            이 이름은 Cloud Storage에 저장된 파일 이름과 일치해야 합니다.
   */
  this.getTextFromImage = async (data) => {
    let functionUrl;
    if (!constants.isDev) {
      functionUrl =
        "https://asia-northeast3-hiqsum-379810.cloudfunctions.net/getTextFromImageByHttp";
    } else {
      functionUrl =
        "https://asia-northeast3-hiqsum-379810.cloudfunctions.net/getTextFromImageByHttp_DEV";
    }

    try {
      const response = await axios.post(functionUrl, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      // console.log('Response:', response.data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   *
   * Answer 이미지를 놓는 부분입니다....
   *
   * @param {object} message - 텍스트를 추출할 이미지 파일의 이름이 포함됨.
   *                            이 이름은 Cloud Storage에 저장된 파일 이름과 일치해야 합니다.
   */
  this.putAnswerForQuestion = async (data) => {
    let functionUrl;
    if (!constants.isDev) {
      functionUrl =
        "https://asia-northeast3-hiqsum-379810.cloudfunctions.net/putAnswerForQuestionByHttp";
    } else {
      functionUrl =
        "https://asia-northeast3-hiqsum-379810.cloudfunctions.net/putAnswerForQuestionByHttp_DEV";
    }

    try {
      const response = await axios.post(functionUrl, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      console.log("Response:", response.data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  /**
   * upload a file to cloud storage
   */
  this.uploadFileToStorage = async (
    bucketName,
    fileLocation,
    file,
    options = {
      onUploadProgress: (progressEvent) => {},
    }
  ) => {
    const { onUploadProgress } = options;
    // Get the signed URL using Axios
    try {
      const response = await axios.get(`${constants.apiUrl}/bucket/${bucketName}/signedUrl`, {
        params: { fileName: fileLocation },
        headers: { accept: "application/json" },
      });
      const { signedUrl } = response.data;

      // Perform the file upload using Axios
      const uploadResponse = await axios.put(signedUrl, file, {
        headers: { "Content-Type": "application/octet-stream" },
        onUploadProgress,
      });

      if (uploadResponse.status === 200) {
        console.log("업로드를 완료했습니다.", fileLocation);
        return fileLocation;
      } else {
        console.error(uploadResponse);
        alert("업로드에 실패했습니다.");
        throw new Error(`Upload failed with status: ${uploadResponse.status}`);
      }
    } catch (error) {
      console.error(error);
      alert("업로드에 실패했습니다.");
      throw error;
    }
  };

  var timer = null;
  this.LogoutTimer = () => {
    let userInfo_ = JSON.parse(window.sessionStorage.getItem("userInfo"));
    let sessionTime = JSON.parse(window.sessionStorage.getItem("sessionTime"));
    var logout = () => {
      if (userInfo_) {
        if (userInfo_["stat"] == "login") {
          this.set("state", {
            id: "",
            pw: "",
            userInfo: {
              stat: "prev",
              admin: false,
            },
            currentPage: "",
          });
          window.sessionStorage.removeItem("userInfo");
          window.sessionStorage.removeItem("session");
          alert("장시간 미사용으로 로그아웃 되었습니다");
          window.location.replace("/");
        }
      }
    };

    var limit = sessionTime;

    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(logout, limit * 1000);
    // timer = setTimeout(logout, limit)
  };

  this.assign = (dst, src) => {
    if (dst == null) {
      // dst = src;
    } else Object.assign(dst, src);
  };
  this.css = (css) => {
    Object.keys(css).forEach((f) => {
      if (Array.isArray(css[f])) {
        css[f].forEach((c) => this.assign(css, c));
        delete css[f];
      }
    });
    return css;
  };
  this.textElip = () => ({
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  });
  this.overflow = (y = "hidden", x = "hidden") => ({
    overflowY: y,
    overflowX: x,
  });
  this.borderDef = () => ({
    outline: "1px solid",
  });
  this.shadowBottom = () => ({
    boxShadow: "0 1px 3px -2px gray", //x, y, blur, spread, color
  });
  this.shadowTop = () => ({
    boxShadow: "0 -1px 5px -2px gray",
  });
  this.clearButton = () => ({
    border: "none",
    outline: "none",
    cursor: "pointer",
  });
  this.flex = (val, shi, bas) => ({
    flexGrow: val,
    flexShrink: shi == null ? 1 : shi,
    flexBasis: bas == null ? 0 : bas,
  });
  this.flexRow = (just, align, rev) => ({
    display: "flex",
    flexDirection: "row" + (rev ? "-reverse" : ""),
    justifyContent: just == null ? "flex-start" : just,
    alignItems: align == null ? "stretch" : align,
    flexWrap: "nowrap",
    // order: 1
  });
  this.flexCol = (just, align, rev) => ({
    display: "flex",
    flexDirection: "column" + (rev ? "-reverse" : ""),
    justifyContent: just == null ? "flex-start" : just,
    alignItems: align == null ? "stretch" : align,
    flexWrap: "nowrap",
    // order: 1
  });
  this.getRandom = (min, max) => Math.floor(Math.random() * (max - min)) + min;
  this.getNumber = (str) => parseInt(str.replace(/[^0-9]/g, ""));
  this.resizeImage = (img, _width) => {
    let canvas = document.createElement("canvas");
    // max_size = 1280,
    // max_size = 1280,
    let width = img.width;
    let height = img.height;

    _width = 120;

    height = (height * _width) / width;
    width = _width;
    canvas.width = width;
    canvas.height = height;
    canvas.getContext("2d").drawImage(img, 0, 0, width, height);

    const dataUrl = canvas.toDataURL("image/jpeg");
    let _file = util.dataURLtoFile(dataUrl);

    return _file;
  };
  this.convertFileSize = (fileSize, fixed) => {
    // console.log("######### ",fileSize);
    //MB 단위 이상일때 MB 단위로 환산
    try {
      var str;
      if (typeof fileSize == "string") {
        return fileSize;
      } else {
        if (fileSize >= 1024 * 1024) {
          fileSize = fileSize / (1024 * 1024);
          fileSize = fixed === undefined ? fileSize : fileSize.toFixed(fixed);
          str = fileSize.toFixed(1) + " MB";
        }
        //KB 단위 이상일때 KB 단위로 환산
        else if (fileSize >= 1024) {
          fileSize = fileSize / 1024;
          fileSize = fixed === undefined ? fileSize : fileSize.toFixed(fixed);
          str = fileSize.toFixed(1) + " KB";
        }
        //KB 단위보다 작을때 byte 단위로 환산
        else {
          fileSize = fixed === undefined ? fileSize : fileSize.toFixed(fixed);
          str = fileSize.toFixed(1) + " byte";
        }
        return str;
      }
    } catch (e) {
      //
    }
  };
  this.convertToDate = (timeStamp) => {
    if (timeStamp < 9999999999) timeStamp = timeStamp * 1000;
    var d = new Date(timeStamp),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  };
  this.convertToDateTime_day = (timeStamp) => {
    let _timeStamp = new Date(timeStamp).getTime();
    let date;
    if (timeStamp < 9999999999) {
      _timeStamp = _timeStamp * 1000;
      // _timeStamp = String(timeStamp)+"000"
    }
    date = new Date(Number(_timeStamp));
    // let date = new Date(Number(_timeStamp));
    var year = date.getFullYear().toString();
    var month = ("0" + (date.getMonth() + 1)).slice(-2); //월 2자리 (01, 02 ... 12)
    var day = ("0" + date.getDate()).slice(-2); //일 2자리 (01, 02 ... 31)
    var hour = ("0" + date.getHours()).slice(-2); //시 2자리 (00, 01 ... 23)
    var minute = ("0" + date.getMinutes()).slice(-2); //분 2자리 (00, 01 ... 59)

    return year + "-" + month + "-" + day;
  };
  this.convertToDateTime_hour = (timeStamp) => {
    let _timeStamp = timeStamp;
    let date;
    if (timeStamp < 9999999999) {
      _timeStamp = _timeStamp * 1000;
      // _timeStamp = String(timeStamp)+"000"
    }
    date = new Date(Number(_timeStamp));
    // let date = new Date(Number(_timeStamp));
    var hour = ("0" + date.getHours()).slice(-2); //시 2자리 (00, 01 ... 23)
    var minute = ("0" + date.getMinutes()).slice(-2); //분 2자리 (00, 01 ... 59)

    return hour + ":" + minute;
  };
  this.convertToDateTime_minute = (timeStamp) => {
    let _timeStamp = timeStamp;
    let date;
    if (timeStamp < 9999999999) {
      _timeStamp = _timeStamp * 1000;
      // _timeStamp = String(timeStamp)+"000"
    }
    date = new Date(Number(_timeStamp));
    // let date = new Date(Number(_timeStamp));
    var year = date.getFullYear().toString().slice(-2); //년도 뒤에 두자리
    var month = ("0" + (date.getMonth() + 1)).slice(-2); //월 2자리 (01, 02 ... 12)
    var day = ("0" + date.getDate()).slice(-2); //일 2자리 (01, 02 ... 31)
    var hour = ("0" + date.getHours()).slice(-2); //시 2자리 (00, 01 ... 23)
    var minute = ("0" + date.getMinutes()).slice(-2); //분 2자리 (00, 01 ... 59)

    return year + "-" + month + "-" + day + "\n" + hour + ":" + minute;
  };

  this.convertToDateTime_minute_bracket = (timeStamp) => {
    let _timeStamp = timeStamp;
    let date;
    if (timeStamp < 9999999999) {
      _timeStamp = _timeStamp * 1000;
      // _timeStamp = String(timeStamp)+"000"
    }
    date = new Date(Number(_timeStamp));
    // let date = new Date(Number(_timeStamp));
    var year = date.getFullYear().toString(); //년도 뒤에 두자리
    var month = ("0" + (date.getMonth() + 1)).slice(-2); //월 2자리 (01, 02 ... 12)
    var day = ("0" + date.getDate()).slice(-2); //일 2자리 (01, 02 ... 31)
    var hour = ("0" + date.getHours()).slice(-2); //시 2자리 (00, 01 ... 23)
    var minute = ("0" + date.getMinutes()).slice(-2); //분 2자리 (00, 01 ... 59)

    return year + "-" + month + "-" + day + "(" + hour + ":" + minute + ")";
  };

  this.convertToDateTime_minute_fullYear = (timeStamp) => {
    // let _timeStamp = timeStamp;
    let _timeStamp = new Date(timeStamp).getTime();
    let date;
    if (timeStamp < 9999999999) {
      _timeStamp = _timeStamp * 1000;
      // _timeStamp = String(timeStamp)+"000"
    }
    date = new Date(Number(_timeStamp));
    // let date = new Date(Number(_timeStamp));
    var year = date.getFullYear().toString(); //년도 뒤에 두자리
    var month = ("0" + (date.getMonth() + 1)).slice(-2); //월 2자리 (01, 02 ... 12)
    var day = ("0" + date.getDate()).slice(-2); //일 2자리 (01, 02 ... 31)
    var hour = ("0" + date.getHours()).slice(-2); //시 2자리 (00, 01 ... 23)
    var minute = ("0" + date.getMinutes()).slice(-2); //분 2자리 (00, 01 ... 59)

    return year + "-" + month + "-" + day + "\n(" + hour + ":" + minute + ")";
  };

  this.convertToDateTime_minute_fullYear_form2 = (timeStamp) => {
    // console.log("timeStamp => ",timeStamp)
    // let _timeStamp = String(timeStamp)+"000"
    let _timeStamp = timeStamp;
    let date;
    if (timeStamp < 9999999999) {
      _timeStamp = _timeStamp * 1000;
      // _timeStamp = String(timeStamp)+"000"
    }
    date = new Date(Number(_timeStamp));
    // let date = new Date(Number(_timeStamp));
    var year = date.getFullYear().toString(); //년도 뒤에 두자리
    var month = ("0" + (date.getMonth() + 1)).slice(-2); //월 2자리 (01, 02 ... 12)
    var day = ("0" + date.getDate()).slice(-2); //일 2자리 (01, 02 ... 31)
    var hour = ("0" + date.getHours()).slice(-2); //시 2자리 (00, 01 ... 23)
    var minute = ("0" + date.getMinutes()).slice(-2); //분 2자리 (00, 01 ... 59)

    return year + "/" + month + "/" + day + "/" + hour + ":" + minute;
  };

  this.convertToDateTime_onlyNum = (timeStamp) => {
    let _timeStamp = timeStamp;
    let date;
    if (timeStamp < 9999999999) {
      _timeStamp = _timeStamp * 1000;
      // _timeStamp = String(timeStamp)+"000"
    }
    date = new Date(Number(_timeStamp));
    // let date = new Date(Number(_timeStamp));
    var year = date.getFullYear().toString().slice(-2); //년도 뒤에 두자리
    var month = ("0" + (date.getMonth() + 1)).slice(-2); //월 2자리 (01, 02 ... 12)
    var day = ("0" + date.getDate()).slice(-2); //일 2자리 (01, 02 ... 31)
    var hour = ("0" + date.getHours()).slice(-2); //시 2자리 (00, 01 ... 23)
    var minute = ("0" + date.getMinutes()).slice(-2); //분 2자리 (00, 01 ... 59)
    var second = ("0" + date.getSeconds()).slice(-2); //초 2자리 (00, 01 ... 59)

    return year + month + day + hour + minute + second;
  };
  this.convertToDateTime = (timeStamp) => {
    let _timeStamp = timeStamp;
    let date;
    if (timeStamp < 9999999999) {
      _timeStamp = _timeStamp * 1000;
      // _timeStamp = String(timeStamp)+"000"
    }
    date = new Date(Number(_timeStamp));
    // let date = new Date(Number(_timeStamp));
    var year = date.getFullYear().toString().slice(-2); //년도 뒤에 두자리
    var month = ("0" + (date.getMonth() + 1)).slice(-2); //월 2자리 (01, 02 ... 12)
    var day = ("0" + date.getDate()).slice(-2); //일 2자리 (01, 02 ... 31)
    var hour = ("0" + date.getHours()).slice(-2); //시 2자리 (00, 01 ... 23)
    var minute = ("0" + date.getMinutes()).slice(-2); //분 2자리 (00, 01 ... 59)
    var second = ("0" + date.getSeconds()).slice(-2); //초 2자리 (00, 01 ... 59)

    return year + "-" + month + "-" + day + "\n" + hour + ":" + minute + ":" + second;
  };
  this.osCheck = () => {
    var ua = navigator.userAgent;

    if (ua.indexOf("NT 6.1") != -1) return "Windows 7";
    else if (ua.indexOf("NT 6.0") != -1) return "Windows Vista/Server 2008";
    else if (ua.indexOf("NT 5.2") != -1) return "Windows Server 2003";
    else if (ua.indexOf("NT 5.1") != -1) return "Windows XP";
    else if (ua.indexOf("NT 5.0") != -1) return "Windows 2000";
    else if (ua.indexOf("NT") != -1) return "Windows NT";
    else if (ua.indexOf("9x 4.90") != -1) return "Windows Me";
    else if (ua.indexOf("98") != -1) return "Windows 98";
    else if (ua.indexOf("95") != -1) return "Windows 95";
    else if (ua.indexOf("Win16") != -1) return "Windows 3.x";
    else if (ua.indexOf("Windows") != -1) return "Windows";
    else if (ua.indexOf("Linux") != -1) return "Linux";
    else if (ua.indexOf("Macintosh") != -1) return "Macintosh";
    else return "";
  };
  this.base64ToBlob = (base64) => {
    try {
      // let format = "application/octet-stream";
      // let format = "*"
      // let code = atob(base64);
      // // let aBuffer = new window.ArrayBuffer(code.length)
      // let aBuffer = new Array(code.length)

      // console.log("AA => ",aBuffer)
      // let uBuffer = new window.Uint8Array(aBuffer)
      // // console.log("UU => ",uBuffer)
      // for (var i = 0; i < code.length; i++){
      //     // uBuffer[i] = code.charCodeAt(i) & 0xff;
      //     // return new Blob([uBuffer],{type : format})
      //     aBuffer[i] = code.charCodeAt(i)
      // }
      // let byteArray = new Uint8Array(aBuffer)
      // console.log("$#$#$#")
      // return new Blob([byteArray] , { type : format})

      let byteCharacters = atob(base64);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);
      // console.log(byteArray)
      let blob = new Blob([byteArray], { type: "text/html" });
      return blob;
    } catch (e) {
      console.log("base64toBlob Error => ", e);
    }
  };
  this.download_ie = (fileData, fileName) => {
    try {
      let blob = this.base64ToBlob(fileData);
      window.navigator.msSaveBlob(blob, fileName);
    } catch (e) {
      console.log("donwload_ie Error => ", e);
    }
  };
  this.downloadURI = (uri, name) => {
    if (window.navigator && window.navigator.msSaveBlob) {
      this.download_ie(uri, name);
    } else {
      // let blob = this.base64ToBlob(uri);
      // console.log("## ",blob)
      var link = document.createElement("a");
      link.download = name;
      link.href = "data:image/jpeg;base64," + uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // delete link;
    }
  };
  this.setCookie = (key, value, expiredays) => {
    let todayDate = new Date();
    todayDate.setDate(todayDate.getDate() + expiredays); // 현재 시각 + 일 단위로 쿠키 만료 날짜 변경
    //todayDate.setTime(todayDate.getTime() + (expiredays * 24 * 60 * 60 * 1000)); // 밀리세컨드 단위로 쿠키 만료 날짜 변경
    document.cookie = key + "=" + value + "; path=/; expires=" + todayDate.toGMTString() + ";";
  };
  this.getCookie = (key) => {
    try {
      key = new RegExp(key + "=([^;]*)"); // 쿠키들을 세미콘론으로 구분하는 정규표현식 정의
      return key.test(document.cookie) ? RegExp.$1 : ""; // 인자로 받은 키에 해당하는 키가 있으면 값을 반환
    } catch (e) {
      console.log("GET COOKIE ERRor => ", e);
    }
  };
  this.delCookie = (key) => {
    try {
      let todayDate = new Date();
      document.cookie = key + "=; path=/; expires=" + todayDate.toGMTString() + ";"; // 현재 시각 이전이면 쿠키가 만료되어 사라짐.
    } catch (e) {
      console.log("DelCookie Error => ", e);
    }
  };
  this.boolCheckCookie = (key) => {
    try {
      return this.getCookie(key) != "" ? true : false;
    } catch (e) {
      console.log("boolcheck Cookie Error ", e);
    }
  };
  this.log = (groupName, obj, text) => {
    console.group(`[${groupName}] ${text} => `);
    Object.keys(obj).map((v, i) => {
      if (typeof obj[v] == "object") {
        console.log("%c" + v + " value", "color:red");
        if (Array.isArray(obj[v])) {
          if (obj[v].length > 2) {
            console.log(obj[v]);
          } else {
            obj[v].map((g, h) => {
              console.table(g);
            });
          }
        } else {
          console.table(obj[v]);
        }
        console.log("%c================================", "color:red");
      } else {
        console.log(
          "%c" + v + " %c: " + "%c" + obj[v],
          "color:red",
          "color:'white",
          "color:orange"
        );
      }
    });
    console.groupEnd("=================");
    // if(this.osCheck().indexOf("Window") != -1 ){
    //     console.log(obj);
    // }
    // else {
    //     console.group(`[${groupName}] ${text} => ` );
    //     Object.keys(obj).map((v,i)=>{
    //         if(typeof(obj[v]) == "object"){
    //             console.log("%c"+v+" value","color:red")
    //             if(Array.isArray(obj[v])){
    //                 if(obj[v].length > 2){
    //                     console.log(obj[v])
    //                 }
    //                 else {
    //                     obj[v].map((g,h)=>{
    //                         console.table(g)
    //                     })
    //                 }
    //             }
    //             else {
    //                 console.table(obj[v])
    //             }
    //             console.log("%c================================" ,"color:red")
    //         }
    //         else {
    //             console.log("%c"+v + " %c: "+"%c"+obj[v],"color:red","color:'white","color:orange")
    //         }
    //     })
    //     console.groupEnd("=================");
    // }
  };
  this.parseAuth = ({ authSet }) => {
    let obj = {};
    if (authSet) {
      authSet.map((v, i) => {
        obj[v["S_OBJECT_CODE"]] = v["S_VALUE"];
      });
    }
    return obj;
  };
  this.parsePageState = ({ pageState }) => {
    let _splitBox = pageState.split("&");
    let _obj = {};
    _splitBox.map((v, i) => {
      _obj[v.split("=")[0]] = v.split("=")[1];
    });
    return _obj;
  };
  this.getUrl = ({ pageState, url }) => {
    Object.keys(pageState).map((v, i) => {
      url += "&" + v + "=" + pageState[v];
    });
    return url;
  };
  this.dateForm = (num) => {
    let _num = num;
    if (num < 10) {
      _num = "0" + num;
    }
    return _num;
  };
  this.percentageForm = (num) => {
    return (Number(num) * 100).toFixed(1) + "%";
  };
  this.percentageSet = (num) => {
    return Number((Number(num) * 100).toFixed(1));
  };
  this.fnLoading = (setLoad, isLoad) => {
    setLoad(isLoad);
  };
  // this.dataURLtoFile = (dataurl) => {
  //     var arr = dataurl.split(','),
  //         mime = arr[0].match(/:(.*?);/)[1],
  //         bstr = atob(arr[1]),
  //         n = bstr.length,
  //         u8arr = new Uint8Array(n);

  //     while(n--){
  //         u8arr[n] = bstr.charCodeAt(n);
  //     }

  //     return new File([u8arr], {type:mime});
  // }
  this.isMobile = () => {
    const isMobileUserAgent =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|like Mac OS X/i.test(
        navigator.userAgent
      );
    return navigator.maxTouchPoints === 5 || isMobileUserAgent;
  };

  // this.convertToDate = (timeStamp) => {
  //     if (timeStamp<9999999999) timeStamp = timeStamp * 1000
  // }

  // this.fnShopRead : async(req)=>{
  //     let res = await sql.query(`select * from TShop where shopId='${req}'`)
  //     if(res.length == 0)return {status : 'E01'};
  //     return{status:'Y'}
  // }
  singletone = this;

  this.decoder = (codeTypeValue, value) => {
    if (!value) return "";
    if (this.get("code").length == 0) return;
    let temp = this.get("code").filter(
      (f) => f["S_CODE_TYPE"] == codeTypeValue && f["S_OBJECT_CODE"] == value
    );
    // console.log(value, temp);
    if (!temp) return;
    let _temp = temp[0]["S_CODE_NAME"];
    if (temp[0]["S_CODE_NAME"].includes("체크")) {
      _temp = "열람";
    }
    return _temp;
  };
  // this.excelDown = (excelData, fileName,info) => {
  //     // console.log("excelData => ",excelData)

  //     // let _excelData = []
  //     let _logData = []
  //     excelData = excelData.map((v,i)=>{
  //         if(i != 0 ) {
  //             _logData.push(v[15]);
  //             return v.slice(0,15)
  //         }
  //         else {
  //             return v
  //         }
  //     })

  //     // console.log("excelData Modi => ",excelData)

  //     apiFn.fnDocLogWrite({
  //         state : {
  //                 // S_SITE_ID : "",
  //                 S_DOC_NO : _logData,
  //                 S_USER_ID : info["S_USER_ID"],
  //                 S_USER_NM : info["S_USER_NM"],
  //                 L_HIS_TYPE : 4,
  //                 S_SITE_ID : info["S_FIRST_GROUP_ID"],
  //             // S_DOC_NO = 엑셀 대상 도면 S_DOC_NO 배열,
  //         }
  //     })
  //     const s2ab = (s) => {
  //         var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  //         var view = new Uint8Array(buf);  //create uint8array as viewer
  //         for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
  //         return buf;
  //     }
  //     const saveFile = async (blob) => {
  //         const a = document.createElement('a');
  //         a.download = fileName;
  //         a.href = URL.createObjectURL(blob);
  //         a.addEventListener('click', (e) => {
  //           setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
  //         });
  //         a.click();
  //     };
  //     var wb = XLSX.utils.book_new();

  //     wb.Props = {
  //         Title: "title",
  //         Subject: "subject",
  //         Author: "programmer93",
  //         Manager: "Manager",
  //         Company: "Company",
  //         Category: "Category",
  //         Keywords: "Keywords",
  //         Comments: "Comments",
  //         LastAuthor: "programmer93",
  //         // CreatedDate: new Date(2021,01,13)
  //     };

  //     // sheet명 생성
  //     wb.SheetNames.push("sheet 1");
  //     // wb.SheetNames.push("sheet 2"); // 시트가 여러개인 경우

  //     // 이중 배열 형태로 데이터가 들어간다.
  //     // var wsData2 = [['가1' , '가2', '가3'],['나1','나2','나3']];	// 시트가 여러개인 경우

  //     // 배열 데이터로 시트 데이터 생성
  //     var ws = XLSX.utils.aoa_to_sheet(excelData);
  //     // var ws2 = XLSX.utils.aoa_to_sheet(wsData2); 	//시트가 여러개인 경우
  //     var wscols = [
  //         {wch:23.0},
  //         {wch:12.0},
  //         {wch:10},
  //         {wch:80.0},
  //         {wch:20},
  //         {wch:20},
  //         {wch:20},
  //         {wch:10},
  //         {wch:30},
  //         {wch:10},
  //         {wch:10},
  //         {wch:20},
  //         {wch:20},
  //         {wch:20},
  //         {wch:20},
  //     ];

  //     ws['!cols'] = wscols;

  //     // 시트 데이터를 시트에 넣기 ( 시트 명이 없는 시트인경우 첫번째 시트에 데이터가 들어감 )
  //     wb.Sheets["sheet 1"] = ws;
  //     // wb.Sheets["sheet 2"] = ws2;	//시트가 여러개인 경우

  //     // 엑셀 파일 쓰기
  //     var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});

  //     // 파일 다운로드
  //     saveFile(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), '엑셀_다운로드.xlsx');
  // }
}

var store = new storeClass();
export default store;
