import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import style from "../../asset/style";
import face_student from "../../asset/Img/face_student.png";
import face_teacher from "../../asset/Img/defaultpic_teacher.png";
import { UiSearchSection } from "../../uiComponents/UiSearchList/UiSearchList";
import UiTextBtn from "../../uiComponents/UiTextBtn/UiTextBtn";
import UiNotice from "../../uiComponents/UiNotice/UiNotice";
import UiTeacherList from "../../uiComponents/UiTeacherList/UiTeacherList";
import Profile_class_Style from "./Profile_class_Style";
import apiFn from "../../asset/apiClass";
import constants from "../../asset/constants";
import util from "../../asset/util";
import Modal_share_book from "./Modal_share_book";
import UiBook from "../../uiComponents/UiBook/UiBook";
import UiTeacherList_Style from "../../uiComponents/UiTeacherList/UiTeacherList_Style";
import { axiosInstance } from "../../api/common";

const Profile_class = ({
  academyData,
  academyData_ori,
  isTeacher,
  userInfo,
  apiPro,
  setTap,
  setFileObj,
}) => {
  let myInfo_init = {
    name: "",
    tel: "",
    email: "",
    collage: "",
    qtotal: "",
    anscount: 0,
    avgTime: "",
    intro: "",
    nick: "",
    content: "",
  };
  let navigate = useNavigate();
  let params = useParams();

  // var [academyData,setAcademyData] = useState([])
  var [noticeData, setNoticeData] = useState([]);
  var [teacherData, setTeacherData] = useState([]);

  var [stat_0_data, setStat_0_data] = useState([]);
  var [stat_1_data, setStat_1_data] = useState([]);

  const [myInfo, setMyInfo] = useState(myInfo_init);
  const [showBookShareModal, setBookShareModal] = useState(false);
  const [bookList, setBookList] = useState(null);

  const apiPro_read_myInfo = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "user_credential",
          where: { "HIQDB_user_credential.name": `= '${userInfo.id}'` },
          ref: [
            `A.* |HIQDB_user A on A.index=HIQDB_user_credential.user_id and user_type = ${userInfo.user_type ?? userInfo.type}`,
          ],
          page: 1,
          amount: 99999,
          sort: "reg_date desc",
        },
      });
      setMyInfo(data?.list[0]);
    } catch (e) {}
  };

  const apiPro_del = async () => {
    try {
      // // console.log("userInfo",userInfo)
      let isOk = await apiFn.exitClass({
        state: {
          orderMid: userInfo["id"],
          targetMid: userInfo["id"],
          cid: params["id"],
        },
      });

      // await apiPro();
      navigate("/academy/teacher/academy");
    } catch (e) {}
  };

  // 학생 참가중인 수업
  const apiPro_read_4 = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "member_class",
          sort: " reg_date desc",
          amount: 9999,
          page: 1,
          ref: ["HIQDB_class.name|HIQDB_member_class.cid=HIQDB_class.id"],
          where: {
            "HIQDB_member_class.mid": `= '${userInfo["id"]}'`,
            "HIQDB_class.pid": `= '${params["id"]}'`,
          },
        },
      });

      let stat_0_array = [];
      let stat_1_array = [];

      data["list"].map((v, i) => {
        if (v["status"] == 0) {
          stat_0_array.push(v);
        }
        if (v["status"] == 1) {
          stat_1_array.push(v);
        }
      });

      setStat_0_data([...stat_0_array]);
      setStat_1_data([...stat_1_array]);
    } catch (e) {}
  };

  const apiPro_approve = async () => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "member_class",
          sendMsg: {
            type: "hakwon_okay",
            orderMid: userInfo["id"],
            targetMid: userInfo["id"],
            cid: params["id"],
            channel: constants.channelId,
          },
          set: [
            {
              key: `cid:${params["id"]}' and mid='${userInfo["id"]}`,
              value: {
                type: params["who"] == "teacher" ? 6 : 0,
                status: 1,
              },
            },
          ],
        },
      });

      await apiPro();
    } catch (e) {
      // console.log("error => ",e);
    }
  };

  // 공지사항
  const apiPro_read_2 = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "board",
          page: 1,
          amount: 3,
          sort: "important desc",
          where: {
            "HIQDB_board.cat_id": `='66'`,
            "HIQDB_board.cid": `='${params["id"]}'`,
          },
        },
      });
      // // console.log(" ===> ",data["list"])
      setNoticeData([...data["list"]]);
    } catch (e) {}
  };

  const getBookList = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "book",
          page: 1,
          amount: 9999,
          u_id: userInfo["id"],
          sort: `reg_date desc`,
          ref: [
            "HIQDB_book_subs.public as subs_public|HIQDB_book_link.bid=HIQDB_book_subs.bid",
            "HIQDB_book_link.mid|HIQDB_book_link.bid=HIQDB_book.id",
            "HIQDB_book_link.type|HIQDB_book_link.bid=HIQDB_book.id",
          ],
          out: ["HIQDB_book_subs.name as subs_name:bid:id"],
          where: {
            "HIQDB_book_link.cid": `= '${params["id"]}'`,
            "HIQDB_book.status": "= 1",
            // "HIQDB_book_link.status" : "!= 0",
          },
        },
      });

      console.log("BOOK DATA => ", data);

      // let bookList = []
      data["list"].forEach((v, i) => {
        if (v["type"] == 8 || v["type"] == 4) {
          v["name"] = v["subs_name"];
        }
        // bookList.push(v)
      });
      setBookList(data.list);
    } catch (e) {}
  };

  useEffect(() => {
    // console.log("userInfo => ",userInfo);;
    getBookList();
  }, []);
  // 강사진
  const apiPro_read_3 = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "member_class",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          ref: [
            // 'HIQDB_class.name|HIQDB_class.id=HIQDB_member_class.cid',
            "HIQDB_class.intro|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.address|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.tel|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.email|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.owner|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_teacher.qtotal|HIQDB_teacher.id=HIQDB_member_class.mid",
          ],
          where: {
            "HIQDB_class.id": `='${params["id"]}'`, // 클래스룸 ID
            "HIQDB_member_class.status": `='1'`,
          },
        },
      });

      // console.log("$$$ ",data["list"]);

      let in_array = data["list"].reduce((a, v, i) => {
        return [
          ...a,
          // v["mid"].toString()
          `"${v["mid"]}"`,
        ];
      }, []);
      // console.log(in_array)
      let teacher = await apiFn.comApi({
        state: {
          table: "user_credential",
          page: 1,
          amount: 9999,
          ref: ["A.* |HIQDB_user A on A.index=HIQDB_user_credential.user_id"],
          sort: "reg_date desc",
          where: {
            "HIQDB_user_credential.name": `in (${in_array})`,
            //             'HIQDB_class.id':`in (${reduceArray})`,
          },
        },
      });

      // let reduceArray = data["list"].reduce((a,v,i)=>{
      //     return [
      //         ...a,
      //         v["pid"]
      //     ]
      // },[])

      // // console.log("reduceArray => ",reduceArray);
      // let data2 = await apiFn.comApi({
      //     state : {
      //         table : "class",
      //         page : 1,
      //         amount : 9999,
      //         sort : "reg_date desc",
      //         where : {
      //             'HIQDB_class.id':`in (${reduceArray})`,
      //         }
      //     }
      // })

      setTeacherData([...teacher["list"]]);
    } catch (e) {
      // console.log("@@@@@@@@@@ ",e);
    }
  };

  const apiPro_regi = async (data) => {
    try {
      // // console.log("userInfo => ",userInfo);
      // // console.log("userInfo => ",academyData_ori);
      let isOk = await apiFn.comApi({
        state: {
          table: "member_class",
          ins: [
            {
              mid: userInfo["id"],
              cid: academyData_ori["id"],
              type: 6,
              status: 0,
              reg_date: 0,
            },
          ],
        },
      });
      await apiPro();
    } catch (e) {
      // console.log("regi error => ",e);
    }
  };

  const apiPro_img = async (e) => {
    try {
      // 0708 주석 이미지 s3 폴더 변경
      // board -> class
      // const name = `/board/${params["id"]}.jpg`;
      const name = `/class/${params["id"]}.jpg`;

      setFileObj({
        // img : img,
        // bookName : bookName,
        name: name,
        file: e,
      });
      navigate("/crop/teacher/d");
      // let reader = new FileReader();
      // let file = e.target.files[0];
      // reader.readAsDataURL(file)

      // const name = `/board/${params["id"]}.jpg`;
      // let _img = await util.toBase64(file);
      // const result =  await store.putBucket(_img, name);
      // // console.log(result);

      // await apiPro_read_myInfo();
    } catch (e) {
      // console.log("apiPro_img Error= >",e)
    }
  };

  useEffect(() => {
    apiPro_read_myInfo();
    apiPro_read_2();
    apiPro_read_3();
    if (params["who"] == "student") {
      apiPro_read_4();
    }
  }, []);

  useEffect(() => {
    // console.log("$$$ ",academyData);
  }, [academyData]);

  useEffect(() => {
    console.log("userInfo => ", userInfo);
  }, []);

  return (
    <>
      <div style={Profile_class_Style.c}>
        <div style={Profile_class_Style.gradeBox_c}>
          <div
            style={{
              ...Profile_class_Style.gradeBox_w,
              backgroundColor: isTeacher ? style.common.t_color : style.common.s_color,
            }}
          >
            <div style={Profile_class_Style.gradebox_v}>
              <div
                style={{
                  marginRight: "10px",
                  display: "flex",
                }}
              >
                <img
                  style={Profile_class_Style.gradeBox_i}
                  // src={isTeacher ? face_teacher : face_student}
                  onError={(e) => {
                    // setIsLoad(false)
                    e.currentTarget.src = isTeacher ? face_teacher : face_student;
                  }}
                  src={
                    userInfo["img"]
                      ? // constants.apiUrl
                        constants.s3_url +
                        // ( constants.apiUrl.includes('dev.com') ? '/test' : '/public' )
                        "" +
                        `${isTeacher ? "/teacher/" : "/student/"}${userInfo["img"]}` +
                        // `${isTeacher ? "/teacher/" : "/student/"}${userInfo["id"].normalize('NFD')}`
                        // `${isTeacher ? "/teacher/" : "/student/"}${userInfo["id"].normalize('NFD')}`
                        // '/teacher/'+userInfo["id"].normalize('NFD')
                        ".jpg?"
                      : // +
                        // util.getRandomInt(1000,9999)
                        userInfo["id"]
                        ? constants.apiUrl +
                          // ( constants.apiUrl.includes('dev.com') ? '/test' : '/public' )
                          "" +
                          `${isTeacher ? "/teacher/" : "/student/"}${userInfo["id"].normalize("NFD")}` +
                          // `${isTeacher ? "/teacher/" : "/student/"}${userInfo["id"].normalize('NFD')}`
                          // '/teacher/'+userInfo["id"].normalize('NFD')
                          ".jpg?" +
                          util.getRandomInt(1000, 9999)
                        : isTeacher
                          ? face_teacher
                          : face_student
                  }
                />
              </div>
              {isTeacher ? (
                <div>
                  <div style={Profile_class_Style.gradebox_text_w}>
                    {/* 박주민 선생님은  */}
                    {`${myInfo["name"]} 선생님은`}
                    <div style={Profile_class_Style.gradebox_text}>
                      {/* 클래스룸 관리자 */}
                      {academyData["status"] == 0
                        ? "가입신청중"
                        : academyData["type"] == "5"
                          ? "클래스룸 관리자"
                          : academyData["type"] == "6"
                            ? "클래스룸 소속 강사님"
                            : academyData["type"] == "9" && "초대 승낙 대기 상태 "}
                    </div>
                    입니다.
                  </div>
                  {academyData["status"] == 0 && (
                    <UiTextBtn
                      text={"신청취소"}
                      btnStyle={Profile_class_Style.gradebox_text_btn}
                      fnClick={() => {
                        let isOk = window.confirm(constants.confirm.cf_16);
                        if (isOk) {
                          apiPro_del();
                        }
                      }}
                    />
                  )}
                  {academyData["type"] == "9" && (
                    <div
                      style={{
                        display: "flex",
                      }}
                    >
                      <UiTextBtn
                        text={"초대 승낙"}
                        btnStyle={Profile_class_Style.gradebox_text_btn}
                        fnClick={() => {
                          let isOk = window.confirm("클래스룸 등록 초대를 승낙하시겠어요?");
                          if (isOk) {
                            apiPro_approve();
                          }
                        }}
                      />
                      <UiTextBtn
                        text={"초대 거절"}
                        btnStyle={{
                          ...Profile_class_Style.gradebox_text_btn,
                          marginLeft: "12px",
                        }}
                        fnClick={() => {
                          let isOk = window.confirm("클래스룸 등록 초대을 거절하시겠어요?");
                          if (isOk) {
                            apiPro_del();
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
              ) : stat_1_data.length == 0 && stat_0_data.length == 0 ? (
                <div>
                  <div style={Profile_class_Style.gradebox_text_w}>
                    해당 클래스룸에 수강 등록된 강의가 없습니다.
                  </div>
                  <div style={Profile_class_Style.gradebox_text_w}>
                    [걍의] 탭에서 수강을 신청해 보세요.
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    padding: "5px 0px",
                  }}
                >
                  {stat_1_data.map((v, i) => (
                    <div style={Profile_class_Style.gradebox_text_w}>
                      {`[${v["name"]}]`}
                      수업을
                      <div style={Profile_class_Style.gradebox_text}>수강중</div>
                      입니다.
                    </div>
                  ))}
                  {stat_0_data.map((v, i) => (
                    <div style={Profile_class_Style.gradebox_text_w}>
                      {`[${v["name"]}]`}
                      수업을
                      <div style={Profile_class_Style.gradebox_text}>가입 승인 대기중</div>
                      입니다.
                    </div>
                  ))}
                  {/* {
                            stat_1_data[0] &&
                            stat_1_data[0]["name"] &&
                            <div style={Profile_class_Style.gradebox_text_w}>
                                {`[${stat_1_data[0]["name"]}]`}
                                {stat_1_data.length > 1 && ` 외 ${stat_1_data.length - 1}개`}
                                수업을
                                <div style={Profile_class_Style.gradebox_text}>
                                    수강중
                                </div>
                                입니다.
                            </div>
                            }
                            {
                            stat_0_data[0] &&
                            stat_0_data[0]["name"] &&
                            <div style={Profile_class_Style.gradebox_text_w}>
                                {`[${stat_0_data[0]["name"]}]`}
                                {stat_0_data.length > 1 && ` 외 ${stat_0_data.length - 1}개`}
                                수업을
                                <div style={Profile_class_Style.gradebox_text}>
                                    가입 승인 대기중
                                </div>
                                입니다.
                            </div>
                            } */}
                </div>
              )}
            </div>
          </div>
        </div>

        <div style={Profile_class_Style.w}>
          <UiSearchSection isBorder={false} data={academyData_ori} />
          {isTeacher && (
            <div style={Profile_class_Style.middle_btn_w}>
              {academyData["type"] == 5 ? (
                <>
                  <UiTextBtn
                    isFile={true}
                    accept={"image/*"}
                    text="이미지 변경"
                    btnStyle={Profile_class_Style.middle_btn}
                    isUnderline={false}
                    fnClick={(e) => {
                      apiPro_img(e);
                    }}
                  />
                  <UiTextBtn
                    text="정보 변경"
                    btnStyle={Profile_class_Style.middle_btn}
                    isUnderline={false}
                    fnClick={() => {
                      navigate(
                        "/profileregi/" + params["who"] + "/" + academyData_ori["id"] + "/default"
                      );
                    }}
                  />

                  <UiTextBtn
                    text="교재 공유"
                    btnStyle={Profile_class_Style.middle_btn}
                    isUnderline={false}
                    fnClick={() => {
                      setBookShareModal(true);
                    }}
                  />
                  <UiTextBtn
                    text="클래스룸 탈퇴"
                    btnStyle={Profile_class_Style.middle_btn}
                    isUnderline={false}
                    fnClick={() => {
                      let isOk = window.confirm(
                        `[${academyData_ori["name"]}] 클래스룸을 탈퇴하시겠습니까?`
                      );
                      if (isOk) {
                        apiPro_del();
                      }
                    }}
                  />
                </>
              ) : academyData["type"] == 6 ? (
                academyData["status"] == 1 && (
                  <UiTextBtn
                    text="클래스룸 탈퇴"
                    btnStyle={Profile_class_Style.middle_btn}
                    isUnderline={false}
                    fnClick={() => {
                      let isOk = window.confirm(
                        `[${academyData_ori["name"]}] 클래스룸을 탈퇴하시겠습니까?`
                      );
                      if (isOk) {
                        apiPro_del();
                      }
                    }}
                  />
                )
              ) : (
                academyData["status"] != 0 &&
                academyData["status"] != 1 &&
                academyData["type"] != 9 && (
                  <UiTextBtn
                    text="등록신청"
                    btnStyle={{
                      fontSize: "14px",
                      color: style.common.fontColor_5,
                    }}
                    isUnderline={false}
                    fnClick={() => {
                      apiPro_regi();
                    }}
                  />
                )
              )}
            </div>
          )}
        </div>
        <UiNotice isTeacher={isTeacher} data={noticeData} where={"class"} />
        <UiTeacherList
          key={"teacherList"}
          isTeacher={isTeacher}
          data={[...teacherData]}
          fnClick={() => {
            setTap("teacher");
          }}
        />

        <div style={UiTeacherList_Style.title_w}>공유 도서</div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(112px, 1fr))",
          }}
        >
          {bookList?.map((v, i) => (
            <UiBook
              onCancel={async () => {
                const { data } = await axiosInstance.delete("/book/share", {
                  data: {
                    classId: params.id,
                    bookList: [v.id],
                    userId: userInfo.id,
                  },
                });

                await getBookList();
              }}
              cancelable={true}
              data={v}
              key={v.name + i}
              id={i}
              navigate={navigate}
              navi={`/book/${params["who"]}/${v["id"]}/0/de`}
              params={params}
              isShare={true}
              isTeacher={isTeacher}
            />
          ))}
        </div>
      </div>

      {showBookShareModal && (
        <Modal_share_book
          setIsModal={(toggle) => {
            getBookList();
            setBookShareModal(toggle);
          }}
          userInfo={userInfo}
        />
      )}
    </>
  );
};

export default Profile_class;
