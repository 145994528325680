import { React, useState, useEffect, useMemo } from "react";
import style from "../../../../../asset/style";
import { useNavigate, useParams } from "react-router";
import UiTextBtn from "../../../../../uiComponents/UiTextBtn/UiTextBtn";
import Reply_Style from "./Reply_Style";
import apiFn from "../../../../../asset/apiClass";
import util from "../../../../../asset/util";
import ContentEditor from "./components/ContentEditor";
import { UiInput } from "../../../../../uiComponents/UiInput/UiInput";

const Reply = ({ userInfo, bid }) => {
  let navigate = useNavigate();

  const isTeacher = useMemo(() => userInfo.type === 2, [userInfo]);

  var [replyData, setReplyData] = useState([]);

  const [content, setContent] = useState("");

  // 댓글 읽기
  const apiPro_read = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "board_reply",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            "HIQDB_board_reply.boid": `= '${bid}'`,
            "HIQDB_board_reply.rtype": `= '0'`,
          },
        },
      });

      setReplyData([...data["list"]]);
    } catch (e) {}
  };

  // 댓글 수정전 일기
  const apiPro_read_2 = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "board_reply",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            "HIQDB_board_reply.id": `= '${bid}'`,
            "HIQDB_board_reply.rtype": `= '0'`,
          },
        },
      });
      setContent(data["list"][0]["content"]);
    } catch (e) {}
  };

  // 댓글 등록
  const apiPro_regi = async () => {
    try {
      let insObj = {
        boid: bid,
        wid: userInfo.id,
        content: content,
        reg_date: 0,
        rtype: 0,
        wtype: isTeacher ? 2 : 1,
      };

      await apiFn.comApi({
        state: {
          table: "board_reply",
          ins: [insObj],
        },
      });
      setContent("");
    } catch (e) {}
  };

  const apiPro_del = async (id) => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "board_reply",
          del: [
            {
              "HIQDB_board_reply.id": id,
            },
          ],
        },
      });
      await apiPro_read();
    } catch (e) {}
  };

  useEffect(() => {
    apiPro_read();
  }, []);

  return (
    <div style={{ position: "relative", marginBottom: 60 }}>
      <div style={{ ...Reply_Style.text_1, display: "flex", justifyContent: "space-between" }}>
        {/* 2345개 댓글 */}
        <div>{`${replyData.length}개 댓글`}</div>
      </div>

      <div
        style={{
          position: "sticky",
          top: 0,
          left: 20,
          backgroundColor: "#fff",
          zIndex: 3,
          display: "flex",
          justifyContents: "space-between",
        }}
      >
        <UiInput inputValue={content} fnChange={(e) => setContent(e.target.value)} />
        <button
          onClick={async () => {
            await apiPro_regi();
            await apiPro_read();
          }}
          style={{
            border: "1px solid rgb(247, 184, 101)",
            borderRadius: 4,
            color: "#fff",
            fontWeight: 600,
            backgroundColor: "rgb(247, 184, 101)",
          }}
        >
          입력
        </button>
      </div>
      {replyData?.map((v, i) => (
        <ReplySection data={v} apiPro_del={apiPro_del} userInfo={userInfo} isTeacher={isTeacher} />
      ))}
    </div>
  );
};

const ReplySection = ({ index, data, userInfo, isTeacher, apiPro_del }) => {
  let params = useParams();
  let navigate = useNavigate();

  const [isWarn, setIsWarn] = useState(false);

  // 신고하기 읽기
  const apiPro_warn_read = async () => {
    try {
      let r_data = await apiFn.comApi({
        state: {
          table: "board_reply",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            "HIQDB_board_reply.wid": `='${userInfo["id"]}'`,
            "HIQDB_board_reply.boid": `='${data["id"]}'`,
            "HIQDB_board_reply.rtype": `='1'`,
          },
        },
      });
      // console.log("@@ ",r_data);
      if (r_data["list"].length > 0) {
        setIsWarn(true);
      } else {
        setIsWarn(false);
      }
    } catch (e) {
      // console.log("$$$ ",e);
    }
  };

  const apiPro_warn_del = async () => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "board_reply",
          del: [
            {
              wid: userInfo["id"],
              boid: data["id"],
              rtype: 1,
            },
          ],
        },
      });
      // console.log("@@ ",isOk)
      await apiPro_warn_read();
    } catch (e) {}
  };

  useEffect(() => {
    // console.log("REPLY DATA => ",data);
    apiPro_warn_read();
  }, []);

  return (
    <div
      style={{
        padding: "14px 10px",
        borderBottom: "1px solid #F6F6F6",
        backgroundColor: index % 2 == 0 ? "#FFFBF5" : "#FBFBFB",
        marginBottom: "2px",
        borderRadius: "5px",
      }}
      onClick={(e) => {
        navigate(`/myInfo/${params["who"]}/${data["wid"]}`);
      }}
    >
      <div style={Reply_Style.section_r_w}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          <ContentEditor content={data.content} readOnly />
          <div
            style={{
              color: "#B0B0B0",
            }}
          >
            {util.convertToDateTime(data["reg_date"])}
          </div>
        </div>
        {/* <div style={{
                    fontSize:"14px",
                    fontWeight:400,
                }}>
                    {data["content"]}
                </div> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontWeight: 400,
            }}
          >
            {data["nick"]}
          </div>
          {userInfo["id"] == data["wid"] && (
            <div
              style={{
                display: "flex",
              }}
            >
              <UiTextBtn
                isUnderline={false}
                text={"수정"}
                btnStyle={{
                  fontSize: "12px",
                  fontWeight: 400,
                  color: style.common.fontColor_1,
                }}
                fnClick={(e) => {
                  navigate("/reply/" + params["who"] + "/" + params["id"] + "/modi&" + data["id"]);
                  e.stopPropagation();
                  e.preventDefault();
                }}
              />
              <UiTextBtn
                isUnderline={false}
                text={"삭제"}
                btnStyle={{
                  fontSize: "12px",
                  fontWeight: 400,
                  marginLeft: "10px",
                  color: style.common.fontColor_3,
                }}
                fnClick={(e) => {
                  apiPro_del(data["id"]);
                  e.stopPropagation();
                  e.preventDefault();
                }}
              />
            </div>
          )}
          {userInfo["id"] != data["wid"] && (
            <UiTextBtn
              isUnderline={false}
              text={isWarn ? "신고취소" : "신고하기"}
              btnStyle={{
                marginLeft: "10px",
                fontSize: "12px",
                fontWeight: 400,
                color: style.common.fontColor_3,
              }}
              fnClick={(e) => {
                if (isWarn) {
                  alert("신고를 철회하시겠어요?");
                  apiPro_warn_del();
                  e.preventDefault();
                  e.stopPropagation();
                } else {
                  // console.log("@@@@@@@@")
                  navigate("/warn/" + params["who"] + "/" + data["id"] + "/reply");
                  e.preventDefault();
                  e.stopPropagation();
                  return;
                }
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { ReplySection, Reply };
