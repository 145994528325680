import RandomUtil from "@utils/common/randomUtil";
import React, { useCallback, useState } from "react";
import LocalOauthView from "@presentation/views/authViews/localOauthView";
import { AccountFormFiledType } from "@presentation/components/common/molecules/form/account/account-form.types";
import { useLocalLogin } from "@hooks/queries/auth/useLocalAuth";
import { useLocalAuthToResponse } from "../../../page/auth/localAuth/hook/useLocalAuthToResponse";
import { FormProvider, useForm } from "react-hook-form";

export default function LocalOauthPage() {
  const generateRandomOauth = useCallback(
    () => ({
      nickname: RandomUtil.getRandomKoreanNickname(),
      identity: `${RandomUtil.getRandomString(10)}testAccount`,
      userName: RandomUtil.getRandomKoreanFullName(),
      email: RandomUtil.getRandomEmail(),
      phoneNumber: RandomUtil.getRandomPhoneNumber(),
    }),
    []
  );

  const [randomOauth, setRandomOauth] = useState(() => {
    const savedData = localStorage.getItem("oauthData");
    if (savedData) {
      return JSON.parse(savedData);
    } else {
      const randomOauth = generateRandomOauth();
      localStorage.setItem("oauthData", JSON.stringify(randomOauth));
      return randomOauth;
    }
  });

  const { mutateAsync } = useLocalLogin();
  const { mutateAsync: localAuthCallbackMutateAsync } = useLocalAuthToResponse();
  const methods = useForm<AccountFormFiledType>({
    mode: "onChange",
    delayError: 500,
    criteriaMode: "all",
  });

  const handleRandomOauth = () => {
    const randomOauth = generateRandomOauth();
    setRandomOauth(randomOauth);
    localStorage.setItem("oauthData", JSON.stringify(randomOauth));
  };

  const handleCheckLogin = async (data: AccountFormFiledType) => {
    await mutateAsync({ oauthInfo: data });
  };

  const handleLocalAuthCallback = async () => {
    await localAuthCallbackMutateAsync(methods.getValues());
  };

  return (
    <FormProvider {...methods}>
      <LocalOauthView
        onSubmit={handleCheckLogin}
        defaultValues={randomOauth}
        onRandomOauth={handleRandomOauth}
        onLocalAuthCallback={handleLocalAuthCallback}
      />
    </FormProvider>
  );
}
