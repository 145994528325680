import { axiosInstanceWithOutAuth } from "@infrastructure/api";
import { ILocalAuthRequest, ILocalAuthResponse } from "./type";

export const localAuthApi = async (
  localAuthDataRequest: ILocalAuthRequest
): Promise<ILocalAuthResponse> => {
  try {
    const response = await axiosInstanceWithOutAuth.post("/auth/local", localAuthDataRequest, {
      withCredentials: true,
    });
    return response.data;
  } catch (error: any) {
    throw new Error(`Error in localAuthApi: ${error.message}`);
  }
};
