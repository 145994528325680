import { FontSize } from "../../domain/entities/theme/font-size.entity";
import { ITypographyRepository } from "../../domain/interfaces/theme/typography-repository.interface";
import { FontWeight } from "../../domain/entities/theme/font-weight.entity";

export class ThemeTypographyRepository implements ITypographyRepository {
  constructor(private theme: any) {}

  getFontSize(size: string): FontSize | null {
    const themeSize = this.theme.typography.fontSizes[size];
    return themeSize ? FontSize.create(themeSize) : null;
  }

  getFontWeight(weight: string): FontWeight | null {
    const themeWeight = this.theme.typography.fontWeights[weight];
    return themeWeight ? FontWeight.create(themeWeight) : null;
  }

  getDefaultFontSize(): FontSize {
    return FontSize.create(this.theme.typography.fontSizes.base)!;
  }

  getDefaultFontWeight(): FontWeight {
    return FontWeight.create(this.theme.typography.fontWeights.regular)!;
  }
}
