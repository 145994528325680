import { IAuthRepository } from "@interfaces";
import { ILocalAuth, ILocalAuthBody } from "@entities";
import { axiosInstanceWithOutAuth } from "@infrastructure/api";
import { CONFIG } from "@config";

class AuthRepositoryImpl implements IAuthRepository {
  constructor() {}
  async setLoginLocalAuth(data: ILocalAuthBody): Promise<ILocalAuth> {
    try {
      const response = await axiosInstanceWithOutAuth.post(
        CONFIG.API.API_ENDPOINTS.AUTH.LOCAL_AUTH,
        data,
        {
          withCredentials: true,
        }
      );
      return response.data;
    } catch (e: any) {
      throw new Error(`Error in setLoginLocalAuth: ${e.message}`);
    }
  }
}

export const authRepository = new AuthRepositoryImpl();
