export const ACADEMY_METADATA = {
  allInOneMath: {
    id: 396,
    code: "aio",
    appName: "ALLINONE",
    academyName: "올인원수학학원",
  },
  dawonMds: {
    id: 892,
    code: "mds",
    appName: "DAWONMDS",
    academyName: "다원학원",
  },
  hansuwiMath: {
    id: 447,
    code: "hsw",
    appName: "HANSUWI",
    academyName: "한수위 수학 전문학원",
  },
  jongroYeongsu: {
    id: 928,
    code: "jym",
    appName: "JONGROYEONGSU",
    academyName: "종로 영수 전문학원",
  },
  everyMath: {
    id: 634,
    code: "mie",
    appName: "EVERYMATH",
    academyName: "매일의 수학",
  },
  myeongSeongMath: {
    id: 802,
    code: "msm",
    appName: "MYEONGSEONG",
    academyName: "명성 수학 학원",
  },
  seoRoKorean: {
    id: 761,
    code: "srk",
    appName: "SEOROKOREAN",
    academyName: "서로국어",
  },
  costudy: {
    id: 816,
    code: "csd",
    appName: "COSTUDY",
    academyName: "코스터디",
  },
  snEdu: {
    id: 638,
    code: "sne",
    appName: "SNEDU",
    academyName: "SNEDU(에스엔에듀)",
  },
  wellMadeMath: {
    id: 800,
    code: "wmm",
    appName: "WELLMADE",
    academyName: "명작수학",
  },
  // TODO: 하이큐썸일때 id를 null 하는것이 맞는 것인가..!
  default: {
    id: null,
    code: "default",
    appName: "HIQSUM",
    academyName: "하이큐썸",
  },
};
