import theme, { Theme } from "@constants/theme";

class ThemeUtil {
  private theme: Theme;

  constructor(theme: Theme) {
    this.theme = theme;
  }

  getColor(colorKey: string): string | undefined {
    const [mainKey, subKey] = colorKey.split(".");

    if (subKey === undefined) {
      return this.theme.colors[mainKey][70];
    }

    return this.theme.colors[mainKey][subKey];
  }

  getFontSize(size: keyof Theme["fontSizes"]): string {
    return this.theme.fontSizes[size];
  }

  getSpacing(size: keyof Theme["space"]): string {
    return this.theme.space[size];
  }

  getFontWeights(weight: keyof Theme["fontWeights"]): number {
    return this.theme.fontWeights[weight];
  }
}

export const themeUtil = new ThemeUtil(theme);
