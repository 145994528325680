import Service from "constants/terms/terms-of-service";
import React from "react";
import { PrivacyPolicy } from "@constants/terms/privacy-policy";
import { MarketingAndAdvertisingConsent } from "@constants/terms/marketing-and-advertising-consent";

export const terms = [
  {
    title: "서비스 이용약관",
    content: "서비스 이용약관 내용입니다.",
    docs: <Service />,
    require: true,
  },
  {
    title: "개인정보 처리방침",
    content: "개인정보 처리방침 내용입니다.",
    docs: <PrivacyPolicy />,
    require: true,
  },
  {
    title: "개인정보의 마케팅 및 광고 활용 약관",
    content: "개인정보의 마케팅 및 광고 활용 약관 내용입니다.",
    docs: <MarketingAndAdvertisingConsent />,
    require: false,
  },
];
