import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import apiFn from "../../asset/apiClass";
import constants from "../../asset/constants";
import style from "../../asset/style";
import Frame from "../../common/frame/Frame";
import UiPeople from "../../uiComponents/UiPeople/UiPeople";
import UiSearchBox from "../../uiComponents/UiSearch/UiSearchBox";
import UiSorting from "../../uiComponents/UiSorting/UiSorting";
import store from "../../asset/store";

const Invite = ({
  // isTeacher
  userInfo,
  id_set,
  setId_set,
  fn,
}) => {
  let params = useParams();
  let navigate = useNavigate();

  const [isTeacher, setIsTeacher] = useState(true);
  const [key, setKey] = useState("");

  const [search, setSearch] = useState("");
  const [title, setTitle] = useState("");
  const [searchType, setSearchType] = useState("name");

  var [data, setData] = useState([]);

  // 선생님 강의 초대
  const apiPro_ins = async (d) => {
    try {
      // console.log(d)
      // return
      let send_type = "hakwon_invite";
      if (params["type"] == "lt") {
        send_type = "suup_invite";
        if (params["form"] == "st") {
          send_type = "student_invite";
        }
      }
      let cid = key.split("&")[1];
      let isOk = await apiFn.comApi({
        state: {
          table: "member_class",
          sendMsg: {
            type: send_type,
            targetMid: d["id"],
            orderMid: userInfo["id"],
            cid: cid,
            channel: constants.channelId,
          },
          ins: [
            {
              mid: d["id"],
              cid: cid,
              type: 9,
              // type : params["form"] == "st" ? 0 : 9,
              // status : params["form"] == "st" ? 0 : 1,
              status: 5,
              reg_date: 0,
            },
          ],
        },
      });

      const stack = store.get("stack");
      if (stack.length > 0) navigate(-1);
      else navigate("/");
    } catch (e) {
      // console.log("@@@@ ",e);
    }
  };

  // 선생님 교재 초대
  const apiPro_ins_book = async (d) => {
    try {
      let bid = key.split("&")[1];
      let isOk = await apiFn.comApi({
        state: {
          table: "book_link",
          sendMsg: {
            type: "book_invite",
            targetMid: d["id"],
            orderMid: userInfo["id"],
            boid: bid,
          },
          ins: [
            {
              mid: d["id"],
              bid: bid,
              type: 8,
              status: 5,
            },
          ],
        },
      });

      const stack = store.get("stack");
      if (stack.length > 0) navigate(-1);
      else navigate("/");
    } catch (e) {}
  };

  const apiPro_read = async () => {
    try {
      let state = {};
      if (params["form"] == "st") {
        state = {
          table: "user_credential",
          sort: "reg_date desc",
          ref: ["A.* |HIQDB_user A on A.index=HIQDB_user_credential.user_id and A.user_type = 1"],
          page: 1,
          amount: 999,
          where: {
            [`A.${searchType}`]: `= '${search}'`,
          },
        };
      } else if (params.form === "de") {
      } else {
        state = {
          table: "user_credential",
          sort: "reg_date desc",
          page: 1,
          ref: ["A.* |HIQDB_user A on A.index=HIQDB_user_credential.user_id and user_type = 2"],
          amount: 999,
          where: {
            [`A.${searchType}`]: `= '${search}'`,
          },
          // equal:{
          //     id : search
          // },
        };
      }

      let data = await apiFn.comApi({
        state: state,
      });
      // console.log("@@ ",data)
      if (data["list"].length > 0) {
        setData([...data["list"]]);
      } else {
        setData([]);
      }
    } catch (e) {}
  };

  useEffect(() => {
    // console.log("parmas => ",params)
    setKey(params["key"]);
    if (params["who"] == "teacher") {
      setIsTeacher(true);
    } else {
      setIsTeacher(false);
    }

    setId_set("");
  }, [params]);

  return (
    <Frame
      title={params["form"] == "st" ? "학생 찾기" : "선생님 찾기"}
      isSearch={false}
      userInfo={userInfo}
      // isTap={true}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0px 10px",
          width: "100%",
        }}
      >
        <UiSorting
          isTeacher={params["form"] == "st" ? false : true}
          data={[
            { name: "이름으로", value: "name" },
            { name: "닉네임으로", value: "nick" },
            { name: "전화번호로", value: "tel" },
            { name: "이메일로", value: "email" },
          ]}
          fnClick={(v) => {
            setSearchType(v);
          }}
        />
        <div
          style={{
            width: "100%",
            marginTop: "10px",
            fontSize: "14px",
            color: style.common.fontColor_1,
            // fontWeight : "bold"
          }}
        >
          <UiSearchBox
            title="검색"
            isTeacher={params["form"] == "st" ? false : true}
            fnChange={(e) => {
              setSearch(e.target.value);
            }}
            fnClick={() => {
              setTitle(search);
              apiPro_read();
            }}
          />
          <div
            style={{
              padding: "10px 0px",
              width: "100%",
              textAlign: "center",
            }}
          >
            <div>* 이름,전화번호 및 이메일 검색은 개인정보 보호를 위해</div>
            <div>검색어와 완전 일치하는 대상만 검색됩니다.</div>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            marginTop: "10px",
            fontSize: "14px",
            color: style.common.fontColor_1,
            // fontWeight : "bold"
          }}
        >
          <div
            style={{
              padding: "10px 0px",
              width: "100%",
              // textAlign:"center",
            }}
          >
            {data.length > 0 && (
              <div
                style={{
                  display: "flex",
                }}
              >
                {`"${title}" 검색결과 `}
                <div
                  style={{
                    marginLeft: "3px",
                    color: style.common.t_color,
                  }}
                >
                  {` (${data.length}건)`}
                </div>
              </div>
            )}
            <UiPeople
              userInfo={userInfo}
              isSearch={true}
              data={data}
              isBlank={false}
              form={2}
              isTeacher={params["form"] == "st" ? false : true}
              fnClick={(d) => {
                let type = params["type"];
                if (type == "lt" || type == "c" || type == "st") {
                  apiPro_ins(d);
                } else if (type == "b") {
                  apiPro_ins_book(d);
                }
                // setId_set(d["id"])
              }}
              isOnly={true}
            />
          </div>
        </div>
      </div>
    </Frame>
  );
};

export default Invite;
