import { axiosInstanceWithAuth } from "@infrastructure/api";

export const logoutApi = async () => {
  try {
    const response = await axiosInstanceWithAuth.post("/account/logout");
    return response.data;
  } catch (error: any) {
    throw new Error(`Error in logoutApi: ${error.message}`);
  }
};
