import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Basic from "../../common/frame/Basic";
import apiFn from "../../asset/apiClass";
import UiSorting from "../../uiComponents/UiSorting/UiSorting";
import Column_pick_Style from "./Column_pick_Style";
import empty_folder from "../../asset/Img/empty-folder.png";
import util from "../../asset/util";
import constants from "../../asset/constants";
import store from "../../asset/store";
import { getUser } from "@asset/storage";

const Column_pick_list = () => {
  let navigate = useNavigate();
  let params = useParams();
  const userInfo = getUser();

  const [isTeacher, setIsTeacher] = useState(true);

  const [folderList, setFolderList] = useState([]);

  // 찜
  const apiPro_regi = async (d) => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "folder_link",
          ins: [
            {
              fid: d,
              boid: params["id"],
              mid: userInfo["id"],
              reg_date: 0,
            },
          ],
          // ins: [ {col1:1, col2:2} ],
        },
      });

      const stack = store.get("stack");
      if (stack.length > 0) navigate(-1);
      else navigate("/");
    } catch (e) {}
  };

  const apiPro_read = async (sorting) => {
    try {
      let data = await apiFn.comApi({
        state: {
          thumb: "y",
          table: "folder",
          sort: sorting ? sorting : "id desc",
          page: 1,
          amount: 9999,
          where: {
            "HIQDB_folder.mid": `='${userInfo["id"]}'`,
            "HIQDB_folder.ftype": `='2'`,
          },
        },
      });
      setFolderList([...data["list"]]);
    } catch (e) {}
  };

  useEffect(() => {
    // console.log("parmas => ",params)
    if (params["who"] == "teacher") {
      setIsTeacher(true);
    } else {
      setIsTeacher(false);
    }
  }, [params]);

  useEffect(() => {
    apiPro_read();
  }, []);

  return (
    <Basic title="찜할 폴더 추가">
      <UiSorting
        title="찜 폴더"
        isTeacher={isTeacher}
        data={[
          { name: "최신순", value: "id desc" },
          { name: "글자순", value: "name desc" },
        ]}
        fnClick={(v) => {
          apiPro_read(v);
        }}
      />
      {folderList.map((v, i) => (
        <PickSection
          fnClick={(data) => {
            let isOk = window.confirm(`[${v["name"]} 폴더] 에 컬럼을 추가 하시겠어요?`);
            if (isOk) {
              apiPro_regi(data["id"]);
            }

            // navigate("/columnclassify/"+params["who"]+"/"+data)
          }}
          data={v}
        />
      ))}
    </Basic>
  );
};

const PickSection = ({ data, fnClick }) => {
  useEffect(() => {
    // console.log("data => ",data)
  }, [data]);

  return (
    <div
      style={Column_pick_Style.section_w}
      onClick={() => {
        fnClick(data);
      }}
    >
      <div style={Column_pick_Style.section_i_w}>
        <img
          onError={(e) => {
            // setIsLoad(false)
            e.currentTarget.src = empty_folder;
          }}
          src={
            data["thumb"]["video_link"]
              ? data["thumb"]["video_link"].includes("watch")
                ? `http://img.youtube.com/vi/${data["thumb"]["video_link"].split("=")[1].split("&")[0]}/mqdefault.jpg`
                : `http://img.youtube.com/vi/${data["thumb"]["video_link"].split("youtu.be/")[1].split("&")[0]}/mqdefault.jpg`
              : // `http://img.youtube.com/vi/${data["thumb"]["video_link"].split("=")[1]}/mqdefault.jpg`
                // `${data["video_link"]}/default.jpg`
                data["thumb"]["id"]
                ? constants.apiUrl +
                  // '/public'
                  // +
                  `/board/${data["thumb"]["id"]}` +
                  ".jpg?" +
                  util.getRandomInt(1000, 9999)
                : empty_folder
          }
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </div>
      <div style={Column_pick_Style.section_text_w}>
        <div style={Column_pick_Style.section_text_1}>{data["name"]}</div>
        <div style={Column_pick_Style.section_text_2}>{`컬럼 ${data["thumb"]["cnt"]}개`}</div>
      </div>
    </div>
  );
};

export default Column_pick_list;
