import React from "react";
import GhostButton from "@components/atoms/ghostButton";
import { IcClose } from "@components/atoms/icons/close";
import styled from "styled-components";
import { Typography } from "@presentation/components/common/atoms";

const SelectLecturesList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.spacing.sm};
  padding-top: ${({ theme }) => theme.spacing.sm};
`;

const SelectLectureItem = styled.li`
  background: ${({ theme }) => theme.colors.primary[60]};
  padding: ${({ theme }) => theme.spacing.xs} ${({ theme }) => theme.spacing.md};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  color: ${({ theme }) => theme.colors.white[70]};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.xs};
`;
type SelectedLecturesProps = {
  selectedLectures: { lectureName: string; lectureId: number }[];
  onToggle: ({ lectureName, lectureId }: { lectureName: string; lectureId: number }) => void;
};

export default function SelectedLecturesList({
  selectedLectures,
  onToggle,
}: SelectedLecturesProps): JSX.Element {
  return (
    <SelectLecturesList>
      {selectedLectures.map((lecture) => (
        <SelectLectureItem key={lecture.lectureId}>
          <Typography variant={"bold.xs"} color={"white"}>
            {lecture.lectureName}
          </Typography>
          <GhostButton
            onClick={() =>
              onToggle({
                lectureName: lecture.lectureName,
                lectureId: lecture.lectureId,
              })
            }
          >
            <IcClose size={16} />
          </GhostButton>
        </SelectLectureItem>
      ))}
    </SelectLecturesList>
  );
}
