import { NavigateFunction } from "react-router";

class NavigationService {
  private static instance: NavigationService;
  private navigator?: NavigateFunction;

  private constructor() {}

  static getInstance(): NavigationService {
    if (!NavigationService.instance) {
      NavigationService.instance = new NavigationService();
    }
    return NavigationService.instance;
  }

  initialize(navigator: NavigateFunction) {
    this.navigator = navigator;
  }

  private ensureNavigator(): NavigateFunction {
    if (!this.navigator) {
      throw new Error("NavigationService must be initialized with navigate function");
    }
    return this.navigator;
  }
  // 기본 네비게이션
  goTo(path: string) {
    this.ensureNavigator()(path);
  }

  // 뒤로 가기
  goBack() {
    this.ensureNavigator()(-1);
  }

  // state와 함께 이동
  goToWithState(path: string, state: any) {
    this.ensureNavigator()(path, { state });
  }

  // 쿼리 파라미터와 함께 이동
  goToWithQuery(path: string, query: Record<string, string>) {
    const searchParams = new URLSearchParams(query);
    this.ensureNavigator()(`${path}?${searchParams.toString()}`);
  }

  // 히스토리 스택에 추가하지 않는 이동
  replace(path: string) {
    this.ensureNavigator()(path, { replace: true });
  }
}

export const navigationService = NavigationService.getInstance();
