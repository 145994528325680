import { API } from "./api";
import { APP_ENV } from "./env";
import { CONSTANTS } from "./constants";

export const CONFIG = {
  API,
  APP_ENV,
  CONSTANTS,
};

Object.freeze(CONFIG);
