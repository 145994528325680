import styled from "styled-components";
import { Theme } from "@constants/theme";

export type TypographyProps = {
  variant?: string;
  color?: string;
  fontWeight?: keyof Theme["fontWeights"];
  fontSize?: keyof Theme["fontSizes"];
  textAlign?: "left" | "center" | "right";
};

const Typography = styled.p<TypographyProps>`
  ${({ variant = "reg.14", color = "natural", fontWeight, fontSize, theme, textAlign }) => {
    const [weightType, sizeType]: [keyof Theme["fontWeights"], keyof Theme["fontSizes"]] =
      variant.split(".") as [keyof Theme["fontWeights"], keyof Theme["fontSizes"]];
    return `    
      text-align: ${textAlign};
    `;
  }}
`;

export default Typography;
