import { SignUpRepository } from "../repository/signUp.repository";
import { JoinUseCase } from "../usecase/join.useCase";
import { MarketingNotifyUseCase } from "../usecase/marketing-notify.useCase";
import { useMutation } from "@tanstack/react-query";
import { IAccountJoinRequest } from "../../../../shared/api/api-gateway/account/join/type";

export interface IJoinData {
  join: IAccountJoinRequest;
  marketingNotify: boolean;
}

export const useJoin = () => {
  const signUpRepository = new SignUpRepository();
  const joinUseCase = new JoinUseCase(signUpRepository);
  const marketingNotifyUseCase = new MarketingNotifyUseCase(signUpRepository);

  return useMutation({
    mutationFn: async (joinData: IJoinData) => {
      const response = await joinUseCase.execute(joinData.join);
      await marketingNotifyUseCase.execute(joinData.marketingNotify);
      return response;
    },
  });
};
