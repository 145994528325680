import React, { forwardRef } from "react";
import styled from "styled-components";

export interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const StyledInput = styled.input`
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.natural[30]};
  padding: ${({ theme }) => theme.spacing.sm};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
`;

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(({ ...props }, ref) => {
  return <StyledInput ref={ref} {...props} />;
});

export default TextInput;
