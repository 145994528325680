import { ACADEMY_METADATA, APP_METADATA, OAUTH, URL } from "../common/constants";
import { urlManager } from "../common/util/url/url.manager";

const constants = {
  //true: 학원앱, false: 하이큐썸
  isAcademy: APP_METADATA.IS_ACADEMY_APP,
  //HIQSUM, ALLINONE과 같은 형태
  academyId: ACADEMY_METADATA.APP_NAME,
  //학원앱: aio 같은 형태, 하이큐썸: default
  channelId: ACADEMY_METADATA.ACADEMY_CODE,
  ownerId: process.env.REACT_APP_OWNER_ID,
  //학원앱: 396 과같은 학원의 id, 하이큐썸: null
  classroomId: ACADEMY_METADATA.ACADEMY_ID,
  //ture: 개발환경, false: 운영환경
  isDev: APP_METADATA.IS_DEVELOPMENT,
  //이미지, 동영상등 서버로부터 gcs에 업로드된 파일을 가져올때 사용하는 url
  s3_url: URL.MEDIA_STORAGE_URL,
  //동영상 버킷 url 주소
  video_bucket: `https://storage.googleapis.com/${APP_METADATA.IS_DEVELOPMENT ? "hiqsum-test-video" : "hiqsum-video"}`,
  //api 서버 주소
  apiUrl: URL.BASE_API_URL,
  //웹소켓 주소
  wsurl: URL.WEBSOCKET_URL,
  //origin 주소 (path로 바꾸면서 뒤에있는 path까지 붙이는걸로)
  redirect_url: urlManager.getOriginWithFirstPathSegment(),
  //네이버 clientId
  naverClientId: OAUTH.NAVER_CLIENT_ID,
  //TODO: 이따 지우자
  naverClientSecret: process.env.REACT_APP_NAVER_SECRET_KEY,
  //세모해 URL
  semohaeUrl: URL.SEMOHAE_URL,
  fileSize: 10 * 1024 * 1024 * 1024,
  confirm: {
    cf_1: "회원을 탈퇴하시겠어요?\n(탈퇴시 등록하신 교재,대화 등 모든 정보가 삭제되며 복구할 수 없습니다.",
    cf_2: "선택한 교재를 삭제하시겠어요? \n(교재에 속한 질문이 모두 사라지며 복구할 수 없어요.)",
    cf_3: "선택한 폴더를 삭제하시겠어요? \n(삭제시 폴더 내의 교재는 모두 폴더 바깥으로 이동됩니다.)",
    cf_4: "에 선생님으로 등록을 신청하시겠어요? \n(클래스룸측의 승인 후 등록이 완료됩니다.)",
    cf_5: "수업에 등록을 신청하시겠어요? \n(클래스룸이나 선생님의 승인 후 등록이 완료됩니다.)",
    cf_6: "를 폐강하시겠어요?\n(폐강한 강의는 학생에게 노출되지 않아요.)",
    cf_7: "로그아웃 하시겠어요?",
    cf_8: "선택한 교재의 공유를 취소하시겠어요?",
    cf_9: "를 삭제하시겠어요?\n(삭제한 강의는 다시 복구할 수 없어요.)",
    cf_10: "선택한 교재의 공유를 취소하시겠어요?",
    cf_11: "정보를 변경하시겠어요?",
    cf_12: "변경할 비밀번호가 다릅니다. 다시확인해주세요.",
    cf_13: "비밀번호를 다시 확인해주세요.",
    cf_14: "비밀번호가 변경 되었습니다.",
    cf_15: "선택하신 클래스룸에 등록을 신청하시겠어요?",
    cf_16: "클래스룸 등록 신청을 취소하시겠어요?",
    cf_17: "선택된 선생님을 탈퇴 처리 하시겠어요?",
    cf_18: "선택된 선생님들의 신청을 거절하시겠어요?",
    cf_19: "선택된 선생님들의 신청을 승인하시겠어요?",
    cf_20: "탈퇴 처리 하시겠어요?",
    cf_21: "승인 처리 하시겠어요?",
    cf_22: "교재를 생성하시겠어요?",
    cf_23: "생성이 완료되었습니다.",
    cf_24: "재로그인이 필요합니다.",
    cf_25: "네트워크 오류가 발생했습니다.",
  },
  regex_imo:
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
};

export const AUTH_CODE = {
  NO_TOKEN: "NO_TOKEN",
  INVALID_TOKEN: "INVALID_TOKEN",
  EXPIRED_TOKEN: "EXPIRED_TOKEN",
  NOT_VERIFIED: "NOT_VERIFIED",
  USER_VERIFIED: "USER_VERIFIED",
  USER_NOT_FOUND: "USER_NOT_FOUND",
  NOT_AUTHORIZED: "NOT_AUTHORIZED",
};
export const ACADEMY_NAMES = {
  396: "올인원 수학 학원",
  447: "한수위 수학 전문 학원",
  634: "매일의 수학",
  638: "SNEdu",
  761: "서로 국어 학원",
  800: "명작 수학 학원",
  802: "명성 수학 학원",
  816: "코스터디",
  892: "다원MDS",
  928: "종로 영수 전문 학원",
};

export default constants;
