import {
  ArrowBackIos,
  Bookmark,
  BookmarkBorder,
  Favorite,
  FavoriteBorder,
} from "@material-ui/icons";
import { React } from "react";
import { useNavigate, useParams } from "react-router";
import style from "../../asset/style";
import Basic_Style from "./Basic_Style";
import PropTypes from "prop-types";

const Basic = ({
  title,
  children,
  isHeart = false,
  isBookMark = false,
  isBookMarkPick = false,
  fnBookMark = () => {},
  fnFavorite = () => {},
  isFavorite = false,
  isPadding = true,
  route = -1,
  backEvent = () => {},
  containerStyle = {},
  pastSearchQna = false,
}) => {
  let navigate = useNavigate();
  let params = useParams();

  return (
    <div style={{ ...Basic_Style.c, ...containerStyle }}>
      <div
        style={{
          ...style.common.block,
          position: "unset",
          maxWidth: "100%",
        }}
      >
        <div
          style={{
            ...Basic_Style.t_w,
            padding: isPadding ? style.common.padding : "0px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: !isPadding ? style.common.padding : "0px",
              zIndex: 99999999999,
            }}
          >
            <ArrowBackIos
              onClick={() => {
                backEvent();
                // window.scrollTo(0,0)
                navigate(route);
              }}
            />
            <div style={Basic_Style.t_text}>{title}</div>
          </div>
          <div style={{}}>
            {isHeart &&
              (isFavorite ? (
                <Favorite
                  style={{
                    margin: "0px 5px",
                    color: params["who"] == "teacher" ? style.common.t_color : style.common.s_color,
                  }}
                  onClick={fnFavorite}
                />
              ) : (
                <FavoriteBorder
                  style={{
                    margin: "0px 5px",
                  }}
                  onClick={fnFavorite}
                />
              ))}
            {isBookMark &&
              (isBookMarkPick ? (
                <Bookmark
                  style={{
                    margin: "0px 5px",
                    color: params["who"] == "teacher" ? style.common.t_color : style.common.s_color,
                  }}
                  onClick={fnBookMark}
                />
              ) : (
                <BookmarkBorder
                  style={{
                    margin: "0px 5px",
                  }}
                  onClick={fnBookMark}
                />
              ))}
          </div>
        </div>
      </div>
      <div
        style={{
          ...Basic_Style.children_w,
          padding: isPadding ? style.common.padding : "0px",
          height: pastSearchQna ? "calc(100% - 50px)" : "",
        }}
      >
        {children}
      </div>
    </div>
  );
};

Basic.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isHeart: PropTypes.bool,
  isBookMark: PropTypes.bool,
  isBookMarkPick: PropTypes.bool,
  fnBookMark: PropTypes.func,
  fnFavorite: PropTypes.func,
  isFavorite: PropTypes.bool,
  isPadding: PropTypes.bool,
  route: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  backEvent: PropTypes.func,
  containerStyle: PropTypes.object,
  pastSearchQna: PropTypes.bool,
};

export default Basic;
