import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { CONFIG } from "@config";
import { tokenManager } from "../../../shared/api/api-gateway/token/token.manager";
import { TokenNameEnum } from "../../../shared/api/api-gateway/token/enum/token.enum";

const commonConfig = {
  baseURL: CONFIG.API.EXTERNAL_URL.API_BASE_URL,
  timeout: 5000,
};

export const axiosInstanceWithOutAuth = axios.create({
  ...commonConfig,
});

export const axiosInstanceWithAuth = axios.create({
  ...commonConfig,
  withCredentials: true,
});

axiosInstanceWithAuth.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const hasRefreshToken = tokenManager.hasToken(TokenNameEnum.REFRESH_TOKEN_METADATA);
    if (!hasRefreshToken) {
      window.location.replace("/");
      throw new Error("로그인이 필요합니다.");
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

const responseSuccessInterceptor = async (response: AxiosResponse<any>) => {
  return response;
};
const responseErrorInterceptor = async (error: AxiosError<any>) => {
  const isUnauthorized = error?.response?.status === 401;
  if (isUnauthorized) {
    alert("로그인이 필요합니다.");
    window.location.replace("/");
  }
  return Promise.reject(error);
};

axiosInstanceWithAuth.interceptors.response.use(
  responseSuccessInterceptor,
  responseErrorInterceptor
);
