export class ColorValue {
  private constructor(private value: string) {}

  static create(value: string): ColorValue | null {
    if (!value) return null;
    if (!ColorValue.isValid(value)) return null;
    return new ColorValue(value);
  }

  private static isValid(color: string): boolean {
    if (color.startsWith("#")) {
      return /^#([A-Fa-f0-9]{3}){1,2}$/.test(color);
    }

    if (color.startsWith("rgb")) {
      return true;
    }

    if (color.startsWith("hsl")) {
      return true;
    }

    return CSS.supports("color", color);
  }

  toString(): string {
    return this.value;
  }
}
