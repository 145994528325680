import React, { useEffect } from "react";
import { Path, useFormContext } from "react-hook-form";
import { AccountFormContainer, AccountFormFieldBox } from "@pages/signUp/accountInfo/styled";
import AccountFormField from "@components/molecules/accountFormField";
import { AccountFieldType } from "@constants/accountForm/types";
import { ACCOUNT_FORM_CONFIG } from "@constants/accountForm";
import { useNicknameCheck } from "@hooks/queries/account/useNicknameCheck";
import Button from "@components/atoms/button";

interface AccountFormProps {
  fields: Array<Path<AccountFieldType>>;
  defaultValues?: Partial<AccountFieldType>;
  onSubmit?: (data: AccountFieldType) => void;
  buttonBackgroundColor?: string;
}

export default function AccountForm({
  fields,
  defaultValues,
  onSubmit,
  buttonBackgroundColor,
}: AccountFormProps): JSX.Element {
  const { formState, control, setValue, handleSubmit } = useFormContext<AccountFieldType>();
  const { handleInputChange } = useNicknameCheck<AccountFieldType>();

  useEffect(() => {
    if (defaultValues) {
      Object.keys(defaultValues).forEach((key) => {
        setValue(key as Path<AccountFieldType>, defaultValues[key as keyof AccountFieldType]);
      });
    }
  }, [defaultValues, setValue]);

  return (
    <AccountFormContainer onSubmit={handleSubmit(onSubmit)}>
      <AccountFormFieldBox>
        {fields.map((key) => (
          <AccountFormField
            key={key}
            formState={formState}
            fieldName={key}
            config={ACCOUNT_FORM_CONFIG[key]}
            control={control}
            onChange={async (e) => {
              if (ACCOUNT_FORM_CONFIG[key].changeEvent)
                await handleInputChange(e.target.value, key);
            }}
          />
        ))}
      </AccountFormFieldBox>
      <Button
        type="submit"
        backgroundColor={buttonBackgroundColor}
        aria-label="가입 완료"
        disabled={!formState.isValid}
      >
        가입 완료
      </Button>
    </AccountFormContainer>
  );
}
