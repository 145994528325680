import { React, useState, useEffect, useCallback, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { Frame } from "../page_index";
import UiLoading from "uiComponents/UiLoading/UiLoading";
import { OrderDetailDocument, CancelOrderDocument } from "types/graphql/graphql";
import util from "asset/util";
import VirtualAccounts from "pages/Payment/Order/portoneAPIv2Banks.json";
import { PaymentContext } from "asset/context";

const Title = ({ children }) => (
  <div
    style={{
      fontSize: 20,
      fontWeight: 700,
      paddingTop: 4,
      paddingBottom: 4,
    }}
  >
    {children}
  </div>
);

const Box = ({ children }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      gap: 4,
      padding: 12,
      backgroundColor: "#FFFFFF",
      borderRadius: 8,
    }}
  >
    {children}
  </div>
);

const BoxItem = ({ title, value }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    }}
  >
    <div
      style={{
        minWidth: 82,
        textAlign: "left",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {title}
    </div>
    <div
      style={{
        textAlign: "left",
        flex: 1,
      }}
    >
      {value}
    </div>
  </div>
);

const CancelOrderButton = ({ onClick, disabled = false, children }) => (
  <button
    style={{
      width: "100%",
      height: 48,
      backgroundColor: "#000000",
      color: "#FFFFFF",
      fontSize: 18,
      fontWeight: 700,
      borderRadius: 8,
    }}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </button>
);

const MyInfo_orderdetail = ({ userInfo }) => {
  let params = useParams();
  let navigate = useNavigate();
  const { setForceUpdate } = useContext(PaymentContext);
  const [virtualAccountBank, setVirtualAccountBank] = useState();
  const [virtualAccountName, setVirtualAccountName] = useState();
  const [virtualAccountNumber, setVirtualAccountNumber] = useState();

  const userId = userInfo.id;
  const orderId = params["id"];
  const { loading, error, data } = useQuery(OrderDetailDocument, {
    fetchPolicy: "network-only",
    variables: {
      userId,
      orderId,
    },
  });
  const [cancelOrderMutation, { loading: cancelLoading, error: cancelError, data: cancelResult }] =
    useMutation(CancelOrderDocument);

  const onChangeVirtualAccountBank = useCallback((event) => {
    setVirtualAccountBank(event.target.value);
  });

  const onChangeVirtualAccountName = useCallback((event) => {
    setVirtualAccountName(event.target.value);
  });

  const onChangeVirtualAccountNumber = useCallback((event) => {
    setVirtualAccountNumber(event.target.value);
  });

  const detail = data?.orderDetail?.order || {};
  const waitingVirtualAccount = "PaymentMethodVirtualAccount" == detail?.payload?.type;

  const cancelOrder = useCallback(() => {
    if (detail.payMethod == "가상계좌") {
      if (!virtualAccountBank) {
        alert("환불받으실 은행을 선택해 주세요");
        return;
      }
      if (!virtualAccountName) {
        alert("환불받으실 계좌번호를 입력해 주세요");
        return;
      }
      if (!virtualAccountNumber) {
        alert("환불받으실 계좌 이름을 입력해 주세요");
        return;
      }
      const refundAccount = {
        bank: virtualAccountBank,
        holderName: virtualAccountName,
        number: virtualAccountNumber,
      };
      cancelOrderMutation({
        variables: {
          input: {
            orderId,
            userId,
            refundAccount,
          },
        },
      });
    } else {
      cancelOrderMutation({
        variables: {
          input: {
            orderId,
            userId,
          },
        },
      });
    }
  }, [
    detail,
    orderId,
    userId,
    cancelOrderMutation,
    virtualAccountBank,
    virtualAccountName,
    virtualAccountNumber,
  ]);

  useEffect(() => {
    if (error) {
      alert("상세 주문 내역을 가져오는데 실패했습니다.");
      navigate(-1);
    }
  }, [error, navigate]);

  useEffect(() => {
    if (cancelError) {
      alert("주문 취소에 실패했습니다.");
      navigate(-1);
    }
  }, [cancelError, navigate]);

  useEffect(() => {
    if (cancelResult) {
      const success = cancelResult?.cancelOrder?.success || false;
      const error = cancelResult?.cancelOrder?.error?.message;
      const payload = cancelResult?.cancelOrder?.payload;
      if (success) {
        setForceUpdate(new Date().getTime());
        alert("주문이 취소되었습니다.");
        navigate(-1);
      } else if (error) {
        alert(error);
      }
    }
  }, [cancelResult, setForceUpdate]);

  if (loading) {
    return <UiLoading text="로딩 중입니다." isText={false} />;
  }
  return (
    <Frame title={"주문 상세"} userInfo={userInfo} isSearch={false}>
      <div
        style={{
          width: "100%",
          backgroundColor: "#F1F1F1",
          padding: "24px 16px",
          display: "flex",
          flexDirection: "column",
          gap: 16,
        }}
      >
        {waitingVirtualAccount && detail.state == "결제 대기" && (
          <Box>
            <div style={{ textAlign: "center" }}>주문이 완료되었습니다.</div>
            <div style={{ textAlign: "center" }}>입금 완료 후 질문권이 전달됩니다.</div>
            <div
              style={{
                textAlign: "center",
                marginTop: 8,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                gap: 12,
              }}
            >
              <div style={{ fontWeight: 800 }}>
                {VirtualAccounts.find((item) => item.code == detail.payload.bank)?.["name"]}
              </div>
              <div style={{ fontWeight: 800 }}>
                <span>{detail.payload.accountNumber}</span>
              </div>
            </div>
            <div />
            <div style={{ textAlign: "center", marginTop: 8 }}>
              {"만료기한 "}
              {new Date(detail.payload.expiredAt).toLocaleDateString("ko-KR", {
                year: "numeric",
                month: "long",
                day: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })}
            </div>
          </Box>
        )}
        <Box>
          <Title>{"결제 내역"}</Title>
          <BoxItem title={"주문 번호"} value={detail.id} />
          <BoxItem title={"주문 일시"} value={detail.datetime} />
          <BoxItem title={"결제 금액"} value={util.formatCurrency(detail.totalAmount)} />
          <BoxItem title={"결제 수단"} value={detail.payMethod} />
          <BoxItem title={"결제 상태"} value={detail.state} />
        </Box>
        {detail?.cancels?.[0] && (
          <Box>
            <Title>{"취소 내역"}</Title>
            <BoxItem title={"취소 일시"} value={detail.cancels[0].datetime} />
            <BoxItem title={"취소 상태"} value={detail.cancels[0].status} />
            <BoxItem title={"취소 사유"} value={detail.cancels[0].reason} />
          </Box>
        )}
        <Box>
          <Title>{"주문 상품"}</Title>
          {detail.items.map((item) => (
            <BoxItem
              key={item.id}
              title={
                <>
                  <div style={{ fontWeight: 500 }}>{item.title}</div>
                  <div style={{ fontSize: 14 }}>{util.formatCurrency(item.price)}</div>
                </>
              }
              value={
                <div style={{ textAlign: "right", fontWeight: 800, fontSize: 20 }}>
                  {item.quantity}개
                </div>
              }
            />
          ))}
        </Box>
        {
          // 가상계좌로 결제했을 시에, 환불 계좌 입력
          detail?.canCancel && detail.payMethod == "가상계좌" && (
            <Box>
              <Title>{"환불 계좌"}</Title>
              <FormControl sx={{ m: 1, minWidth: 80 }}>
                <InputLabel id="virtual-account-select" size="small">
                  입금은행
                </InputLabel>
                <Select
                  size="small"
                  labelId="virtual-account-select"
                  value={virtualAccountBank}
                  onChange={onChangeVirtualAccountBank}
                  autoWidth
                  label="VirtualAccount"
                >
                  <MenuItem value="">
                    <em>_</em>
                  </MenuItem>
                  {VirtualAccounts.map((item) => (
                    <MenuItem value={item.code}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 80 }}>
                <TextField
                  id="virtual-account-number"
                  label="계좌번호"
                  variant="outlined"
                  size="small"
                  value={virtualAccountNumber}
                  onChange={onChangeVirtualAccountNumber}
                />
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 80 }}>
                <TextField
                  id="virtual-account-name"
                  label="이름"
                  variant="outlined"
                  size="small"
                  value={virtualAccountName}
                  onChange={onChangeVirtualAccountName}
                />
              </FormControl>
            </Box>
          )
        }
        {detail?.canCancel && (
          <CancelOrderButton
            disabled={
              detail.payMethod == "VIRTUAL_ACCOUNT" &&
              (!virtualAccountBank || !virtualAccountName || !virtualAccountNumber)
            }
            onClick={() => cancelOrder()}
          >
            주문취소
          </CancelOrderButton>
        )}
      </div>
      {cancelLoading && <UiLoading text="취소 중입니다." isText={false} />}
    </Frame>
  );
};

export default MyInfo_orderdetail;
