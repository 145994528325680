import { React, useState, useEffect } from "react";
import style from "../../asset/style";
import { useNavigate, useParams } from "react-router";
import Basic from "../../common/frame/Basic";
import UiTextBtn from "../../uiComponents/UiTextBtn/UiTextBtn";
import Reply_Style from "./Reply_Style";
import { UiInput } from "../../uiComponents/UiInput/UiInput";
import apiFn from "../../asset/apiClass";
import util from "../../asset/util";
import store from "../../asset/store";

const Reply = ({ userInfo }) => {
  let params = useParams();
  let navigate = useNavigate();

  const [isTeacher, setIsTeacher] = useState(true);

  var [replyData, setReplyData] = useState([]);

  const [content, setContent] = useState("");

  // 댓글 읽기
  const apiPro_read = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "board_reply",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            "HIQDB_board_reply.boid": `= '${params["id"]}'`,
            "HIQDB_board_reply.rtype": `= '0'`,
          },
        },
      });

      setReplyData([...data["list"]]);
    } catch (e) {}
  };

  // 댓글 수정전 일기
  const apiPro_read_2 = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "board_reply",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            "HIQDB_board_reply.id": `= '${params["where"].split("&")[1]}'`,
            "HIQDB_board_reply.rtype": `= '0'`,
          },
        },
      });
      setContent(data["list"][0]["content"]);
    } catch (e) {}
  };

  // 댓글 등록
  const apiPro_regi = async () => {
    try {
      let insObj = {
        boid: params["id"],
        wid: userInfo["id"],
        content: content,
        reg_date: 0,
        rtype: 0,
        wtype: isTeacher ? 2 : 1,
      };
      if (params["where"].split("&")[0] == "modi") {
        insObj["boid"] = params["where"].split("&")[1];
        await apiFn.comApi({
          state: {
            table: "board_reply",
            set: [
              {
                key: `id:${params["where"].split("&")[1]}`,
                value: {
                  content: content,
                },
              },
            ],
          },
        });

        const stack = store.get("stack");
        if (stack.length > 0) navigate(-1);
        else navigate("/");
      } else {
        await apiFn.comApi({
          state: {
            table: "board_reply",
            ins: [insObj],
          },
        });
        setContent("");

        const stack = store.get("stack");
        if (stack.length > 0) navigate(-1);
        else navigate("/");
      }
    } catch (e) {}
  };

  const apiPro_del = async (id) => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "board_reply",
          del: [
            {
              "HIQDB_board_reply.id": id,
            },
          ],
        },
      });
      await apiPro_read();
    } catch (e) {}
  };

  useEffect(() => {
    // console.log("parmas => ",params)
    if (params["who"] == "teacher") {
      setIsTeacher(true);
    } else {
      setIsTeacher(false);
    }

    if (params["where"].split("&")[0] == "modi") {
      // // console.log("@?@?@? ###");
      apiPro_read_2();
    } else if (params["where"] == "main") {
      apiPro_read();
    }
  }, [params]);

  useEffect(() => {}, []);

  return (
    <Basic title={params["where"] == "main" ? "댓글" : "댓글 쓰기"}>
      <div
        style={{
          ...Reply_Style.t_btn_w,
          color: isTeacher ? style.common.t_color : style.common.s_color,
        }}
        onClick={() => {
          if (params["where"] == "main") {
            navigate("/reply/" + params["who"] + "/" + params["id"] + "/write");
          } else {
            // navigate(-1)
            apiPro_regi();
          }
        }}
      >
        {params["where"] == "main" ? "댓글 쓰기" : "등록"}
      </div>
      {params["where"] == "main" ? (
        <>
          <div style={{}}>
            <div style={Reply_Style.text_1}>
              {/* 2345개 댓글 */}
              {`${replyData.length}개 댓글`}
            </div>
            {replyData.map((v, i) => (
              <ReplySection
                data={v}
                apiPro_del={apiPro_del}
                userInfo={userInfo}
                isTeacher={isTeacher}
              />
            ))}
          </div>
        </>
      ) : (
        <div
          style={{
            padding: "40px 0px",
          }}
        >
          <UiInput
            inputClass={params["who"] == "teacher" ? "input_t" : "input_s"}
            type="desc"
            dsecStyle={{
              height: "80px",
            }}
            inputValue={content}
            placeholder={"댓글을 적어주세요."}
            maxLength={30}
            fnChange={(e) => setContent(e.target.value)}
          />
        </div>
      )}
    </Basic>
  );
};

const ReplySection = ({ index, data, userInfo, isTeacher, apiPro_del }) => {
  let params = useParams();
  let navigate = useNavigate();

  const [isWarn, setIsWarn] = useState(false);

  // 신고하기 읽기
  const apiPro_warn_read = async () => {
    try {
      let r_data = await apiFn.comApi({
        state: {
          table: "board_reply",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            "HIQDB_board_reply.wid": `='${userInfo["id"]}'`,
            "HIQDB_board_reply.boid": `='${data["id"]}'`,
            "HIQDB_board_reply.rtype": `='1'`,
          },
        },
      });
      // console.log("@@ ",r_data);
      if (r_data["list"].length > 0) {
        setIsWarn(true);
      } else {
        setIsWarn(false);
      }
    } catch (e) {
      // console.log("$$$ ",e);
    }
  };

  const apiPro_warn_del = async () => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "board_reply",
          del: [
            {
              wid: userInfo["id"],
              boid: data["id"],
              rtype: 1,
            },
          ],
        },
      });
      // console.log("@@ ",isOk)
      await apiPro_warn_read();
    } catch (e) {}
  };

  useEffect(() => {
    // console.log("REPLY DATA => ",data);
    apiPro_warn_read();
  }, []);

  return (
    <div
      style={{
        padding: "14px 10px",
        borderBottom: "1px solid #F6F6F6",
        backgroundColor: index % 2 == 0 ? "#FFFBF5" : "#FBFBFB",
        marginBottom: "2px",
        borderRadius: "5px",
      }}
      onClick={(e) => {
        navigate(`/myInfo/${params["who"]}/${data["wid"]}`);
      }}
    >
      <div style={Reply_Style.section_r_w}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          <div>{data["content"]}</div>
          <div
            style={{
              color: "#B0B0B0",
            }}
          >
            {util.convertToDateTime(data["reg_date"])}
          </div>
        </div>
        {/* <div style={{
                    fontSize:"14px",
                    fontWeight:400,
                }}>
                    {data["content"]}
                </div> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              fontSize: "14px",
              fontWeight: 400,
            }}
          >
            {data["nick"]}
          </div>
          {userInfo["id"] == data["wid"] && (
            <div
              style={{
                display: "flex",
              }}
            >
              <UiTextBtn
                isUnderline={false}
                text={"수정"}
                btnStyle={{
                  fontSize: "12px",
                  fontWeight: 400,
                  color: style.common.fontColor_1,
                }}
                fnClick={(e) => {
                  navigate("/reply/" + params["who"] + "/" + params["id"] + "/modi&" + data["id"]);
                  e.stopPropagation();
                  e.preventDefault();
                }}
              />
              <UiTextBtn
                isUnderline={false}
                text={"삭제"}
                btnStyle={{
                  fontSize: "12px",
                  fontWeight: 400,
                  marginLeft: "10px",
                  color: style.common.fontColor_3,
                }}
                fnClick={(e) => {
                  apiPro_del(data["id"]);
                  e.stopPropagation();
                  e.preventDefault();
                }}
              />
            </div>
          )}
          {userInfo["id"] != data["wid"] && (
            <UiTextBtn
              isUnderline={false}
              text={isWarn ? "신고취소" : "신고하기"}
              btnStyle={{
                marginLeft: "10px",
                fontSize: "12px",
                fontWeight: 400,
                color: style.common.fontColor_3,
              }}
              fnClick={(e) => {
                if (isWarn) {
                  alert("신고를 철회하시겠어요?");
                  apiPro_warn_del();
                  e.preventDefault();
                  e.stopPropagation();
                } else {
                  // console.log("@@@@@@@@")
                  navigate("/warn/" + params["who"] + "/" + data["id"] + "/reply");
                  e.preventDefault();
                  e.stopPropagation();
                  return;
                }
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { ReplySection, Reply };
