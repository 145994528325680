import { axiosInstanceWithAuth } from "@infrastructure/api";
import { IAcademyLecturesRelatedParams } from "./type";

export const getAcademyLecturesRelatedApi = async (
  academyLecturesParams: IAcademyLecturesRelatedParams
) => {
  const { academyCode, page, limit } = academyLecturesParams;
  try {
    const response = await axiosInstanceWithAuth.get(`/academy/${academyCode}/lectures/related`, {
      params: {
        limit,
        page,
      },
    });
    return response.data;
  } catch (error: any) {
    throw new Error(`Error in getAcademyLecturesRelatedApi: ${error.message}`);
  }
};
