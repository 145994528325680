import { ILocalAuthRepository } from "../interface/localAuth-repository.interface";
import {
  ILocalAuthRequestData,
  ILocalAuthResponse,
} from "../../../../shared/api/api-gateway/auth/login/local_auth/type";
import { localAuthApi } from "../../../../shared/api/api-gateway/auth/login/local_auth";
import { ILocalAuthToResponseRequestData } from "../../../../shared/api/api-gateway/auth/login/local_auth_to_response/type";
import { localAuthToResponseApi } from "../../../../shared/api/api-gateway/auth/login/local_auth_to_response";

export class LocalAuthRepository implements ILocalAuthRepository {
  async postLocalAuthLink(localAuthData: ILocalAuthRequestData): Promise<ILocalAuthResponse> {
    const localAuthRequestData = {
      oauthInfo: localAuthData,
    };
    return await localAuthApi(localAuthRequestData);
  }
  async postLocalAuthToResponse(localAuthData: ILocalAuthToResponseRequestData) {
    const localAuthCallbackRequest = {
      oauthInfo: localAuthData,
    };
    const response = await localAuthToResponseApi(localAuthCallbackRequest);
    return response.result;
  }
}
