import { ArrowBackIos } from "@material-ui/icons";
import React, { CSSProperties, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import search_pic from "../../asset/Img/search_pic.png";
import search_text from "../../asset/Img/search_text.png";
import apiFn from "../../asset/apiClass";
import constants from "../../asset/constants";
import style from "../../asset/style";
import Frame from "../../common/frame/Frame";
import Message_Home_Style from "../Message/Message_Home_Style";
import Message_Style from "../Message/Message_Style";
import Book_book from "./Book_book";
import Book_share from "./Book_share";
import Book_subs from "./Book_subs";
import Book_teacher from "./Book_teacher";
import Book_undefined from "./Book_undefined";
import Modal_modi from "./Modal_modi";
import Modal_people from "./Modal_people";
import Modal_share from "./Modal_share";
import Modal_text from "./Modal_text";
import { getUser } from "@asset/storage";

type BookProps = {
  isFull: boolean;
  setIsFull: Function;
  del_bookdId: number;
  setDel_bookId: Function;
  del_sorting?: number;
  setFileObj: Function;
  fileObj: any;
  qnaData: any;
  setQnaData: Function;
  setHiddenUrl: Function;
  hiddenNav: string;
  setHiddenNav: Function;
  setAddSearch: Function;
  explainData: any;
  setExplainData: Function;
  explainPage: number;
  setExplainPage: Function;
};
type BookDataType = {
  approve: any;
  book_pw: any;
  book_type: any;
  cntClass: any;
  cntMobum: any;
  cntQna: any;
  content: any;
  id: any;
  img: any;
  name: any;
  open: any;
  reg_date: any;
  reg_id: any;
  reg_id_index: any;
  reg_nick: any;
  status: any;
  tcnt: any;
  tseq: any;
  usee: any;
};

const Book = ({
  isFull,
  setIsFull,
  del_bookdId,
  setDel_bookId,
  del_sorting,
  setFileObj,
  fileObj,
  qnaData,
  setQnaData,
  setHiddenUrl,
  hiddenNav,
  setHiddenNav,
  setAddSearch,
  explainData,
  setExplainData,
  explainPage,
  setExplainPage,
}: BookProps) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const userInfo = getUser();

  const myInfo_init = {
    name: "",
    tel: "",
    email: "",
    collage: "",
    qtotal: "",
    anscount: 0,
    avgTime: "",
    intro: "",
    nick: "",
    content: "",
  };

  const [isModi, setIsModi] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const [isPeople, setIsPeople] = useState(false);

  const [isSearch, setIsSearch] = useState(false);
  const [isText, setIsText] = useState(false);

  const [myInfo, setMyInfo] = useState(myInfo_init);

  const [isTeacher, setIsTeacher] = useState(true);

  const [tap, setTap] = useState("book");

  const [subsData, setSubsData] = useState({
    name: "",
    bid: params["bid"],
    public: "",
    pw: "",
    approve: "N",
    subs_tel: null,
    subs_email: "",
    reg_date: 0,
  });

  const [linkData, setLinkData] = useState({});
  const [bookData, setBookData] = useState({} as BookDataType);
  const [qnaCount, setQnaCount] = useState({
    live: 0,
    mobum: 0,
    qna: 0,
  });

  const [subsCount, setSubsCount] = useState(0);

  const apiPro_img = async (e) => {
    try {
      setQnaData({});
      setAddSearch({
        bid: params["id"],
      });
      window.localStorage.setItem("saveSearchPrevUrl", location.pathname);
      setFileObj({
        name: ``,
        file: e,
      });
      setHiddenUrl(window.location.pathname);
      navigate(`/crop/${params["who"]}/bookImageSearch`);
    } catch (e) {
      console.log("apiPro_img Error= >", e);
    }
  };

  const apiPro_subs_count = async () => {
    try {
      let count = await apiFn.comApi({
        state: {
          table: "book_link",
          page: 1,
          amount: 9999,
          sort: "mid desc",
          where: {
            "HIQDB_book_link.bid": `='${params["id"]}'`,
            "HIQDB_book_link.type": `in ('4','8')`,
          },
        },
      });

      setSubsCount(count["list"].length);
    } catch (e) {}
  };

  const apiPro_subs_qnaCount = async (type, qCount) => {
    // try {
    //     let data = await apiFn.qnaSubs({
    //         state : {
    //             id : userInfo["id"],
    //             page : 1,
    //             amount : 9999,
    //             sort : "reg_date desc",
    //             bid : params["id"],
    //             type : type
    //         }
    //     })
    //
    //     console.log("QnaCount : ",data["list"])
    //     qCount["subs"] = data["list"].length
    //     // data["list"].map((v)=>console.log(v["mid"]))
    // }
    // catch(e){
    //     console.log("apiPro_subs_qnaCount error : ",e)
    // }
  };

  const apiPro_read_student = async () => {
    let qCount = {};

    let state = {
      id: params["id"],
      page: 1,
      amount: 9999,
      sort: "reg_date desc",
      cid: params["cid"],
      sid: userInfo["id"],
    };

    apiFn
      .studentBooklist({
        state: {
          ...state,
        },
      })
      .then((res) => {
        // console.log("@#@#@#@# : : : : ",res["list"]);
        if (res["list"].length > 0) {
          setBookData({ ...res["list"][0] });
          apiFn
            .comApi({
              state: {
                table: "book_subs",
                page: 1,
                amount: 9999,
                sort: "id asc",
                where: {
                  "HIQDB_book_subs.bid": `= '${res["list"][0]["id"]}'`,
                },
              },
            })
            .then((res) => {
              if (res["list"].length > 0) {
                console.log("subsData => ", res["list"][0]);
                setSubsData({
                  ...res["list"][0],
                });
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      });

    apiFn
      .comApi({
        state: {
          table: "book_link",
          page: 1,
          amount: 9999,
          sort: "mid desc",
          where: {
            "HIQDB_book_link.mid": `='${userInfo["id"]}'`,
            "HIQDB_book_link.bid": `='${params["id"]}'`,
          },
        },
      })
      .then((res) => {
        let obj = { ...res["list"][0] };
        // if(res["list"]){
        //     res["list"].map((v)=>{
        //         if(v["status"] == 1)obj["status"] = 1
        //     })
        //     setLinkData({
        //         ...obj
        //     })
        //     console.log("@@ : ",obj)
        //     console.log("@@@@ : : : :" ,res["list"]);
        //     if(res["list"].length > 0){
        //         apiPro_subs_qnaCount(res["list"][0]["type"], qCount);
        //     }
        // }
      })
      .catch((e) => console.log("# ", e));

    apiPro_subs_count();

    try {
      let res = await (
        await fetch(`${constants.apiUrl}/book/${params.id}/user/${userInfo.id}/questions/count`)
      ).json();
      console.log("RES COUNT : ", res["count"]);

      // console.log("@@ ",res["count"]);

      // qCount = {
      //     ...qCount,
      //     ...res["count"]
      // }
      // setQnaCount({
      //     ...qCount
      // })
      setQnaCount({
        ...res["count"],
      });
    } catch (e) {
      console.log("$ ", e);
    }
  };

  const apiPro_read = async () => {
    try {
      let state = {};
      let qCount = {};
      let data = [];

      apiFn
        .comApi({
          state: {
            table: "book",
            page: 1,
            amount: 9999,
            sort: "reg_date desc",
            // ref : [
            //     "HIQDB_book_link.cid|HIQDB_book_link.bid=HIQDB_book.id",
            // ],
            equal: {
              id: params["id"],
            },
          },
        })
        .then((res) => {
          // console.log("Book res => ",res);
          if (res["list"][0]) {
            setBookData({ ...res["list"][0] });
            apiFn
              .comApi({
                state: {
                  table: "book_subs",
                  page: 1,
                  amount: 9999,
                  sort: "id asc",
                  where: {
                    "HIQDB_book_subs.bid": `= '${res["list"][0]["id"]}'`,
                  },
                },
              })
              .then((res) => {
                if (res["list"].length > 0) {
                  console.log("subsData => ", res["list"]);
                  setSubsData({
                    ...res["list"][0],
                  });
                }
              })
              .catch((e) => {
                console.log(e);
              });
          }
        })
        .catch((e) => console.log("@ ", e));

      apiFn
        .comApi({
          state: {
            table: "book_link",
            page: 1,
            amount: 9999,
            sort: "mid desc",
            where: {
              "HIQDB_book_link.mid": `='${userInfo["id"]}'`,
              "HIQDB_book_link.bid": `='${params["id"]}'`,
            },
          },
        })
        .then((res) => {
          console.log("linkData => ", res);
          let obj = { ...res["list"][0] };
          if (res["list"]) {
            res["list"].map((v) => {
              if (v["status"] == 1) obj["status"] = 1;
              if (v.type === 5) {
                obj.type = 5;
              }
            });
            setLinkData({
              ...obj,
            });
            // console.log("@@ : ",obj)
            if (params["id"] !== "0") apiPro_subs_qnaCount(res["list"][0]["type"], qCount);
          }
        })
        .catch((e) => console.log("# ", e));

      apiPro_subs_count();
    } catch (e) {
      console.log("@#@# ", e);
    }
  };

  // 내 정보
  const apiPro_read_myInfo = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "user_credential", //userInfo["type"] == 1 ? "student" : "teacher",
          key: `name:${userInfo["id"]}`,
        },
      });
      setMyInfo(data);
    } catch (e) {}
  };

  const apiPro_qnaCount = async () => {
    try {
      let res = await (
        await fetch(`${constants.apiUrl}/book/${params.id}/user/${userInfo.id}/questions/count`)
      ).json();
      console.log("RES COUNT : ", res["count"]);

      // console.log("@@ ",res["count"]);

      // qCount = {
      //     ...qCount,
      //     ...res["count"]
      // }
      // setQnaCount({
      //     ...qCount
      // })
      setQnaCount({
        ...res["count"],
      });
    } catch (e) {
      console.log("$ ", e);
    }
  };

  useEffect(() => {
    setQnaData([]);
    window.localStorage.removeItem("isImgSearch");
    if (fileObj["bookName"]) {
      setIsModi(true);
    }

    if (hiddenNav) {
      if (hiddenNav.includes("profile_")) {
      } else {
        if (hiddenNav == "share&") {
          setTap("share");
        } else {
          setTap(hiddenNav);
          setHiddenNav("");
        }
      }
    }
    return () => {
      // setQnaData([])
    };
  }, []);

  useEffect(() => {
    if (tap == "book") {
      if (params["id"] === "0") return;
      // apiPro_read_myInfo();
      if (params["cid"] == "subs" || params["who"] == "teacher") {
        // apiPro_read();
        apiPro_qnaCount();
      } else {
        apiPro_read_student();
      }
    }
  }, [tap]);

  useEffect(() => {
    if (!isModi) {
      apiPro_read();
    }
  }, [isModi]);

  useEffect(() => {
    if (params["who"] == "teacher") {
      setIsTeacher(true);
    } else {
      setIsTeacher(false);
    }
    if (params["where"].includes("search")) {
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }
  }, [params]);

  return (
    <Frame
      type="2"
      isSearch={false}
      backEvent={() => {
        setFileObj({});
      }}
      title={bookData.name ?? ""}
    >
      <div
        style={{
          width: "100%",
        }}
      >
        {params["who"] == "teacher" && params["id"] !== "0" && (
          <div
            style={{
              display: "flex",
              padding: style.common.sub_padding,
            }}
          >
            <div
              style={{
                ...Message_Style.tap_text,
                fontSize: "18px",
                fontWeight: 700,
                marginRight: "30px",
                borderBottom: tap == "book" ? "2px solid" : "none",
                borderBottomColor:
                  tap == "book"
                    ? "#3D3A39"
                    : // (isTeacher ? style.common.t_color : style.common.s_color)
                      "none",
                color:
                  tap == "book"
                    ? "#3D3A39"
                    : // (isTeacher ? style.common.t_color : style.common.s_color)
                      style.common.fontColor_2,
              }}
              onClick={() => {
                // navigate("/profile"+"/"+params["who"]+"/academy")
                setTap("book");
              }}
            >
              교재
            </div>
            {
              // linkData["type"] == 4 || linkData["type"] == 8 ?
              linkData["status"] == 1 &&
              // linkData["type"] == 0 || // 정상 학생 회원
              (linkData["type"] == 5 || // 관리자 (선생)
                linkData["type"] == 6 || // 일반회원 (선생)
                linkData["type"] == 7) ? ( // 조교 (선생)
                <>
                  <div
                    style={{
                      ...Message_Style.tap_text,
                      fontSize: "18px",
                      fontWeight: 700,
                      marginRight: "30px",
                      borderBottom: tap == "share" ? "2px solid" : "none",
                      borderBottomColor:
                        tap == "share"
                          ? "#3D3A39"
                          : // (isTeacher ? style.common.t_color : style.common.s_color)
                            "none",
                      color:
                        tap == "share"
                          ? "#3D3A39"
                          : // (isTeacher ? style.common.t_color : style.common.s_color)
                            style.common.fontColor_2,
                    }}
                    onClick={() => {
                      // navigate("/profile"+"/"+params["who"]+"/column")
                      setTap("share");
                    }}
                  >
                    공유
                  </div>
                  <div
                    style={{
                      ...Message_Style.tap_text,
                      fontSize: "18px",
                      fontWeight: 700,
                      marginRight: "30px",
                      borderBottom: tap == "teacher" ? "2px solid" : "none",
                      borderBottomColor:
                        tap == "teacher"
                          ? "#3D3A39"
                          : // (isTeacher ? style.common.t_color : style.common.s_color)
                            "none",
                      color:
                        tap == "teacher"
                          ? "#3D3A39"
                          : // (isTeacher ? style.common.t_color : style.common.s_color)
                            style.common.fontColor_2,
                    }}
                    onClick={() => {
                      // navigate("/profile"+"/"+params["who"]+"/teacher")
                      setTap("teacher");
                    }}
                  >
                    {/* 관리 선생님 */}
                    참여자
                  </div>
                  {/*
                    {linkData["type"] == 5 &&
                    <div style={{
                        ...Message_Style.tap_text,
                        fontSize : '18px',
                        fontWeight:700,
                        marginRight : '30px',
                        borderBottom : tap == "subs" ? "2px solid" : "none",
                        borderBottomColor : tap == "subs" ?
                        "#3D3A39"
                        // (isTeacher ? style.common.t_color : style.common.s_color)
                        :
                        "none"
                        ,
                        color :
                        tap == "subs" ?
                        "#3D3A39"
                        // (isTeacher ? style.common.t_color : style.common.s_color)
                        :
                        style.common.fontColor_2

                    }}
                        onClick={()=>{
                            // navigate("/profile"+"/"+params["who"]+"/teacher")
                            setTap("subs")
                        }}
                    >
                        구독 관리
                    </div>
                    } */}
                </>
              ) : (
                subsData["public"] == "Y" && (
                  <div
                    style={{
                      ...Message_Style.tap_text,
                      justifyContent: "flex-end",
                      display: "flex",
                      fontSize: "12px",
                      flex: 1,
                      color: isTeacher ? style.common.t_color : style.common.s_color,
                    }}
                    onClick={() => {
                      // navigate("/profile"+"/"+params["who"]+"/teacher")
                      // setTap("teacher")
                    }}
                  >
                    {`구독자 ${subsCount}명`}
                  </div>
                )
              )
            }
          </div>
        )}
        <div
          style={{
            marginTop: "10px",
            width: "100%",
            // padding : "0px 10px",
          }}
        >
          {params["id"] === "0" ? (
            // 교재 미지정 / 삭제된 질문 리스트 페이지 이동
            <Book_undefined
              explainData={explainData}
              setExplainData={setExplainData}
              setQnaData={setQnaData}
              del_bookdId={del_bookdId}
              setDel_bookId={setDel_bookId}
              userInfo={userInfo}
              setHiddenUrl={setHiddenUrl}
              del_sorting={del_sorting}
            />
          ) : tap == "book" ? (
            <Book_book
              setIsModi={setIsModi}
              setIsText={setIsText}
              apiPro_img={apiPro_img}
              isFull={isFull}
              setIsFull={setIsFull}
              subsData={subsData}
              explainData={explainData}
              setExplainData={setExplainData}
              explainPage={explainPage}
              setExplainPage={setExplainPage}
              isTeacher={isTeacher}
              userInfo={userInfo}
              myInfo={myInfo}
              linkData={linkData}
              bookData={bookData}
              setFileObj={setFileObj}
              fileObj={fileObj}
              qnaData={qnaData}
              setQnaData={setQnaData}
              qnaCount={qnaCount}
              setHiddenUrl={setHiddenUrl}
              apiPro_read_link={apiPro_read}
            />
          ) : tap == "share" ? (
            <Book_share
              setHiddenUrl={setHiddenUrl}
              isShare={isShare}
              setIsShare={setIsShare}
              userInfo={userInfo}
              isTeacher={isTeacher}
              linkData={linkData}
              setHiddenNav={setHiddenNav}
            />
          ) : tap == "teacher" ? (
            <Book_teacher
              isPeople={isPeople}
              setIsPeople={setIsPeople}
              linkData={linkData}
              isTeacher={isTeacher}
              userInfo={userInfo}
              setHiddenNav={setHiddenNav}
              hiddenNav={hiddenNav}
            />
          ) : (
            tap == "subs" && (
              <Book_subs
                linkData={linkData}
                isTeacher={isTeacher}
                userInfo={userInfo}
                setHiddenNav={setHiddenNav}
                hiddenNav={hiddenNav}
              />
            )
          )}
        </div>
      </div>
      {
        // params["id"] != 0 &&
        params["where"] != "del" &&
          (isText ? (
            <Modal_text
              setIsText={setIsText}
              isTeacher={params["who"] == "teacher" ? true : false}
            />
          ) : (
            isSearch && (
              <div
                style={
                  {
                    // display:"flex",
                    // flexDirection:"column",
                    // display : "flex",
                    // flex:1,
                    height: "100vh",
                    // alignItems:"center",
                    position: "fixed",
                    top: 0,
                    width: "100%",
                    borderBottom: "1px solid",
                    borderColor: style.common.borderColor,
                    maxWidth: style.common.maxWidth,
                    // height:"60px",
                    backgroundColor: "rgba(200,200,200,0.5)",
                  } as CSSProperties
                }
              >
                <div
                  style={{
                    padding: "10px",
                    backgroundColor: "white",
                  }}
                >
                  <div
                    style={{
                      // padding : style.common.padding,
                      padding: "10px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        fontSize: "8sp",
                        fontWeight: "bold",
                        alignItems: "center",
                        zIndex: 100,
                      }}
                    >
                      <ArrowBackIos
                        onClick={() => {
                          // navigate(`/lecture/detail/${params["who"]}/${params["id"]}/de`)
                          navigate(`/book/${params["who"]}/${params["id"]}/${params["cid"]}/de`);

                          setIsSearch(false);
                        }}
                      />
                      검색
                    </div>
                  </div>
                  <div
                    style={{
                      // padding : "0px 20px",
                      // display:"flex",
                      // flex:1,

                      width: "94%",
                    }}
                  >
                    <div style={Message_Home_Style.search_w}>
                      <div style={Message_Home_Style.search_b as CSSProperties}>
                        <input
                          id="picture_find"
                          type="file"
                          accept="image/*"
                          style={{
                            display: "none",
                          }}
                          onChange={apiPro_img}
                        />
                        <label
                          htmlFor="picture_find"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <img src={search_pic} style={Message_Home_Style.search_i_1} />
                          <div style={Message_Home_Style.text_2}>사진으로 해답 찾기</div>
                        </label>
                      </div>
                      <div
                        style={Message_Home_Style.search_b as CSSProperties}
                        onClick={() => {
                          setIsText(true);
                          // navigate(`/message/${params["who"]}/home&text`)
                        }}
                      >
                        <img src={search_text} style={Message_Home_Style.search_i_2} />
                        <div style={Message_Home_Style.text_2}>텍스트로 해답 찾기</div>
                      </div>
                    </div>
                  </div>
                  {/* 보조 설명이 필요할 때 해당 공간에 보조 글귀를 남깁니다. */}
                </div>
              </div>
            )
          ))
      }
      {isModi && (
        <Modal_modi
          bookData={bookData}
          setIsModal={setIsModi}
          userInfo={userInfo}
          title="교재 정보 설정"
          fileObj={fileObj}
          setFileObj={setFileObj}
          placeholder=""
        />
      )}
      {isShare && (
        <Modal_share setIsModal={setIsShare} userInfo={userInfo} setFileObj={setFileObj} />
      )}
      {isPeople && <Modal_people setIsModal={setIsPeople} userInfo={userInfo} />}
    </Frame>
  );
};

export default Book;
