export class FontSize {
  private constructor(private value: string) {}

  static create(size: string): FontSize | null {
    if (size.endsWith("rem") || size.endsWith("px")) {
      return new FontSize(size);
    }
    return null;
  }

  toString(): string {
    return this.value;
  }
}
