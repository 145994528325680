import { pathToUrl } from "@helpers/url";
import { AccountApiEndpoints } from "@api/constants";
import { axiosInstance } from "@api/common";

export const getCheckNicknameUsable = async (nickname: string) => {
  const url = pathToUrl(AccountApiEndpoints.GET_CHECK_NICKNAME, { nickname });
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    console.error("Error checking nickname:", error);
    throw new Error("Failed to check nickname");
  }
};
