import { axiosInstanceWithOutAuth } from "@infrastructure/api";
import { IAccountJoinRequest } from "./type";

export const accountJoinApi = async (accountRequest: IAccountJoinRequest): Promise<any> => {
  try {
    const response = await axiosInstanceWithOutAuth.post("/account/join", accountRequest, {
      withCredentials: true,
    });
    return response.data;
  } catch (error: any) {
    throw new Error(`Error in accountJoinApi: ${error.message}`);
  }
};
