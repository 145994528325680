import React from "react";
import styled from "styled-components";
import { themeUtil } from "@utils/theme";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  onClick?: () => void;
  backgroundColor?: string;
  color?: string;
  size?: "small" | "medium" | "large";
  disabled?: boolean;
}

const StyledButton = styled.button<ButtonProps>`
  border: none;
  cursor: pointer;
  font-weight: 500;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  padding: ${({ size }) => {
    switch (size) {
      case "small":
        return "6px 8px";
      case "large":
        return "15px 24px";
      default:
        return "10px 16px";
    }
  }};
  background-color: ${({ backgroundColor }) => themeUtil.getColor(backgroundColor)};
  color: ${({ color }) => themeUtil.getColor(color)};
  &:disabled {
    background: ${({ theme }) => theme.colors.natural[20]};
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  backgroundColor = "student",
  color = "white",
  size = "medium",
  disabled = false,
  type,
}) => {
  return (
    <StyledButton
      onClick={onClick}
      color={color}
      backgroundColor={backgroundColor}
      size={size}
      disabled={disabled}
      type={type}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
