import React, { ChangeEvent, useEffect, useState } from "react";
import TextInputWithLabel from "@components/molecules/textInputWithLabel";
import Button from "@components/atoms/button";
import { useAcademyLectureList } from "@api/academy/useAcademyLectureList";
import PageContainer from "@components/templates/PageContainer";
import { useInView } from "react-intersection-observer";
import { useLectureSearch } from "@api/lecture/useLectureSearch";
import useDebounce from "@hooks/common/use_debounce";
import { ActionSection, ButtonBox, ContentBox, ContentSection } from "@pages/lecture/select/styled";
import { useNavigate } from "react-router";
import { useJoinLecture } from "@api/lecture/useJoinLecture";
import LectureList from "@components/pages/lecture/lecutreList";
import SelectedLecturesList from "@components/pages/lecture/selectedLecturesList";
import { CONFIG } from "@config";
import { ACADEMY_RELATION_ENUM } from "../../../domain/enum/academy";
import { accountAtom } from "@atoms/account/atom";
import { useAtomValue } from "jotai";
import { ACCOUNT_TYPE_ENUM } from "@enum";
import { useGetAcademyInfo } from "@hooks/queries/academy/useAcadmey";

const ROWS_PER_PAGE = 10;

export type SelectedLectures = Map<number, { lectureName: string; lectureId: number }>;

export default function LectureSelectPage(): JSX.Element {
  const [searchText, setSearchText] = useState<string>("");
  const [selectedLectures, setSelectedLectures] = useState<SelectedLectures>(new Map());
  const academyCode = CONFIG.APP_ENV.APP_ACADEMY_CODE;
  const isAcademyApp = CONFIG.APP_ENV.IS_ACADEMY_APP;
  const debounceSearchText = useDebounce(searchText, 500);
  const isSearching = Boolean(debounceSearchText);
  const navigate = useNavigate();
  const {
    academyLectureListFetchNextPage,
    academyLectureListData,
    academyLectureListHasNextPage,
    academyLectureListIsFetchingNextPage,
  } = useAcademyLectureList({
    limit: ROWS_PER_PAGE,
  });
  const {
    lectureSearchFetchNextPage,
    lectureSearchData,
    lectureSearchHasNextPage,
    lectureSearchIsFetchingNextPage,
  } = useLectureSearch({
    limit: ROWS_PER_PAGE,
    searchText: debounceSearchText,
    enable: isSearching,
  });
  const { mutateAsync } = useJoinLecture();
  const { ref: inViewRef, inView } = useInView();
  const { refetch: refetchAcademyInfo } = useGetAcademyInfo(academyCode);
  const accountAtomValue = useAtomValue(accountAtom);

  useEffect(() => {
    if (inView) {
      isSearching
        ? lectureSearchHasNextPage && lectureSearchFetchNextPage()
        : academyLectureListHasNextPage && academyLectureListFetchNextPage();
    }
  }, [inView, isSearching, lectureSearchHasNextPage, academyLectureListHasNextPage]);

  const handleSearchLecture = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchText(event.target.value);
  };
  const handleToggleLectureSelection = ({
    lectureName,
    lectureId,
  }: {
    lectureName: string;
    lectureId: number;
  }) => {
    setSelectedLectures((prevSelected) => {
      const newSelected = new Map(prevSelected);
      newSelected.has(lectureId)
        ? newSelected.delete(lectureId)
        : newSelected.set(lectureId, { lectureName, lectureId });
      return newSelected;
    });
  };
  const handleEnterMain = async () => {
    let whereToGo =
      accountAtomValue.type === ACCOUNT_TYPE_ENUM.TEACHER
        ? "/message/teacher/home"
        : "/message/student/home";
    if (isAcademyApp) {
      const { data: academyInfo } = await refetchAcademyInfo();
      if (academyInfo?.status !== ACADEMY_RELATION_ENUM.JOINED) {
        whereToGo = "/auth/?res=ok";
      }
    }
    navigate(whereToGo, { replace: true });
  };

  const handleJoinLecture = async () => {
    const lectureIds = Array.from(selectedLectures.keys());
    await Promise.all(lectureIds.map((lectureId) => mutateAsync(lectureId)));
    await handleEnterMain();
  };

  return (
    <PageContainer>
      <TextInputWithLabel
        onChange={handleSearchLecture}
        label={"수업 검색"}
        placeholder={"검색어를 입력해주세요."}
      />
      <ContentSection>
        <ContentBox>
          <LectureList
            ref={inViewRef}
            lectureData={lectureSearchData || academyLectureListData}
            selectedLectures={selectedLectures}
            searchText={searchText}
            isFetching={academyLectureListIsFetchingNextPage || lectureSearchIsFetchingNextPage}
            onToggle={handleToggleLectureSelection}
          />
        </ContentBox>
      </ContentSection>
      <ActionSection>
        <SelectedLecturesList
          selectedLectures={Array.from(selectedLectures.values())}
          onToggle={handleToggleLectureSelection}
        />

        <ButtonBox>
          {!CONFIG.APP_ENV.IS_ACADEMY_APP && (
            <Button backgroundColor={"natural.30"} onClick={handleEnterMain}>
              나중에 수업 신청하기
            </Button>
          )}
          <Button
            backgroundColor={"primary"}
            onClick={handleJoinLecture}
            disabled={selectedLectures.size === 0}
          >
            선택한 수업 신청하기
          </Button>
        </ButtonBox>
      </ActionSection>
    </PageContainer>
  );
}
