import React from "react";
import styled from "styled-components";

type AccountInfoItemProps = {
  label: string;
  value: string;
};

const AccountInfoContainer = styled.div``;
const AccountInfoTitle = styled.dt`
  color: ${({ theme }) => theme.colors.natural[50]};
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
`;
const AccountInfoValue = styled.dd`
  color: ${({ theme }) => theme.colors.natural[70]};
  font-size: ${({ theme }) => theme.typography.fontSizes.base};
  font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
`;

function AccountInfoItem({ label, value }: AccountInfoItemProps): JSX.Element {
  return (
    <AccountInfoContainer>
      <AccountInfoTitle>{label}</AccountInfoTitle>
      <AccountInfoValue>{value}</AccountInfoValue>
    </AccountInfoContainer>
  );
}

export default AccountInfoItem;
