// @ts-nocheck
import { useMutation } from "@apollo/client";
import { PersonAddAlt1 } from "@mui/icons-material";
import { useMyLectures, useUserByLecture } from "api/people";
import { getUser } from "asset/storage";
import Footer from "components/molecules/Footer";
import React, { Suspense, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { CreateInitialTicketDocument } from "types/graphql/graphql";
import UiLoading from "uiComponents/UiLoading/UiLoading";
import class5_black from "../../asset/Img/class5_black.png";
import apiFn from "../../asset/apiClass";
import style from "../../asset/style";
import util from "../../asset/util";
import UiBtn from "../../uiComponents/UiBtn/UiBtn";
import { UiInput } from "../../uiComponents/UiInput/UiInput";
import UiPeople_Type2 from "../../uiComponents/UiPeople/UiPeople_Type2";
import UiTextBtn from "../../uiComponents/UiTextBtn/UiTextBtn";
import Message_Style from "../Message/Message_Style";
import { Frame } from "../page_index";
import InviteModal from "./InviteModal";
import Modal_people from "./Modal_people";
import type { Lecture } from "./PeopleType";

const PeopleMainWrapper = (props) => {
  return (
    <>
      <Suspense
        fallback={<UiLoading isTransparent={true} isText={false} isCircle={false} text="로딩중" />}
      >
        <People_Main {...props} />
      </Suspense>
      <Footer />
    </>
  );
};

const People_Main = ({
  setFileObj,
  setIsLoading,
  setQnaData,
  setHiddenUrl,
  scrollKey,
  setScrolllKey,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const userInfo = getUser();

  //해당 수업에 대한 데이터를 띄우기 위해 사용
  const [selectLecture, setSelectLecture] = useState<Lecture>({
    id: "",
    name: "",
    pid: "",
    reg_id: "",
    reg_date: 0,
    intro: "",
    address: "",
    tel: "",
    email: "",
    stype: 0,
    del_date: 0,
    assign_date: 0,
    owner: "",
    memberType: 0,
    lectureStatus: 0,
    joinCnt: 0,
  });
  //모달에 넘기기 위한 사람 데이터
  const [selectPeople, setSelectPeople] = useState({});

  //모달 띄우기용 사람 클릭
  const [isPeople, setIsPeople] = useState(false);
  //수업 상세 화면용 클릭
  const [isLecture, setIsLecture] = useState(false);
  //수업 더보기용 클릭
  const [isMore, setIsMore] = useState(false);
  //수업 초대 모달 띄우기용
  const [showLectureInviteModal, setShowLectureInviteModal] = useState(false);

  const [searchText, setSearchText] = useState("");

  //수강신청 체크된 데이터
  const [selectData, setSelectData] = useState({});

  //수강신청 화면
  const [isEdit, setIsEdit] = useState(false);

  const isTeacher: boolean = useMemo(() => params["who"] === "teacher", [params]);

  const { data: myLectures } = useMyLectures();
  const { data: academyPeople, refetch: getAcademyPeople } = useUserByLecture(selectLecture.id);
  // 질문권 생성
  const [createInitialTicket] = useMutation(CreateInitialTicketDocument);

  const { lectureList, totalJoinCount } = useMemo(() => {
    const lectureList = myLectures?.getLecture?.lectures?.filter(
      (data) => data.lectureStatus === 1
    );
    if (lectureList.length === 1) {
      setSelectLecture(lectureList[0]);
    }
    let totalCount = 0;
    lectureList?.forEach((data) => {
      if (data.memberType === 5) {
        totalCount += data.joinCnt;
      }
    });
    return { lectureList, totalJoinCount: totalCount };
  }, [myLectures]);

  const { userList, participatingTeacherList, applyUserList } = useMemo(() => {
    let userList = [];
    let participatingTeacherList = [];
    let applyUserList = [];
    const lectureList =
      academyPeople?.getUserByLecture?.user[0]?.HIQDB_user_credentials[0]?.HIQDB_member_classes;
    if (!lectureList) return { userList, participatingTeacherList, applyUserList };
    const users = lectureList.flatMap((item) =>
      item.cid_HIQDB_class.HIQDB_member_classes.map((memberClass) => {
        const user = memberClass.mid_HIQDB_user_credential.user;
        // 참여 중인 선생님, 수업 참여 신청한 사람들을 알아내기 위한 type, status 추가
        user.type = memberClass.type;
        user.status = memberClass.status;
        return user;
      })
    );
    const uniqueIdList = new Set(users.map((user) => user.id));
    if (isTeacher) {
      // 선생님일 경우 학생을 구분하여 userList에 추가
      userList = Array.from(uniqueIdList)
        .filter((id) =>
          users.find((user) => user.id === id && user.user_type === 1 && user.status === 1)
        )
        .map((id) =>
          users.find((user) => user.id === id && user.user_type === 1 && user.status === 1)
        );
      if (selectLecture.id !== "") {
        // 수업이 선택되어 있을 경우 참여 중인 선생님과 수업 참여 신청한 사람들을 구분하여 추가
        participatingTeacherList = Array.from(uniqueIdList)
          .filter((id) =>
            users.find((user) => user.id === id && user.user_type === 2 && user.status === 1)
          )
          .map((id) =>
            users.find((user) => user.id === id && user.user_type === 2 && user.status === 1)
          );
        applyUserList = Array.from(uniqueIdList)
          .filter((id) => users.find((user) => user.id === id && user.status === 0))
          .map((id) => users.find((user) => user.id === id && user.status === 0));
      }
    } else {
      // 학생일 경우 선생님을 구분하여 userList에 추가
      userList = Array.from(uniqueIdList)
        .filter((id) =>
          users.find((user) => user.id === id && user.user_type === 2 && user.status === 1)
        )
        .map((id) =>
          users.find((user) => user.id === id && user.user_type === 2 && user.status === 1)
        );
    }
    return { userList, participatingTeacherList, applyUserList };
  }, [academyPeople, selectLecture]);

  const filteredUserList = useMemo(() => {
    if (searchText === "") return userList;
    let result = userList.filter((data) => {
      if (
        data["name"].includes(searchText) ||
        data["nick"].includes(searchText) ||
        data["tel"].includes(searchText)
      ) {
        return data;
      }
    });
    return result;
  }, [searchText, academyPeople]);
  const apiPro_set = async (d, isDel, isApprove, isMulti, isUp, isDown, isAssist) => {
    const authCheck =
      participatingTeacherList.filter((teacher) => teacher.id === userInfo.id)[0].type === 5;
    try {
      if (d.owner || authCheck) {
        let state = {
          typecheck: {
            cid: selectLecture.id || lectureList[0]?.id,
            id: userInfo.id,
          },
        };
        if (isDown) {
          state.sendMsg = {
            orderMid: userInfo.id,
            targetMid: d.id,
            cid: selectLecture.id || lectureList[0]?.id,
            type: "suup_admindown",
          };
          state.set = [
            {
              key: `cid:${selectLecture.id || lectureList[0]?.id}' and mid='${d.id}`,
              value: {
                type: 6,
              },
            },
          ];
        } else {
          if (isUp) {
            state.sendMsg = {
              orderMid: userInfo.id,
              targetMid: d.id,
              cid: selectLecture.id || lectureList[0]?.id,
              type: "suup_adminup",
            };
            state.set = [
              {
                key: `cid:${selectLecture.id || lectureList[0]?.id}' and mid='${d.id}`,
                value: {
                  type: 5,
                },
              },
            ];
          }
          if (isAssist) {
            state.sendMsg = {
              orderMid: userInfo.id,
              targetMid: d.id,
              cid: selectLecture.id || lectureList[0]?.id,
              type: "suup_adminup",
            };
            state.set = [
              {
                key: `cid:${selectLecture.id || lectureList[0]?.id}' and mid='${d.id}`,
                value: {
                  type: 7,
                },
              },
            ];
          }
          if (isDel) {
            if (isMulti) {
              state.del = Object.keys(d).reduce((a, v, i) => {
                return [
                  ...a,
                  {
                    mid: v,
                    cid: selectLecture.id || lectureList[0]?.id,
                  },
                ];
              }, []);
            } else {
              state.del = [
                {
                  mid: d.id,
                  cid: selectLecture.id || lectureList[0]?.id,
                },
              ];
            }
          }
          if (isApprove) {
            // console.log('approve: ', selectLecture, d)
            const {
              list: [user],
            } = await apiFn.comApi({
              state: {
                table: "user_credential",
                page: 1,
                ref: ["A.* |HIQDB_user A on A.index=HIQDB_user_credential.user_id"],
                amount: 999,
                sort: "id asc",
                where: {
                  "HIQDB_user_credential.name": `= '${d.id}'`,
                },
              },
            });
            let isTargetTeacher = user?.user_type === 2;
            if (isMulti) {
              state.set = Object.keys(d).reduce((a, v, i) => {
                return [
                  ...a,
                  {
                    key: `cid:${params.id}' and mid='${v}`,
                    value: {
                      status: 1,
                    },
                  },
                ];
              }, []);
            } else {
              state.sendMsg = {
                type: `${isTargetTeacher ? "suup" : "student"}_approve`,
                targetMid: d.id,
                orderMid: userInfo.id,
                cid: selectLecture.id || lectureList[0]?.id,
              };
              state.set = [
                {
                  key: `cid:${selectLecture.id || lectureList[0].id}' and mid='${d.id}`,
                  value: {
                    status: 1,
                  },
                },
              ];
            }
          }
        }
        if (!d.owner) {
          let isOk = await apiFn.comApi({
            state: {
              table: "member_class",
              ...state,
            },
          });
          if (isOk.status == 30) {
            alert("해당 정보를 수정할 수 있는 권한이 없습니다.");
          }
        } else {
          alert("수업 소유자는 강등 될 수 없습니다.");
        }
        setIsEdit(false);
        util.postWeb("isModal_off");
        await getAcademyPeople(selectLecture.id);
      } else {
        alert("해당 정보를 수정할 수 있는 권한이 없습니다.");
      }
    } catch (e) {
      util.c_err_log(`apiPro_set Error => ${e}`);
    }
  };

  const apiPro_approve = async (d) => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "member_class",
          sendMsg: {
            orderMid: userInfo["id"],
            targetMid: userInfo["id"],
            cid: d.id,
            type: isTeacher ? "suup_okay" : "student_okay",
            channel: constants.channelId,
          },
          set: [
            {
              key: `cid:${d.id}' and mid='${userInfo["id"]}`,
              value: {
                type: isTeacher ? 6 : 0,
                status: 1,
              },
            },
          ],
          forTrace: "apiPro_approve",
        },
      });
      await setSelectLecture({});
      await setIsLecture(false);
      await getAcademyPeople(selectLecture.id);
    } catch (e) {
      // console.log("error => ",e);
    }
  };

  const apiPro_del = async (data) => {
    try {
      console.log("CALL APIPRO DEL ", data);
      // return;
      let isOk = await apiFn.exitClass({
        state: {
          orderMid: userInfo.id,
          targetMid: data.id,
          cid: selectLecture.id,
          forTrace: "apiPro_del",
        },
      });

      setIsEdit(false);
      util.postWeb("isModal_off");

      await getAcademyPeople(selectLecture.id);
    } catch (e) {
      console.log(e);
    }
  };

  const onSearch = async (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("isVideo_off");
      document.addEventListener("message", async (e) => {
        if (e.data === "isModal") {
          // setIsEdit(false)
          // setIsDel(false)
          setIsPeople(false);
        }
      });
    }
  }, []);

  return (
    <>
      <Frame
        isSearch={false}
        userInfo={userInfo}
        isBack={false}
        // t_visible={false}
        // isTap = {true}
        isFooter={false}
        type="2"
        title={
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "24px",
                lineHeight: "28px",
                textAlign: "center",
                letterSpacing: "2px",
              }}
            >
              {isTeacher ? "내 학원" : "내 선생님"}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "230px",
              }}
            >
              <UiInput
                type="basic"
                basic_container_style={{
                  height: "94%",
                }}
                fnChange={(e) => {
                  onSearch(e);
                }}
                placeholder={"전화번호/이름/닉네임 검색"}
                inputClass={isTeacher ? "input_t" : "input_c"}
              />
            </div>
          </div>
        }
      >
        <div
          style={{
            ...Message_Style.c,
            position: "relative",
          }}
        >
          {lectureList.length === 1 && isTeacher && !isEdit && (
            <button
              style={{
                position: "absolute",
                backgroundColor: "transparent",
                border: "none",
                padding: 14,
                top: 0,
                right: 10,
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => setShowLectureInviteModal(true)}
            >
              <PersonAddAlt1 />
              &nbsp;수업에 초대
            </button>
          )}
          {isLecture && (
            <div
              style={{
                padding: "10px 20px 0px 20px",
              }}
            >
              <button
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "red",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
                onClick={() => {
                  setIsLecture(false);
                  setSelectLecture({});
                }}
              >
                {"전체 보기"}
              </button>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px 0px 10px 0px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // width:"110px",
                    height: "26px",
                    padding: "10px 0px",
                    color: "#3D3A39",
                    fontWeight: 700,
                    fontSize: "18px",
                  }}
                >
                  <img
                    src={class5_black}
                    style={{
                      width: "28px",
                      marginRight: "5px",
                      paddingBottom: "2px",
                      // height : "20px",
                    }}
                  />
                  {selectLecture["name"]}
                </div>

                {selectLecture.memberType === 9 && (
                  <div>
                    <UiBtn
                      btnStyle={{
                        width: 56,
                        height: 24,
                        fontSize: 12,
                        color: "white",
                        borderColor: isTeacher ? style.common.t_color : style.common.s_color,
                        backgroundColor: isTeacher ? style.common.t_color : style.common.s_color,
                      }}
                      title="수락"
                      fnClick={async () => {
                        await apiPro_approve(selectLecture);
                        Object.keys(selectData).forEach(async (id) => {
                          await createInitialTicket({
                            variables: {
                              userId: id,
                              academyId: selectLecture.id,
                            },
                          });
                        });
                      }}
                    />
                    <UiBtn
                      btnStyle={{
                        width: 56,
                        height: 24,
                        fontSize: 12,
                        color: "white",
                        borderColor: "rgb(231, 170, 170)",
                        backgroundColor: "rgb(231, 170, 170)",
                      }}
                      title="거절"
                      fnClick={async () => {
                        await apiPro_del(selectLecture);
                        setIsLecture(false);
                        setSelectLecture({});
                      }}
                    />
                  </div>
                )}
                {isTeacher && !isEdit && (
                  <button
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setShowLectureInviteModal(true);
                    }}
                  >
                    <PersonAddAlt1 />
                    &nbsp;수업에 초대
                  </button>
                )}
              </div>
            </div>
          )}

          {!isLecture && lectureList.length > 1 && (
            <>
              <div
                style={{
                  display: "flex",
                  padding: "10px 20px 10px 20px",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // width:"110px",
                    height: "26px",
                    padding: "10px 0px",
                    borderBottom: "2px solid",
                    borderBottomColor: "#5EA3C6",
                    color: "#5EA3C6",
                    fontWeight: 700,
                    fontSize: "18px",
                  }}
                >
                  내 수업
                </div>
                {isTeacher && !isEdit && (
                  <button
                    style={{
                      backgroundColor: "transparent",
                      border: "none",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      alert("먼저 수업을 선택해주세요");
                    }}
                  >
                    <PersonAddAlt1 />
                    &nbsp;수업에 초대
                  </button>
                )}
              </div>
              <div
                style={{
                  padding: "0px 20px 10px 20px",
                }}
              >
                {isMore
                  ? lectureList.map((v) => (
                      <div
                        key={v.id}
                        style={{
                          width: "100%",
                          display: "flex",
                          fontWeight: 400,
                          fontSize: "16px",
                          color: "#3D3A39",
                          padding: "10px 0px",
                        }}
                        onClick={() => {
                          setIsLecture(true);
                          setSelectLecture(v);
                        }}
                      >
                        {v["name"]}
                        {isTeacher && v.joinCnt > 0 && (
                          <div
                            style={{
                              borderRadius: "100%",
                              width: 6,
                              height: 6,
                              backgroundColor: style.common.s_color,
                            }}
                          />
                        )}
                      </div>
                    ))
                  : lectureList.slice(0, 3).map((v) => (
                      <div
                        key={v.id}
                        style={{
                          width: "100%",
                          display: "flex",
                          fontWeight: 400,
                          fontSize: "16px",
                          color: "#3D3A39",
                          padding: "10px 0px",
                        }}
                        onClick={() => {
                          setIsLecture(true);
                          setSelectLecture(v);
                        }}
                      >
                        {v["name"]}
                        {isTeacher && v.joinCnt > 0 && (
                          <div
                            style={{
                              borderRadius: "100%",
                              width: 6,
                              height: 6,
                              backgroundColor: style.common.s_color,
                            }}
                          />
                        )}
                      </div>
                    ))}
                {lectureList.length > 3 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                      fontWeight: 400,
                      fontSize: "14px",
                      color: "#5EA3C6",
                      gap: 5,
                    }}
                    onClick={() => {
                      setIsMore(!isMore);
                    }}
                  >
                    {isMore ? (
                      "접기"
                    ) : (
                      <>
                        {userInfo?.type === 2 && totalJoinCount !== 0 && (
                          <span style={{ color: "#000" }}>총 신청자 {totalJoinCount}명</span>
                        )}
                        더 보기
                      </>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
          {isTeacher &&
            applyUserList.length > 0 &&
            (Object.keys(selectLecture).length > 0 || Object.keys(lectureList).length === 1) && (
              <>
                <div
                  style={{
                    display: "flex",
                    padding: "10px 20px 10px 20px",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      // width:"110px",
                      height: "26px",
                      padding: "10px 0px",
                      borderBottom: "2px solid",
                      borderBottomColor: "#333",
                      color: "#333",
                      fontWeight: 700,
                      fontSize: "18px",
                    }}
                  >
                    수강 신청
                  </div>

                  {isEdit && (
                    <div style={{ display: "flex", width: 120, justifyContent: "space-between" }}>
                      <UiTextBtn
                        text={"승인"}
                        isUnderline={false}
                        btnStyle={{
                          color: "skyblue",
                        }}
                        fnClick={async (e) => {
                          await Promise.allSettled(
                            Object.keys(selectData).map((id) =>
                              apiPro_set({ id }, false, true, false)
                            )
                          );
                          setIsEdit(false);
                          setSelectData({});
                          Object.keys(selectData).forEach(async (id) => {
                            await createInitialTicket({
                              variables: {
                                userId: id,
                                academyId: selectLecture.id,
                              },
                            });
                          });
                        }}
                      />
                      <UiTextBtn
                        isUnderline={false}
                        btnStyle={{
                          color: "#B42E2E",
                        }}
                        text="거절"
                        fnClick={async () => {
                          await Promise.allSettled(
                            Object.keys(selectData).map((id) => apiPro_del({ id }))
                          );
                          if (applyUserList.length === 0) {
                            setIsLecture(false);
                            setSelectLecture({});
                          }
                          setSelectData({});
                        }}
                      />
                      <UiTextBtn
                        text={"취소"}
                        isUnderline={false}
                        btnStyle={{
                          color: "#ababab",
                        }}
                        fnClick={(e) => {
                          setIsEdit(false);
                          setSelectData({});
                        }}
                      />
                    </div>
                  )}
                </div>
                <div
                  style={{
                    padding: "0px 20px 10px 20px",
                  }}
                >
                  <UiPeople_Type2
                    isBlank={false}
                    data={applyUserList}
                    form={2}
                    selectData={selectData}
                    isPeople={true}
                    isEdit={isEdit}
                    isTeacher={isTeacher}
                    isOnly
                    fnClick={() => {
                      setIsEdit(true);
                    }}
                    fnApprove={(d) => {
                      apiPro_set(d, false, true, false);
                      getAcademyPeople(d?.cid);
                    }}
                    fnReject={(d) => {
                      apiPro_del(d);
                      getAcademyPeople(d?.cid);
                    }}
                    setHiddenNav={() => {}}
                    fnCheck={(e) => {
                      if (selectData[e.target.id] == "Y") {
                        delete selectData[e.target.id];
                        setSelectData({
                          ...selectData,
                        });
                      } else {
                        setSelectData({
                          ...selectData,
                          [e.target.id]: "Y",
                        });
                      }
                    }}
                  />
                </div>
              </>
            )}

          {isTeacher &&
            (Object.keys(selectLecture).length > 0 || Object.keys(lectureList).length === 1) &&
            participatingTeacherList?.length > 0 && (
              <>
                <div
                  style={{
                    display: "flex",
                    padding: "10px 20px 10px 20px",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      // width:"110px",
                      height: "26px",
                      padding: "10px 0px",
                      borderBottom: "2px solid",
                      borderBottomColor: style.common.t_color,
                      color: style.common.t_color,
                      fontWeight: 700,
                      fontSize: "18px",
                    }}
                  >
                    참여 중인 선생님
                  </div>
                </div>
                <div style={{ padding: "0 20px" }}>
                  <UiPeople_Type2
                    // fnExit={}
                    // fnUp={}
                    form={2}
                    userInfo={userInfo}
                    isBlank={false}
                    data={participatingTeacherList}
                    isOnly={isTeacher && selectLecture.type === 6}
                    manageSub={true}
                    navigate={navigate}
                    isTeacherPage={isTeacher}
                    fnUp={(d) => {
                      apiPro_set(d, false, false, false, true, false);
                      getAcademyPeople(d?.cid);
                    }}
                    fnAssist={(d) => {
                      apiPro_set(d, false, false, false, false, false, true);
                      getAcademyPeople(d?.cid);
                    }}
                    fnApprove={(d) => {
                      apiPro_set(d, false, true, false, false, false);
                      getAcademyPeople(d?.cid);
                    }}
                    fnDown={(d) => {
                      apiPro_set(d, true, false, false, false, true);
                      getAcademyPeople(d?.cid);
                    }}
                    fnExit={(d) => {
                      apiPro_del(d);
                    }}
                    isTeacher={isTeacher}
                    fnClick={(d) => {
                      setSelectPeople(d);
                      util.postWeb("isModal");
                      setIsPeople(true);
                    }}
                    setHiddenNav={() => {}}
                  />
                </div>
              </>
            )}

          <div
            style={{
              display: "flex",
              padding: "10px 20px 10px 20px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // width:"110px",
                height: "26px",
                padding: "10px 0px",
                borderBottom: "2px solid",
                borderBottomColor: "#F7B865",
                color: "#F7B865",
                fontWeight: 700,
                fontSize: "18px",
              }}
            >
              {isTeacher ? "수강 중인 내 학생" : "참여 중인 내 선생님"}
            </div>
            {!isTeacher && (
              <div
                style={{
                  display: "flex",
                  marginTop: "10px",
                }}
              >
                <QnaCircle type="a" />
                <QnaCircle type="b" />
                <QnaCircle type="c" />
              </div>
            )}
          </div>
          <div
            style={{
              padding: "0px 20px 10px 20px",
            }}
          >
            {userList.length === 0 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100px",
                  fontSize: "16px",
                  color: "#3D3A39",
                }}
              >
                {isTeacher ? "수강 중인 학생이 없습니다." : "참여 중인 선생님 없습니다."}
              </div>
            )}
            <UiPeople_Type2
              isStat={true}
              scrollKey={scrollKey}
              setScrolllKey={setScrolllKey}
              userInfo={userInfo}
              isTeacher={isTeacher}
              isPeople={false}
              fnQan_see={(e, data) => {
                setFileObj({
                  base64: "null",
                });
                navigate(`/searchresult/${params["who"]}/see&${data["id"]}/de`);
              }}
              isOnly={true}
              fnClick={(d) => {
                setSelectPeople(d);
                util.postWeb("isModal");
                setIsPeople(true);
              }}
              data={filteredUserList}
            />
          </div>
          {isPeople && (
            <Modal_people
              data={selectPeople}
              setIsModal={setIsPeople}
              setFileObj={setFileObj}
              setQnaData={setQnaData}
              setHiddenUrl={setHiddenUrl}
            />
          )}
        </div>
      </Frame>
      {showLectureInviteModal && (
        <InviteModal
          cid={(lectureList.length === 1 && lectureList[0]?.id) || selectLecture?.id}
          name={(lectureList.length === 1 && lectureList[0]?.name) || selectLecture?.name}
          onClose={() => setShowLectureInviteModal(false)}
        />
      )}
    </>
  );
};

const QnaCircle = ({ type = "a" }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "14px",
          height: "14px",
          alignItems: "center",
          justifyContent: "center",
          margin: "0px 20px",
          borderRadius: 50,
          backgroundColor:
            type == "a" ? "#A8E2B8" : type == "b" ? "#FCDB92" : type == "c" && "#F87A7A",
        }}
      ></div>
      <div
        style={{
          fontSize: "12px",
          fontWeight: 400,
          marginTop: "3px",
        }}
      >
        {type == "a" ? "원할해요" : type == "b" ? "질문해요" : type == "c" && "어려워요"}
      </div>
    </div>
  );
};

export default PeopleMainWrapper;
