import { IAccountRepository } from "@interfaces";
import { accountRepository } from "@infrastructure/repositories/account";

class AccountUseCase {
  private accountRepository: IAccountRepository;

  constructor(accountRepository: IAccountRepository) {
    this.accountRepository = accountRepository;
  }

  getProfile() {
    return this.accountRepository.getProfile();
  }
}

export const accountUseCase = new AccountUseCase(accountRepository);
