import { ITokenStorageRepository } from "./token-storage.interface";
import { IToken } from "../token.interface";
import { TokenNameEnum } from "../enum/token.enum";

class TokenCookieRepository implements ITokenStorageRepository {
  private readonly tokenNames: ReadonlyArray<TokenNameEnum>;

  constructor() {
    this.tokenNames = Object.values(TokenNameEnum);
  }

  private getTokenFromCookie(tokenName: string): string | null {
    const cookies = document.cookie.split(";");
    const tokenCookie = cookies
      .map((cookie) => cookie.trim())
      .find((cookie) => cookie.startsWith(tokenName));
    return tokenCookie ? tokenCookie.split("=")[1] : null;
  }

  saveToken(token: IToken): void {
    document.cookie = `${token.tokenName}=${token.tokenValue}; expires=${token.expiresIn.toUTCString()}`;
  }
  getToken(tokenName: TokenNameEnum): IToken {
    const tokenValue = this.getTokenFromCookie(tokenName);
    if (!tokenValue) {
      throw new Error("Token not found");
    }
    return {
      tokenName: tokenName,
      tokenValue,
      expiresIn: new Date(decodeURIComponent("2025-01-26T08%3A35%3A16.000Z")),
    };
  }

  private removeToken(tokenName: string): void {
    document.cookie = `${tokenName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;  path=/`;
  }
  clearToken(): void {
    this.tokenNames.forEach((tokenName) => this.removeToken(tokenName));
  }

  hasToken(tokenName: TokenNameEnum): boolean {
    return !!this.getTokenFromCookie(tokenName);
  }
}
export { TokenCookieRepository };
