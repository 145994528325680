import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import AccountForm from "@components/organisms/accountForm";
import { AccountFieldType } from "@constants/accountForm/types";

type AccountFormTemplateProps = {
  buttonBackgroundColor?: string;
  onSubmit: (data: AccountFieldType) => void;
  formFields: (keyof AccountFieldType)[];
  formDefaultValues?: AccountFieldType;
};

export default function AccountFormTemplate({
  buttonBackgroundColor,
  onSubmit,
  formFields,
  formDefaultValues,
}: AccountFormTemplateProps): JSX.Element {
  const methods = useForm<AccountFieldType>({
    mode: "onChange",
    delayError: 500,
    criteriaMode: "all",
  });

  return (
    <FormProvider {...methods}>
      <AccountForm
        fields={formFields}
        defaultValues={formDefaultValues}
        onSubmit={onSubmit}
        buttonBackgroundColor={buttonBackgroundColor}
      />
    </FormProvider>
  );
}
