export enum AtomKeys {
  // ui
  ANSWER_VIEW = "answer_view",
  FULL_SCREEN = "full_screen",
  ACTION_BUTTONS = "actions_buttons",
  SIDE_MENU = "side_menu",
  SHOW_MESSAGE_LOADING = "show_message_loading",
  DRAGGING_MODAL = "dragging_modal",
  CLICK_CONTEXT_ID = "click_context_id",
  USER_TYPE = "user_type",

  // swiper
  SWIPER_SLIDE_REAL_INDEX = "swiper_slide_real_index",
  SIWPER_IS_ZOOMED = "swiper_is_zoomed",

  // book (qna)
  QUESTION_ID = "question_id",

  // search
  SEARCH_HISTORY = "search_history",
  SEMOHAE_SEARCH = "semohae_search",

  //modal
  OCR_EDIT_MODAL = "ocr_edit_modal",
  EDIT_SHOW_MODAL = "edit_show_modal",
  CAPTURE_STATE_MODAL = "capture_state_modal",

  //socket
  SOCKET = "socket",
  SOCKET_MESSAGE = "socket_message",

  //question
  QUESTION_CONTENT = "question_content",

  //answer
  ANSWER_CONTENT = "answer_content",

  //sheet
  CAPTURE_SIZE_SHEET = "capture_size_sheet",
}
