export enum EndPoints {
  // book
  BOOK = `/book`,
  GET_QUESTIONS_BY_BOOK_ID = "qna-list?isAnswer=0",
  GET_ANSWERS_BY_ID = "qna-list?isAnswer=1",

  // search
  SEARCH_ANSWER = "/search/question",
}

export enum AccountApiEndpoints {
  SET_STAT_STORE = "/account/stat_store/:userId/set",
  GET_STAT_STORE = "/account/stat_store/:userId/:search_key",
  GET_CHECK_NICKNAME = "/account/check-nickname-usable/:nickname",
  CREATE_JOIN_ACCOUNT = "/account/join",
  GET_PROFILE = "/account/profile",
}

export enum QueryKeys {
  USER_METADATA = "user_metadata",
  ACADEMY_LECTURE_LIST = "academy_lecture_list",
  LECTURE_SEARCH_LIST = "lecture_search_list",
  ACCOUNT_PROFILE = "account_profile",
}

export enum AuthApiEndpoints {
  CREATE_LOCAL_AUTH = "/auth/login/local_auth",
}

export enum AcademyApiEndpoints {
  GET_LECTURE_LIST = "/academy/:academy_code/lectures/list",
  GET_LECTURE_SEARCH_LIST = "/academy/:academy_code/lectures/search",
}

export enum LectureApiEndpoints {
  GET_LECTURE_SEARCH_LIST = "/lecture/search",
  SET_LECTURE = "/lecture/:lectureId/request",
}
