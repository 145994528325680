import styled from "styled-components";
import { Link } from "react-router-dom";

export const LogoContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
`;

export const MainLogo = styled.img`
  width: 50%;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
`;

export const DescriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledImg = styled.img`
  width: 100%;
  max-width: 400px;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
`;

export const Description = styled.p`
  font-size: 1.29em;
  font-weight: 400;
`;

export const LoginButtonContainer = styled.section`
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
`;

export const LoginButton = styled.button<{ background?: string; color?: "dark" | "white" }>`
  background: ${(props) => props.background || "#f5b21a"};
  width: 100%;
  padding: 1.14em 0;
  border-radius: 5px;
  color: ${(props) => (props.color === "dark" ? "#242424" : "#fff")};
  cursor: pointer;
  font-size: 1em;
`;

export const ContactLink = styled(Link)`
  font-size: 14px;
  color: #cecece;
`;
