import { React, useState } from "react";
import style from "../../asset/style";
import { useNavigate, useParams } from "react-router";
import Basic from "../../common/frame/Basic";
import { UiInput } from "../../uiComponents/UiInput/UiInput";
import apiFn from "../../asset/apiClass";
import Reply_Style from "../Reply/Reply_Style";
import store from "../../asset/store";

const Warn = ({ userInfo }) => {
  let params = useParams();
  let navigate = useNavigate();

  const [isTeacher, setIsTeacher] = useState(true);

  var [replyData, setReplyData] = useState([]);

  const [content, setContent] = useState("");

  // 신고하기
  const apiPro_regi = async () => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "board_log",
          ins: [
            {
              wid: userInfo["id"],
              bid: params["id"],
              btype: 3,
              reg_date: 0,
              content: content,
            },
          ],
        },
      });

      const stack = store.get("stack");
      if (stack.length > 0) navigate(-1);
      else navigate("/");
    } catch (e) {}
  };

  // 신고하기 댓글일떄
  const apiPro_regi_2 = async () => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "board_reply",
          ins: [
            {
              wid: userInfo["id"],
              boid: params["id"],
              wtype: params["who"] == "teacher" ? 2 : 1,
              rtype: 1,
              reg_date: 0,
              content: content,
            },
          ],
        },
      });

      const stack = store.get("stack");
      if (stack.length > 0) navigate(-1);
      else navigate("/");
    } catch (e) {
      // console.log("?",e)
    }
  };

  return (
    <Basic title={"신고하기"}>
      <div
        style={{
          ...Reply_Style.t_btn_w,
          color: params["who"] == "teacher" ? style.common.t_color : style.common.s_color,
        }}
        onClick={() => {
          if (params["where"] == "reply") {
            apiPro_regi_2();
          } else {
            apiPro_regi();
          }
        }}
      >
        {"등록"}
      </div>
      <div
        style={{
          padding: "40px 0px",
        }}
      >
        <UiInput
          inputClass={params["who"] == "teacher" ? "input_t" : "input_s"}
          type="desc"
          dsecStyle={{
            height: "80px",
          }}
          inputValue={content}
          placeholder={"신고할 내용을 적어주세요."}
          maxLength={30}
          fnChange={(e) => setContent(e.target.value)}
        />
      </div>
    </Basic>
  );
};

export default Warn;
