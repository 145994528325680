export enum ACCOUNT_TYPE_ENUM {
  STUDENT = "STUDENT",
  TEACHER = "TEACHER",
  ADMIN = "ADMIN",
  SUPER_ADMIN = "SUPER_ADMIN",
}

export enum ACCOUNT_STATUS_ENUM {
  OK = "ok",
  NOT_FOUND = "notfound",
  ERROR = "error",
  CONFLICT = "conflict",
}
