import { IColorRepository } from "../../../domain/interfaces/theme/color-repository.interface";
import { ColorValue } from "../../../domain/entities/theme/color.entity";

export interface GetColorValueUseCaseRequest {
  colorInput: string | null;
}

export class GetColorValueUseCase {
  constructor(private colorRepository: IColorRepository) {}

  execute(request: GetColorValueUseCaseRequest): string {
    const { colorInput } = request;
    let color = this.colorRepository.getDefaultColor();

    if (colorInput) {
      const directColor = ColorValue.create(colorInput);
      if (directColor) {
        color = directColor;
      } else if (colorInput.includes(".")) {
        const [palette, shade] = colorInput.split(".");
        color = this.colorRepository.getColorByPaletteAndShade(palette, shade) ?? color;
      } else {
        color = this.colorRepository.getColorByPalette(colorInput) ?? color;
      }
    }

    return color.toString();
  }
}
