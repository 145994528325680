import colors from "./colors";
import { foundations } from "./foundations";
import typography from "./typography";
import constants from "./constants";

export const theme = {
  colors,
  typography,
  ...foundations,
  ...constants,
};
