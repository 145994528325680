import style from "../../asset/style";

const UiBtnStyle = {
  default_wrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid",
    borderColor: style.common.borderColor,
    fontSize: 14,
    width: "100%",
    height: "44px",
    borderRadius: 5,
    cursor: "pointer",
    outline: "none",
    margin: "7.5px 0px",

    backgroundColor: "white",
    color: "#444",

    // backgroundColor:"red",
    // backgroundColor:'#fff',
    // marginLeft:"7px",
    // marginRight:"8px",
    // borderColor : constants.style.color_10,
    // color:constants.style.color_5,
  },
  typeA_wrapper: {
    width: "133px",
    height: "38px",
    color: "#fff",
    fontWeight: "bold",
    marginBottom: "7px",
    marginRight: 0,
    marginLeft: "4px",
    // backgroundColor : constants.style.color_1
  },
};

export default UiBtnStyle;
