import { IAccessRule } from "../interface/academyEnter.interface";
import { IAcademyLecturesRelatedResponse } from "../../../../shared/api/api-gateway/academy/academy_code/lectures/related/type";
import { ICheckAcademyInfo } from "../interface/checkAcademyInfo.interface";

export class CheckMainAccessUseCase {
  constructor(private readonly rules: IAccessRule[]) {}

  execute(academy: ICheckAcademyInfo): boolean {
    const lectureStatusList = this.collectLectureStatuses(academy.lectureRelatedList);

    return this.rules.every((rule) =>
      rule.check({ ...academy, lectureStatuses: lectureStatusList })
    );
  }

  private collectLectureStatuses(lecturesData: IAcademyLecturesRelatedResponse[]) {
    return lecturesData.flatMap((page) => page.lectures.flatMap((lectures) => lectures.status));
  }
}
