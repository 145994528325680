import { IUrlManager } from "./interface/url-manager.interface";

export class UrlManager implements IUrlManager {
  private readonly url: string;
  private readonly pathname: string;
  private readonly host: string;
  private readonly origin: string;

  constructor() {
    this.url = window.location.href;
    this.pathname = window.location.pathname;
    this.host = window.location.host;
    this.origin = window.location.origin;
  }

  getUrl(): string {
    return this.url;
  }

  getPathname(): string {
    return this.pathname;
  }

  getHost(): string {
    return this.host;
  }

  getOrigin(): string {
    return this.origin;
  }

  getFirstPathSegment(): string {
    return this.pathname.split("/")[1];
  }
  redirectToDefault(): void {
    window.history.replaceState({}, "", "/");
  }
  getOriginWithFirstPathSegment(): string {
    const firstPathSegment = this.getFirstPathSegment();
    return `${this.origin}${firstPathSegment ? `/${firstPathSegment}` : ""}`;
  }
}

export const urlManager = new UrlManager();
