import React from "react";
import styled from "styled-components";
import { AcademyListItem } from "@api/academy/types";
import HighlightedText from "@components/molecules/highlightedText";
import { Typography } from "@presentation/components/common/atoms";

const Container = styled.li<{ active: boolean }>`
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme, active }) =>
    active ? theme.colors.primary[70] : theme.colors.natural[30]};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  padding: ${({ theme }) => theme.spacing.sm};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm};
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
`;
const DescriptionWrapper = styled.div``;

const MemberQuantityBox = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.spacing.sm};
`;
export default function LectureItem({
  lecture,
  active,
  searchText,
  onClickItem,
}: {
  lecture: AcademyListItem;
  active: boolean;
  searchText?: string;
  onClickItem?: () => void;
}): JSX.Element {
  const { lectureName, ownerName, introMessage, academyName, memberQuantity } = lecture;

  return (
    <Container
      active={active}
      role={"option"}
      tabIndex={0}
      aria-checked={active}
      onClick={onClickItem}
    >
      <TitleWrapper>
        <HighlightedText text={lectureName} highlightText={searchText} variant={"bold.base"} />
        <HighlightedText text={academyName} highlightText={searchText} />
      </TitleWrapper>
      <DescriptionWrapper>
        <HighlightedText text={introMessage} highlightText={searchText} fontSize={"xs"} />
        <HighlightedText
          text={ownerName}
          highlightText={searchText}
          fontSize={"xs"}
          frontText={"담당 "}
          backText={" 선생님"}
        />
        <MemberQuantityBox>
          <Typography fontSize={"xs"}>
            강사수 {memberQuantity?.teacher ?? 0} 명 | 학생수 {memberQuantity?.student ?? 0} 명
          </Typography>
        </MemberQuantityBox>
      </DescriptionWrapper>
    </Container>
  );
}
