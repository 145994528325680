import { CheckMainAccessBuilderUseCase } from "../usecase/checkMainAccess-builder.useCase";
import { ApprovalRule } from "../util/approvalRule";
import { MinimumLectureCountRule } from "../util/minimumLectureCountRule";
import { useGetAcademyInfo } from "../../../academy/shared/hook/useGetAcademyInfo";
import { useGetLecturesRelatedToAcademy } from "../../../academy/shared/hook/useGetLecturesRelatedToAcademy";
import { CONFIG } from "@config";

interface UseCheckMainAccessResult {
  onCheckEnterMain: () => Promise<boolean>;
}

export const useCheckMainAccess = (): UseCheckMainAccessResult => {
  const academyApp = CONFIG.APP_ENV.IS_ACADEMY_APP;
  const academyAppCode = CONFIG.APP_ENV.APP_ACADEMY_CODE;
  const { refetch: getAcademyInfoRefetch } = useGetAcademyInfo(academyAppCode);
  const { refetch: getLecturesRelatedToAcademy } = useGetLecturesRelatedToAcademy(academyAppCode);
  const academyRule = {
    requireApproval: true,
    minimumLectureCount: 1,
    requireActiveLectures: true,
  };
  const checkMainAccess = new CheckMainAccessBuilderUseCase()
    .addRule(new ApprovalRule())
    .addRule(new MinimumLectureCountRule())
    .build();

  const checkAccess = async (): Promise<boolean> => {
    if (!academyApp) {
      return true;
    }

    const [academyResponse, lecturesResponse] = await Promise.all([
      getAcademyInfoRefetch(),
      getLecturesRelatedToAcademy(),
    ]);

    return checkMainAccess.execute({
      academy_code: academyResponse.data.id,
      academy_status: academyResponse.data.status,
      lectureRelatedList: lecturesResponse.data.pages,
      rules: academyRule,
    });
  };

  return {
    onCheckEnterMain: checkAccess,
  };
};
