import React, { useContext } from "react";
import { SignUpContext } from "@providers/pages/signUp";
import styled from "styled-components";
import { descriptions } from "@pages/signUp/descriptions";
import { Typography } from "@presentation/components/common/atoms";

const currentStepDescription = ["계정정보", "약관동의", "개인정보 입력"];

const Container = styled.section`
  margin-top: ${({ theme }) => theme.spacing.md};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.md};
`;
const TitleSection = styled.div`
  background: ${({ theme }) => theme.colors.natural[0]};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  padding: ${({ theme }) => theme.spacing.md};
  position: relative;
`;

const Image = styled.img`
  position: absolute;
  right: -4px;
  bottom: 0;
  width: 120px;
`;

const StepSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export default function StepIndicator(): JSX.Element {
  const { signUpFormData, currentStep } = useContext(SignUpContext);
  const { type } = signUpFormData;
  const { imageUrl, pointColor, typeText } = descriptions[type];

  return (
    <Container>
      <TitleSection>
        <Typography variant={"bold.sm"} color={`${pointColor}.70`}>
          {typeText} 회원 가입
        </Typography>
        <Image src={imageUrl} alt={`${typeText} 캐릭터`} />
      </TitleSection>
      <StepSection>
        <Typography variant={"bold.base"}>{currentStepDescription[currentStep]}</Typography>
      </StepSection>
    </Container>
  );
}
