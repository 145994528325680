import { React } from "react";
import UiFolder_Style from "./UiFolder_Style";
import folder_ from "../../asset/Img/folder_.png";
import folderStyle from "./UiFolder.module.scss";

const UiFolder = ({
  data,
  id,
  isEdit,
  isTeacher,
  setIsSearch,
  selectData,
  setSelectData,
  params,
  navigate,
  fnLongClick,
  fnCheck,
  fnClick,
  navi,
  isNavi,
}) => {
  // let navigate = useNavigate();

  // const bind = useLongPress(()=>{
  //     try {
  //         fnLongClick(data["id"])
  //     }
  //     catch(e){
  //         // console.log("Long Press Error => ",e)
  //     }
  // },{
  //     threshold: 1000,
  // });

  return (
    <div
      // {...bind}

      style={{
        ...UiFolder_Style.folder_c,
        border: isEdit ? (selectData[data["id"]] == "Y" ? "1px solid #F09294" : "none") : "none",
      }}
      onClick={() => {
        if (isEdit) {
          // console.log("ISDEIT TRUE")
          if (selectData[data["id"]] == "Y") {
            delete selectData[data["id"]];
            setSelectData({
              ...selectData,
            });
          } else {
            // console.log("ISDEIT @#@#@#")
            setSelectData({
              ...selectData,
              [data["id"]]: "Y",
            });
          }
        } else {
          if (fnClick) {
            fnClick(data);
          }
          if (isNavi) navigate(navi);
        }
      }}
    >
      {/* {
            isEdit &&
            <div style={UiFolder_Style.folder_chk}>
                <UiCheckBox
                    fnClick={fnCheck}
                    checked={selectData[data["id"]] == "Y" ? true : false}
                    className={isTeacher ? "t" : "s"}
                    value={data["id"]}
                />
            </div>
            } */}
      <img
        // src={empty_folder}
        src={folder_}
        style={UiFolder_Style.folder_img}
      />
      <div className={folderStyle.folderName}>{data["name"]}</div>
    </div>
  );
};

export default UiFolder;
