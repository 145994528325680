import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { React, useEffect, useState } from "react";
import UiBtn from "../UiBtn/UiBtn";
import UiSlide_Style from "./UiSlide_Style";
import util from "../../asset/util";

const Title = ({ titleData, setTitleData, data, isArrow, index, apiPro, fnClickAll }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      style={{
        display: "flex",
      }}
      onClick={() => {
        let obj = {};
        // console.log(Object.keys(titleData).indexOf(data["name"]));
        let spliceNum = Object.keys(titleData).indexOf(data["name"]);
        Object.keys(titleData)
          .splice(0, spliceNum + 1)
          .map((v, i) => {
            obj[titleData[v]["name"]] = {
              ...titleData[v],
            };
          });
        // console.log("obj => ",obj);
        setTitleData({
          ...obj,
        });
        if (data["name"] == "전체") {
          apiPro();
          fnClickAll();
        } else {
          apiPro(data);
        }
      }}
    >
      {isArrow && index != 0 && (
        <div
          style={{
            padding: "0px 3px",
          }}
        ></div>
      )}
      <div
        style={{
          fontWeight: "bold",
        }}
      >
        {util.textTrim(data["name"], 12)}
      </div>
    </div>
  );
};

const UiSilde = ({
  boardObj,
  isBoard = false,
  initData,
  fnClickAll,
  apiPro,
  data = [
    {
      id: 1,
      name: "시대인재(강남)",
    },
    {
      id: 2,
      name: "대치보습클래스룸",
    },
    {
      id: 3,
      name: "명예와전통수학전문클래스룸",
    },
    {
      id: 4,
      name: "강남클래스룸",
    },
    {
      id: 5,
      name: "ASDFASDF",
    },
  ],
  isFull = true,
  isPadding = true,
  fnClick,
  tempTitleData = {},
  // setTempTitleData
}) => {
  const [isOpen, setIsOpen] = useState(false);
  var [titleData, setTitleData] = useState({
    전체: {
      name: "전체",
      id: 0,
    },
  });

  useEffect(() => {
    // console.log("$$$ ",data);
    // console.log("$$$ ",data);
    // console.log("$$$ ",data);

    // // console.log(initData)
    // // console.log(initData)

    // // console.log("+================")
    // // console.log("$$$ ",data);
    // // console.log("$$$ ",data);

    // // console.log("+================")

    // // console.log("@@@ ",titleData)
    // // console.log("@@@ ",titleData)

    try {
      if (isBoard) {
        // // console.log("+================")

        // console.log("%%% ",tempTitleData)
        // console.log("%%% ",tempTitleData)
        if (Object.keys(tempTitleData).length > 1) {
          setTitleData({
            ...tempTitleData,
          });
          data = [];
        } else {
          if (data.length == 0) {
            setIsOpen(false);
          } else {
            setIsOpen(true);
          }
        }
        // if(initData["id"]){
        //     setTitleData({
        //         ...titleData,

        //     })
        //     setIsOpen(false)
        // }
        // else {
        //     setIsOpen(true)
        // }
      } else {
        if (data.length == 0) {
          setIsOpen(false);
        } else {
          setIsOpen(true);
        }
      }
    } catch (e) {
      // console.log("$$ ",e)
    }
  }, [data]);

  useEffect(() => {
    // // console.log(titleData)
    // // console.log("!!!! ",Object.keys(titleData))
    // // console.log("!!!! ",Object.keys(titleData).length)
    // if(Object.keys(tempTitleData).length > 1){
    //     // console.log("$$$$$$$$$$$$")
    //     setTempTitleData({
    //         ...titleData
    //     })
    //     // console.log("$$$$$$$$$$$$")
    //     // console.log("$$$$$$$$$$$$")
    //     // console.log("$$$$$$$$$$$$")
    // }
  }, [titleData]);

  return (
    <div style={UiSlide_Style.c}>
      <div
        style={{
          ...UiSlide_Style.w,
          padding: isPadding ? "10px 20px 0px 20px" : "0px",
        }}
      >
        {Object.keys(titleData).map((v, i) => {
          return (
            <Title
              fnClickAll={fnClickAll}
              apiPro={apiPro}
              key={"title_" + v}
              titleData={titleData}
              setTitleData={setTitleData}
              data={titleData[v]}
              index={i}
              fnClick={fnClick}
              isArrow={Object.keys(titleData).length > 1 ? true : false}
            />
          );
        })}
        {Object.keys(tempTitleData).length == 1 &&
          data.length > 0 &&
          (isOpen ? (
            <KeyboardArrowUp
              style={UiSlide_Style.arrow}
              onClick={() => {
                setIsOpen(false);
              }}
            />
          ) : (
            <KeyboardArrowDown
              style={UiSlide_Style.arrow}
              onClick={() => {
                setIsOpen(true);
              }}
            />
          ))}
      </div>
      {isOpen && (
        <div
          style={{
            ...UiSlide_Style.title_c,
            width: isFull ? "100vw" : "92vw",
          }}
        >
          {data.length > 0 && Object.keys(titleData).length != 1 && (
            <UiBtn
              btnStyle={{
                ...UiSlide_Style.title_btn,
                width: "80px",
                marginLetf: "20px",
              }}
              title={"선택안함"}
              fnClick={() => {
                // setTitleData({
                //     ...titleData,
                //     ["선택안함"] : {
                //         id : "999",
                //         name : "선택안함"
                //     }
                // })
                setIsOpen(false);
              }}
            />
          )}
          {data.map((v, i) => (
            <div
              style={{
                ...UiSlide_Style.title_w,
                marginLeft: i == 0 && "15px",
              }}
            >
              <UiBtn
                btnStyle={UiSlide_Style.title_btn}
                title={v["name"]}
                fnClick={() => {
                  apiPro(v);
                  fnClick(v);
                  // console.log("$$$ ",v);
                  setTitleData({
                    ...titleData,
                    [v["name"]]: {
                      ...v,
                    },
                  });
                }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UiSilde;
