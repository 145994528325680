const fontWeights = {
  bol: 700,
  md: 500,
  reg: 400,
};

const fontSizes = {
  12: "0.86em",
  14: "1em",
  16: "1.14em",
  20: "1.43em",
  24: "1.71em",
};

const typography = {
  fontWeights,
  fontSizes,
};

export default typography;
