import { axiosInstance } from "@api/common";
import { AcademyApiEndpoints } from "@api/constants";
import { pathToUrl } from "@helpers/url";
import { APP_METADATA } from "@constants";
import { AcademyListResponseDTO } from "@api/academy/types";

export const getAcademyLectureList = async (
  page: number,
  limit: number
): Promise<AcademyListResponseDTO> => {
  const url = pathToUrl(AcademyApiEndpoints.GET_LECTURE_LIST, {
    academy_code: APP_METADATA.ACADEMY_CODE,
  });
  const response = await axiosInstance.get(url, {
    params: {
      page,
      limit,
    },
  });
  return response.data;
};

export const getAcademyLectureSearchList = async ({
  page,
  limit,
  searchText,
}: {
  page: number;
  limit: number;
  searchText: string;
}) => {
  const url = pathToUrl(AcademyApiEndpoints.GET_LECTURE_SEARCH_LIST, {
    academy_code: APP_METADATA.ACADEMY_CODE,
  });
  const response = await axiosInstance.get(url, {
    params: {
      page,
      limit,
      searchText,
    },
  });
  return response.data;
};
