import React from "react";
import { Control, Controller, FieldValues, FormState } from "react-hook-form";
import TextInputWithLabel from "@components/molecules/textInputWithLabel";
import { ErrorMessage } from "@hookform/error-message";
import styled from "styled-components";
import { AccountFieldConfigType, AccountFieldType } from "@constants/accountForm/types";
import { Typography } from "@presentation/components/common/atoms";

type AccountFormFieldProps = {
  fieldName: keyof AccountFieldType;
  control: Control<FieldValues>;
  formState: FormState<FieldValues>;
  config?: AccountFieldConfigType;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Container = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  position: relative;
`;
const ErrorMessageBox = styled.div`
  position: absolute;
  bottom: -20px;
`;

export default function AccountFormField({
  fieldName,
  formState,
  control,
  config = {},
  onChange,
}: AccountFormFieldProps): JSX.Element {
  const { label, placeholder, type, defaultValue, rules } = config;

  return (
    <Container>
      <Controller
        control={control}
        defaultValue={defaultValue || ""}
        rules={rules}
        render={({ field }) => (
          <TextInputWithLabel
            {...field}
            label={label}
            placeholder={placeholder}
            type={type}
            onChange={(e) => {
              field.onChange(e);
              if (onChange) {
                onChange(e);
              }
            }}
          />
        )}
        name={fieldName}
      />
      <ErrorMessageBox>
        <ErrorMessage
          name={fieldName}
          errors={formState.errors}
          render={({ message }) => (
            <Typography color={"danger.70"} fontSize={"xs"}>
              {message}
            </Typography>
          )}
        />
      </ErrorMessageBox>
    </Container>
  );
}
