/* eslint-disable */
import { useDebounceEffect } from "hooks/useDebounceEffect";
import React, { useEffect, useRef, useState } from "react";
import ReactCrop, { centerCrop, Crop, makeAspectCrop, PixelCrop } from "react-image-crop";
import { canvasPreview } from "./canvasPreview";

import { ArrowBackIos, RotateLeft, RotateRight } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import style from "asset/style";
import CommonModal from "components/atoms/CommonModal";
import "react-image-crop/dist/ReactCrop.css";
import Resizer from "react-image-file-resizer";
import "./UiCrop.css";

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "px", // 0913 project 5 관련 수정 % -> px
        width: mediaWidth / 2, // 0913 project 5 관련 수정 mediaWidth -> mediaWidth/2
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

interface ImageCropModalProps extends CommonModalControl {
  setFileObj: (file: any) => void;
  fileObj: any;
  setIsCrop?: (isCrop: boolean) => void;
}

export default function ImageCropModal({
  setFileObj,
  fileObj,
  setIsCrop,
  open,
  onClose,
}: ImageCropModalProps) {
  const [isLoading, setImageLoading] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [isPortrait, setIsPortrait] = useState(false);
  const [windowSize, setWindowSize] = useState({});

  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState<number | undefined>(1);

  const [img_obj, setImg_obj] = useState({});

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () => setImgSrc(reader.result.toString() || ""));
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;

      setAspect(0);
      setImg_obj({
        width: width,
        height: height,
        w_h: width / height,
      });

      let pixel_obj: PixelCrop = {
        unit: "px",
        x: centerAspectCrop(width, height, width / height).x,
        y: centerAspectCrop(width, height, width / height).y,
        width: centerAspectCrop(width, height, width / height).width,
        height: centerAspectCrop(width, height, width / height).height,
      };

      setCompletedCrop(pixel_obj);
      setAspect(0);
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  const getCrop = (
    image: HTMLImageElement,
    canvas: HTMLCanvasElement,
    crop: PixelCrop,
    scale = 1,
    rotate = 0
  ) =>
    new Promise((r, j) => {
      try {
        const TO_RADIANS = Math.PI / 180;
        const ctx = canvas.getContext("2d");

        if (!ctx) {
          throw new Error("No 2d context");
        }

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        const pixelRatio = 1;

        canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

        ctx.scale(pixelRatio, pixelRatio);
        ctx.imageSmoothingQuality = "high";

        const cropX = crop.x * scaleX;
        const cropY = crop.y * scaleY;

        const rotateRads = rotate * TO_RADIANS;
        const centerX = image.naturalWidth / 2;
        const centerY = image.naturalHeight / 2;

        ctx.save();

        // 5) Move the crop origin to the canvas origin (0,0)
        ctx.translate(-cropX, -cropY);
        // 4) Move the origin to the center of the original position
        ctx.translate(centerX, centerY);
        // 3) Rotate around the origin
        ctx.rotate(rotateRads);
        // 2) Scale the image
        ctx.scale(scale, scale);
        // 1) Move the center of the image to the origin (0,0)
        ctx.translate(-centerX, -centerY);

        ctx.drawImage(
          image,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight
        );

        // alert(canvas)

        canvas.toBlob(
          (b) => {
            try {
              Resizer.imageFileResizer(
                b,
                1280,
                1280,
                "JPEG",
                90,
                0,
                (uri) => {
                  // // console.log("@@@ ",uri)
                  r(uri);
                  ctx.restore();
                },
                "base64"
              );
            } catch (err) {
              setImageLoading(false);
            }
          },
          "image/jpeg",
          1.0
        );
      } catch (e) {
        setImageLoading(false);
      }
    });

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, scale, rotate);
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  // S3 저장
  const apiPro_img = async (e) => {
    try {
      previewCanvasRef.current.width = completedCrop.width;
      previewCanvasRef.current.height = completedCrop.height;

      const name = `${fileObj["name"]}`;
      let _img = await getCrop(
        imgRef.current,
        previewCanvasRef.current,
        completedCrop,
        scale,
        rotate
      );
      let isRe = false;
      let isMsgImg = false;
      if (fileObj["isRe"]) {
      }
      if (fileObj["isMsgImg"]) {
      }

      setFileObj({
        ...fileObj,
        base64: _img,
      });
      setImageLoading(false);
      onClose();
    } catch (e) {
      setImageLoading(false);
    }
  };

  // img set
  const set_img = async (e) => {
    try {
      const name = `${fileObj["name"]}`;

      let _img = await getCrop(
        imgRef.current,
        previewCanvasRef.current,
        completedCrop,
        scale,
        rotate
      );
      setFileObj({
        ...fileObj,
        isImg: true,
        base64: _img,
      });

      if (setIsCrop) {
        setIsCrop(false);
      } else {
        onClose();
        setImageLoading(false);
      }
    } catch (e) {
      setImageLoading(false);
    }
  };

  useEffect(() => {}, [imgSrc]);

  useEffect(() => {
    if (windowSize["width"]) {
      if (windowSize["width"] < windowSize["height"]) {
        setIsPortrait(true);
      } else {
        setIsPortrait(false);
      }
      setCrop(centerAspectCrop(img_obj["width"], img_obj["height"], img_obj["w_h"]));

      let pixel_obj: PixelCrop = {
        unit: "px",
        x: 1,
        y: 1,
        width: imgRef.current.width,
        height: imgRef.current.height,
      };

      setCompletedCrop(pixel_obj);
    } else {
      setIsPortrait(true);
    }
  }, [windowSize]);

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    try {
      if (fileObj["file"]) {
        let reader = new FileReader();
        let file = fileObj["file"].target.files[0];
        setFileObj({
          ...fileObj,
          fileName: file["name"],
        });
        console.log("file => ", file);
        onSelectFile(fileObj["file"]);

        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }
    } catch (error) {
      console.log("Error => ", error);
    }
  }, []);

  console.log("open", open);
  return (
    <CommonModal open={open} onClose={onClose}>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "black",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "absolute",
            top: 0,
            padding: "15px 15px",
          }}
        >
          <ArrowBackIos
            style={{ color: "white" }}
            onClick={() => {
              if (setIsCrop) {
                setFileObj({});
                setIsCrop(false);
              } else {
                onClose();
              }
            }}
          />
          <div
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              color: "white",
            }}
          >
            {"이미지 편집"}
          </div>
        </div>
        <div
          style={{
            width: "100vw",
            // backgroundColor:"yellow",
            height: isPortrait ? "80vh" : "100vh",
            display: "flex",
            marginTop: isPortrait ? "70px" : "0px",
            alignItems: "center",
            justifyContent: isPortrait ? "center" : "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ReactCrop
              style={{
                maxHeight: "80vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: isPortrait ? "0px" : "70px",
              }}
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => {
                setCompletedCrop(c);
              }}
              aspect={aspect}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{
                  transform: `scale(${scale}) rotate(${rotate}deg)`,
                }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          </div>

          <div
            style={{
              height: "100vh",
              backgroundColor: "white",
              width: "70px",
              display: isPortrait ? "none" : "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexDirection: "column",
                alignItems: "center",
                width: "92%",
                height: "100%",
              }}
            >
              <RotateLeft
                style={{
                  fontSize: "48px",
                }}
                onClick={() => {
                  let _rotate = Math.min(360, Math.max(-360, Number(rotate - 90)));
                  if (Math.abs(_rotate) == 90 || Math.abs(_rotate) == 270) {
                    setScale(0.65);
                  } else {
                    setScale(1);
                  }
                  if (_rotate == 360 || _rotate == -360) {
                    setRotate(0);
                  } else {
                    setRotate(Math.min(360, Math.max(-360, Number(rotate - 90))));
                  }
                }}
              />
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid",
                  borderColor: style.common.borderColor,
                  fontSize: 14,
                  height: "44px",
                  cursor: "pointer",
                  outline: "none",
                  margin: "7.5px 0px",
                  color: "white",
                  width: "60px",
                  backgroundColor: style.common.t_color,
                  borderRadius: 20,
                }}
                onClick={() => {
                  if (setIsCrop) {
                    apiPro_img(null);
                  } else {
                    setImageLoading(true);
                    set_img(null);
                  }
                }}
              >
                {"확인"}
              </button>
              <RotateRight
                style={{
                  fontSize: "48px",
                }}
                onClick={() => {
                  // setRotate(Math.min(180, Math.max(-180, Number(rotate+90))))
                  let _rotate = Math.min(360, Math.max(-360, Number(rotate + 90)));
                  if (Math.abs(_rotate) == 90 || Math.abs(_rotate) == 270) {
                    setScale(0.65);
                  } else {
                    setScale(1);
                  }
                  if (_rotate == 360 || _rotate == -360) {
                    setRotate(0);
                  } else {
                    setRotate(Math.min(360, Math.max(-360, Number(rotate + 90))));
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: 0,
            height: "70px",
            backgroundColor: "white",
            width: "100vw",
            display: isPortrait ? "flex" : "none",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "92%",
            }}
          >
            <RotateLeft
              style={{
                fontSize: "48px",
              }}
              onClick={() => {
                let _rotate = Math.min(360, Math.max(-360, Number(rotate - 90)));
                if (Math.abs(_rotate) == 90 || Math.abs(_rotate) == 270) {
                  setScale(0.65);
                } else {
                  setScale(1);
                }
                if (_rotate == 360 || _rotate == -360) {
                  setRotate(0);
                } else {
                  setRotate(Math.min(360, Math.max(-360, Number(rotate - 90))));
                }
              }}
            />
            <button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid",
                borderColor: style.common.borderColor,
                fontSize: 14,
                height: "44px",
                cursor: "pointer",
                outline: "none",
                margin: "7.5px 0px",
                color: "white",
                width: "100px",
                backgroundColor: style.common.t_color,
                borderRadius: 20,
              }}
              onClick={() => {
                if (setIsCrop) {
                  apiPro_img(null);
                } else {
                  setImageLoading(true);
                  set_img(null);
                }
              }}
            >
              {"확인"}
            </button>
            <RotateRight
              style={{
                fontSize: "48px",
              }}
              onClick={() => {
                // setRotate(Math.min(180, Math.max(-180, Number(rotate+90))))
                let _rotate = Math.min(360, Math.max(-360, Number(rotate + 90)));
                if (Math.abs(_rotate) == 90 || Math.abs(_rotate) == 270) {
                  setScale(0.65);
                } else {
                  setScale(1);
                }
                if (_rotate == 360 || _rotate == -360) {
                  setRotate(0);
                } else {
                  setRotate(Math.min(360, Math.max(-360, Number(rotate + 90))));
                }
              }}
            />
          </div>
        </div>
        {isLoading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              display: "flex",
              width: "100vw",
              height: "100vh",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(200,200,200,0.5)",
            }}
          >
            <CircularProgress
              style={{
                color: style.common.t_color,
              }}
            />
            <div
              style={{
                marginTop: "15px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontWeight: 600,
                color: style.common.t_color,
              }}
            >
              <div>잠시만 기다려주세요</div>
            </div>
          </div>
        )}
        <div
          style={{
            display: "none",
          }}
        >
          {Boolean(completedCrop) && (
            <canvas
              ref={previewCanvasRef}
              style={{
                zIndex: -2,
                position: "absolute",
                border: "1px solid black",
                objectFit: "contain",
              }}
              width={351}
              height={468}
            />
          )}
        </div>
      </div>
    </CommonModal>
  );
}
