import React, { useEffect, useState } from "react";
import styled, { DefaultTheme } from "styled-components";
import Typography from "@components/atoms/typography";
import checkSvg from "@asset/svgs/check.svg";
import { themeUtil } from "@utils/theme";

export interface CheckboxProps {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  color?: string;
  theme?: DefaultTheme;
  label?: string;
  size?: number;
}

const Container = styled.label`
  display: inline-flex;
  gap: ${({ theme }) => theme.spacingxs};
  align-items: center;
  cursor: pointer;
`;

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  display: none;
`;

const StyledCheckbox = styled.div<CheckboxProps>`
  ${({ checked, color, size, theme }) => {
    return `
      width: ${size || "16"}px; 
      height: ${size || "16"}px;
      border-radius: ${theme.borderRadius.sm};   
      background-color: ${checked ? themeUtil.getColor(color) : theme.colors.natural[30]};
      box-sizing: border-box;
      position: relative;   

      ${
        checked &&
        `
          &:after {
            content: ''; 
            background-image:url('${checkSvg}');
            width: 100%; /* 필요에 따라 크기 조정 */
            height: 100%;
            color: #FFF;
            background-size: contain;
            background-repeat: no-repeat;
            display: inline-block; 
            text-align: center;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          } 
      `
      }
  `;
  }}
`;

export default function Checkbox({
  checked: controlledChecked = false,
  onChange,
  color,
  label,
}: CheckboxProps): JSX.Element {
  const [isChecked, setIsChecked] = useState(controlledChecked);

  useEffect(() => {
    if (controlledChecked !== undefined) {
      setIsChecked(controlledChecked);
    }
  }, [controlledChecked]);

  const handleClick = () => {
    const newChecked = !isChecked;
    setIsChecked(newChecked);
    onChange?.(newChecked);
  };

  return (
    <Container>
      <HiddenCheckbox checked={isChecked} onChange={() => {}} />
      <StyledCheckbox
        role={"checkbox"}
        checked={isChecked}
        onClick={handleClick}
        tabIndex={0}
        aria-checked={isChecked}
        color={color}
      />
      {label && <Typography as={"span"}>{label}</Typography>}
    </Container>
  );
}
