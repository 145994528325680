import { useTheme } from "styled-components";
import { ThemeTypographyRepository } from "@infrastructure/theme/typography.repository";
import { ThemeColorRepository } from "@infrastructure/theme/color.repository";
import {
  GetTypographyStyleUseCase,
  TypographyStyleRequest,
} from "@application/useCase/theme/getTypographyStyle.useCase";

export const useTypography = () => {
  const theme = useTheme();
  const typographyRepository = new ThemeTypographyRepository(theme);
  const colorRepository = new ThemeColorRepository(theme);
  const getTypographyStyle = new GetTypographyStyleUseCase(typographyRepository, colorRepository);

  return {
    getStyle: (props: TypographyStyleRequest) => getTypographyStyle.execute(props),
  };
};
