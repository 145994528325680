import React from "react";
import styled from "styled-components";
import { descriptions } from "@pages/signUp/descriptions";
import { Typography } from "@presentation/components/common/atoms";
import { AccountEnum } from "../../../../shared/enum/account";

const ButtonContainer = styled.button`
  background: #f1f1f1;
  border-radius: 5px;
  padding: 0 1em;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 90px;
`;

const DescriptionSection = styled.section`
  text-align: start;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;

const Image = styled.img`
  position: absolute;
  right: -7px;
  bottom: 0;
  width: 120px;
`;

export default function TypeSelectButton({
  type,
  onSelectType,
}: {
  type: AccountEnum;
  onSelectType?: () => void;
}): JSX.Element {
  const { mainDescription, typeText, imageUrl, pointColor } = descriptions[type];

  return (
    <ButtonContainer onClick={onSelectType}>
      <DescriptionSection>
        <Typography>{mainDescription}</Typography>
        <Typography fontSize={"sm"} fontWeight={"bold"} color={`${pointColor}.70`}>
          {typeText} 회원 가입 {">"}
        </Typography>
      </DescriptionSection>
      <Image src={imageUrl} alt={`${typeText}캐릭터`} />
    </ButtonContainer>
  );
}
