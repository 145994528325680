import React, { useEffect } from "react";
import { navigationService } from "../../../../common/service/navigation/navigation.service";
import { useNavigate } from "react-router";

export default function NavigationInitProvider({ children }) {
  const navigate = useNavigate();

  useEffect(() => {
    navigationService.initialize(navigate);
  }, [navigate]);
  return <>{children}</>;
}
