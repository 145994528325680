import { axiosInstanceWithAuth } from "@infrastructure/api";
import { IMarketingNotifyRequest } from "./type";

export const marketingNotifyApi = async (
  marketingNotifyData: IMarketingNotifyRequest
): Promise<any> => {
  try {
    const response = await axiosInstanceWithAuth.post(
      "/account/marketing-notify",
      marketingNotifyData
    );
    return response.data;
  } catch (error: any) {
    throw new Error(`Error in marketingNotifyApi: ${error.message}`);
  }
};
