import { ButtonVariant } from "@presentation/components/common/atoms/button/button.types";

export const getDefaultColors = (variant: ButtonVariant, theme: any) => {
  switch (variant) {
    case "filled":
      return {
        background: theme.colors.primary[70],
        color: "white",
      };
    case "outlined":
      return {
        background: theme.colors.white[70],
        color: theme.colors.natural[60],
      };
    case "ghost":
      return {
        background: "transparent",
        color: "inherit",
      };
    case "link":
      return {
        background: "transparent",
        color: theme.colors.primary[70],
      };
    default:
      return {
        background: theme.colors.primary[70],
        color: "white",
      };
  }
};
