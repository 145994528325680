import { Add, FeaturedPlayList, ViewList } from "@material-ui/icons";
import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import apiFn from "../../asset/apiClass";
import style from "../../asset/style";
import util from "../../asset/util";
import UiBtn from "../../uiComponents/UiBtn/UiBtn";
import { UiCheckBox } from "../../uiComponents/UiCheckBox/UiCheckBox";
import UiExplain from "../../uiComponents/UiExplain/UiExplain";
import UiExplain_type_b from "../../uiComponents/UiExplain/UiExplain_type_b";
import UiSorting from "../../uiComponents/UiSorting/UiSorting";
import UiTextBtn from "../../uiComponents/UiTextBtn/UiTextBtn";
import UiTopPopTextBtn from "../../uiComponents/UiTopPopTextBtn/UiTopPopTextBtn";
import Lecture_book_Style from "../Profile/Lecture_book_Style";
import Copy from "./Copy";
import Academy_book_Style from "../Academy/Academy_book_Style";
import UiQuestion from "../../uiComponents/UiQuestion/UiQuestion";
import store from "../../asset/store";

const Book_undefined = ({
  explainData,
  setExplainData,
  userInfo,
  setHiddenUrl,
  del_bookdId,
  setDel_bookId,
  del_sorting,
  setQnaData,
}) => {
  let navigate = useNavigate();
  let params = useParams();

  const [isComplete, setIsComplete] = useState(false);

  const [isSwitch, setIsSwitch] = useState(true);
  const [isTeacher, setIsTeacher] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [isAll, setIsAll] = useState(false);

  var [bookList, setBookList] = useState([]);

  var [selectData, setSelectData] = useState({});
  var [selectData_l, setSelectData_l] = useState(0);

  var [qnaData, setQnaDa] = useState([]);

  var [bookData, setBookData] = useState({});
  var [qnaCount, setQnaCount] = useState({});
  var [linkData, setLinkData] = useState({});

  const all_select = () => {
    try {
      let obj = {};
      qnaData.map((v, i) => {
        if (isAll) {
          obj[v["id"]] = "N";
          setIsAll(false);
        } else {
          obj[v["id"]] = "Y";
          setIsAll(true);
        }
      });
      setSelectData({
        ...obj,
      });
    } catch (e) {}
  };

  const apiPro_read_qnaInfo = async () => {
    try {
      let state = {};
      let data = [];
      if (params["who"] == "student") {
        state = {
          id: params["id"],
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          cid: params["cid"],
          sid: userInfo["id"],
        };

        apiFn
          .studentBooklist({
            state: {
              ...state,
            },
          })
          .then((res) => {
            if (res["list"]) {
              setBookData({ ...res["list"][0] });
            }
          });
      } else {
        // data = await apiFn.comApi({
        apiFn
          .comApi({
            state: {
              table: "book",
              page: 1,
              amount: 9999,
              sort: "reg_date desc",
              // ref : [
              //     "HIQDB_book_link.cid|HIQDB_book_link.bid=HIQDB_book.id",
              // ],
              equal: {
                id: params["id"],
              },
            },
          })
          .then((res) => {
            console.log("res => ", res);
            if (res["list"]) {
              setBookData({ ...res["list"][0] });
            }
          })
          .catch((e) => console.log("@ ", e));

        // let isStat = await apiFn.comApi({
        apiFn
          .comApi({
            state: {
              table: "book_link",
              page: 1,
              amount: 9999,
              sort: "mid desc",
              where: {
                "HIQDB_book_link.mid": `='${userInfo["id"]}'`,
                "HIQDB_book_link.bid": `='${params["id"]}'`,
              },
            },
          })
          .then((res) => {
            console.log("linkData => ", res);
            if (res["list"]) {
              setLinkData({
                ...res["list"][0],
              });
            }
          })
          .catch((e) => console.log("# ", e));
      }

      console.log("book Data => ", data);

      // let qnaCount = await apiFn.qnaList({
      apiFn
        .qnaCount({
          state: {
            id: userInfo["id"],
            page: 1,
            amount: 9999,
            sort: "reg_date desc",
            bid: "0",
            count: "Y",
            // status : status
          },
        })
        .then((res) => {
          console.log("qnaCount => ", res["count"]);
          setQnaCount({
            ...res["count"],
          });
        })
        .catch((e) => console.log("$ ", e));
      // util.objLog(data["list"][0])
      // setBookData({
      //     ...data["list"][0]
      // })
    } catch (e) {
      console.log("@#@# ", e);
    }
  };

  const apiPro_read = async (sort, status) => {
    try {
      // let _status = ""
      // if(status == "ready"){
      //     _status = "ready"
      // }
      // if(status == "proc"){
      //     _status = "live"
      // }
      // if(status == "complete"){
      //     _status = "qna"
      // }

      // let state = {}
      // if(params["type"] == "book"){
      //     state["bid"] = params["id"]
      // }
      // else if(params["type"] == "lecture"){
      //     state["cid"] = params["id"]
      // }
      let data = await apiFn.qnaList({
        state: {
          id: userInfo["id"],
          page: 1,
          amount: 9999,
          sort: sort ? sort : "reg_date desc",
          status: status ? status : "live",
          bid: "0",
        },
      });

      console.log("qnadata => ", data);

      setQnaDa([...data["list"]]);
    } catch (e) {}
  };

  // 내 교재 리스트 호출
  const apiPro_read_bookList = async () => {
    try {
      if (params["who"] == "teacher") {
        let isOk = await apiFn.comApi({
          state: {
            table: "book",
            page: 1,
            amount: 999,
            u_id: userInfo["id"],
            sort: "reg_date desc",
            ref: [
              "HIQDB_book_link.cid|HIQDB_book_link.bid=HIQDB_book.id",
              "HIQDB_book_link.type|HIQDB_book_link.bid=HIQDB_book.id",
            ],
            where: {
              "HIQDB_book_link.mid": `= '${userInfo["id"]}'`,
              "HIQDB_book.status": `!= '9'`,
              "HIQDB_book_link.fid": `= 0`,
              // "HIQDB_book_link.cid" : `= 0`,
            },
          },
        });

        let array = isOk["list"].reduce((a, v, i) => {
          return [
            ...a,
            {
              name: v["name"],
              value: v["id"],
            },
          ];
        }, []);

        setBookList([
          { name: "전체", value: "all" },
          { name: "교재 미지정", value: "0" },
          ...array,
        ]);
      } else {
        // let isOk = await apiFn.studentBooklist({
        //     state : {
        //         sid : userInfo["id"],
        //         page : 1,
        //         amount : 9999,
        //         sort : "reg_date desc",
        //         fid : 0,
        //     }
        // })
        // console.log("student Book Data => ", isOk)
        // setBookList([
        //     ...isOk["list"]
        // ])
      }
    } catch (e) {
      console.log("EE ", e);
    }
  };

  // 삭제 목록 호출
  const apiPro_read_del = async (sort, bid) => {
    try {
      let bid_;
      let sort_;
      if (bid) {
        if (bid == "all") {
          bid_ = null;
        } else {
          bid_ = bid;
        }
      } else {
        if (window.localStorage.getItem("del_lc")) {
          if (window.localStorage.getItem("del_lc") == "all") {
            bid_ = null;
          } else {
            bid_ = window.localStorage.getItem("del_lc");
          }
        } else {
          if (del_bookdId == "all") {
            bid_ = null;
          } else {
            bid_ = del_bookdId;
          }
        }
      }

      if (sort) {
        sort_ = sort;
      } else {
        sort_ = del_sorting;
      }

      console.log("bid_ : ", bid_);
      console.log("bid : ", bid);
      let data = await apiFn.qnaList({
        state: {
          id: userInfo["id"],
          page: 1,
          amount: 9999,
          sort: sort_,
          // status : status,
          isRemove: "Y",
          bid: bid_,
          // del_bookdId != "0" ?
          //     del_bookdId
          //     :
          //     (bid ? bid : "0")
          // ,
        },
      });

      let del_box = [];
      Date.prototype.ymd = function () {
        if (this.getTime() < 1000000000000) this.setTime(this.getTime() * 1000);
        var mm = this.getMonth() + 1;
        var dd = this.getDate();

        return [this.getFullYear(), (mm > 9 ? "" : "0") + mm, (dd > 9 ? "" : "0") + dd].join("");
      };
      let today_date = new Date().ymd();
      let today_day = today_date.substr(6, 2);
      let today_month = today_date.substr(4, 2);
      // let today_month = 10;

      console.log("@ day ", today_day);
      console.log("# month ", today_month);

      // (30 - current day) + removeeDay
      // let today_date = 20221020

      let removeList = [];
      data["list"].map((v, i) => {
        // let month_ = v["remove_date"].substr(0,8)
        let month_ = v["remove_date"].substr(0, 8).substr(4, 2);
        if (today_month == month_) {
          console.log("@@ ", month_);
          console.log("equal month");
          // console.log(deadLine)
          console.log("REMOVE DATE : ", v["remove_date"].substr(0, 8));
          console.log("REMOVE DATE : ", v["remove_date"].substr(0, 8).substr(6, 8));
          console.log(
            "Current DATE - REMOVE DATE : ",
            new Date().ymd() - Number(v["remove_date"].substr(0, 8))
          );
          if (today_date - Number(v["remove_date"].substr(0, 8)) > 30) {
            del_box.push({
              id: v["id"],
            });
          } else {
            v["leftover"] = today_date - Number(v["remove_date"].substr(0, 8));
            removeList.push(v);
          }
        } else {
          console.log("REMOVE DATE : ", v["remove_date"].substr(0, 8));
          console.log("1 : ", today_date.substr(6, 2));
          console.log("2 : ", Number(v["remove_date"].substr(0, 8).substr(6, 2)));
          console.log("3 : ", 30 - today_date.substr(6, 2));
          console.log("4 : ", Number(v["remove_date"].substr(0, 8).substr(6, 2)));
          let deadLine =
            30 -
            Number(v["remove_date"].substr(0, 8).substr(6, 2)) +
            Number(today_date.substr(6, 2));
          console.log("not equal month");
          console.log("Deadline : ", deadLine);
          if (deadLine > 30) {
            del_box.push({
              id: v["id"],
            });
          } else {
            v["leftover"] = deadLine;
            removeList.push(v);
          }
        }
      });

      if (del_box.length > 0) {
        await apiFn.comApi({
          state: {
            table: "online_qna",
            del: del_box,
          },
        });
      }

      console.log("qnadata => ", data["list"]);
      setQnaDa([...removeList]);
      // setDel_bookId("0")
    } catch (e) {
      console.log(e);
    }
  };

  const apiPro_mobum = async (isDel) => {
    try {
      let set = Object.keys(selectData).reduce((a, v, i) => {
        return [
          ...a,
          {
            key: `id:${v}`,
            value: {
              // mobum_date : new Date().getTime().toString().substring(0,10)
              mobum_date: 0,
            },
          },
        ];
      }, []);

      let del = Object.keys(selectData).reduce((a, v, i) => {
        return [
          ...a,
          {
            id: v,
            // mobum_date : 0
            // value : {
            //     // mobum_date : new Date().getTime().toString().substring(0,10)
            // }
          },
        ];
      }, []);

      // console.log(set)
      // return;
      let state = {};
      if (isDel) {
        state["del"] = del;
      } else {
        state["set"] = set;
      }
      let isOk = await apiFn.comApi({
        state: {
          table: "online_qna",
          ...state,
        },
      });

      const stack = store.get("stack");
      if (stack.length > 0) navigate(-1);
      else navigate("/");
    } catch (e) {}
  };

  const apiPro_del = async () => {
    try {
      let promiseArray;
      if (params["where"] == "del") {
        promiseArray = Object.keys(selectData).map((v, i) =>
          apiFn.deleteQna({
            state: {
              qid: v,
              orderMid: userInfo["id"],
            },
          })
        );
      } else {
        promiseArray = Object.keys(selectData).map((v, i) =>
          apiFn.comApi({
            state: {
              table: "online_qna",
              set: [
                {
                  key: `id:${v}`,
                  value: {
                    remove_date: 0,
                  },
                },
              ],
            },
          })
        );
      }
      await Promise.all(promiseArray);
      setSelectData({});
      setSelectData_l(0);
      setIsEdit(false);
      util.postWeb("isModal_off");
      // return;

      if (params["where"] == "del") {
        if (window.sessionStorage.getItem("book_unde")) {
          setIsComplete(JSON.parse(window.sessionStorage.getItem("book_unde")));
          await apiPro_read_del();
        } else {
          setIsComplete(false);
          await apiPro_read_del();
        }
      } else {
        if (window.sessionStorage.getItem("book_unde")) {
          setIsComplete(JSON.parse(window.sessionStorage.getItem("book_unde")));
          await apiPro_read("", JSON.parse(window.sessionStorage.getItem("book_unde")));
        } else {
          setIsComplete(false);
          await apiPro_read("", false);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const apiPro_restore = async () => {
    try {
      let promiseArray = Object.keys(selectData).map((v, i) =>
        apiFn.comApi({
          state: {
            table: "online_qna",
            set: [
              {
                key: `id:${v}`,
                value: {
                  remove_date: "del",
                },
              },
            ],
          },
        })
      );
      await Promise.all(promiseArray);
      setSelectData({});
      setSelectData_l(0);
      setIsEdit(false);
      util.postWeb("isModal_off");
      // return;
      if (window.sessionStorage.getItem("book_unde")) {
        setIsComplete(JSON.parse(window.sessionStorage.getItem("book_unde")));
        await apiPro_read_del();
      } else {
        setIsComplete(false);
        await apiPro_read_del();
      }
    } catch (e) {}
  };

  useEffect(() => {
    selectData_l = 0;
    console.log("selectData => ", selectData);
    Object.keys(selectData).map((v, i) => {
      if (setSelectData[v] == "Y") {
        selectData_l = selectData_l + 1;
      } else if (selectData[v] == "N") {
        if (setSelectData_l == 0) return;
        selectData_l = selectData_l - 1;
      }
    });
    setSelectData_l(selectData_l);
  }, [selectData]);

  useEffect(() => {
    console.log(window.location.pathname);
    console.log("params => ", params);
    if (params["who"] == "teacher") {
      setIsTeacher(true);
    } else {
      setIsTeacher(false);
    }
  }, [params]);

  // useEffect(()=>{
  //     console.log("isComplete => ",isComplete)
  //     // apiPro_read();
  // },[isComplete])

  useEffect(() => {
    setQnaData([]);
    // if(params["where"] == "del")
    try {
      if (window.sessionStorage.getItem("book_unde")) {
        setIsComplete(JSON.parse(window.sessionStorage.getItem("book_unde")));
        apiPro_read("", JSON.parse(window.sessionStorage.getItem("book_unde")));
      } else {
        if (params["where"] == "del") {
          apiPro_read_del(""); // 삭제된 질문 리스트
          apiPro_read_bookList(); // 내 교재 리스트 호출
        } else {
          setIsComplete(false);
          /**  0130 주석 */
          // apiPro_read("",false) // 교재 미지정 질문 리스트
          setQnaData([]);
          setExplainData([]);
          apiPro_read_qnaInfo();
        }
      }
      if (window.ReactNativeWebView) {
        document.addEventListener("message", async (e) => {
          if (e.data == "isModal") {
            setIsEdit(false);
          }
        });
      }
      return () => {
        try {
          document.removeEventListener("message");
        } catch (e) {}
      };
    } catch (e) {}

    return () => {
      setDel_bookId("0");
    };
  }, []);

  // useEffect(()=>{
  //     console.log("link : ",linkData)
  //     console.log("bookData : ",bookData)
  //     console.log("qnaCount : ",qnaCount)
  // },[linkData, bookData, qnaCount])

  return (
    <>
      {params["where"] != "copy" && params["where"] != "move" && params["where"] != "del" && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            padding: "0px 0px 0px 15px",
          }}
        >
          {/* <UiCheckBox
                    value={"isImportant"}
                    name="iI"
                    className={params["who"] == "teacher" ? "t" : "s"}
                    checked={isComplete}
                    fnCheck={(e)=>{
                        window.sessionStorage.setItem("book_unde",JSON.stringify(e.target.checked))
                        apiPro_read("",e.target.checked)
                        if(e.target.checked){
                            setIsComplete(true)
                        }
                        else {
                            setIsComplete(false)
                        }
                    }}
                /> */}
          {/* <div style={{
                    ...Book_book_Style.book_r_w,
                    width:"100%",
                    fontSize:"16px",
                    paddingTop:"10px",
                }}>
                    {
                    <div style={Book_book_Style.book_r_v}>
                        <div style={Book_book_Style.book_r_text_w}>
                            전체 질문수
                            <div style={{
                                ...Book_book_Style.book_r_text,
                                color : !params["who"] == "teacher" ? style.common.s_color : style.common.t_color,
                            }}>
                                1
                            </div>
                        </div>
                        <div style={Book_book_Style.book_r_text_w}>
                            ㅕ수
                            <div style={{
                                ...Book_book_Style.book_r_text,
                                color : !params["who"] == "teacher" ? style.common.s_color : style.common.t_color,
                            }}>
                                1
                            </div>
                        </div>
                    </div>
                    }
                </div> */}
          {/* <UiBasicSelect
                    defaultValue={"5"}
                    data={[
                        {name : "모범 풀이", value : "mobum"},
                        {name : "모범 풀이 대기", value : "ready"},
                        {name : "진행중인 질문", value : "live"},
                        {name : "완료된 질문", value : "qna"},
                    ]}
                    fnChange={(v)=>{
                        let save = "";
                        if(v == "a"){
                            save = "모범 풀이"
                        }
                        if(v == "b"){
                            save = "모범 풀이 대기"
                        }
                        if(v == "c"){
                            save = "진행중인 질문"
                        }
                        if(v == "d"){
                            save = "완료된 질문"
                        }
                        window.localStorage.setItem("noBook_sort", save)
                        apiPro_read("", v)
                    }}
                    title={
                        window.localStorage.getItem("noBook_sort") ?
                           window.localStorage.getItem("noBook_sort")
                           :
                           "질문 유형"
                   }
                    c_style={{
                        minWidth : "140px",
                        marginRight:"10px",
                        fontSize : "10px",
                        marginRight:5,
                        verticalAlign:"center"
                    }}
                /> */}

          {/* <UiBtn
                    btnStyle={{
                        borderRadius : 20,
                        height : '30px',
                        padding : "18px 10px",
                        // width:"60px",
                    }}
                    title={"모범 풀이"}
                />
                <UiBtn
                    btnStyle={{
                        borderRadius : 20,
                        height : '30px',
                        padding : "18px 10px",
                        // width:"60px",
                    }}
                    title={"모범 풀이 대기"}
                />
                <UiBtn
                    btnStyle={{
                        borderRadius : 20,
                        height : '30px',
                        padding : "18px 10px",
                        // width:"60px",
                    }}
                    title={"진행중인 질문"}
                />
                <UiBtn
                    btnStyle={{
                        borderRadius : 20,
                        height : '30px',
                        padding : "18px 10px",
                        // width:"60px",
                    }}
                    title={"완료된 질문"}
                /> */}
          {/* <div style={{
                    fontSize : "12px",
                    color : style.common.fontColor_1,
                    marginLeft : "5px",
                }}>
                </div> */}
          {/* <div style={{
                    fontSize : "12px",
                    color : style.common.fontColor_1,
                    marginLeft : "5px",
                }}>
                    모범 풀이 대기
                </div> */}
          {/* <div style={{
                    fontSize : "12px",
                    color : style.common.fontColor_1,
                    marginLeft : "5px",
                }}>
                    진행중인 질문
                </div> */}
          {/* <div style={{
                    fontSize : "12px",
                    color : style.common.fontColor_1,
                    marginLeft : "5px",
                }}>
                    완료된 질문
                </div> */}
        </div>
      )}
      {params["where"] == "copy" ? (
        <Copy
          userInfo={userInfo}
          isTeacher={isTeacher}
          qnaData={selectData}
          setQnaData={setSelectData}
          setIsEdit={setIsEdit}
        />
      ) : params["where"] == "move" ? (
        <Copy
          userInfo={userInfo}
          isTeacher={isTeacher}
          qnaData={selectData}
          setQnaData={setSelectData}
          setIsEdit={setIsEdit}
          isMove={true}
        />
      ) : (
        <div style={{ width: "100%" }}>
          {isEdit && (
            <UiTopPopTextBtn
              title="편집 취소"
              isTeacher={isTeacher}
              fnClick={() => {
                setIsEdit(false);
                util.postWeb("isModal_off");
                setSelectData({});
              }}
            />
          )}

          {isEdit ? (
            <div style={{ padding: "0px 10px" }}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  padding: "30px 0px 10px 0px",
                  justifyContent: "center",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontSize: style.common.select_fontSize,
                  borderTop: isEdit ? "none" : "1px solid",
                  borderColor: style.common.borderColor,
                }}
              >
                선택된 문항을
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    // backgroundColor : "red",
                    // paddingRight : "10px",
                  }}
                >
                  <UiTextBtn
                    text={"삭제"}
                    btnStyle={{
                      color: style.common.fontColor_3,
                      fontSize: style.common.select_fontSize,
                      marginRight: "7.5px",
                    }}
                    fnClick={() => {
                      // let isOk = window.confirm(constants.confirm.cf_3)
                      let isOk = window.confirm(
                        "선택된 문제는 영구히 삭제됩니다. 계속 진행하시겠습니까?"
                      );
                      if (isOk) {
                        apiPro_del();
                      }
                    }}
                  />
                  {params["where"] == "del" && (
                    <UiTextBtn
                      text={"복구"}
                      btnStyle={{
                        fontSize: style.common.select_fontSize,
                        color: style.common.fontColor_5,
                        marginLeft: "7.5px",
                      }}
                      fnClick={() => {
                        let isOk = window.confirm("선택된 질문문항을 복구하시겠습니까?");
                        if (isOk) {
                          apiPro_restore();
                        }
                      }}
                    />
                  )}
                  {/* <UiTextBtn
                                text={params["form"] == "de" ? "모범풀이 해제" : "모범풀이 등록"}
                                btnStyle={{
                                    color : style.common.fontColor_5,
                                    fontSize : style.common.select_fontSize,
                                    marginLeft : "7.5px"
                                }}
                                fnClick={()=>{
                                    if(params["form"] == "de"){
                                        apiPro_mobum(true);
                                    }
                                    else {
                                        apiPro_mobum(false);
                                    }
                                }}
                            /> */}
                  {params["where"] != "del" && (
                    <div
                      style={{
                        fontSize: style.common.select_fontSize,
                        color: style.common.fontColor_5,
                        marginLeft: "7.5px",
                      }}
                    >
                      다른 교재로
                    </div>
                  )}
                  {params["form"] == "de" && (
                    <UiTextBtn
                      text={"복사"}
                      btnStyle={{
                        fontSize: style.common.select_fontSize,
                        color: style.common.fontColor_5,
                        marginLeft: "7.5px",
                      }}
                      fnClick={() => {
                        util.postWeb("isModal_off");
                        navigate(`/explainmodel/${params["who"]}/${params["id"]}/copy/de`);
                      }}
                    />
                  )}
                  {params["where"] != "del" && (
                    <UiTextBtn
                      text={"이동"}
                      btnStyle={{
                        fontSize: style.common.select_fontSize,
                        color: style.common.fontColor_5,
                        marginLeft: "7.5px",
                      }}
                      fnClick={() => {
                        util.postWeb("isModal_off");
                        navigate(`/book/${params["who"]}/${params["id"]}/${params["cid"]}/move`);
                      }}
                    />
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontSize: "12px",
                  padding: "10px 0px 5px 0px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: "12px",
                  }}
                  onClick={() => {
                    all_select();
                  }}
                >
                  <UiCheckBox className={isTeacher ? "t" : "s"} checked={isAll} />
                  <div
                    style={{
                      marginLeft: "10px",
                    }}
                  >
                    전체선택
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {
                params["where"] == "del" && (
                  <div
                    style={{
                      padding: "10px 10px 0px 10px",
                      marginTop: "10px",
                    }}
                  >
                    <UiSorting
                      title={`총 ${qnaData.length} 문항`}
                      defaultValue={del_sorting ? del_sorting : null}
                      data={[
                        { name: "등록 날짜순", value: "reg_date desc" },
                        { name: "삭제된 날짜순", value: "remove_date desc" },
                      ]}
                      fnClick={(d) => {
                        apiPro_read_del(d);
                      }}
                      isTeacher={isTeacher}
                    />
                  </div>
                )
                // :
                // <div style={{
                //     padding : "5px 10px 0px 10px" ,
                //     // marginTop:'10px',
                // }}>
                //     <UiSorting
                //         title={`총 ${qnaData.length} 문항`}
                //         defaultValue={del_sorting ? del_sorting : null}
                //         data={[
                //             { name : "날짜순" , value : "reg_date desc"},
                //             { name : "출처순" , value : "title asc"},
                //         ]}
                //         fnClick={(d)=>{
                //             apiPro_read(d, window.localStorage.getItem("noBook_sort"))
                //         }}
                //         isTeacher={isTeacher}
                //     />
                // </div>
              }
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: params["form"] == "de" ? "space-between" : "flex-end",
                  padding: "0px 10px 5px 0px",
                }}
              >
                {params["form"] == "de" && (
                  <div
                    style={{
                      ...Lecture_book_Style.t_btn_w,
                      width: "150px",
                    }}
                  >
                    <UiBtn
                      btnStyle={Lecture_book_Style.t_btn}
                      title={
                        <div style={Academy_book_Style.btn_title}>
                          <Add style={Academy_book_Style.btn_Add} />
                          <div
                            style={{
                              paddingLeft: "12px",
                            }}
                          >
                            {/* 폴더 생성 */}
                            모범 풀이 추가
                          </div>
                        </div>
                        // <div style={Lecture_book_Style.t_btn_text}>
                        //     <Add
                        //         style={Lecture_book_Style.t_btn_Add}
                        //     />
                        //     모범 풀이 추가
                        // </div>
                      }
                      fnClick={() => {
                        navigate(`/mobumregi/${params["who"]}/${params["id"]}/de/de`);
                      }}
                    />
                  </div>
                )}
                {params["where"] == "del" && (
                  <div
                    style={{
                      display: "flex",
                      // width : "80px",
                      height: "30px",
                      marginBottom: "15px",
                      justifyContent: "space-between",
                    }}
                  >
                    {/* {
                        params["where"].includes("del") &&
                        <UiBasicSelect
                            defaultValue={window.localStorage.getItem("del_lc") ? window.localStorage.getItem("del_lc") : del_bookdId}
                            data={bookList}
                            fnChange={(v)=>{
                                console.log(v)
                                // apiPro_read_del("",v["value"])
                                window.localStorage.setItem("del_lc", v)
                                apiPro_read_del("", v)
                                setDel_bookId(v["value"])
                            }}
                            // fontSize={"10px"}
                            title={"교재"}
                            c_style={{
                                height:"30px",
                                minWidth : "100px",
                                marginRight:"10px",
                                fontSize : "10px",
                            }}
                        />
                        } */}
                    <FeaturedPlayList
                      style={{
                        color: isSwitch
                          ? params["who"] == "teacher"
                            ? style.common.t_color
                            : style.common.s_color
                          : style.common.borderColor,
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        setIsSwitch(true);
                      }}
                    />
                    <ViewList
                      style={{
                        color: !isSwitch
                          ? params["who"] == "teacher"
                            ? style.common.t_color
                            : style.common.s_color
                          : style.common.borderColor,
                      }}
                      onClick={() => {
                        setIsSwitch(false);
                      }}
                    />
                  </div>
                )}
              </div>
            </>
          )}
          {params["where"] == "del" ? (
            <div
              style={{
                backgroundColor: style.common.borderColor2,
                display: "flex",
                flexDirection: "column",
                flex: 1,
              }}
            >
              {isSwitch
                ? qnaData.map((v, i) => (
                    // <></>
                    <UiExplain
                      isTeacher={isTeacher}
                      data={v}
                      isQna={params["form"] == "proc" ? true : false}
                      id={v["id"]}
                      fnClick={() => {
                        // if(v["sid"]){
                        setHiddenUrl(window.location.pathname);
                        // if(params["who"] == "teacher"){
                        //     navigate(`/talk/${params["who"]}/0/${v["id"]}/${v["sid"]}/${v["tid"]}`)
                        // }
                        // else {
                        //     navigate(`/talk/${params["who"]}/0/${v["id"]}/${v["sid"]}/${v["tid"]}`)
                        // }
                        navigate(`/talk/${params["who"]}/0/${v["id"]}/${v["sid"]}/${v["tid"]}`);
                        // }
                      }}
                      isEdit={isEdit}
                      selectData={selectData}
                      fnLongClick={(id) => {
                        setIsEdit(true);
                        util.postWeb("isModal");
                        setSelectData({
                          ...selectData,
                          [id]: "Y",
                        });
                      }}
                      isMsgSection={false}
                      fnCheck={(e) => {
                        if (selectData[e.target.id] == "Y") {
                          delete selectData[e.target.id];
                          setSelectData({
                            ...selectData,
                          });
                        } else {
                          setSelectData({
                            ...selectData,
                            [e.target.id]: "Y",
                          });
                        }
                      }}
                    />
                  ))
                : qnaData.map((v, i) => (
                    <UiExplain_type_b
                      isTeacher={isTeacher}
                      data={v}
                      id={v["id"]}
                      isQna={params["form"] == "proc" ? true : false}
                      fnClick={() => {
                        if (v["sid"]) {
                          setHiddenUrl(window.location.pathname);
                          // if(params["who"] == "teacher"){
                          //     navigate(`/talk/${params["who"]}/0/${v["id"]}/${v["sid"]}/${v["tid"]}`)
                          // }
                          // else {
                          //     navigate(`/talk/${params["who"]}/0/${v["id"]}/${v["sid"]}/${v["tid"]}`)
                          // }
                          navigate(`/talk/${params["who"]}/0/${v["id"]}/${v["sid"]}/${v["tid"]}`);
                        }
                      }}
                      isEdit={isEdit}
                      selectData={selectData}
                      fnLongClick={(id) => {
                        setIsEdit(true);
                        util.postWeb("isModal");
                        setSelectData({
                          ...selectData,
                          [id]: "Y",
                        });
                      }}
                      fnCheck={(e) => {
                        if (selectData[e.target.id] == "Y") {
                          delete selectData[e.target.id];
                          setSelectData({
                            ...selectData,
                          });
                        } else {
                          setSelectData({
                            ...selectData,
                            [e.target.id]: "Y",
                          });
                        }
                      }}
                    />
                  ))}
            </div>
          ) : (
            <UiQuestion
              qnaCount={qnaCount}
              isTeacher={params["who"] == "teacher" ? true : false}
              isComplete={params["who"] == "teacher" ? true : false}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Book_undefined;
