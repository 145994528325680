import { ILocalAuthRepository } from "../interface/localAuth-repository.interface";
import { ILocalAuthToResponseRequestData } from "../../../../shared/api/api-gateway/auth/login/local_auth_to_response/type";

export class LocalAuthToResponseUseCase {
  constructor(private readonly localAuthRepository: ILocalAuthRepository) {}

  execute(localAuthData: ILocalAuthToResponseRequestData): Promise<string> {
    return this.localAuthRepository.postLocalAuthToResponse(localAuthData);
  }
}
