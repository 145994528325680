import React, { useMemo, useState } from "react";
import { Frame } from "../page_index";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import UiBtn from "../../uiComponents/UiBtn/UiBtn";
import apiFn from "../../asset/apiClass";
import constants from "../../asset/constants";
import { useNavigate } from "react-router";
import UiLoading from "../../uiComponents/UiLoading/UiLoading";

const Youtube = ({ userInfo, scrollKey, setScrolllKey }) => {
  const [type, setType] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [qnaList, setQnaList] = useState([]);

  const query = useMemo(() => {
    return `${year}년도 ${month}월 ${type}`;
  }, [month, year, type]);

  const navigate = useNavigate();

  const getQnaList = async () => {
    try {
      setIsLoading(true);
      const { list } = await apiFn.qnaList({
        state: {
          id: userInfo["id"],
          page: 1,
          sort: "score desc",
          amount: 9999,
          status: "mobum",
          bid: 687,
          title: query,
        },
      });
      setQnaList(list);
    } catch (e) {
      console.error(e);
      alert("문제를 가져오는데 실패했습니다");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Frame
      userInfo={userInfo}
      isBack={false}
      type="2"
      isSearch={false}
      title={
        <div
          style={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "24px",
            lineHeight: "28px",
            textAlign: "center",
            letterSpacing: "2px",
          }}
        >
          유튜브 기출
        </div>
      }
    >
      <div style={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
            height: 80,
            position: "fixed",
            backgroundColor: "white",
          }}
        >
          <div style={{ width: 130 }}>
            <FormControl fullWidth>
              <InputLabel id="youtube-month-type-label">유형</InputLabel>
              <Select
                labelId="youtube-type-select-label"
                id="youtube-type-select"
                value={type}
                label="Type"
                onChange={(e) => setType(e.target.value)}
              >
                <MenuItem value={""}>전체</MenuItem>
                <MenuItem value={"수능"}>수능</MenuItem>
                <MenuItem value={"평가원"}>평가원/모의평가</MenuItem>
                <MenuItem value={"교육청"}>교육청/학력평가</MenuItem>
                <MenuItem value={"사관학교"}>사관학교</MenuItem>
                <MenuItem value={"경찰대"}>경찰대</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div style={{ width: 100 }}>
            <FormControl fullWidth>
              <InputLabel id="youtube-year-select-label">년도</InputLabel>
              <Select
                labelId="youtube-year-select-label"
                id="youtube-year-select"
                value={year}
                label="Year"
                onChange={(e) => setYear(e.target.value)}
              >
                <MenuItem value={""}>전체</MenuItem>
                {new Array(new Date().getFullYear() - 2000).fill(0).map((_, index) => (
                  <MenuItem value={new Date().getFullYear() - index}>
                    {new Date().getFullYear() - index}년
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ width: 70 }}>
            <FormControl fullWidth>
              <InputLabel id="youtube-month-select-label">월</InputLabel>
              <Select
                labelId="youtube-month-select-label"
                id="youtube-month-select"
                value={month}
                label="Month"
                onChange={(e) => setMonth(e.target.value)}
              >
                <MenuItem value={""}>전체</MenuItem>
                {new Array(12).fill(0).map((_, index) => (
                  <MenuItem value={index + 1}>{index + 1}월</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div style={{ width: 50 }}>
            <UiBtn title={"검색"} fnClick={getQnaList} />
          </div>
        </div>

        <div style={{ marginTop: 80 }}>
          {qnaList?.map(({ title, img, original_img, id, sid, tid }) => {
            return (
              <button
                style={{
                  marginTop: 40,
                  borderTop: "1px solid #dfdfdf",
                  borderBottom: "1px solid #dfdfdf",
                  backgroundColor: "white",
                  borderLeft: "none",
                  borderRight: "none",
                }}
                onClick={() => {
                  setScrolllKey(id);
                  navigate(`/talk/student/${id}/${id}/${sid}/${tid}`);
                }}
                ref={(r) => {
                  if (id === scrollKey)
                    r?.scrollIntoView({
                      block: "center",
                    });
                }}
              >
                <div style={{ textAlign: "left", margin: 10 }}>{title}</div>
                <img
                  width={"100%"}
                  src={`${constants.s3_url}/qna/${img ?? original_img}.jpg`}
                  loading={"lazy"}
                  alt={`문제사진 ${img ?? original_img}`}
                />
              </button>
            );
          })}
        </div>
      </div>
      {isLoading && <UiLoading text={"정보를 가져오는 중입니다."} />}
    </Frame>
  );
};

export default Youtube;
