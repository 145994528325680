//APP metadata

export const APP_METADATA = {
  ACADEMY_ID: Number(process.env.REACT_APP_ACADEMY_ID),
  ACADEMY_CODE: process.env.REACT_APP_ACADEMY_CODE,
  APP_NAME: process.env.REACT_APP_NAME,
  IS_ACADEMY: process.env.REACT_APP_IS_ACADEMY === "true",
};

export const EXTERNAL_URL = {
  IMAGE_BASE_URL: process.env.REACT_APP_S3_URL,
};

export const IMAGE_URL = {
  LOGO: `${EXTERNAL_URL.IMAGE_BASE_URL}/adcademy/logo/${APP_METADATA.ACADEMY_ID}.png`,
};

export const API = {
  BASE_URL: process.env.REACT_APP_API_URL,
};
