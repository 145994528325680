import React, { useEffect } from "react";
import { AccountFormFiledType } from "@presentation/components/common/molecules/form/account/account-form.types";
import { Controller, Path, useFormContext } from "react-hook-form";
import { ACCOUNT_FORM_CONFIG } from "@presentation/components/common/molecules/form/account/account-form.config";
import { Button, TextInput } from "@presentation/components/common/atoms";
import { CONFIG } from "@config";

export type AccountFormProps = {
  fields?: (keyof AccountFormFiledType)[];
  defaultValues?: AccountFormFiledType;
  onSubmit?: (data: AccountFormFiledType) => void;
};

function AccountForm({
  fields = ["userName"],
  onSubmit,
  defaultValues,
}: AccountFormProps): JSX.Element {
  const { handleSubmit, control, setValue } = useFormContext<AccountFormFiledType>();
  useEffect(() => {
    if (defaultValues) {
      Object.keys(defaultValues).forEach((key) => {
        setValue(
          key as Path<AccountFormFiledType>,
          defaultValues[key as keyof AccountFormFiledType]
        );
      });
    }
  }, [defaultValues, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {fields.map((fieldName) => {
        const { label, placeholder, type, rules, defaultValue } = ACCOUNT_FORM_CONFIG[fieldName];
        return (
          <Controller
            key={fieldName}
            control={control}
            name={fieldName}
            defaultValue={defaultValue}
            rules={CONFIG.APP_ENV.IS_DEVELOPMENT ? {} : rules}
            render={({ field, fieldState: { error } }) => (
              <TextInput
                {...field}
                label={label}
                placeholder={placeholder}
                type={type}
                error={error?.message}
                onChange={(e) => {
                  field.onChange(e);
                }}
              />
            )}
          />
        );
      })}
      <Button type={"submit"} onSubmit={() => onSubmit}>
        로컬 로그인
      </Button>
    </form>
  );
}

export default AccountForm;
