import React, { useEffect, useState } from "react";
import "../UiInput/UiInput.css";
import style from "../../asset/style";
import util from "../../asset/util";

const UiTime = ({ timeData, timeStyle, fnChange, inputClass, isMin = false, initValue }) => {
  var [one, setOne] = useState("0".padStart(2, "0"));
  var [two, setTwo] = useState("0".padStart(2, "0"));

  let regex = /[0-9]/;

  useEffect(() => {
    // console.log("one => ",one)
    // console.log("two => ",two)
    try {
      if (!regex.test(one)) {
        if (one) {
          alert("숫자만 입력해주세요.");
          setOne(0);
        }
      }
      if (!regex.test(two)) {
        if (two) {
          alert("숫자만 입력해주세요.");
          setOne(0);
        }
      }
      if (isMin) {
        // if(Number(one) > 60){
        //     alert("분은 60분까지만 입력 가능하세요.")
        //     setOne(0)
        // }
        if (Number(two) > 60) {
          alert("초는 60초까지만 입력 가능하세요.");
          setTwo(0);
        }
      } else {
        if (Number(one) > 60) {
          alert("시간은 24시간까지만 입력 가능하세요.");
          setOne(0);
        }
        if (Number(two) > 60) {
          alert("분은 60분까지만 입력 가능하세요.");
          setTwo(0);
        }
      }

      if (one.length == 1) {
        one = "0" + one;
      }

      if (two.length == 1) {
        two = "0" + two;
      }

      // if(!one){
      //     one = "00"
      //     setOne(one)
      // }
      // if(!two){
      //     two = "00"
      //     setTwo(two)
      // }

      let time_sum = `${one}:${two}`;
      if (initValue) {
        let _time = time_sum.split(":");

        let total_time = Number(_time[0] * 60 + Number(_time[1]));

        if (total_time > initValue) {
          alert("동영상 종료시간을 초과하였어요.");
          let split = util.converToMinute(initValue).split(":");
          // let split = util.converToSecond(initValue).split(":")
          setOne(Number(split[0]));
          setTwo(Number(split[1]));
        }
        fnChange(time_sum);
      } else {
        fnChange(time_sum);
      }
    } catch (e) {
      // console.log(e)
    }
  }, [one, two]);

  useEffect(() => {
    if (timeData) {
      let split = timeData.split(":");
      setOne(Number(split[0]));
      setTwo(Number(split[1]));
    }
  }, [timeData]);

  useEffect(() => {
    // if(isMin){
    //     // console.log("initValue => ", util.converToMinute(initValue))
    // }
    // else {
    //     // console.log("initValue => ", util.converToSecond(initValue))
    // }
    if (initValue) {
      // let split = util.converToSecond(initValue).split(":")
      let split = util.converToMinute(initValue).split(":");
      setOne(Number(split[0]));
      setTwo(Number(split[1]));
    }
  }, [initValue]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <input
        className={inputClass}
        maxLength={"3"}
        style={{
          // position : "relative",
          textAlign: "center",
          display: "flex",
          padding: "2px 4px",
          height: "30px",
          fontSize: "12px",
          width: "30px",
          borderRadius: 5,
          border: "1px solid",
          borderColor: style.common.borderColor,
          alignItems: "center",
          justifyContent: "center",
          zIndex: 0,
        }}
        value={one}
        onChange={(e) => {
          if (!isNaN(Number(e.target.value))) setOne(Number(e.target.value));
        }}
      />
      <div
        style={{
          padding: "0px 5px 5px 5px",
          fontWeight: 600,
        }}
      >
        :
      </div>
      <input
        className={inputClass}
        maxLength={"2"}
        style={{
          textAlign: "center",
          position: "relative",
          display: "flex",
          padding: "2px 4px",
          height: "30px",
          fontSize: "12px",
          width: "30px",
          borderRadius: 5,
          border: "1px solid",
          borderColor: style.common.borderColor,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "transparent",
          zIndex: 0,
        }}
        value={two}
        onChange={(e) => {
          if (!isNaN(Number(e.target.value))) setTwo(Number(e.target.value));
        }}
      />
      {/* <input
                className={inputClass}
                type="time"
                style={{
                    position : "relative",
                    display:"flex",
                    padding : "2px 10px",
                    height : "30px",
                    fontSize : "12px",
                    width : "60px",
                    borderRadius : 5,
                    border : "1px solid",
                    borderColor : style.common.borderColor,
                    alignItems:"center",
                    justifyContent:"center",
                    fontSize : 0,
                    backgroundColor : "transparent",
                    zIndex : 1,
                    backgroundImage : `url(${timepick})`,
                    backgroundRepeat : "no-repeat",
                    backgroundPosition : "57px 6px",
                    backgroundSize : "20px 20px"
                    // width : "100px",
                    // margin : 0,
                    // ...UiInputStyle.time_wrapper,
                    // ...timeStyle,
                    // display:"none",
                }}
                onChange={(e)=>{
                    fnChange(e);
                    setTime(e.target.value)
                }}
                id="timePcik"
                name='timePcik'
                // locale={"24-hour"}
            />
            <label style={{
                position : "absolute",
                display:"flex",
                padding : "2px 10px",
                height : "30px",
                fontSize : "12px",
                width : "60px",
                borderRadius : 5,
                // border : "1px solid",
                // borderColor : style.common.borderColor,
                alignItems:"center",
                justifyContent:"center",
                zIndex : 0,
            }}>
                {time}
            </label>
             */}
    </div>
  );
};

export default UiTime;
