import { ITypographyRepository } from "../../../domain/interfaces/theme/typography-repository.interface";
import { IColorRepository } from "../../../domain/interfaces/theme/color-repository.interface";
import { ColorValue } from "../../../domain/entities/theme/color.entity";

export interface TypographyStyleRequest {
  variant?: string;
  fontSize?: string;
  fontWeight?: string;
  color?: string;
}

export interface TypographyStyleResponse {
  fontSize: string;
  fontWeight: number;
  color: string;
}

export class GetTypographyStyleUseCase {
  constructor(
    private typographyRepository: ITypographyRepository,
    private colorRepository: IColorRepository
  ) {}

  execute(request: TypographyStyleRequest): TypographyStyleResponse {
    const { variant, fontSize, fontWeight, color } = request;

    let variantFontSize = null;
    let variantFontWeight = null;

    if (variant) {
      const [weight, size] = variant.split(".");
      if (size) variantFontSize = size;
      if (weight) variantFontWeight = weight;
    }

    const finalFontSize = fontSize
      ? this.typographyRepository.getFontSize(fontSize)
      : variantFontSize
        ? this.typographyRepository.getFontSize(variantFontSize)
        : this.typographyRepository.getDefaultFontSize();

    const finalFontWeight = fontWeight
      ? this.typographyRepository.getFontWeight(fontWeight)
      : variantFontWeight
        ? this.typographyRepository.getFontWeight(variantFontWeight)
        : this.typographyRepository.getDefaultFontWeight();

    let finalColor = this.colorRepository.getDefaultColor();

    if (color) {
      const directColor = ColorValue.create(color);
      if (directColor) {
        finalColor = directColor;
      } else if (color.includes(".")) {
        const [palette, shade] = color.split(".");
        const paletteColor = this.colorRepository.getColorByPaletteAndShade(palette, shade);
        if (paletteColor) finalColor = paletteColor;
      } else {
        const paletteColor = this.colorRepository.getColorByPalette(color);
        if (paletteColor) finalColor = paletteColor;
      }
    }

    return {
      fontSize: finalFontSize.toString(),
      fontWeight: finalFontWeight.getValue(),
      color: finalColor.toString(),
    };
  }
}
