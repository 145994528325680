export const CONSTANTS = {
  API_TIMEOUT: 5000,
  LIMIT_PER_PAGE: 10,
  TEST_ACCOUNT: [
    {
      name: "박주호 선생님",
      email: "ssamjju@naver.com",
      identity: "ssamjju",
    },
    {
      name: "스원 학생",
      email: "dudekfrhd@naver.com",
      identity: "dudekfrhd",
    },
    { name: "선생님A", email: "ZZTestTeacherA1", identity: "ZZTestTeacherA1" },
    { name: "선생님B", email: "ZZTestTeacherB1", identity: "ZZTestTeacherB1" },
    { name: "조교A", email: "ZZTestAssistantA1", identity: "ZZTestAssistantA1" },
    { name: "조교B", email: "ZZTestAssistantB1", identity: "ZZTestAssistantB1" },
    { name: "학생A", email: "ZZTestStudentA1", identity: "ZZTestStudentA1" },
    { name: "학생B", email: "ZZTestStudentB1", identity: "ZZTestStudentB1" },
  ],
};
