import { React } from "react";
import style from "../../asset/style";
import UiTopPopTextBtn_Style from "./UiTopPopTextBtn_Style";

const UiTopPopTextBtn = ({ title = "", fnClick, isTeacher, c_style, t_style }) => {
  return (
    <div
      style={{
        ...UiTopPopTextBtn_Style.edit_w,
        // width:"100vw",
        // maxWidth:style.common.maxWidth,
        // backgroundColor:"white",
        color: isTeacher ? style.common.t_color : style.common.s_color,
        ...c_style,
        // backgroundColor:"red",
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          position: "absolute",
          // backgroundColor:"yellow",
          top: 0,
          right: 7,
          paddingRight: "15px",
          width: "100px",
          ...t_style,
        }}
        onClick={(e) => {
          fnClick(e);
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        {title}
      </div>
    </div>
  );
};

export default UiTopPopTextBtn;
