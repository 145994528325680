import { axiosInstanceWithAuth } from "@infrastructure/api";
import { IAcademyInfo } from "./type";

export const getAcademyInfoApi = async (academyCode: string): Promise<IAcademyInfo> => {
  try {
    const response = await axiosInstanceWithAuth.get(`/academy/${academyCode}/info`);
    return response.data;
  } catch (error: any) {
    throw new Error(`Error in getAcademyInfoApi: ${error.message}`);
  }
};
