import React, { useContext, useEffect, useState } from "react";
import Checkbox from "@components/atoms/checkbox";
import { ContentSection, FlexBox, TermsDocsBox, TermsWrapper } from "@pages/signUp/terms/styled";
import Divider from "@components/atoms/divider";
import { SignUpContext, SignUpStepEnum } from "@providers/pages/signUp";
import Accordion from "@components/molecules/accordion";
import { terms } from "@constants/terms";
import Button from "@components/atoms/button";
import { useNavigate } from "react-router";
import Appbar from "@components/pages/signUp/appbar";
import StepIndicator from "@components/pages/signUp/stepIndicator";
import PageContainer from "@components/templates/PageContainer";
import { Typography } from "@presentation/components/common/atoms";

export default function TermsPage(): JSX.Element {
  const { signUpFormData, updateFormData, updateStep } = useContext(SignUpContext);
  const [termsAgree, setTermsAgree] = useState<boolean[]>([false, false, false]);
  const navigate = useNavigate();

  const handleToggleTermsAgree = (checked: boolean, index: number) => {
    const newTermsAgree = [...termsAgree];
    newTermsAgree[index] = checked;
    setTermsAgree(newTermsAgree);
  };

  const handleToggleAllTermsAgree = (checked: boolean) => {
    const newTermsAgree = termsAgree.map(() => checked);
    setTermsAgree(newTermsAgree);
  };

  const handleNextStep = () => {
    updateFormData({
      marketingNotify: termsAgree[2],
    });
    updateStep(SignUpStepEnum.ACCOUNT_INFO);
    navigate("/sign-up/account-info");
  };

  useEffect(() => {
    if (!signUpFormData.type) {
      navigate("/", { replace: true });
    }
  }, [signUpFormData.type]);

  if (!signUpFormData.type) {
    return <></>;
  }

  return (
    <PageContainer>
      <Appbar />
      <StepIndicator />
      <ContentSection>
        <TermsWrapper>
          <FlexBox>
            <Checkbox
              color={signUpFormData.type}
              checked={termsAgree.every((agree) => agree)}
              onChange={(checked) => handleToggleAllTermsAgree(checked)}
            />
            <Typography variant={"bold.sm"}>회원가입 약관에 모두 동의합니다.</Typography>
          </FlexBox>
          <Divider />
          {terms.map((term, index) => {
            const title = `${term.title} ${term.require ? "(필수)" : "(선택)"}`;
            return (
              <Accordion
                key={`term-${index}`}
                title={title}
                leftElement={
                  <Checkbox
                    color={signUpFormData.type}
                    checked={termsAgree[index]}
                    onChange={(checked) => handleToggleTermsAgree(checked, index)}
                  />
                }
              >
                <TermsDocsBox>{term?.docs}</TermsDocsBox>
              </Accordion>
            );
          })}
        </TermsWrapper>
        <Button
          backgroundColor={signUpFormData.type}
          disabled={!(termsAgree[0] && termsAgree[1])}
          onClick={handleNextStep}
        >
          다음
        </Button>
      </ContentSection>
    </PageContainer>
  );
}
