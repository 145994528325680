import { ISignUpRepository } from "../interface/signUp-repository.interface";
import { IMarketingNotifyRequest } from "../../../../shared/api/api-gateway/account/marketing-notify/type";
import { marketingNotifyApi } from "../../../../shared/api/api-gateway/account/marketing-notify";
import { accountJoinApi } from "../../../../shared/api/api-gateway/account/join";

export class SignUpRepository implements ISignUpRepository {
  async postMarketingNotify(marketingNotify: boolean): Promise<any> {
    const marketingNotifyData: IMarketingNotifyRequest = {
      marketingNotify,
    };
    return await marketingNotifyApi(marketingNotifyData);
  }
  async postJoin(accountData: any): Promise<any> {
    return await accountJoinApi(accountData);
  }
}
