import { React, useEffect, useState } from "react";
import style from "../../asset/style";
import { Frame } from "../page_index";
import { useNavigate, useParams } from "react-router";
import Message_Style from "../Message/Message_Style";
import apiFn from "../../asset/apiClass";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";
import UiQna from "./UiQna";
import imageview from "../../asset/Img/imageview.png";
import gridview from "../../asset/Img/gridview.png";
import listview from "../../asset/Img/listview.png";
import bookmark_on from "../../asset/Img/bookmark_on.png";
import UiQna_image from "./UiQna_image";
import UiQna_grid from "./UiQna_grid";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress } from "@mui/material";
import UiLoading from "../../uiComponents/UiLoading/UiLoading";
import Modal_qna_book from "./Modal_qna_book";
import { useDeleteQuestion } from "../../api/question";
import { getUser } from "@asset/storage";

const QNA = ({
  explainPage,
  setExplainPage,
  setHiddenUrl,
  scrollKey,
  setScrolllKey,
  qnaData,
  setQnaData,
}) => {
  let params = useParams();
  let navigate = useNavigate();

  let sort = [
    { name: "날짜순", value: "reg_date desc" },
    { name: "출처순", value: "title asc" },
  ];
  const userInfo = getUser();

  var [isLoading, setIsLoading] = useState(true);
  var [hasLoad, setHasLoad] = useState(false);

  var [ex_sort, setEx_sort] = useState("");
  var [listStat, setListStat] = useState("listview");

  const [isEnd, setIsEnd] = useState(false);
  var [isSort, setIsSort] = useState(false);

  var [lectureData, setLectureData] = useState({});
  var [stat, setStat] = useState({});

  const [isManager, setIsManager] = useState(false);

  var [isModal_book, setIsModal_book] = useState(false);
  var [isModal_book_paste, setIsModal_book_paste] = useState(false);

  var [selectData, setSelectData] = useState({});

  var [isSubs, setIsSubs] = useState(false);
  var [bookData, setBookData] = useState({});
  var [statInfo, setStatInfo] = useState({});
  const [isMultiSelect, setIsMultiSelect] = useState(false);
  const [multiSelectData, setMultiSelectData] = useState([]);

  // 강의 정보
  const apiPro_read_lecture = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "member_class",
          page: 1,
          amount: 9999,
          sort: `reg_date desc`,
          ref: [
            "HIQDB_class.name|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.intro|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.address|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.tel|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.email|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.owner|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.img|HIQDB_class.id=HIQDB_member_class.cid",
            "HIQDB_class.reg_id|HIQDB_class.id=HIQDB_member_class.cid",
          ],
          where: {
            "HIQDB_member_class.cid": `='${params["id"]}'`,
            "HIQDB_member_class.mid": `='${userInfo["id"]}'`,
            "HIQDB_class.type": `='1'`,
          },
        },
      });

      setLectureData({
        ...data["list"][0],
      });

      setStat({
        type: data["list"][0]["type"],
      });
      if (userInfo["id"] == data["list"][0]["reg_id"]) {
        setIsManager(true);
      } else {
        setIsManager(false);
      }
      // setIsLoad(true)

      // if(data["list"].length == 0){
      //     setIsJoin(false)
      // }
      // else{
      //     setIsJoin(true)
      // }
    } catch (e) {}
  };

  // 질문 목록
  const apiPro_read = async (sort, isSort, page, isForce) => {
    try {
      // console.log("EXMODEL READ")
      // console.log("sort : ",sort)
      // console.log("isSort : ",isSort)
      // console.log("page : ",page);

      // setIsSort(false)
      let fnCeil = (num) => {
        try {
          return Math.ceil(num / 10) * 10;
        } catch (e) {}
      };

      let status = "mobum";
      if (params["form"] == "ready") {
        status = "ready";
      }
      if (params["form"] == "proc") {
        status = "live";
      }
      if (params["form"] == "complete") {
        status = "qna";
      }
      let state = {};
      if (params["type"] == "book") {
        state["bid"] = params["id"];
      } else if (params["type"] == "lecture") {
        state["cid"] = params["id"];
      }

      if (params["id"] == 0) {
        state["isMy"] = "Y";
      }
      let amount = 10;
      let pass = true;

      // console.log('qnaData lenght : ',qnaData.length)

      console.log("EXMODEL STATE", state);

      if (qnaData.length > 0) {
        if (page) {
          if (fnCeil(qnaData[0]["tcnt"]) <= page * amount) {
            pass = false;
          } else {
            pass = true;
          }
        } else {
          if (explainPage) {
            // console.log("Ceil : ",fnCeil(qnaData[0]["tcnt"]))
            if (fnCeil(qnaData[0]["tcnt"]) < explainPage * amount) {
              pass = false;
              // setIsEnd(true)
            } else {
              pass = true;
            }
          }
        }
      }

      if (isForce) {
        pass = true;
      }
      console.log("pass : ", pass);
      if (!pass) return;

      let data;

      if (params["form"].includes("subs")) {
        // data = await apiFn.qnaSubs({
        //     state : {
        //         id : userInfo["id"],
        //         page : isSort ? 1 : explainPage,
        //         amount : amount,
        //         sort : sort ? sort : "reg_date desc",
        //         bid : params["id"],
        //         type : params["form"].split("&")[1]
        //     }
        // })
        data = await apiFn.qnaList({
          state: {
            id: userInfo["id"],
            page: isSort ? 1 : explainPage,
            amount: amount,
            sort: sort ? sort : "reg_date desc",
            status: "subs",
            ...state,
          },
        });
      } else {
        console.log("PAAAAGE : ", isSort ? 1 : explainPage);
        data = await apiFn.qnaList({
          state: {
            id: userInfo["id"],
            page: isSort ? 1 : explainPage,
            amount: amount,
            sort: sort ? sort : "reg_date desc",
            status: status,
            ...state,
          },
        });
      }

      console.log("qnadata => ", data);

      // setQnaData([
      //     ...data["list"]
      // ])
      if (!isSort) {
        setExplainPage(explainPage + 1);
      }
      if (isSort) {
        setQnaData([...data["list"]]);
      } else {
        setQnaData([...qnaData, ...data["list"]]);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
      setIsEnd(true);
    }
  };

  const delQnaData = async () => {
    try {
      for (const data of multiSelectData) {
        await useDeleteQuestion(data);
      }

      setMultiSelectData([]);
      setIsMultiSelect(false);
      apiPro_read(ex_sort, true, "", true);
    } catch (e) {
      console.error(e);
    }
  };

  // 교재 정보
  const apiPro_read_book = async () => {
    try {
      let state = {};

      const [data, isStat] = await Promise.all([
        apiFn.comApi({
          state: {
            table: "book",
            page: 1,
            amount: 9999,
            sort: "reg_date desc",
            // ref : [
            //     "HIQDB_book_link.cid|HIQDB_book_link.bid=HIQDB_book.id",
            // ],
            equal: {
              id: params["id"],
            },
          },
        }),
        apiFn.comApi({
          state: {
            table: "book_link",
            page: 1,
            amount: 9999,
            sort: "mid desc",
            where: {
              "HIQDB_book_link.mid": `='${userInfo["id"]}'`,
              "HIQDB_book_link.bid": `='${params["id"]}'`,
            },
          },
        }),
      ]);

      // console.log("book data => ",data)

      console.log("isStat => ", isStat);
      setStat({
        ...isStat["list"][0],
      });

      if (isStat["list"].length > 0) {
        if (isStat["list"][0]["type"] == 8 || isStat["list"][0]["type"] == 4) {
          setIsSubs(true);
        } else {
          setIsSubs(false);
        }
      } else {
        setIsSubs(false);
      }

      if (params["id"] === 0) {
        setIsManager(true);
      } else {
        if (userInfo["id"] === data["list"][0]["reg_id"]) {
          setIsManager(true);
        } else {
          setIsManager(false);
        }
      }
      console.log("BOOKDATA : ", data["list"][0]);
      setStatInfo({
        ...isStat["list"][0],
      });
      setBookData({
        ...data["list"][0],
      });
    } catch (e) {
      console.log("@#@# ", e);
    }
  };

  useEffect(() => {
    if (!isModal_book) {
      if (qnaData.length < 10) {
        apiPro_read("", true, 1, true);
      } else {
        setIsLoading(false);
      }
    }
  }, [isModal_book]);

  useEffect(() => {
    // console.log("scrollKey AAAA : ",scrollKey)
    setExplainPage(explainPage + 1);
    if (params["type"] == "lecture") {
      apiPro_read_lecture();
    }
    if (params["type"] == "book") {
      apiPro_read_book();
    }
    if (window.localStorage.getItem("explain_section")) {
      setListStat(window.localStorage.getItem("explain_section"));
    }
    if (window.ReactNativeWebView) {
      document.addEventListener("message", async (e) => {
        if (e.data == "isModal") {
          setIsEdit(false);
        }
      });
    }
    return () => {
      try {
        // setScrolllKey("");
        // setExplainPage(1)
        // setQnaData([])
        document.removeEventListener("message");
      } catch (e) {}
    };
  }, []);

  // useEffect(()=>{
  //    console.log("qnaData Length : ", qnaData.length );
  // },[qnaData])

  useEffect(() => {
    console.log("####### scrollKey : ", scrollKey);
  }, [scrollKey]);

  return (
    <Frame
      // t_visible={false}
      // isTap={false}
      isBack={true}
      type="2"
      isSearch={false}
      title={
        <div
          style={{
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "24px",
            lineHeight: "28px",
            textAlign: "center",
            letterSpacing: "2px",
          }}
        >
          {params["form"] == "proc"
            ? "진행중인 질문"
            : params["form"] == "complete"
              ? "완료된 질문"
              : params["form"] == "ready"
                ? "모범 풀이 등록 대기"
                : "모범 풀이"}
        </div>
      }
    >
      {isLoading && <UiLoading text="문제 정보를 불러오고 있습니다." />}
      <div
        style={{
          ...Message_Style.c,
          // padding : listStat == "gridview" ? "0px 15px": "0px",
          padding: "0px 10px",
        }}
      >
        {/* <div style={{
                    padding:"16px 20px 10px 20px",
                    borderBottom : "1px solid #E6E6E6",
                }}>

                </div> */}
        {params["form"] == "proc" ? (
          ""
        ) : params["form"] == "complete" ? (
          ""
        ) : params["form"] == "ready" ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              // marginTop:"15px",
              padding: "15px 0px 0px 5px",
            }}
          >
            <img
              src={bookmark_on}
              style={{
                width: "20px",
                height: "20px",
                marginTop: "5px",
              }}
            />
            <div
              style={{
                display: "flex",
                fontSize: "14px",
                fontWeight: 400,
                color: "#3D3A39",
              }}
            >
              {`선생님이 직접 올린 모범 풀이 등록 대기 중인 질문 목록`}
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              // marginTop:"15px",
              padding: "15px 0px 0px 5px",
            }}
          >
            <img
              src={bookmark_on}
              style={{
                width: "20px",
                height: "20px",
                marginTop: "5px",
              }}
            />
            <div
              style={{
                display: "flex",
                fontSize: "14px",
                fontWeight: 400,
                color: "#3D3A39",
              }}
            >
              {`선생님이 직접 고른 모범 풀이 `}
              <div
                style={{
                  fontWeight: 700,
                  marginLeft: "5px",
                }}
              >
                총 {qnaData.length > 0 ? qnaData[0]["tcnt"] : 0}문항
              </div>
            </div>
          </div>
        )}
        {params["form"] == "ready" && params["type"] != "lecture" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-around",
              backgroundColor: "#FFFBF5",
              padding: "15px 10px",
              height: "101px",
              marginBottom: "1px",
              borderRadius: "15px",
              marginTop: "20px",
              // height:"120px",
            }}
            onClick={() => {}}
          >
            {qnaData.length > 0 ? (
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#A5A5A5",
                  marginBottom: "10px",
                }}
              >
                새로운 문항을 등록해주세요!
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  fontWeight: 400,
                  fontSize: "14px",
                  color: "#A5A5A5",
                  marginBottom: "10px",
                }}
              >
                등록 대기 중인 모범 풀이가 없습니다.
                <div>새로운 문항을 등록해주세요!</div>
              </div>
            )}
            <div
              style={{
                display: "flex",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "70px",
                  height: "35px",
                  borderRadius: "20px",
                  backgroundColor: "#F7B865",
                  color: "white",
                  fontSize: "14px",
                  fontWeight: 700,
                  marginRight: "10px",
                }}
                onClick={() => {
                  navigate(`/mobumregi/${params["who"]}/${params["id"]}/de/de`);
                }}
              >
                + 등록
              </div>
              {params["id"] == 687 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "70px",
                    height: "35px",
                    borderRadius: "20px",
                    backgroundColor: "#F7B865",
                    color: "white",
                    fontSize: "14px",
                    fontWeight: 700,
                    marginLeft: "10px",
                  }}
                  onClick={() => {
                    navigate(`/mobumregi/${params["who"]}/${params["id"]}/de/gi`);
                  }}
                >
                  + 기출
                </div>
              )}
            </div>
          </div>
        )}
        <div
          style={{
            padding: "10px 10px 0px 10px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "6px",
            }}
          >
            <div
              style={{
                position: "relative",
              }}
            >
              <div
                style={{
                  display: "flex",
                  fontSize: "18px",
                  alignItems: "center",
                  fontWeight: 700,
                }}
              >
                {qnaData.length > 0 ? `총 ${qnaData[0]["tcnt"]} 문항` : `총 0 문항`}
                {isSort ? (
                  <ArrowDropUp
                    style={{
                      fontSize: "40px",
                      marginBottom: "3px",
                    }}
                    onClick={() => {
                      setIsSort(false);
                    }}
                  />
                ) : (
                  <ArrowDropDown
                    style={{
                      fontSize: "40px",
                      marginBottom: "3px",
                    }}
                    onClick={() => {
                      setIsSort(true);
                    }}
                  />
                )}
              </div>
              {isSort ? (
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    // top:10,
                    borderRadius: "20px",
                    border: "1px solid #F6F6F6",
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    padding: "3px 0px",
                    backgroundColor: "white",
                  }}
                >
                  {sort.map((v, i) => (
                    <div
                      style={{
                        padding: "15px 0px",
                        width: "120px",
                        backgroundColor: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "#3D3A39",
                        borderRadius: "20px",
                      }}
                      onClick={(e) => {
                        setIsLoading(true);
                        setIsSort(false);
                        apiPro_read(v["value"], true);
                        setExplainPage(1);
                        setEx_sort(v["value"]);
                        e.stopPropagation();
                      }}
                    >
                      {v["name"]}
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>

            <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
              {isMultiSelect && (
                <div
                  onClick={() => {
                    const isOk = confirm("삭제하면 되돌릴 수 없습니다. \n해당 문제를 삭제할까요?");
                    if (isOk) {
                      delQnaData();
                    }
                  }}
                >
                  삭제
                </div>
              )}
              <div
                onClick={() => {
                  setIsMultiSelect(!isMultiSelect);
                  setMultiSelectData([]);
                }}
              >
                {isMultiSelect ? "취소" : "선택"}
              </div>

              {listStat == "listview" ? (
                <img
                  src={listview}
                  style={{
                    width: "26px",
                    height: "26px",
                  }}
                  onClick={() => {
                    setListStat("gridview");
                    window.localStorage.setItem("explain_section", "gridview");
                  }}
                />
              ) : listStat == "gridview" ? (
                <img
                  src={gridview}
                  style={{
                    width: "26px",
                    height: "26px",
                  }}
                  onClick={() => {
                    setListStat("imageview");
                    window.localStorage.setItem("explain_section", "imageview");
                  }}
                />
              ) : (
                // listStat == "imageview" &&
                <img
                  src={imageview}
                  style={{
                    width: "26px",
                    height: "26px",
                  }}
                  onClick={() => {
                    setListStat("listview");
                    window.localStorage.setItem("explain_section", "listview");
                  }}
                />
              )}
            </div>
          </div>
        </div>
        {
          <div
            style={{
              display: "flex",
              overflow: "scroll",
              paddingBottom: "10px",
              // width : listStat == "gridview" ? "100%" : "auto",
            }}
          >
            <div
              style={{
                // backgroundColor : style.common.borderColor2,
                // display:"flex",
                // flexDirection:"column",
                // flex:1,
                // paddingBottom:"50px",
                // width:"50vw",
                width: "100%",
                height: "100%",
                overflow: "auto",
              }}
              id="scrollableDiv"
            >
              <InfiniteScroll
                onScroll={() => {
                  setHasLoad(true);
                }}
                style={{}}
                dataLength={qnaData.length}
                next={() => {
                  apiPro_read(ex_sort ? ex_sort : "");
                }}
                loader={
                  qnaData.length > 0 &&
                  qnaData.length != qnaData[0]["tcnt"] && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        padding: "10px 0px 10px 0px",
                      }}
                    >
                      <CircularProgress
                        style={{
                          fontSize: "12px",
                          color:
                            params["who"] == "teacher"
                              ? style.common.t_color
                              : style.common.s_color,
                        }}
                      />
                    </div>
                  )
                }
                hasMore={hasLoad}
                scrollableTarget="scrollableDiv"
              >
                {listStat == "gridview" ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    {qnaData.map((v, i) => (
                      <div
                        style={{
                          display: "flex",
                          flexGrow: 1,
                          maxWidth: "200px",
                          justifyContent: "center",
                          // width : "calc((100vw - 40px)/3)"
                        }}
                      >
                        <UiQna_grid
                          scrollKey={scrollKey}
                          statInfo={statInfo}
                          isDot={stat["type"] != 5 ? false : true}
                          index={i}
                          data={v}
                          isEdit={isMultiSelect}
                          selectData={multiSelectData}
                          fnClick={() => {
                            setScrolllKey(v["id"]);
                            // if(v["sid"]){
                            setHiddenUrl(window.location.pathname);
                            navigate(`/talk/${params["who"]}/0/${v["id"]}/${v["sid"]}/${v["tid"]}`);
                          }}
                          fnCheck={(qna) => {
                            setMultiSelectData((prev) => {
                              if (prev.includes(qna.id)) {
                                const index = prev.findIndex((data) => data === qna.id);
                                prev.splice(index, 1);
                                return [...prev];
                              } else {
                                return [...prev, qna.id];
                              }
                            });
                          }}
                          apiPro={() => {
                            apiPro_read(ex_sort, true, "", true);
                          }}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  qnaData.map((v, i) =>
                    listStat == "listview" ? (
                      <UiQna
                        statInfo={statInfo}
                        setSelectData={setSelectData}
                        setIsModal={setIsModal_book}
                        setIsModal_paste={setIsModal_book_paste}
                        scrollKey={scrollKey}
                        isDot={stat["type"] != 5 ? false : true}
                        index={i}
                        data={v}
                        isEdit={isMultiSelect}
                        isMultiSelect={isMultiSelect}
                        selectData={multiSelectData}
                        fnClick={() => {
                          setScrolllKey(v["id"]);
                          // if(v["sid"]){
                          setHiddenUrl(window.location.pathname);
                          navigate(`/talk/${params["who"]}/0/${v["id"]}/${v["sid"]}/${v["tid"]}`);
                        }}
                        fnCheck={(qna) => {
                          setMultiSelectData((prev) => {
                            if (prev.includes(qna.id)) {
                              const index = prev.findIndex((data) => data === qna.id);
                              prev.splice(index, 1);
                              return [...prev];
                            } else {
                              return [...prev, qna.id];
                            }
                          });
                        }}
                        apiPro={() => {
                          apiPro_read(ex_sort, true, "", true);
                        }}
                      />
                    ) : (
                      <UiQna_image
                        scrollKey={scrollKey}
                        statInfo={statInfo}
                        isDot={stat["type"] != 5 ? false : true}
                        index={i}
                        data={v}
                        isEdit={isMultiSelect}
                        selectData={multiSelectData}
                        fnClick={() => {
                          setScrolllKey(v["id"]);
                          // if(v["sid"]){
                          setHiddenUrl(window.location.pathname);
                          navigate(`/talk/${params["who"]}/0/${v["id"]}/${v["sid"]}/${v["tid"]}`);
                        }}
                        fnCheck={(qna) => {
                          setMultiSelectData((prev) => {
                            if (prev.includes(qna.id)) {
                              const index = prev.findIndex((data) => data === qna.id);
                              prev.splice(index, 1);
                              return [...prev];
                            } else {
                              return [...prev, qna.id];
                            }
                          });
                        }}
                        apiPro={() => {
                          apiPro_read(ex_sort, true, "", true);
                        }}
                      />
                    )
                  )
                )}
              </InfiniteScroll>
            </div>
          </div>
        }
      </div>
      {isModal_book && (
        <Modal_qna_book
          selectData={selectData}
          userInfo={userInfo}
          setIsModal={setIsModal_book}
          type="move"
        />
      )}
      {isModal_book_paste && (
        <Modal_qna_book
          selectData={selectData}
          userInfo={userInfo}
          setIsModal={setIsModal_book_paste}
          type="paste"
        />
      )}
    </Frame>
  );
};

export default QNA;
