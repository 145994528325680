import { pathToUrl } from "@infrastructure/utils/pathToUrl";

export const API_ENDPOINTS = {
  ACCOUNT: {
    GET_PROFILE: "/account/profile",
    SET_EXISTING_ACCOUNT_LINK: "/account/link",
  },
  ACADEMY: {
    GET_ACADEMY_INFO: (academyCode: number | string): string =>
      pathToUrl("/academy/:academyCode/info", { academyCode }),
    SET_ACADEMY_REQUEST: (academyCode: number | string): string =>
      pathToUrl("/academy/:academyCode/request", { academyCode }),
    GET_ACADEMY_LECTURE_RELATED: (academyCode: number | string): string =>
      pathToUrl("/academy/:academyCode/lectures/related", { academyCode }),
  },
  AUTH: {
    LOCAL_AUTH: "/auth/login/local_auth",
  },
};
