import { StudyBookFolderListItem, useStudyBookFolderList, useStudyBookMove } from "api/studyBook";
import { ReactComponent as MoreIcon } from "asset/images/icon/more.svg";
import FolderBlueTablet from "asset/images/studybook-folder-blue-tablet.svg";
import FolderBlue from "asset/images/studybook-folder-blue.svg";
import FolderGreenTablet from "asset/images/studybook-folder-green-tablet.svg";
import FolderGreen from "asset/images/studybook-folder-green.svg";
import FolderOrangeTablet from "asset/images/studybook-folder-orange-tablet.svg";
import FolderOrange from "asset/images/studybook-folder-orange.svg";
import FolderPurpleTablet from "asset/images/studybook-folder-purple-tablet.svg";
import FolderPurple from "asset/images/studybook-folder-purple.svg";
import FolderRedTablet from "asset/images/studybook-folder-red-tablet.svg";
import FolderRed from "asset/images/studybook-folder-red.svg";
import FolderYellowTablet from "asset/images/studybook-folder-yellow-tablet.svg";
import FolderYellow from "asset/images/studybook-folder-yellow.svg";
import { getUser } from "asset/storage";
import CustomCheckbox from "components/atoms/CustomCheckbox";
import { FontStyleMap } from "components/atoms/Font";
import { useMediaQuery } from "hooks/useMediaQuery";
import React, {
  DragEvent,
  MouseEvent,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router";
import styled, { css } from "styled-components";
import { StudyBookFolderContext } from "../context/StudyBookFolderContext";
import FolderSubMenu from "./FolderSubMenu";

const COLOR_LIST = [FolderRed, FolderYellow, FolderGreen, FolderBlue, FolderPurple, FolderOrange];
const COLOR_LIST_TABLET = [
  FolderRedTablet,
  FolderYellowTablet,
  FolderGreenTablet,
  FolderBlueTablet,
  FolderPurpleTablet,
  FolderOrangeTablet,
];

const FolderList = () => {
  const { data } = useStudyBookFolderList();
  const folderList = useMemo(
    () => [{ name: "수업에 의한 열람 교재", id: 0 } as StudyBookFolderListItem, ...data["list"]],
    [data]
  );
  return (
    <Container>
      {folderList.map((folder, index) => {
        return <FolderItem {...folder} key={folder.id} index={index} />;
      })}
    </Container>
  );
};

type FolderItmeProps = {
  index: number;
} & StudyBookFolderListItem;

const FolderItem = ({ index, ...folder }: FolderItmeProps) => {
  const { isDeleteMode, checkedFolderList, addCheckedFolder, removeCheckedFolder } =
    useContext(StudyBookFolderContext);

  const isMobile = useMediaQuery("(max-width: 820px)");
  const colorList = isMobile ? COLOR_LIST : COLOR_LIST_TABLET;
  const ref = useRef<HTMLLabelElement>(null);

  const handleCheck = useCallback((event) => {
    if (event.target.checked) {
      addCheckedFolder(folder);
    } else {
      removeCheckedFolder(folder.id);
    }
  }, []);

  const isChecked = useMemo(
    () => !!checkedFolderList.find((item) => item.id === folder.id),
    [checkedFolderList]
  );

  const [isDragOver, setIsDragOver] = useState(false);
  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const { mutate } = useStudyBookMove();

  const handleDrop = async (event: DragEvent<HTMLLabelElement>) => {
    event.preventDefault();
    setIsDragOver(false);
    const bookids = event.dataTransfer.getData("bookIds");
    await mutate({ bidList: bookids.split(","), fid: folder?.id });
  };

  const navigate = useNavigate();

  const handleClick = useCallback(
    (event: MouseEvent<HTMLLabelElement, globalThis.MouseEvent>) => {
      event.stopPropagation();
      if (!isDeleteMode && !isSubmenuOpen) {
        const userInfo = getUser();
        navigate(
          // `/bookfolder/${userInfo.type === WhoType.student ? WhoIs.student : WhoIs.teacher}/${folder.id}/list`
          `/study-book/folder/${folder.id}`
        );
      }
    },
    [handleCheck, isChecked, isDeleteMode, folder.id]
  );
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  return (
    <>
      <FolderItemContainer
        isDragOver={isDragOver}
        background={colorList[index % 6]}
        ref={ref}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleClick}
      >
        <FolderNameFont>{folder.name}</FolderNameFont>
        {folder.id !== 0 &&
          (isDeleteMode ? (
            <CheckboxContainer>
              <CustomCheckbox checked={isChecked} onChange={handleCheck} />
            </CheckboxContainer>
          ) : (
            <a
              onClick={(event) => {
                event.stopPropagation();
                setIsSubmenuOpen(true);
              }}
            >
              <MoreIcon />
            </a>
          ))}
      </FolderItemContainer>
      <FolderSubMenu
        folder={folder}
        onClose={() => setIsSubmenuOpen(false)}
        open={isSubmenuOpen}
        anchorElement={ref.current}
      />
    </>
  );
};
const Container = styled.div`
  gap: 24px;
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  margin-right: -20px;
  margin-top: 32px;
  padding-right: 20px;
`;

const FolderItemContainer = styled.label<{ background: string; isDragOver: boolean }>`
  cursor: pointer;
  ${({ isDragOver }) =>
    isDragOver &&
    css`
      filter: brightness(0.9);
    `}
  box-sizing: border-box;
  background-color: white;
  scroll-snap-align: start;
  min-width: 113px;
  max-width: 113px;
  height: 94px;
  display: flex;
  position: relative;
  justify-content: space-between;
  border-radius: 11px;
  background: #fff;
  @media screen and (min-width: 820px) {
    max-width: 149px;
    min-width: 149px;
    height: 130px;
  }
  ${({ background }) => css`
    background-image: url(${background});
  `}
  a {
    cursor: pointer;
    min-width: 16px;
    min-height: 16px;
    margin: 4px;
    > svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const FolderNameFont = styled.div`
  margin: 12px;
  color: var(--neutral-color-natural-60, #434343);
  ${FontStyleMap["KR/medium/$KR.BS.MD.12"]}
  @media screen and (min-width: 820px) {
    ${FontStyleMap["KR/medium/$KR.BM.MD.14"]}
  }
`;

export const CheckboxContainer = styled.div`
  margin-top: 8px;
  margin-right: 8px;
`;
export default FolderList;
