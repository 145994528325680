import { IcDefatultProps } from "@components/atoms/icons/types";
import React from "react";
import { themeUtil } from "@utils/theme";

export function IcClose({ size = 24, color = "white" }: IcDefatultProps): JSX.Element {
  const themeColor = themeUtil.getColor(color);
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.31372 6L17.6274 17.3137"
        stroke={themeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M17.3137 6L6.00001 17.3137"
        stroke={themeColor}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
