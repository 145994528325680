import { React, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import apiFn from "../../asset/apiClass";
import constants from "../../asset/constants";
import util from "../../asset/util";
import { UiInput } from "../../uiComponents/UiInput/UiInput";

var fontStyle = {
  fontFamily: "Noto Sans",
  fontStyle: "normal",
};

const Modal_modi = ({
  lectureData,
  myInfo,
  setIsModal,
  isModal,
  userInfo,
  title,
  placeholder,
  setIsCrop,
  setFileObj,
  fileObj,
}) => {
  let params = useParams();
  let navigate = useNavigate();

  var [name, setName] = useState("");
  var [nick, setNick] = useState("");
  var [tel, setTel] = useState("");
  var [email, setEmail] = useState("");
  var [intro, setIntro] = useState("");

  var [acaname, setAcaname] = useState("");

  var [img, setImg] = useState();

  const [classroom, setClassroom] = useState(null);

  const apiPro_modi = async () => {
    try {
      const [classroomInfo, modifiedUserInfo] = await Promise.all([
        (async () => {
          if (userInfo.type === 1) return { status: 200 };
          try {
            const res = await fetch(
              `${constants.apiUrl}/user/${userInfo.id}/classroom/${classroom.id}`,
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ newClassroomName: classroom.name }),
              }
            );

            if (res.status !== 200) {
              throw new Error("error on classroomname");
            }
            return await res.json();
          } catch (e) {
            console.error(e);
            throw new Error(e);
          }
        })(),
        (async () => {
          const {
            list: [userCredentialInfo],
          } = await apiFn.comApi({
            state: {
              table: "user_credential",
              where: {
                "HIQDB_user_credential.name": `= '${userInfo.id}'`,
              },
              sort: "createdAt desc",
              page: 1,
              amount: 9999,
            },
          });

          if (!userCredentialInfo) {
            alert("해당 하는 사용자 정보를 찾을 수 없습니다");
            throw new Error("user id = name not found");
          }

          return apiFn.comApi({
            state: {
              table: "user",
              set: [
                {
                  key: `\`index\`:${userCredentialInfo.user_id}`,
                  value: {
                    name,
                    nick,
                    tel,
                    email,
                    intro,
                    // ...modiData
                  },
                },
              ],
            },
          });
        })(),
      ]);
      setIsModal(false);
    } catch (e) {
      // util.c_err_log(`apiPro_modi Error => ${e}`)
      console.error(e);
      alert("정보 변경에 실패했습니다");
    }
  };

  const apiPro_img = async (e) => {
    try {
      setFileObj({
        name: `/${params["who"]}/${params["id"]}.jpg`,
        file: e,
      });
      navigate(`/crop/${params["who"]}/d?myInfo=true`);
      setIsCrop(true);
    } catch (e) {
      console.log("apiPro_img Error= >", e);
    }
  };

  useEffect(() => {
    // setFileObj({})
    // // console.log("@@ ",fileObj);

    // if(lectureData["apporve"] == "N"){
    //     setIsApprove(false)
    // }
    console.log("MI : ", myInfo);
    setName(myInfo["name"]);
    setNick(myInfo["nick"]);
    setTel(myInfo["tel"]);
    setEmail(myInfo["email"]);
    setIntro(myInfo["intro"]);
  }, [myInfo]);

  const getClassroomName = async () => {
    setClassroom(await (await fetch(`${constants.apiUrl}/user/${userInfo.id}/classroom`)).json());
  };

  useEffect(() => {
    if (userInfo.type === 2) {
      getClassroomName();
    }
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        zIndex: 130002,
        backgroundColor: "rgba(150, 150, 150,0.5)",
        // backgroundColor:"red",
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={() => {
        // setIsModal(false)
      }}
    >
      <div
        style={{
          // width : "75%",
          width: "300px",
          // minHeight:"55%",
          // maxHeight:"60%",
          backgroundColor: "white",
          padding: "35px 20px 40px 20px",
          borderRadius: 10,
          border: "1px solid rgb(200, 200, 200)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.37))",
        }}
        onClick={(e) => {
          // e.stopPropagation();
          // e.preventDefault();
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "18px",
            fontWeight: 700,
          }}
        >
          {title}
        </div>
        <div
          style={{
            display: "flex",
            fontSize: "14px",
            fontWeight: 700,
            marginTop: "10px",
            // justifyContent:"center",
            padding: "10px 0px 25px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "120px",
              height: "120px",
              marginRight: "10px",
              borderRadius: "10px",
            }}
          >
            <input
              id="lfb"
              accept="image/*"
              type="file"
              style={{ display: "none" }}
              onChange={(e) => {
                util.fileOnchange(e, (event, r) => {
                  img = r;
                  // apiPro_img(e);
                  apiPro_img(e);
                });
              }}
            />
            <label
              htmlFor="lfb"
              style={{
                width: "120px",
                height: "120px",
              }}
            >
              <img
                src={
                  fileObj["base64"] ??
                  ` ${constants.s3_url}/${params["who"]}/${myInfo.id.normalize("NFD")}.jpg?${new Date().getTime()}` ??
                  undefined
                }
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px",
                }}
              />
            </label>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                marginBottom: "3px",
              }}
            >
              이름
            </div>
            <div
              style={{
                display: "flex",
                // width:"130px",
                // height:"32px",
                flex: 1,
                marginBottom: "3px",
              }}
            >
              {name}
              {/* <UiInput
                                inputClass={"input_t"}
                                basic_container_style={{
                                    height:"28px",
                                }}
                                inputValue={name}
                                placeholder={placeholder}
                                fnChange={(e)=>{
                                    setName(e.target.value)
                                }}
                            /> */}
            </div>
            {userInfo.type === 2 && (
              <>
                <div
                  style={{
                    marginBottom: "3px",
                  }}
                >
                  학원명
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                  }}
                >
                  <UiInput
                    inputClass={"input_t"}
                    basic_container_style={{
                      height: "28px",
                    }}
                    inputValue={classroom?.name}
                    placeholder={placeholder}
                    fnChange={(e) => {
                      setClassroom((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }));
                    }}
                  />
                </div>
              </>
            )}
            <div
              style={{
                marginBottom: "3px",
              }}
            >
              닉네임
            </div>
            <div
              style={{
                display: "flex",
                // width:"130px",
                // height:"32px",
                flex: 1,
              }}
            >
              <UiInput
                inputClass={"input_t"}
                basic_container_style={{
                  height: "28px",
                }}
                inputValue={nick}
                placeholder={placeholder}
                fnChange={(e) => {
                  setNick(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontSize: "14px",
            fontWeight: 700,
            justifyContent: "center",
            padding: "10px 0px 25px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                marginBottom: "3px",
              }}
            >
              전화번호
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                // height:"32px",
                flex: 1,
              }}
            >
              {tel}
              {/* <UiInput
                                inputClass={"input_t"}
                                basic_container_style={{
                                    height:"28px",
                                }}
                                inputValue={tel}
                                placeholder={placeholder}
                                fnChange={(e)=>{
                                    setTel(e.target.value)
                                }}
                            /> */}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                marginBottom: "3px",
              }}
            >
              이메일
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                // height:"32px",
                flex: 1,
              }}
            >
              <UiInput
                inputClass={"input_t"}
                basic_container_style={{
                  height: "28px",
                }}
                inputValue={email}
                placeholder={placeholder}
                fnChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
          </div>
          {!util.isKeyExists(myInfo, "stype") && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  marginBottom: "3px",
                }}
              >
                학원명
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  // height:"32px",
                  flex: 1,
                }}
              >
                <UiInput
                  inputClass={"input_t"}
                  basic_container_style={{
                    height: "28px",
                  }}
                  inputValue={acaname}
                  placeholder={placeholder}
                  fnChange={(e) => {
                    setAcaname(e.target.value);
                  }}
                />
              </div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                marginBottom: "3px",
              }}
            >
              소개글
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                // height:"32px",
                flex: 1,
              }}
            >
              <UiInput
                inputClass={"input_t"}
                type="desc"
                maxLength={"80"}
                // basic_container_style={{
                //     height:"28px",
                // }}
                inputValue={intro}
                placeholder={placeholder}
                fnChange={(e) => {
                  setIntro(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "180px",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "87px",
                height: "40px",
                borderRadius: "15px",
                backgroundColor: "#D8D8D8",
                fontSize: "14px",
                color: "#FFFFFF",
                fontWeight: 700,
              }}
              onClick={() => {
                setFileObj({});
                setIsModal(false);
              }}
            >
              취소
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "87px",
                height: "40px",
                borderRadius: "15px",
                backgroundColor: "#5EA3C6",
                fontSize: "14px",
                color: "#FFFFFF",
                fontWeight: 700,
              }}
              onClick={apiPro_modi}
            >
              등록
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal_modi;
