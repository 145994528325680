const colors = {
  teacher: {
    70: "#487fba",
  },
  student: {
    70: "#f5b21a",
  },
  natural: {
    0: "#f1f1f1",
    10: "#fafafa",
    20: "#f0f0f0",
    30: "#d9d9d9",
    40: "#bfbfbf",
    50: "#8c8c8c",
    60: "#434343",
    70: "#262626",
    80: "#141414",
  },
  primary: {
    10: "#fef3f1",
    20: "#fed4cc",
    30: "#febcb1",
    40: "#fea69b",
    50: "#fe8f86",
    60: "#fe746e",
    70: "#ff595c",
    80: "#cd403d",
  },
  danger: {
    10: "#fff1f0",
    20: "#ffccc7",
    30: "#ffa39e",
    40: "#ff7875",
    50: "#ff4d4f",
    60: "#f5222d",
    70: "#cf1322",
    80: "#a8071a",
  },
  warning: {
    10: "#fffdfa",
    20: "#fff9ee",
    30: "#fff7e1",
    40: "#ffeab3",
    50: "#ffdd82",
    60: "#ffc62b",
    70: "#ffad0d",
    80: "#fe9b0e",
  },
  success: {
    10: "#fbfefc",
    20: "#f2faf6",
    30: "#e5f5ec",
    40: "#c0e5d1",
    50: "#97d4b4",
    60: "#6bc497",
    70: "#47b881",
    80: "#0c9d61",
  },
  info: {
    10: "#f8fcff",
    20: "#f1f8ff",
    30: "#e4f2ff",
    40: "#bdddff",
    50: "#93c8ff",
    60: "#4ba1ff",
    70: "#3d82f6",
    80: "#3a70e2",
  },
  white: {
    70: "#FFFFFF",
  },
  black: {
    70: "#000000",
  },
};

export default colors;
