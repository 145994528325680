import React from "react";
import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  height: 100vh;
  max-width: 715px;
  display: flex;
  flex-direction: column;
  padding: 1em;
`;

export default function PageContainer({ children }) {
  return <Container>{children}</Container>;
}
