import styled, { css } from "styled-components";

const flexColumnStyle = css`
  display: flex;
  flex-direction: column;
`;

const gapSmStyle = css`
  gap: ${({ theme }) => theme.spacing.sm};
`;

const gapMdStyle = css`
  gap: ${({ theme }) => theme.spacing.md};
`;

export const HeaderContainer = styled.header`
  text-align: center;
  padding-bottom: ${({ theme }) => theme.spacing.sm};
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  border-bottom: 1px solid ${({ theme }) => theme.colors.info[30]};
`;

export const MainContentContainer = styled.main`
  ${flexColumnStyle}
  flex: 1;
  padding-top: ${({ theme }) => theme.spacing.sm};
`;

export const ContentSection = styled.div`
  ${flexColumnStyle}
  ${gapMdStyle}
    flex: 1;
`;

export const DescriptionArea = styled.div`
  ${flexColumnStyle}
  ${gapSmStyle}
    line-height: 1.3;
  font-size: ${({ theme }) => theme.typography.fontSizes.base};
`;

export const AccountInfoArea = styled.dl`
  ${flexColumnStyle}
  ${gapMdStyle}
    border-radius: ${({ theme }) => theme.borderRadius.md};
  background: ${({ theme }) => theme.colors.natural[10]};
  padding: ${({ theme }) => theme.spacing.md};
`;

export const ActionSection = styled.div`
  ${flexColumnStyle}
  ${gapSmStyle}
`;

export const ButtonArea = styled.div`
  ${flexColumnStyle}
  ${gapSmStyle}
`;

interface ButtonProps {
  backgroundColor?: string;
  color?: string;
  border?: string;
}

export const Button = styled.button<ButtonProps>`
  background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.colors.primary[70]};
  color: ${({ theme, color }) => color || theme.colors.white[70]};
  padding: ${({ theme }) => `${theme.spacing.sm} ${theme.spacing.md}`};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s;
  border: ${({ theme, border }) => border || "unset"};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary[70]};
  }
`;
