import React from "react";
import { Outlet } from "react-router";
import { SignUpProvider } from "@providers/pages/signUp";

export default function SignUp(): JSX.Element {
  return (
    <SignUpProvider>
      <Outlet />
    </SignUpProvider>
  );
}
