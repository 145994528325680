import { ITokenStorageRepository } from "./storage/token-storage.interface";
import { IToken, ITokenManager } from "./token.interface";
import { TokenNameEnum } from "./enum/token.enum";
import { TokenCookieRepository } from "./storage/token-cookie.repository";

class TokenManager implements ITokenManager {
  constructor(private readonly tokenRepository: ITokenStorageRepository) {}

  saveToken(token: IToken): void {
    this.tokenRepository.saveToken(token);
  }
  getToken(tokenName: TokenNameEnum): IToken {
    return this.tokenRepository.getToken(tokenName);
  }
  clearToken(): void {
    this.tokenRepository.clearToken();
  }
  hasToken(tokenName: TokenNameEnum): boolean {
    return this.tokenRepository.hasToken(tokenName);
  }
}

export const tokenManager = new TokenManager(new TokenCookieRepository());
