import { CloseSharp } from "@material-ui/icons";
import React, { CSSProperties, useEffect, useRef } from "react";
import intro_logo from "../../asset/Img/book1.png";
import constants from "../../asset/constants";
import store from "../../asset/store";
import util from "../../asset/util";
import UiBook_Style from "./UiBook_Style";
import styles from "./style.module.scss";

type UiBookProps = {
  fnClick?: (data: any) => void;
  setScrolllKey?: (key: string) => void;
  data?: any;
  isEdit?: boolean;
  selectData?: any;
  setSelectData?: (data: any) => void;
  selectOnlyOne?: boolean;
  navigate?: (navi: number | string) => void;
  navi?: number | string;
  scrollKey?: string;
  isOuth?: number;
  basicBook?: boolean;
  cancelable?: boolean;
  onCancel?: (e: any) => void;
  basicBooks?: any[];
};

const UiBook = ({
  fnClick,
  setScrolllKey,
  data = {},
  isEdit = false,
  selectData = {},
  setSelectData,
  selectOnlyOne = false,
  navigate,
  navi,
  scrollKey,
  isOuth,
  basicBook,
  cancelable = false,
  onCancel = () => {},
  basicBooks = [],
}: UiBookProps) => {
  let imgRef = useRef();
  var scrollRef = useRef(null);

  useEffect(() => {
    try {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({
          // behavior: "smooth",
          block: "center",
        });
        if (setScrolllKey) {
          setScrolllKey("");
        }
      }
    } catch (e) {}
  }, [scrollRef]);

  const BookLabel = ({ bookData }) => {
    const { type, id, status, book_type = 0 } = bookData;
    let bookLabel: string;
    let bookTypeLabel = "";
    if (type === 5) {
      bookLabel = "관리";
    } else if ([4, 6].includes(type)) {
      bookLabel = "참여";
    } else if ([0, 1].includes(type) || (type === 8 && status === 5)) {
      bookLabel = "열람";
    } else if (type === 8 && status !== 5) {
      bookLabel = "구독";
    } else if (type === 9 && status === 5) {
      bookLabel = "초대";
    } else {
      bookLabel = "";
    }

    if (basicBooks.includes(id)) {
      bookLabel = bookLabel === "" ? "열람|기본" : `${bookLabel}|기본`;
    }
    if ([1, 2].includes(book_type)) {
      bookTypeLabel = "PDF";
    }

    return (
      <div className={styles.bookBox}>
        {[1, 2].includes(book_type) && (
          <div className={styles.bookTypeLabel}>
            <b>{bookTypeLabel}</b>
          </div>
        )}
        <div
          className={styles.bookLabel}
          style={{
            backgroundColor: type === 5 && "#ACD0CB",
          }}
        >
          {bookLabel}
        </div>
      </div>
    );
  };
  return (
    <div
      // {...bind}
      ref={scrollKey == data["id"] ? scrollRef : null}
      style={
        {
          ...UiBook_Style.c,
          background: [1, 2].includes(data?.book_type) ? "#cbeaf14d" : "#FFFBF5",
          border:
            (isEdit && (selectData[data["id"]] || selectData[data.id] === 0)) ||
            (selectOnlyOne && Number(Object.keys(selectData)[0]) === data.id)
              ? "1px solid #F09294"
              : "none",
        } as CSSProperties
      }
      onClick={() => {
        //
        // return;
        if (isEdit) {
          if (selectData[data["id"]] || selectData[data.id] === 0) {
            delete selectData[data["id"]];
            setSelectData({
              ...selectData,
            });
          } else {
            console.log("ISDEIT @#@#@#", data.type, data);
            if (selectOnlyOne) {
              setSelectData({ [data.id]: data.type });
            } else {
              setSelectData({
                ...selectData,
                [data["id"]]: data.type,
              });
            }
          }
        } else {
          if (fnClick) {
            fnClick(data);
          }
          if (navigate && (navi as number) < 0 && store.get("stack").length <= 0) {
            navigate("/");
          } else if (navigate) {
            navigate(navi);
          }
        }
        // }
        return;
      }}
    >
      <div style={UiBook_Style.i_w as CSSProperties}>
        {cancelable && (
          <button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onCancel(e);
            }}
            style={{
              position: "absolute",
              top: -10,
              right: -10,
              color: "#fff",
              backgroundColor: "#aa0000",
              display: "flex",
              width: 25,
              height: 25,
              margin: 0,
              padding: 0,
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              borderRadius: "100%",
              border: "none",
            }}
          >
            <CloseSharp fontSize={"small"} />
          </button>
        )}
        <img
          ref={imgRef}
          onError={(e) => {
            e.currentTarget.src = intro_logo;
          }}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: 5,
            // width : "120px",
            // height : "160px",
            // objectFit : "cover",
            // objectPosition:"center",
            // objectFit:"contain"
            // border : "1px solid",
            // borderColor : style.common.borderColor4
          }}
          src={
            data["img"]
              ? constants.s3_url +
                // +
                // '/public'
                `/bookCover/${data["img"]}` +
                ".jpg"
              : data["id"]
                ? constants.s3_url +
                  `/bookCover/${data["id"]}` +
                  ".jpg?" +
                  util.getRandomInt(1000, 9999)
                : intro_logo
          }
        />
        {/* {
                isPw &&
                <Key  style={{
                    position:"absolute",
                    top : 3,
                    left : 3,
                    fontSize : "26px",
                    color : style.common.fontColor_3
                }}/>
                } */}
        {(Boolean(isOuth) || isOuth == 0) && <BookLabel bookData={data}></BookLabel>}
        {basicBook && <div className={styles.bookLabel}>기본</div>}
      </div>

      <div
        style={
          {
            ...UiBook_Style.text_1,
            // fontSize:"0.65rem"
          } as CSSProperties
        }
      >
        {data["name"]}
      </div>
      {/* {
            isShare ?
            <div style={UiBook_Style.text_2}>
                {data["cntClass"] ? `${data["cntClass"]}개 수업 공유중` : "0개 수업 공유중" }
            </div>
            :
            <div style={UiBook_Style.text_2}>
                {data["className"] ? `${data["className"]}` : ""}
            </div>
            } */}
      {/* {
            !isTeacher &&
            <div style={UiBook_Style.text_2}>
                {(data["teachers"] && data["teachers"].length > 0 ) && data["teachers"][0]["name"] +" 선생님"}
                {(data["teachers"] && data["teachers"].length > 1) && " 외 "+(data["teachers"].length-1)+"건"}
            </div>
            } */}
      {/* <div style={UiBook_Style.text_3}>
                미답변
                <div style={{
                    padding : "0px 3px",
                    color : style.common.fontColor_3
                }}>
                {data["cntLive"] ? `${data["cntLive"]}` : 0}
                </div>
                / 전체
                <div style={{
                    padding : "0px 3px",
                    color : style.common.fontColor_4
                }}>
                {data["cntTotal"] ? `${data["cntTotal"]}` : 0}
                </div>
            </div> */}
    </div>
  );
};

export default UiBook;
