import { useMutation, useQueryClient } from "@tanstack/react-query";
import { logoutUseCase } from "../usecase/logout";
import { useNavigate } from "react-router";

export const useLogout = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: () => {
      return logoutUseCase.execute();
    },
    onSuccess: () => {
      alert("로그아웃 되었습니다");
      queryClient.clear();
      window.localStorage.clear();
      navigate("/", { replace: true });
    },
  });
};
