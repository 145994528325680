import React from "react";

export function MarketingAndAdvertisingConsent(): JSX.Element {
  return (
    <>
      1. 개인정보 처리목적
      <br />본 서비스는 제공하는 서비스에 대한 홍보 및 마케팅, 광고에 활용하기 위해 회원 가입 시
      수집한 개인정보를 활용합니다.
      <br />
      <br />
      2. 수집하는 개인정보 항목
      <br />본 서비스는 회원 가입 시 다음과 같은 개인정보를 수집합니다.
      <br />
      <br />
      성명, 이메일 주소, 연락처, 생년월일, 성별, 거주 지역, 직업, 교육 수준, 관심 분야 등<br />
      <br />
      3. 개인정보 이용기간
      <br />본 서비스는 개인정보를 회원 가입 시 수집하며, 회원 탈퇴 요청 시까지 이용됩니다.
      <br />
      <br />
      4. 개인정보 제공
      <br />본 서비스는 수집한 개인정보를 법적으로 요구되는 경우를 제외하고는 제3자에게 제공하지
      않습니다.
      <br />
      <br />
      5. 개인정보의 광고 활용
      <br />본 서비스는 수집한 개인정보를 이용하여 회원이 관심을 가질만한 광고 및 마케팅 정보를
      제공합니다.
      <br />
      <br />
      6. 개인정보의 마케팅 활용
      <br />본 서비스는 수집한 개인정보를 이용하여 회원이 관심을 가질만한 마케팅 정보를 제공합니다.
      <br />
      <br />
      7. 개인정보 보호책임자
      <br />본 서비스의 개인정보 보호책임자는 다음과 같습니다.
      <br />
      <br />
      이름: 박주호 (CEO)
      <br />
      이메일: hiqbank@gmail.com
      <br />
      <br />
      8. 개인정보 보호정책 변경
      <br />본 서비스는 개인정보 보호정책을 변경할 경우, 그 내용과 적용일자를 본 홈페이지에서
      공지합니다. 이용자는 변경된 개인정보 보호정책에 대해 수시로 확인하여 줄 것을 권장합니다.
    </>
  );
}
