import styled from "styled-components";

export const ContentSection = styled.section`
  margin-top: ${({ theme }) => theme.spacing.lg};
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-bottom: ${({ theme }) => theme.spacing.md};
`;

export const TermsWrapper = styled.div`
  flex: 1;
`;

export const FlexBox = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing.sm};
`;

export const TermsDocsBox = styled.div`
  max-height: 300px;
  overflow: auto;
`;

export const ButtonWrapper = styled.div``;
