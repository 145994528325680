import React, { useState } from "react";
import styled from "styled-components";
import Typography from "@components/atoms/typography";
import { IcArrowDown } from "@components/atoms/icons/arrowDown";

export type AccordionProps = {
  title: string;
  children: React.ReactNode;
  leftElement?: React.ReactNode; // title 앞에 표시할 요소를 위한 Prop
  toggleElement?: React.ReactNode;
};

const AccordionContainer = styled.section`
  margin-bottom: ${({ theme }) => theme.spacing.sm};
  overflow: hidden;
`;

const AccordionHeader = styled.header`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing.sm};
  padding-top: ${({ theme }) => theme.spacing.sm};
  padding-bottom: ${({ theme }) => theme.spacing.sm};
`;

const LeftElementWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TitleWrapper = styled.div`
  flex: 1;
`;

const ToggleButton = styled.button`
  display: flex;
  align-items: center;
`;

const AccordionContent = styled.div`
  padding-top: ${({ theme }) => theme.spacing.sm};
  background-color: #fff;
`;

const Accordion: React.FC<AccordionProps> = ({ title, children, leftElement, toggleElement }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <AccordionContainer>
      <AccordionHeader>
        {leftElement && <LeftElementWrapper>{leftElement}</LeftElementWrapper>}
        <TitleWrapper>
          <Typography>{title}</Typography>
        </TitleWrapper>
        <ToggleButton onClick={handleToggleAccordion}>
          {toggleElement || <IcArrowDown />}
        </ToggleButton>
      </AccordionHeader>
      {isOpen && <AccordionContent id="accordion-content">{children}</AccordionContent>}
    </AccordionContainer>
  );
};

export default Accordion;
