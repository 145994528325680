import React from "react";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  max-width: 715px;
  display: flex;
  flex-direction: column;
  padding: 1em;
`;

type BaseLayoutProps = {
  children: React.ReactNode;
};

export function BaseLayout({ children }: BaseLayoutProps): JSX.Element {
  return <Container>{children}</Container>;
}
