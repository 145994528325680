import { React, useEffect, useMemo, useRef, useState } from "react";
import hi from "../../asset/Img/hi.png";
import intro_logo from "../../asset/Img/intro_logo.png";
import style from "../../asset/style";
import UiMessage_Style from "./UiMessage_Style";
import { useLongPress } from "use-long-press";
import { UiCheckBox } from "../UiCheckBox/UiCheckBox";
import util from "../../asset/util";
import constants from "../../asset/constants";
import {
  Image as IM,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Link,
  PeopleAlt,
  ThumbUp,
  Videocam,
} from "@material-ui/icons";
import { useParams } from "react-router";
import InfiniteScroll from "react-infinite-scroll-component";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { getUser } from "@asset/storage";

const UiMessage = ({
  tcnt,
  listStat,
  isDetail,
  setIsDetail,
  data = [],
  userInfo,
  selectData,
  isEdit = false,
  isTeacher = false,
  fnClick,
  fnLongClick,
  fnCheck,
  apiPro_counts,
  setScrolllKey,
  scrollKey,
  isLoading = false,
  isHide = false,
}) => {
  let params = useParams();
  // let data = [1,2,3,4,5]
  // let data = []

  const [isChk, setIsChk] = useState(true);
  // const [isChk,setIsChk] = useState(window.localStorage.getItem("msg_home") ? window.localStorage.getItem("msg_home") : true);
  const [parseData, setParseData] = useState([]);

  var [state, setState] = useState({
    items: Array.from({ length: 20 }),
  });

  var [hasLoad, setHasLoad] = useState(false);
  var [scrollHeight, setScrollHeight] = useState(window.innerHeight - 200);
  const scrollRef = useRef(null);

  useEffect(() => {
    // try {
    //     if(isChk){
    //         console.log("Call ? ? ? ?")
    //         apiPro_counts("Y",1,true);
    //     }
    //     else {
    //         console.log("Call ? ? ? ? @@  @@  @@  @")
    //         apiPro_counts("",1,true);
    //     }
    //     setHasLoad(false)
    // }
    // catch(e){
    //     console.log("UiMessage Chk Action Error => ",e)
    // }
    // // console.log("@# ",isChk)
    // if(!isChk){
    //     // let parse = []
    //     // data.map((v,i)=>{
    //     //     if(v["ans_date"]){
    //     //         parse.push(v)
    //     //     }
    //     // })
    //     setParseData([
    //         ...data
    //     ])
    // }
    // else {
    //     let parse = []
    //     data.map((v,i)=>{
    //         // // console.log(v)
    //         if(v["qid"] != 0 && !v["ans_date"]){
    //             parse.push(v)
    //         }
    //         // if(!v["ans_date"]){
    //         //     parse.push(v)
    //         // }
    //     })
    //     setParseData([
    //         ...parse
    //     ])
    // }
  }, [isChk]);
  useEffect(() => {
    if (window.localStorage.getItem("msg_home")) {
      setIsChk(JSON.parse(window.localStorage.getItem("msg_home")));
    }
  }, []);

  // let m_ref = useRef()
  // useEffect(()=>{
  //     console.log(m_ref.current)
  //     alert(document.getElementById("message_c").clientHeight)
  // },[])

  // useEffect(()=>{
  //    console.log("isHide : ",isHide)
  // },[isHide])

  // useEffect(()=>{
  //     console.log("data : ",data);
  // },[data])

  // useEffect(()=>{
  //     alert(window.innerHeight)
  //     alert(window.screen.height)
  // },[])

  // var height_ = window.screen.height - (62 + 61.5 + 100)
  // var height_ = window.innerHeight - (100 + 61 )

  useEffect(() => {
    window.addEventListener("resize", () => {
      // alert("height : ",window.innerHeight)
      setScrollHeight(window.innerHeight - 200);
    });
    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  return (
    <div
      style={{
        backgroundColor: "white",
        height: "100%",
        // paddingBottom:"50px"
        // marginTop:"50px",
      }}
      // ref={m_ref}
      id="message_c"
    >
      {data.length > 0 ? (
        // 1012 무한스크롤 관련 주석
        <div
          id="scrollableDiv"
          style={{
            height: scrollHeight,
            overflow: "auto",
          }}
          ref={scrollRef}
        >
          <InfiniteScroll
            onScroll={() => {
              setHasLoad(true);
            }}
            style={
              {
                // height:"500px",
              }
            }
            dataLength={data.length}
            next={() => {
              // console.log("this next : ")
              // return;
              // console.log("next isChk : ",isChk)
              // console.log("next isChk : ",JSON.parse(window.localStorage.getItem("msg_home")))
              if (window.localStorage.getItem("msg_home")) {
                if (JSON.parse(window.localStorage.getItem("msg_home"))) {
                  apiPro_counts("Y");
                } else {
                  apiPro_counts("");
                }
              } else {
                apiPro_counts("");
              }
            }}
            hasMore={hasLoad}
            loader={
              data.length > 0 &&
              data.length < tcnt && (
                // <></>
                // <>Loading....</>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    padding: "0px 0px 10px 0px",
                  }}
                >
                  <CircularProgress
                    style={{
                      fontSize: "12px",
                      color: params.who === "teacher" ? style.common.t_color : style.common.s_color,
                    }}
                  />
                </div>
              )
            }
            scrollableTarget="scrollableDiv"
          >
            {!isLoading &&
              data.map((v, index) => {
                // hide date 관련
                if (
                  (isHide && params["who"] === "teacher" && v["t_hide_date"]) ||
                  (params["who"] === "student" && v["s_hide_date"]) ||
                  (!isHide && params["who"] === "teacher" && !v["t_hide_date"]) ||
                  (params["who"] === "student" && !v["s_hide_date"])
                ) {
                  return (
                    <UiMessageSection
                      listStat={listStat}
                      isDetail={isDetail}
                      setIsDetail={setIsDetail}
                      key={index}
                      fnClick={fnClick}
                      selectData={selectData}
                      fnCheck={fnCheck}
                      index={index}
                      id={v["id"]}
                      data={v}
                      isEdit={isEdit}
                      isTeacher={isTeacher}
                      fnLongClick={fnLongClick}
                      setScrolllKey={setScrolllKey}
                      scrollKey={scrollKey}
                      parentScrollRef={scrollRef}
                    />
                  );
                }
              })}
          </InfiniteScroll>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: scrollHeight,
            fontSize: "14px",
            color: style.common.fontColor_1,
          }}
        >
          {/* 메세지가 비어있습니다. */}
        </div>
      )}
    </div>
  );
};

const UiMessageSection = ({
  id,
  index,
  data,
  selectData,
  isEdit,
  isTeacher,
  fnClick,
  fnLongClick,
  fnCheck,
  setScrolllKey,
  scrollKey,
  parentScrollRef,
}) => {
  let params = useParams();
  const userInfo = getUser();
  if (!userInfo) return null;
  var [isQ, setIsQ] = useState(false);

  const bind = useLongPress(
    () => {
      try {
        if (id == 0) return;
        fnLongClick(data);
      } catch (e) {
        // console.log("Long Press Error => ",e)
      }
    },
    {
      threshold: 2000,
    }
  );

  var scrollRef = useRef(null);

  const senderName = useMemo(() => {
    // if(data.wtype === 1 || data.wtype === true) {
    //     return data?.sid_HIQDB_user_credential?.user?.name
    // } else if(data.wtype === 2) {
    //     return data?.tid_HIQDB_user_credential?.user?.name
    // } else if (data.wtype === 4){
    //     return data?.sid_HIQDB_user_credential?.user?.name
    // } else {
    //     return '알 수 없음'
    // }
    if (userInfo?.type === 1) {
      return data?.tid_HIQDB_user_credential?.user?.nick;
    } else {
      return data?.sid_HIQDB_user_credential?.user?.nick;
    }
  }, [data, userInfo]);

  const senderNick = useMemo(() => {
    // if(data.wtype === 1 || data.wtype === true) {
    //     return data?.sid_HIQDB_user_credential?.user?.nick
    // } else if(data.wtype === 2) {
    //     return data?.tid_HIQDB_user_credential?.user?.nick
    // } else if (data.wtype === 4){
    //     return data?.sid_HIQDB_user_credential?.user?.nick
    // } else {
    //     return '알 수 없음'
    // }
    if (userInfo?.type === 1) {
      return data?.tid_HIQDB_user_credential?.user?.nick;
    } else {
      return data?.sid_HIQDB_user_credential?.user?.nick;
    }
  }, [data, userInfo]);

  useEffect(() => {
    if (scrollRef.current && parentScrollRef.current) {
      const messageBoxSize =
        scrollRef.current.parentElement.clientHeight +
        parseInt(scrollRef.current.parentElement.style.marginTop);
      const maxVisibleMessageBox =
        Math.round(parentScrollRef.current.clientHeight / messageBoxSize) / 2;
      if (
        parentScrollRef.current.scrollHeight - maxVisibleMessageBox * messageBoxSize >
        scrollRef.current.offsetTop
      ) {
        scrollRef.current.scrollIntoView({ block: "center" });
      } else {
        parentScrollRef.current.scrollTop = parentScrollRef.current.scrollHeight;
      }
    }
  }, [scrollRef]);

  const AnswerIconBox = () => {
    return (
      <div>
        {data?.answerType?.imgAnswer && (
          <IM
            style={{
              color: style.common.t_color,
              fontSize: "18px",
              marginRight: "2px",
            }}
          />
        )}
        {data?.answerType?.videoAnswer && (
          <Videocam
            style={{
              color: style.common.t_color,
              fontSize: "18px",
              marginRight: "3px",
            }}
          />
        )}
        {data?.answerType?.linkAnswer && (
          <Link
            style={{
              color: style.common.t_color,
              fontSize: "18px",
              marginRight: "3px",
            }}
          />
        )}
      </div>
    );
  };

  return (
    <div
      style={{
        marginTop: 10,
        padding: scrollKey === data["id"] ? "15px 10px" : "15px 10px 0px",
        // backgroundColor:"yellow",
        // maxWidth:"100vw",
        backgroundColor: scrollKey === data["id"] ? "rgba(203,234,241, 0.3)" : "transparent",
      }}
      id={`msg_list_c_${index}`}
    >
      <div
        style={UiMessage_Style.section_c}
        id={`msg_list_${index}`}
        {...bind()}
        ref={scrollKey === data["id"] ? scrollRef : null}
        onClick={() => {
          if (isEdit) return;
          fnClick(data);
        }}
      >
        {id != 0 && isEdit && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "10px",
            }}
          >
            <UiCheckBox
              className={isTeacher ? "t" : "s"}
              // value={"?"}
              // className={"message_?"}
              checked={
                // listStat == "d" ?
                // selectData[data["qid"]] == "Y" ? true : false
                // :
                selectData[id] === "Y"
              }
              value={id}
              // value={listStat == "d" ? data["qid"] : id}
              fnClick={fnCheck}
              containerStyle={{
                padding: "0px",
              }}
            />
          </div>
        )}
        <div
          style={{
            // width : '20%',
            height: "56px",
            width: "56px",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          {data.sid === "hiqsum_admin" || data.tid === "hiqsum_admin" ? (
            <img
              src={intro_logo}
              style={{
                width: "100%",
                height: "100%",
                border: "1px solid #f6f6f6",
                borderRadius: 10,
              }}
            />
          ) : data?.qid_HIQDB_online_qna?.["member_cnt"] > 2 && data["qid"] != 0 ? (
            <div
              style={{
                position: "relative",
                width: "46px",
                height: "46px",
                backgroundColor: data?.qid_HIQDB_online_qna?.["ans_date"] ? "#EFD9B4" : "#B9B4EF",
                fontFamily: "Noto Sans",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "22px",
                textAlign: "center",
                borderRadius: "15px",
                color: "#FFFFFF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {data?.qid_HIQDB_online_qna?.["ans_date"]
                ? !data["original"] || data["original"] == 0
                  ? "A"
                  : "A+"
                : "G"}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontSize: "11px",
                  color: style.common.fontColor_1,
                  marginTop: "6px",
                  position: "absolute",
                  top: "-10px",
                  left: "-2px",
                }}
              >
                {!isEdit && data["unreadCnt"] > 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "bold",
                      // padding : '2px 7px',
                      height: "16px",
                      width: "16px",
                      // backgroundColor : '#EFD9B4',
                      backgroundColor: style.common.fontColor_3,
                      borderRadius: 20,
                      color: "white",
                      fontSize: "10px",
                    }}
                  >
                    {data["unreadCnt"]}
                  </div>
                )}
              </div>
              <div
                style={
                  data?.qid_HIQDB_online_qna?.understand === "y"
                    ? {
                        position: "absolute",
                        right: -7,
                        top: -7,
                      }
                    : {
                        position: "absolute",
                        right: -15,
                        top: -10,
                      }
                }
              >
                {data?.qid_HIQDB_online_qna?.understand === "y" && (
                  <ThumbUp
                    style={{
                      // position :"absolute",
                      // top:0,
                      color: style.common.s_color,
                      fontSize: "18px",
                      marginRight: "2px",
                      // marginBottom:"10px"
                      paddingTop: "2px",
                    }}
                  />
                )}
                {senderName &&
                  senderNick &&
                  !senderName.includes("HiQSUM") &&
                  data["qid"] != 0 &&
                  (data?.qid_HIQDB_online_qna?.["member_cnt"] ? (
                    data?.qid_HIQDB_online_qna?.["member_cnt"] > 2 && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          // justifyContent:"flex-end",
                          fontSize: "12px",
                          color: style.common.t_color,
                          paddingRight: 5,
                          marginTop: data?.qid_HIQDB_online_qna?.understand === "y" ? "5px" : "3px",
                          // marginLeft:"10px",
                          // color : params["who"] == "teacher" ? style.common.t_color : style.common.s_color,
                        }}
                      >
                        <PeopleAlt
                          style={{
                            // paddingLeft:"5px",
                            // marginLeft : data["understand"] == "y" ? "8px" : "0px",
                            fontSize: "14px",
                          }}
                        />
                        <div
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          {data?.qid_HIQDB_online_qna?.member_cnt}
                        </div>
                      </div>
                    )
                  ) : (
                    <></>
                  ))}
              </div>
            </div>
          ) : (
            <div
              style={{
                position: "relative",
                width: "44px",
                height: "44px",
                backgroundColor:
                  data["qid"] && data.qid !== "0"
                    ? data?.qid_HIQDB_online_qna?.["ans_date"]
                      ? !data["original"] || data["original"] == 0
                        ? "#EFD9B4"
                        : "#EFD9B4"
                      : "#F09294"
                    : "#97D8D7",
                fontFamily: "Noto Sans",
                fontStyle: "normal",
                fontWeight: 500,
                fontSize: "16px",
                lineHeight: "22px",
                textAlign: "center",
                borderRadius: "15px",
                color: "#FFFFFF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {data.qid !== 0
                ? data?.qid_HIQDB_online_qna?.["ans_date"]
                  ? !data?.qid_HIQDB_online_qna?.["original"] ||
                    data?.qid_HIQDB_online_qna?.["original"] == 0
                    ? "A"
                    : "A+"
                  : // :
                    // <div style={{...UiMessage_Style.section_r_t_t_text_1,padding:0,color : style.common.fontColor_3}}>
                    //     {/* {data["wtype"] == 4 && "[질문]"} */}
                    //     [추가질문완료]
                    // </div>
                    !data?.qid_HIQDB_online_qna?.["original"] ||
                      data?.qid_HIQDB_online_qna?.["original"] == 0
                    ? "Q"
                    : "Q+"
                : "M"}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  fontSize: "11px",
                  color: style.common.fontColor_1,
                  marginTop: "6px",
                  position: "absolute",
                  top: "-10px",
                  left: "-2px",
                }}
              >
                {!isEdit && data["unreadCnt"] > 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontWeight: "bold",
                      // padding : '2px 7px',
                      height: "16px",
                      width: "16px",
                      // backgroundColor : '#EFD9B4',
                      backgroundColor: style.common.fontColor_3,
                      borderRadius: 20,
                      color: "white",
                      fontSize: "10px",
                    }}
                  >
                    {data["unreadCnt"]}
                  </div>
                )}
              </div>
              <div
                style={
                  data?.qid_HIQDB_online_qna?.understand === "y"
                    ? {
                        position: "absolute",
                        right: -7,
                        top: -7,
                      }
                    : {
                        position: "absolute",
                        right: -15,
                        top: -10,
                      }
                }
              >
                {data?.qid_HIQDB_online_qna?.understand === "y" && (
                  <ThumbUp
                    style={{
                      // position :"absolute",
                      // top:0,
                      color: style.common.s_color,
                      fontSize: "18px",
                      marginRight: "2px",
                      // marginBottom:"10px"
                      paddingTop: "2px",
                    }}
                  />
                )}
                {data["senderTitle"] &&
                  !data["senderTitle"].includes("HiQSUM") &&
                  data["qid"] != 0 &&
                  (data["member_cnt"] ? (
                    data["member_cnt"] > 2 && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "12px",
                          color: style.common.t_color,
                          paddingRight: 5,
                          // color : params["who"] == "teacher" ? style.common.t_color : style.common.s_color,
                        }}
                      >
                        <PeopleAlt
                          style={{
                            fontSize: "14px",
                          }}
                        />
                        <div
                          style={{
                            fontSize: "12px",
                          }}
                        >
                          {data["member_cnt"]}
                        </div>
                      </div>
                    )
                  ) : (
                    <></>
                  ))}
              </div>
            </div>
          )}
        </div>
        <div
          style={{
            ...UiMessage_Style.section_r_c,
            width: "calc(100% - 55px)",
          }}
        >
          <div
            style={{
              width: "75%",
              paddingRight: 5,
              paddingLeft: 5,
            }}
          >
            <div
              style={{
                ...UiMessage_Style.section_r_t_w,
              }}
            >
              <div
                style={{
                  ...UiMessage_Style.section_r_t_t_w,
                  fontSize: "16px",

                  // maxHeight:"1.4em",
                  // minHeight:"1.4em",
                }}
              >
                {/* {
                                data["qid"] ?
                                (
                                data["ans_date"] ?
                                (
                                    (!data["original"] ||
                                    data["original"] == 0) ?
                                    <div style={{...UiMessage_Style.section_r_t_t_text_1,padding:0,color : style.common.fontColor_3}}>
                                        [답변완료]
                                    </div>
                                    :
                                    <div style={{...UiMessage_Style.section_r_t_t_text_1,padding:0,color : style.common.fontColor_3}}>
                                        [추가질문완료]
                                    </div>
                                )
                                :
                                (
                                    (data["original"] == 0 || !data["original"] ) ?
                                    <div style={{...UiMessage_Style.section_r_t_t_text_1,
                                    }}>
                                        {
                                        data["member_cnt"] ?
                                            data["member_cnt"] == 1 ? "[질문]" : "[단체질문]"
                                            :
                                            "[질문]"
                                        }
                                    </div>
                                    :
                                    <div style={{...UiMessage_Style.section_r_t_t_text_1,
                                    }}>
                                        {
                                        data["member_cnt"] ?
                                            data["member_cnt"] == 1 ? "[추가질문]" : "[단체질문]"
                                            :
                                            "[추가질문]"
                                        }
                                    </div>
                                )
                                )
                                :
                                <></>
                                } */}

                {/* i) 1:1 질문방 - “질문자실명(문제출처)”

                                ii) 1:n 질문방- “질문자실명(문제출처)” - 밖에 인원수도 표시

                                iii) 모범풀이 등록으로 만든 1:n질문방 - “선생님실명(문제출처)” - 밖에 인원수도 표시됨 */}
                <div
                  style={{
                    ...UiMessage_Style.section_r_t_t_text_2,
                    fontSize: "14px",
                    marginTop: "2px",
                  }}
                >
                  {
                    // data?.qid_HIQDB_online_qna?.member_cnt > 2 ?
                    //     (
                    //         `${data.qid_HIQDB_online_qna?.tid_HIQDB_user_credential?.user?.name} 선생님의 단체방 `
                    //         // data["title"] ?
                    //         // `${data["title"] }`
                    //         // :
                    //         // `${ownerInfo["name"] ? ownerInfo["name"] : ""} 선생님의 단체 질문방`
                    //         // `${userInfo.type === 1 ? data?.tid_HIQDB_user_credential?.user?.name + `(${data?.tid_HIQDB_user_credential?.user?.nick})` + " 선생님" : ""} ${data?.qid_HIQDB_online_qna?.title ?? ''}`
                    //     )
                    //     :
                    data?.sid?.includes("hiqsum_admin") || data?.tid?.includes("hiqsum_admin")
                      ? `[HiQ 알림]`
                      : userInfo.type === 1
                        ? data?.tid_HIQDB_user_credential?.user?.name +
                          `(${data?.tid_HIQDB_user_credential?.user?.nick})` +
                          " 선생님"
                        : !data?.sid_HIQDB_user_credential?.user
                          ? "모범 등록"
                          : `${data?.sid_HIQDB_user_credential?.user?.name} 학생`
                  }
                  {data?.qid_HIQDB_online_qna?.ans_date && <AnswerIconBox />}
                </div>
              </div>
            </div>
            <div
              style={{
                ...UiMessage_Style.section_r_b_w,
                fontSize: "12px",
                marginTop: "2px",
                paddingBottom: "2px",
                maxWidth: "80%",
              }}
            >
              <div
                style={{
                  ...style.common.line_text,
                  lineHeight: "1.4em",
                  maxHeight: "1.4em",
                  minHeight: "1.4em",
                  WebkitBoxOrient: "horizontal",
                  fontSize: "12px",
                  width: "100%",
                  whiteSpace: "nowrap",
                  display: "inline-block",
                }}
              >
                {/* 문제를 풀어보려고 했는데 설명없이 하려니 ~~ */}
                {
                  data &&
                    // data["qid"] == 0 ?
                    // <div style={{
                    //     // ...style.common.line_text,
                    //     overflow:"hidden",
                    //     textOverflow:'ellipsis',
                    //     wordWrap:'break-word',
                    //     // width:"100%",
                    //     // maxWidth:"70vw"
                    // }}>
                    //     {data["content"].replace(/<br>/gi,"\n")}
                    // </div>
                    // util.textTrim(data["content"].replace(/<br>/gi,"\n"), 35)
                    // :
                    (data?.["content"]?.includes("&contentType") ? (
                      "새 질문이 등록되었습니다."
                    ) : data?.["content"]?.includes("ctype=0&") ? (
                      util.textTrim(
                        data?.["content"]?.replace(/<br>/gi, "\n").split("ctype=0&content=")[1],
                        35
                      )
                    ) : data?.["content"]?.includes("ctype=1&") ? (
                      <div style={{ display: "flex" }}>
                        <IM
                          style={{
                            color: style.common.t_color,
                            fontSize: "18px",
                            marginRight: "2px",
                          }}
                        />
                        {/* image.jpg */}
                        이미지
                      </div>
                    ) : data?.["content"]?.includes("ctype=2&") ? (
                      <div style={{ display: "flex" }}>
                        <Videocam
                          style={{
                            color: style.common.t_color,
                            fontSize: "18px",
                            marginRight: "3px",
                          }}
                        />
                        영상
                      </div>
                    ) : data?.["content"]?.includes("ctype=3&") ? (
                      <div style={{ display: "flex" }}>
                        <Videocam
                          style={{
                            color: style.common.t_color,
                            fontSize: "18px",
                            marginRight: "3px",
                          }}
                        />
                        {/* video.mp4 */}
                        영상
                      </div>
                    ) : data?.["content"]?.includes("ctype=4&") ? (
                      <div style={{ display: "flex" }}>
                        <Link
                          style={{
                            color: style.common.t_color,
                            fontSize: "18px",
                            marginRight: "3px",
                          }}
                        />
                        {/* video.mp4 */}
                        질문방 링크
                      </div>
                    ) : data?.["content"]?.includes("ctype=6&") ? (
                      <div style={{ display: "flex" }}>
                        {`${data?.["content"]?.split("=")[2]} 선생님께 질문이 이관되었어요.`}
                      </div>
                    ) : data?.["content"]?.includes("ctype=7&") ? (
                      <div style={{ display: "flex" }}>{`${data?.["content"]?.split("=")[2]}`}</div>
                    ) : data?.["content"]?.includes("ctype=8&") ? (
                      <div style={{ display: "flex" }}>
                        <Link
                          style={{
                            color: style.common.t_color,
                            fontSize: "18px",
                            marginRight: "3px",
                          }}
                        />
                        {/* video.mp4 */}
                        질문방 링크
                      </div>
                    ) : data?.["content"]?.includes("ctype=9&") ? (
                      <div style={{ display: "flex" }}>
                        {`${data?.["content"]?.split("=")[2]}님이 질문방에 참여하셨어요.`}
                      </div>
                    ) : data?.["content"]?.includes("ctype=10&") ? (
                      <div style={{ display: "flex" }}>답변이 완료되었습니다.</div>
                    ) : data?.["content"]?.includes("ctype=11&") ? (
                      <div style={{ display: "flex" }}>답변완료가 취소 되었어요.</div>
                    ) : (
                      data["content"]
                    ))
                  // util.textTrim(data?.["content"]?.replace(/<br>/gi,"\n"), 35)
                }
              </div>
            </div>
            {data?.qid_HIQDB_online_qna && (
              <div
                style={{
                  color: "#dadada",
                  fontWeight: 500,
                  fontSize: 10,
                  width: "100%",
                  alignSelf: "center",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                (출처 :{" "}
                {(data?.qid_HIQDB_online_qna?.title ?? data.isVideoQuestion)
                  ? `${data?.qid_HIQDB_online_qna?.content.split(".")[0]}-${parseInt(Number(data?.qid_HIQDB_online_qna?.content.split(".")[1]) / 60)}:${parseInt(Number(data?.qid_HIQDB_online_qna?.content.split(".")[1]) % 60)}`
                  : data?.qid_HIQDB_online_qna?.content}
                )
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "flex-end",
              minWidth: "60px",
            }}
          >
            {!isEdit && (
              <div style={UiMessage_Style.section_r_t_t_text_3}>
                {/* 15:34 */}
                {moment.unix(data.reg_date).format("YY/MM/DD")}
              </div>
            )}
            {data["qid"] != 0 && !data.isVideoQuestion && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  //  ...style.common.line_text,
                  // lineHeight:"1.4em",
                  // maxHeight:"1.4em",
                  // minHeight:"1.4em",
                  //  WebkitBoxOrient:'horizontal',
                  fontSize: "10px",
                  marginBottom: "5px",
                  color: style.common.fontColor_7,
                }}
                onClick={(e) => {
                  setIsQ(!isQ);
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                {isQ ? (
                  <>
                    문제닫기
                    <KeyboardArrowUp
                      style={{
                        fontSize: "13px",
                        color:
                          params["who"] == "teacher" ? style.common.t_color : style.common.s_color,
                      }}
                    />
                  </>
                ) : (
                  <>
                    문제보기
                    <KeyboardArrowDown
                      style={{
                        fontSize: "13px",
                        color:
                          params["who"] == "teacher" ? style.common.t_color : style.common.s_color,
                      }}
                    />
                  </>
                )}
              </div>
            )}
            {data.isVideoQuestion && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  //  ...style.common.line_text,
                  // lineHeight:"1.4em",
                  // maxHeight:"1.4em",
                  // minHeight:"1.4em",
                  //  WebkitBoxOrient:'horizontal',
                  fontSize: "10px",
                  marginBottom: "5px",
                  color: style.common.fontColor_7,
                }}
              >
                <Videocam />
                영상 질문
              </div>
            )}
            {/* <div style={UiMessage_Style.section_r_m_w}>

                            {
                            !isEdit &&
                            data["unreadCnt"] > 0 &&
                            <div style={UiMessage_Style.section_r_m_r_w}>
                                {data["unreadCnt"]}
                            </div>
                            }
                        </div> */}
          </div>
        </div>
      </div>
      {isQ && (
        <div
          style={{
            border: "2px solid",
            borderColor: params["who"] === "teacher" ? style.common.t_color : style.common.s_color,
            borderRadius: 20,
            padding: "8px 10px 4px 10px",
          }}
        >
          <img
            className={data?.qid_HIQDB_online_qna?.mosaic === "y" ? "mosaic" : null}
            style={{
              height: "100%",
              width: "100%",
              borderRadius: 15,
            }}
            onError={(e) => {
              e.currentTarget.src = hi;
            }}
            src={
              data?.qid_HIQDB_online_qna?.original !== 0
                ? `${constants.s3_url}/qna/${data?.qid_HIQDB_online_qna?.original_img}.jpg`
                : `${constants.s3_url}/qna/${data?.qid_HIQDB_online_qna?.img}.jpg`
            }
          />
        </div>
      )}
    </div>
  );
};

export default UiMessage;
