import { IAccessRule } from "../interface/academyEnter.interface";
import { ICheckAcademyInfo } from "../interface/checkAcademyInfo.interface";

export class MinimumLectureCountRule implements IAccessRule {
  constructor() {}

  check(academy: ICheckAcademyInfo) {
    return academy.lectureStatuses.length >= academy.rules.minimumLectureCount;
  }
}
