import { useMutation } from "@tanstack/react-query";
import { setLecture } from "@api/lecture/index";
import { LectureJoinResponseDTO } from "@api/lecture/types";

export const useJoinLecture = () => {
  return useMutation({
    mutationFn: async (lectureId: number): Promise<LectureJoinResponseDTO> => {
      return await setLecture(lectureId);
    },
  });
};
