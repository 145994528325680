import { useMutation, useQuery } from "@tanstack/react-query";
import { IAcademyInfo } from "@entities";
import { AcademyQueryKeys } from "@application/academy/queryKeys";
import { academyUseCase } from "@application/academy";

export const useGetAcademyInfo = (academyCode: number | string) => {
  return useQuery<IAcademyInfo>({
    queryKey: [AcademyQueryKeys.GET_ACADEMY_INFO, academyCode],
    queryFn: () => {
      return academyUseCase.getAcademyInfo(academyCode);
    },
    enabled: false,
    useErrorBoundary: false,
    suspense: false,
  });
};

export const useSetAcademyRequest = () => {
  return useMutation({
    mutationFn: (academyCode: number | string) => {
      return academyUseCase.setAcademyRequest(academyCode);
    },
  });
};
