import { Favorite, FavoriteBorder, LinkOutlined, Visibility } from "@material-ui/icons";
import { React, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import face_teacher from "../../../asset/Img/defaultpic_teacher.png";
import style from "../../../asset/style";

import noBook from "../../../asset/Img/book1.png";
import apiFn from "../../../asset/apiClass";
import constants from "../../../asset/constants";
import util from "../../../asset/util";
import Basic from "../../../common/frame/Basic";
import UiBtn from "../../../uiComponents/UiBtn/UiBtn";
import UiEditor from "../../../uiComponents/UiEditor/UiEditor";
import UiReactPlayer from "../../../uiComponents/UiReactPlayer/UiReactPlayer";
import UiTextBtn from "../../../uiComponents/UiTextBtn/UiTextBtn";
import Column_pick_Style from "../Column_pick_Style";
// import '../Column_detail.css'
import { Reply } from "./components/Reply";

import styles from "./style.module.scss";
import { getUser } from "../../../asset/storage";

const Index = ({ setHiddenNav, setFileObj, fileObj, qnaData, setQnaData }) => {
  let navigate = useNavigate();
  let params = useParams();
  const userInfo = getUser();

  const [data, setData] = useState({});
  const [isPick, setIsPick] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);

  const [isImg, setIsImg] = useState(true);
  const [isWarn, setIsWarn] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  // 찜 제거
  const apiPro_del_pick = async () => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "folder_link",
          del: [
            {
              boid: params["id"],
              mid: userInfo["id"],
            },
          ],
        },
      });
      await apiPro_read();
    } catch (e) {}
  };

  // 제거 제거
  const apiPro_del_favorite = async () => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "board_log",
          del: [
            {
              bid: params["id"],
              btype: 2,
              wid: userInfo["id"],
            },
          ],
          delAll: true,
        },
      });

      setIsFavorite(false);
      await apiPro_read();
    } catch (e) {}
  };

  // 좋아요
  const apiPro_good = async () => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "board_log",
          ins: [
            {
              wid: userInfo["id"],
              bid: params["id"],
              btype: 2,
            },
          ],
        },
      });

      // console.log(isOk)
      await apiPro_read();
    } catch (e) {}
  };

  // 신고하기 읽기
  const apiPro_warn_read = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "board_log",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            "HIQDB_board_log.wid": `='${userInfo["id"]}'`,
            "HIQDB_board_log.bid": `='${params["id"]}'`,
            "HIQDB_board_log.btype": `='3'`,
          },
        },
      });
      // console.log("@@ ",data);
      if (data["list"].length > 0) {
        setIsWarn(true);
      } else {
        setIsWarn(false);
      }
    } catch (e) {}
  };

  const apiPro_warn_del = async () => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "board_log",
          del: [
            {
              wid: userInfo["id"],
              bid: params["id"],
              btype: 3,
            },
          ],
        },
      });
      // console.log("@@ ",isOk)
      await apiPro_warn_read();
    } catch (e) {}
  };

  const apiPro_read = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          files: "y",
          read: {
            wid: userInfo["id"],
            bid: params["id"],
          },
          table: "board",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          ref: [
            "HIQDB_board_cat.name|HIQDB_board_cat.id=HIQDB_board.cat_id",
            "HIQDB_class.name as cname|HIQDB_board.cid=HIQDB_class.id",
            `HIQDB_member_class.type as type|HIQDB_member_class.cid=HIQDB_class.id and HIQDB_member_class.mid = '${userInfo.id}'`,
          ],
          where: {
            "HIQDB_board.id": `='${params["id"]}'`,
          },
          count: [`HIQDB_board_reply.boid:boid:id|and countTable.boid=\'${params["id"]}\'|rcnt`],
        },
      });

      let isChk = await apiFn.comApi({
        state: {
          table: "folder_link",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            "HIQDB_folder_link.boid": `='${params["id"]}'`,
            "HIQDB_folder_link.mid": `='${userInfo["id"]}'`,
          },
        },
      });
      let isChk2 = await apiFn.comApi({
        state: {
          table: "board_log",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            "HIQDB_board_log.bid": `='${params["id"]}'`,
            "HIQDB_board_log.wid": `='${userInfo["id"]}'`,
            "HIQDB_board_log.btype": `='2'`,
          },
        },
      });

      apiPro_warn_read();

      if (isChk["list"].length > 0) {
        setIsPick(true);
      } else {
        setIsPick(false);
      }

      if (isChk2["list"].length > 0) {
        setIsFavorite(true);
      } else {
        setIsFavorite(false);
      }

      setIsImg(true);
      setData({
        ...data["list"][0],
      });

      console.log("DATA => ", data["list"][0]);
    } catch (e) {
      // console.log("@ ",e);
    }
  };

  const apiPro_del = async () => {
    try {
      // let isOk = await apiFn.comApi({
      //     state : {
      //         table : 'board',
      //         del : [{
      //             id : params["id"]
      //         }]
      //     }
      // })
      let isOk = await apiFn.deleteBoard({
        state: {
          bid: params["id"],
          orderMid: userInfo["id"],
        },
      });
      alert("삭제했습니다.");
      // navigate(`/lecture/detail/${params["who"]}/${params["type"]}/de`)
      navigate(-1);
    } catch (e) {
      alert("삭제 중 오류가 발생했습니다");
    }
  };

  useEffect(() => {
    apiPro_read();
    if (params["type"] != "de") {
      setHiddenNav("column");
    }
  }, []);

  useEffect(() => {
    if (window.ReactNativeWebView) {
      if (params["type"].includes("full")) {
        window.ReactNativeWebView.postMessage("isVideo_on");
      } else {
        window.ReactNativeWebView.postMessage("isVideo_off");
      }
    }
  }, [params]);

  // const [isFull,setIsFull] = useState(false)

  let imgRef = useRef(null);

  return (
    <div
      style={{
        position: "relative",
        flex: 1,
        maxWidth: style.common.maxWidth,
      }}
    >
      {/* {
        isFull &&
        <div style={{
            position:"fixed",
            top: 0,
            width:"1000px",
            height:"70px",
            // backgroundColor : "red",
            // zIndex:2147483647
        }}>

        </div>
        } */}
      <Basic
        containerStyle={{ overflow: "hidden", maxHeight: "100vh" }}
        route={
          params["type"] == "act"
            ? -1
            : // `/profile/${params["who"]}/${params["type"]}/de`
              -1
        }
        title={
          <div
            style={{
              alignItems: "center",
              fontSize: "20px",
              color: style.common.fontColor_5,
              fontWeight: 700,
            }}
          >
            {/* 발렌타인오발렌타인오발렌타인오발렌타인오발렌타인오발렌타인오발렌타인오발렌타인오발렌45 */}
            {data["title"]}
          </div>
        }
        isBookMark={true}
        isBookMarkPick={isPick}
        fnBookMark={() => {
          if (isPick) {
            let isOk = window.confirm("찜 취소를 하시겠어요?");
            if (isOk) {
              apiPro_del_pick();
            }
          } else {
            navigate(`/picklist/${params["who"]}/${params["id"]}`);
          }
        }}
      >
        {data["cat_id"] && data["cat_id"] != 158 && (
          <div
            style={{
              maxHeight: "50vh",
              maxWidth: "98vw",
              minHeight: "25vh",
            }}
          >
            <UiReactPlayer
              qnaData={qnaData}
              setQnaData={setQnaData}
              videoStyle={{
                maxWidth: params["type"] == "full" ? "100vw" : "92vw",
              }}
              isPlayer={data["video_link"] ? true : false}
              isFull={params["type"] == "full" ? true : false}
              data={{
                ...data,
                video_link: data.video_link ?? `${constants.video_bucket}/board/${data?.id}.mp4`,
              }}
              userInfo={userInfo}
              setFileObj={setFileObj}
              fileObj={fileObj}
            />
          </div>
        )}
        <div
          style={{
            display: "flex",
            // flex:1,
            flexDirection: "column",
            minHeight: "80px",
            borderBottom: "1px solid",
            borderColor: style.common.borderColor4,
          }}
        >
          <div
            style={{
              padding: "0px 0px 0px 0px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  color: style.common.fontColor_6,
                  display: "flex",
                  alignItems: "center",
                  fontSize: "12px",
                }}
              >
                {isFavorite ? (
                  <Favorite
                    style={{
                      color:
                        params["who"] == "teacher" ? style.common.t_color : style.common.s_color,
                    }}
                    onClick={apiPro_del_favorite}
                  />
                ) : (
                  <FavoriteBorder onClick={apiPro_good} />
                )}

                <div
                  style={{
                    color: style.common.fontColor_3,
                    margin: "0px 10px 0 4px",
                  }}
                >
                  {data.likeCnt}
                </div>
                <Visibility style={{ marginRight: 4 }} />
                {`${data.readCnt}`}
              </div>

              <Link
                to={`/lecture/detail/${userInfo.type === 1 ? "student" : "teacher"}/${data.cid}/de`}
                style={{
                  color: style.common.fontColor_1,
                  fontSize: "14px",
                  fontWeight: 500,
                }}
              >
                {/* 입시컬럼 */}
                {/*{data["name"]}*/}
                {data.cname} 수업
              </Link>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "20px 0px 10px 0px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  color: style.common.fontColor_6,
                  display: "flex",
                  fontSize: "12px",
                  alignItems: "center",
                }}
                onClick={() => {
                  navigate(`/myinfo/${params["who"]}/${data["mid"]}`);
                }}
              >
                <img
                  onError={(e) => {
                    e.currentTarget.src = face_teacher;
                  }}
                  src={
                    data["mid"] &&
                    `${constants.s3_url}/teacher/${data["mid"].normalize(
                      "NFD"
                    )}.jpg?${util.getRandomInt(1000, 9999)}`
                  }
                  style={{
                    width: "26px",
                    height: "26px",
                    borderRadius: 6,
                    marginRight: "5px",
                  }}
                />
                {data["writer"] && data["writer"]["name"]} {" 선생님 "}
              </div>

              <div
                style={{
                  color: style.common.fontColor_6,
                  display: "flex",
                  alignItems: "center",
                  fontSize: "12px",
                }}
              >
                <button
                  className={styles.LinkButton}
                  onClick={async () => {
                    await util.copyClip(
                      `${constants.redirect_url}columndetail/student/${params.id}/lecture_video`
                    );
                    alert("강의 영상 주소가 클립보드에 복사 됐습니다");
                  }}
                >
                  <LinkOutlined fontSize={"small"} />
                </button>
                {/* 2022.02.14 */}
                {data.reg_date && util.convertToDateTime_form2(data.reg_date)}
              </div>
            </div>

            {!showDetail && (
              <UiTextBtn
                btnStyle={{
                  display: "flex",
                  fontSize: "12px",
                }}
                text={"상세 설명 보기"}
                fnClick={() => setShowDetail(true)}
              />
            )}
          </div>
        </div>
        <div
          style={{
            padding: "10px 0px",
            maxHeight: "calc(50vh - 52px - 62px)",
            overflow: "scroll",
          }}
        >
          {
            // (data["cat_id"] != 158 ) &&
            <div
              style={{
                position: "relative",
              }}
            >
              {isImg && data["id"] && (
                <img
                  // alt={noBook}
                  className={data["mosaic"] == "y" ? "mosaic" : null}
                  ref={imgRef}
                  style={{
                    maxWidth: "100%",
                  }}
                  onLoad={(e) => {}}
                  onError={(e) => {
                    // console.log("IMG ERROr => ",e);
                    e.currentTarget.src = noBook;
                    setIsImg(false);
                  }}
                  src={
                    data["id"]
                      ? data["cat_id"] != 158
                        ? constants.apiUrl +
                          // '/public'
                          // +
                          `/board/${data["id"]}/pic` +
                          ".jpg?" +
                          util.getRandomInt(1000, 9999)
                        : constants.apiUrl +
                          // '/public'
                          // +
                          `/board/${data["id"]}_t` +
                          ".jpg?" +
                          util.getRandomInt(1000, 9999)
                      : noBook
                  }
                />
              )}
            </div>
          }

          {showDetail && (
            <div>
              {/* UIEDIT COMPONENT..... */}
              <div
                style={{
                  padding: isImg && "10px 0px",
                }}
                className={"editor_"}
              >
                <UiEditor readOnly={true} initValue={data["content"]} />
              </div>
              <div
                style={{
                  padding: "5px 0px 55px 0px",
                }}
              >
                {data["files"] &&
                  data["files"].map((v, i) => (
                    <div
                      style={{
                        ...Column_pick_Style.t_btn_w,
                        minWidth: "180px",
                      }}
                    >
                      <UiBtn
                        // isFile={true}
                        btnStyle={{
                          ...Column_pick_Style.t_btn,
                          padding: "18px 0px",
                          backgroundColor: style.common.t_color,
                          color: "white",
                          width: "auto",
                        }}
                        title={
                          <div
                            style={{
                              width: "100%",
                              ...Column_pick_Style.t_btn_text,
                              // marginLeft:"15px",
                              padding: "0px 12px 0px 10px",
                            }}
                          >
                            <a
                              style={{
                                color: "white",
                              }}
                              download
                              // href={"http://s3_file?filename="+v.origin+"?filepath="+getS3Folder()+'/board/'+v.bid+'/'+v.name}
                              href={
                                "http://s3_file?filename=" +
                                v["origin"] +
                                "?filepath=" +
                                constants.apiUrl +
                                "/" +
                                v["name"].normalize("NFD")
                              }
                              // href={constants.apiUrl+"/"+util.getS3Folder()+'/board/'+v["bid"]+'/'+v["name"]}
                            >
                              {util.textTrim(v["origin"], 35)}
                            </a>
                          </div>
                        }
                        fnClick={() => {}}
                      />
                    </div>
                  ))}
              </div>

              <UiTextBtn
                btnStyle={{
                  display: "flex",
                  fontSize: "12px",
                }}
                text={"접기"}
                fnClick={() => setShowDetail(false)}
              />
            </div>
          )}

          <Reply userInfo={userInfo} bid={params.id} />
        </div>

        <div
          style={{
            position: "absolute",
            bottom: 0,
            // flex:1,
            display: "flex",
            width: "calc(100% - 20px)",
            height: "50px",
            borderTop: "1px solid",
            alignItems: "center",
            justifyContent: "space-between",
            borderColor: style.common.borderColor4,
            backgroundColor: "white",
            // backgroundColor:'green',
          }}
        >
          <div
            style={{
              display: "flex",
              // backgroundColor:'red',
            }}
          >
            {data.mid !== userInfo.id && data.type !== 5 && (
              <UiTextBtn
                text={isWarn ? "신고취소" : "신고하기"}
                isUnderline={false}
                btnStyle={{
                  fontSize: "12px",
                  color: style.common.fontColor_3,
                  marginRight: "10px",
                }}
                fnClick={() => {
                  if (isWarn) {
                    alert("신고를 철회하시겠어요?");
                    apiPro_warn_del();
                  } else {
                    navigate("/warn/" + params["who"] + "/" + params["id"] + "/write");
                  }
                }}
              />
            )}
            {(data.mid === userInfo.id || data.type === 5) && (
              <>
                <UiTextBtn
                  text={"삭제하기"}
                  isUnderline={false}
                  btnStyle={{
                    fontSize: "12px",
                    color: style.common.fontColor_3,
                    marginRight: "10px",
                  }}
                  fnClick={() => {
                    let isOk = window.confirm("글을 삭제하시겠어요?");
                    if (isOk) {
                      apiPro_del();
                    }
                  }}
                />
                <UiTextBtn
                  text={"수정하기"}
                  isUnderline={false}
                  btnStyle={{
                    fontSize: "12px",
                    color: style.common.fontColor_1,
                    marginLeft: "10px",
                  }}
                  fnClick={() => {
                    // navigate(`/write/${params["who"]}/${data["cat_id"]}/0/0/y&${params["id"]}&${params["type"]}`)
                    navigate(`/class/${data.cid}/video/edit/${data.id}`);
                    // if(params["type"] == "de"){
                    //     navigate(`/write/${params["who"]}/${data["cat_id"]}/0/0/y&${params["id"]}`)
                    // }
                    // else{
                    //     navigate(`/write/${params["who"]}/${data["cat_id"]}/0/0/y&${params["id"]}&${params["type"]}`)
                    // }
                  }}
                />
              </>
            )}
          </div>
        </div>
      </Basic>
    </div>
  );
};

export default Index;
