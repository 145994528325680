import { axiosInstance } from "./common";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useRecoilValue } from "recoil";
import { questionContentAtom } from "@application/recoils/question/atoms";

const saveSemohaeAnswer = async ({ questionId, ownerId, semohaeAnswer }) => {
  const response = await axiosInstance.post("/semohae/semohaeAnwer", {
    questionId,
    ownerId,
    semohaeAnswer,
  });
  return response.data;
};

export const useSaveSemohaeAnswer = () => {
  const queryClient = useQueryClient();

  return useMutation(saveSemohaeAnswer, {
    onSuccess: () => {
      queryClient.invalidateQueries(["questions"]);
    },
  });
};

const getSemohaeAnswer = async (query: string) => {
  const response = await axios.post(
    "https://damohaelabs-cmfxdxducyfwa7g6.koreacentral-01.azurewebsites.net/api/v1/chat/semohae",
    {
      query,
    }
  );
  return response.data;
};

export const useGetSemohaeAnswer = () => {
  const questionContent = useRecoilValue(questionContentAtom);
  return useMutation({
    mutationFn: async () => {
      return await getSemohaeAnswer(questionContent);
    },
  });
};
