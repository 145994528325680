import { axiosInstance } from "@api/common";
import { LectureApiEndpoints } from "@api/constants";
import {
  LectureJoinResponseDTO,
  LectureSearchRequestDTO,
  LectureSearchResponseDTO,
} from "@api/lecture/types";
import { pathToUrl } from "@helpers/url";

export const getLectureSearchList = async ({
  page,
  limit,
  searchText,
}: LectureSearchRequestDTO): Promise<LectureSearchResponseDTO> => {
  const response = await axiosInstance.get(LectureApiEndpoints.GET_LECTURE_SEARCH_LIST, {
    params: {
      page,
      limit,
      searchText,
    },
  });
  return response.data;
};

export const setLecture = async (lectureId: number): Promise<LectureJoinResponseDTO> => {
  const url = pathToUrl(LectureApiEndpoints.SET_LECTURE, {
    lectureId: lectureId.toString(),
  });
  const response = await axiosInstance.post(
    url,
    {},
    {
      withCredentials: true,
    }
  );
  return response.data;
};
