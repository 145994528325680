import { uploadVideoFileToStorage } from "api/fileUpload";
import { React, useEffect, useState } from "react";
import ReactPlayer from "react-player/lazy";
import { useNavigate, useParams } from "react-router";
import add_link from "../../asset/Img/add_link.png";
import add_movie from "../../asset/Img/add_movie.png";
import apiFn from "../../asset/apiClass";
import store from "../../asset/store";
import style from "../../asset/style";
import util from "../../asset/util";
import Basic from "../../common/frame/Basic";
import UiBtn from "../../uiComponents/UiBtn/UiBtn";
import { UiInput } from "../../uiComponents/UiInput/UiInput";
import UiLoading from "../../uiComponents/UiLoading/UiLoading";
import UiTime from "../../uiComponents/UiTime/UiTime";

const Video = ({ userInfo, setFileObj, fileObj, boardObj, setBoardObj, hiddenUrl }) => {
  let params = useParams();
  let navigate = useNavigate();

  const [isTeacher, setIsTeacher] = useState(true);

  const [linkText, setLinkText] = useState("");
  const [link, setLink] = useState("");
  const [preview, setPreview] = useState(null);

  var [start, setStart] = useState("0:00");
  var [end, setEnd] = useState("0:00");

  var [isLoading, setIsLoading] = useState(false);

  var apiPro_send_qna = async (content) => {
    try {
      if (params["type"].split("&")[1] == 0) {
        await store.axiosPost({
          cat: "hiq",
          cmd: "messageSet",

          wtype: params["who"] == "student" ? 1 : 2,
          ctype: 2, // 1 | 10 not source, 11 source
          content: content,
          qid: params["type"].split("&")[1],
          sid: fileObj["sid"],
          tid: fileObj["tid"],
        });
        // setFileObj({});
      } else {
        await apiFn.onlineQnaMsgSet({
          state: {
            wtype: params["who"] == "student" ? 1 : 2,
            wid: userInfo["id"],
            qid: params["type"].split("&")[1],
            ctype: 2, // 1 | 10 not source, 11 source
            content: content,
            // play_time : time ? Math.round(time["duration"]) : null,
            // file_size : time ? time["size"] : null
          },
        });
      }
      const stack = store.get("stack");
      if (stack.length > 0) {
        navigate(-1);
      } else {
        navigate("/");
      }
    } catch (e) {
      // setIsLoading(false);
      // setIsAttach(false)
      // console.log("error >",e )
    }
  };

  const fileOnChange = (e, key) => {
    // // console.log("222 ",e);
    try {
      let reader = new FileReader();
      let file = e.target.files[0];

      // video 시간 관련 코드
      var myVideos = [];
      window.URL = window.URL || window.webkitURL;
      myVideos.push(file);
      var video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        var duration = video.duration;
        myVideos[myVideos.length - 1].duration = duration;
      };
      video.src = URL.createObjectURL(file);
      setFileObj({
        ...fileObj,
        isVideo: true,
        videoFile: file,
        myVideos: myVideos[0],
        duration: myVideos[myVideos.length - 1].duration,
      });
      // navigate(-1)
      setTimeout(() => {
        navigate(`/video/${params["who"]}/exlocal`);
      }, 500);

      // reader.onloadend = (e) => {
      //     reader.readAsDataURL(file)
      // }

      // reader.onerror = (e) =>{
      // }
    } catch (e) {
      // console.log("File on Change Error= > ",e);
    }
  };

  var apiPro_send_qna_local = async (
    ctype,
    base64,
    videoFile,
    isOri,
    time,
    qid,
    video_start,
    video_end
  ) => {
    try {
      // console.log("send Qna Data => ",ctype,base64,videoFile,isOri,time)
      // console.log(video_start)
      // console.log(video_end)
      // console.log("params => ",params)
      // console.log(talk)
      // return;
      if (isOri) {
        if (ctype == 8) {
          apiFn.onlineQnaMsgSet({
            state: {
              wtype: params["who"] == "student" ? 1 : 2,
              wid: userInfo["id"],
              qid: oriQnaRoom,
              ctype: ctype, // 1 | 10 not source, 11 source
              content: qid,
              // ori_id : ori_id
            },
          });
        } else {
          let ori_id = `${isOri["room"]}&${isOri["msg_id"]}`;
          apiFn.onlineQnaMsgSet({
            state: {
              wtype: params["who"] == "student" ? 1 : 2,
              wid: userInfo["id"],
              qid: oriQnaRoom,
              ctype: ctype, // 1 | 10 not source, 11 source
              content: isOri["content"],
              ori_id: ori_id,
            },
          });
        }
        // setIsLoading(false);
        navigate(`/message/${params["who"]}/home`);
        navigate(`/talk/${params["who"]}/0/${oriQnaRoom}/${params["sid"]}/${params["tid"]}`);
        return;
      } else {
        var name_add = "";
        let name = "";
        let contentName = "";
        if (ctype == 1) {
          // let name = `/qna/${qid}/${isOk["id"]}.jpg`
          name_add =
            new Date().getTime() + "_" + util.getRandomInt(100, 999) + "_" + videoFile.name;
          name = `/qna/${qid}/${name_add}`;
          // contentName =
          store.putBucket(base64, name, false, false);
        }
        if (ctype == 2) {
          name_add = talk;
        }
        if (ctype == 3) {
          // let name = `qna/${qid}/${isOk["id"]}.mp4`
          // // console.log("### ",videoFile);
          // // console.log("### ",videoFile);
          // // console.log("### ",videoFile);
          // name_add =  new Date().getTime()+'_'+util.getRandomInt(100, 999)+'_'+util.replace_mp4(videoFile.name);

          name_add =
            new Date().getTime() + "_" + util.getRandomInt(100, 999) + "_" + videoFile.name;
          if (fileObj["qid"] == 0) {
            name = `general/${fileObj["tid"]}___${fileObj["sid"]}/${name_add}`;
          } else {
            name = `qna/${qid}/${name_add}`;
          }
          console.log("name => ", name);
          // util.putVideo(videoFile, {}, "", name);

          const bucketName = !constants.isDev ? "hiqsum-video" : "hiqsum-test-video";
          uploadVideoFileToStorage({
            bucketName,
            file: videoFile,
            fileLocation: name,
          });
        }
        // // console.log("time => ",time["duration"])
        // // console.log("st => ",video_start)
        // // console.log("end => ",video_end)
        if (video_end == 0) {
          if (video_start >= time["duration"]) {
            alert("시작시간이 종료시간을 넘을 수 없어요.");
            setIsLoading(false);
            return;
          }
        } else {
          if (video_start >= video_end) {
            alert("시작시간이 종료시간을 넘을 수 없어요.");
            setIsLoading(false);
            return;
          }
        }

        setIsLoading(false);

        // // console.log("time => ",time["size"])
        if (fileObj["qid"] == 0) {
          await store.axiosPost({
            cat: "hiq",
            cmd: "messageSet",
            // wtype : userInfo["type"] == 1 ? 1 : 2,
            wtype: params["who"] == "student" ? 1 : 2,
            sid: fileObj["sid"],
            tid: fileObj["tid"],
            content: name_add,
            ctype: ctype,
            play_time: time ? Math.round(time["duration"]) : null,
            file_size: time ? time["size"] : null,
            video_start: video_start,
            video_end: video_end,
          });
        } else {
          await apiFn.onlineQnaMsgSet({
            state: {
              wtype: params["who"] == "student" ? 1 : 2,
              wid: userInfo["id"],
              qid: qid,
              ctype: ctype, // 1 | 10 not source, 11 source
              content: name_add,
              play_time: time ? Math.round(time["duration"]) : null,
              file_size: time ? time["size"] : null,
              video_start: video_start,
              video_end: video_end,
            },
          });
        }
        // setFileObj({});
        setIsLoading(false);
        // navigate(hiddenUrl)

        const stack = store.get("stack");
        if (stack.length > 0) navigate(-1);
        else navigate("/");
        // await apiPro_read();
        // setIsLoading(false);
        // setIsAttach(false)
      }
    } catch (e) {
      setIsLoading(false);
      // setIsLoading(false);
      // setIsAttach(false)

      // await apiFn.comApi({
      //     state : {
      //         table : "warn",
      //         ins : [{
      //             content : "type VVV:" + e.message,
      //             reg_date : 0,

      //         }]
      //     }
      // })

      console.log("error >", e);
    }
  };

  useEffect(() => {
    // initValue={fileObj["myVideos"] && Math.round(fileObj["myVideos"]["duration"])}
    // console.log("parmas => ",params)
    if (params["who"] == "teacher") {
      setIsTeacher(true);
    } else {
      setIsTeacher(false);
    }
  }, [params]);

  useEffect(() => {
    if (fileObj?.videoFile) {
      setPreview(URL.createObjectURL(fileObj.videoFile));
    }
  }, [fileObj.videoFile]);

  const [rv, setRv] = useState();

  const [initValue_external, setInitValue_external] = useState("");

  return (
    <Basic
      title={
        params["type"] == "de"
          ? "영상 등록하기"
          : params["type"] == "exlocal"
            ? "로컬 자료"
            : "외부 영상 링크"
      }
    >
      {params["type"] == "de" ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "80vh",
            alignItems: "center",
            justifyContent: "center",
            // borderBottom : "1px solid",
            // borderBottomColor : style.common.borderColor1
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              fontSize: "14px",
              padding: "0px 20px",
              margin: "0px 20px",
              color: style.common.fontColor_7,
            }}
          >
            <input
              type={"file"}
              // accept={"image/*"}
              accept={"video/*"}
              id="video_file"
              onChange={(e) => {
                fileOnChange(e);
              }}
              style={{
                display: "none",
              }}
            />
            <label
              htmlFor="video_file"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                src={add_movie}
                style={{
                  width: "56px",
                  marginBottom: "7px",
                }}
              />
              {/* <Folder  style={{
                            fontSize : "52px"
                        }}/> */}
              로컬 자료 등록
            </label>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              fontSize: "14px",
              padding: "0px 20px",
              margin: "0px 20px",
              color: style.common.fontColor_7,
            }}
            onClick={() => {
              navigate(`/video/${params["who"]}/ex`);
            }}
          >
            <img
              src={add_link}
              style={{
                width: "50px",
                marginBottom: "7px",
              }}
            />
            {/* <VideoCall style={{
                        fontSize : "52px"
                    }}
                        onClick={()=>{
                            navigate(`/video/${params["who"]}/ex`)
                        }}
                    /> */}
            영상 링크
          </div>
        </div>
      ) : params["type"] == "exlocal" ? (
        <div>
          <div
            style={{
              width: "100%",
              height: "200px",
              // backgroundColor : "yellow",
            }}
          >
            <ReactPlayer
              // url={fileObj["videoFile"]}
              url={preview}
              width="100%"
              height={"100%"}
              playing={false}
              controls={true}
              pip={false}
              ref={(r) => {
                setRv(r);
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              //    alignItems:"center",
              //    justifyContent:"space-between",
            }}
          >
            <div
              style={{
                padding: "20px 0px ",
                fontWeight: "bold",
              }}
            >
              시작시간
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  // width:"70px",
                  marginTop: "10px",
                }}
              >
                <UiTime
                  // timeData={startTime}
                  isMin={true}
                  inputClass={params["who"] == "teacher" ? "input_t" : "input_s"}
                  type="time"
                  timeStyle={{}}
                  fnChange={(d) => {
                    // // console.log(d)
                    start = d;
                    // fnChange(d)
                    // setStart(d)
                  }}
                />
                {/* <UiLectureTime
                                day={""}
                                isDay={false}
                                isTeacher={params["who"] == "teacher" ? true : false}
                                fnChange={(d)=>{
                                    // console.log(d)
                                }}
                                fnChange2={(d)=>{
                                    // console.log(d)
                                }}
                            /> */}
                {/* <UiInput
                                type="basic"
                                placeholder={"0:00"}
                                fnChange={(e)=>{
                                    start = e.target.value
                                }}
                            /> */}
              </div>
            </div>
            <div
              style={{
                padding: "20px 0px 20px 20px",
                fontWeight: "bold",
              }}
            >
              종료시간
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "70px",
                  marginTop: "10px",
                }}
              >
                <UiTime
                  // timeData={startTime}
                  isMin={true}
                  initValue={fileObj["myVideos"] && Math.round(fileObj["myVideos"]["duration"])}
                  inputClass={params["who"] == "teacher" ? "input_t" : "input_s"}
                  type="time"
                  timeStyle={{}}
                  fnChange={(d) => {
                    // fnChange(d)
                    // // console.log(d)
                    end = d;
                    // setEnd(d)
                  }}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              padding: "20px 0px",
              fontSize: "14px",
            }}
          >
            위 영상을 등록하시겠어요?
            <div
              style={{
                display: "flex",
                padding: "15px 0px",
                flexDirection: "column",
                justifyContent: "flex-end",
                flex: 1,
                flexGrow: 1,
              }}
            >
              <UiBtn
                btnStyle={{
                  // ...Register_One_style.b,
                  color: "white",
                  borderColor: isTeacher ? style.common.t_color : style.common.s_color,
                  backgroundColor: isTeacher ? style.common.t_color : style.common.s_color,
                }}
                title={"확인!"}
                fnClick={() => {
                  // console.log(fileObj)
                  // return;
                  let isOk = window.confirm(
                    "입력하신 영상정보를 등록하시겠습니까?\n영상은 대화방을 나가도 업로드 됩니다. 업로드가 완료되면 자동으로 영상 등록이 됩니다."
                  );
                  if (isOk) {
                    setStart(start);
                    setEnd(end);
                    // let time = start.split(":")
                    // let time_end = end.split(":")
                    // let video_start = Number(( time[0] * 60 ) + ( time[1] ))
                    // let video_end = Number(( time_end[0] * 60 ) + ( time_end[1] ))

                    // // console.log(start)
                    // // console.log(end)

                    let start_time = start.split(":");
                    let end_time = end.split(":");

                    // // console.log("!! ",start_time)
                    // // console.log("!! ",end_time)

                    let video_start = Number(start_time[0] * 60 + Number(start_time[1]));
                    let video_end = Number(end_time[0] * 60 + Number(end_time[1]));

                    // console.log("vidoe_start => ",video_start)
                    // console.log("video_end=> ",video_end)
                    // console.log("fileObj => ",fileObj)
                    setIsLoading(true);
                    if (fileObj["isMsg"]) {
                      apiPro_send_qna_local(
                        3,
                        null,
                        fileObj["videoFile"],
                        false,
                        fileObj["myVideos"],
                        fileObj["qid"],
                        video_start,
                        video_end
                      );
                    } else {
                      setBoardObj({
                        ...boardObj,
                        video_link: link,
                        video_start: video_start,
                        video_end: video_end,
                      });
                      setIsLoading(false);
                      navigate(hiddenUrl);
                    }
                    // return;
                  }
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div
            style={
              {
                // display:"flex",
              }
            }
          >
            링크
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  width: "73%",
                }}
              >
                <UiInput
                  type="basic"
                  placeholder={""}
                  fnChange={(e) => {
                    setLinkText(e.target.value);
                  }}
                  inputClass={isTeacher ? "input_t" : "input_c"}
                />
              </div>
              <UiBtn
                btnStyle={{
                  width: "25%",
                  backgroundColor: isTeacher ? style.common.t_color : style.common.s_color,
                  color: "white",
                }}
                title={"링크"}
                fnClick={() => {
                  setLink(linkText);
                  // setTimeout(()=>{
                  //     rv.seekTo(0.5,'fraction')
                  // },5000)
                }}
              />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: "200px",
              // backgroundColor : "yellow",
            }}
          >
            <ReactPlayer
              url={link}
              width="100%"
              height={"100%"}
              playing={false}
              controls={true}
              pip={false}
              onReady={() => {
                if (link.startsWith("https://vimeo.com/")) {
                  rv.getInternalPlayer()
                    .getDuration()
                    .then((duration) => {
                      setInitValue_external(duration);
                    });
                } else {
                  setInitValue_external(rv.getDuration());
                }
              }}
              ref={(r) => {
                setRv(r);
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                padding: "20px 0px 20px 0px",
                fontWeight: "bold",
              }}
            >
              시작시간
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  // width:"70px",
                  marginTop: "10px",
                }}
              >
                {/* <UiInput
                                type="basic"
                                placeholder={"0:00"}
                                fnChange={(e)=>{
                                    setStart(e.target.value)
                                }}
                            /> */}
                <UiTime
                  // timeData={startTime}
                  isMin={true}
                  inputClass={params["who"] == "teacher" ? "input_t" : "input_s"}
                  type="time"
                  timeStyle={{}}
                  fnChange={(d) => {
                    // console.log(d)
                    start = d;
                    // fnChange(d)
                  }}
                />
              </div>
            </div>
            <div
              style={{
                padding: "20px 0px 20px 20px",
                fontWeight: "bold",
              }}
            >
              종료시간
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  // width:"70px",
                  marginTop: "10px",
                }}
              >
                {/* <UiInput
                                type="basic"
                                placeholder={"0:00"}
                                fnChange={(e)=>{
                                    // end = e.target.value
                                    setEnd(e.target.value)
                                }}
                            /> */}
                <UiTime
                  isMin={true}
                  // timeData={startTime}
                  // initValue={fileObj["myVideos"] && Math.round(fileObj["myVideos"]["duration"])}
                  // initValue={rv && Math.round(rv.getDuration())}
                  initValue={initValue_external}
                  inputClass={params["who"] == "teacher" ? "input_t" : "input_s"}
                  type="time"
                  timeStyle={{}}
                  fnChange={(d) => {
                    // fnChange(d)
                    end = d;
                  }}
                />
              </div>
            </div>
          </div>

          <div
            style={{
              padding: "20px 0px",
              fontSize: "14px",
            }}
          >
            위 영상을 등록하시겠어요?
            <div
              style={{
                display: "flex",
                padding: "15px 0px",
                flexDirection: "column",
                justifyContent: "flex-end",
                flex: 1,
                flexGrow: 1,
              }}
            >
              <UiBtn
                btnStyle={{
                  // ...Register_One_style.b,
                  color: "white",
                  borderColor: isTeacher ? style.common.t_color : style.common.s_color,
                  backgroundColor: isTeacher ? style.common.t_color : style.common.s_color,
                }}
                title={"확인"}
                fnClick={() => {
                  // // console.log("end => ",end)
                  // // console.log("start => ",start)
                  // return;
                  let isOk = window.confirm("입력하신 영상정보를 등록하시겠습니까?");

                  let start_time = start.split(":");
                  let end_time = end.split(":");

                  let video_start = Number(start_time[0] * 60 + Number(start_time[1]));
                  let video_end = Number(end_time[0] * 60 + Number(end_time[1]));

                  let link_ = link + `&start=${video_start}&end=${video_end}`;

                  // // console.log(rv)
                  // // console.log(rv.getDuration())
                  // // console.log("video_end => ",video_end);
                  // // console.log("video_start => ",video_start);
                  // // console.log(link_)

                  if (video_start == 0 && video_end == 0) {
                    alert("시간을 입력해주세요.");
                    setIsLoading(false);
                    return;
                  }

                  if (video_start >= video_end) {
                    alert("시작시간이 종료시간을 넘을 수 없어요.");
                    setIsLoading(false);
                    return;
                  }
                  if (video_start >= rv.getDuration()) {
                    alert("시작시간이 영상 총 재생시간을 넘을 수 없어요.");
                    setIsLoading(false);
                    return;
                  }

                  if (isOk) {
                    if (params["type"].includes("ms")) {
                      apiPro_send_qna(link_);
                    } else {
                      setBoardObj({
                        ...boardObj,
                        video_link: link_,
                        video_start: video_start,
                        video_end: video_end,
                      });
                      navigate(hiddenUrl);
                    }
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}
      {isLoading && (
        <UiLoading
          isText={false}
          // text=''
        />
      )}
    </Basic>
  );
};

export default Video;
