import { Button as MuiButton, SxProps, Theme } from "@mui/material";
import React, { ComponentProps, useMemo } from "react";

type ButtonProps = ComponentProps<typeof MuiButton>;
const CustomButton = ({ children, variant, size = "medium", ...restProps }: ButtonProps) => {
  const variantStyle: SxProps<Theme> = useMemo(() => {
    if (variant === "outlined") {
      return {
        color: "grey.600",
        border: "1px solid",
        borderColor: "grey.200",
        backgroundColor: "white",
        ":hover": {
          color: "primary.main",
          backgroundColor: "white",
        },
        ":active": {
          borderColor: "primary.800",
          color: "primary.800",
        },
      };
    } else if (variant === "text") {
      return {
        color: "grey.600",
        backgroundColor: "white",
        ":hover": {
          backgroundColor: "grey.200",
        },
        ":active": {
          backgroundColor: "grey.300",
        },
      };
    } else {
      return {
        color: "white",
        ":hover": {
          backgroundColor: "primary.600",
        },
        ":active": {
          backgroundColor: "primary.800",
        },
      };
    }
  }, [variant]);

  return (
    <MuiButton
      {...restProps}
      variant={variant}
      size={size}
      disableElevation
      disableRipple
      sx={{
        boxShadow: "none",
        fontWeight: 500,
        fontFamily: `"Spoqa Han Sans Neo", "sans-serif", "Apple SD Gothic Neo"`,
        // ...sizeStyle,
        ...variantStyle,
      }}
    >
      {children}
    </MuiButton>
  );
};

const rere = () => {
  return <CustomButton>버튼</CustomButton>;
};

export default CustomButton;
