import { React, useEffect, useState } from "react";
import style from "../../asset/style";
import { Frame } from "../page_index";
import { useNavigate, useParams } from "react-router";
import Message_Style from "../Message/Message_Style";
import Academy_main from "./Academy_main";
import Academy_class from "./Academy_class";
import Academy_subs from "./Academy_subs";
import { getUser } from "@asset/storage";

const Academy = ({
  hiddenNav,
  setHiddenUrl,
  s_bookList,
  setS_BookList,
  setCreateObj,
  scrollKey,
  setScrolllKey,
}) => {
  let params = useParams();
  let navigate = useNavigate();

  const [isTeacher, setIsTeacher] = useState(true);
  const [isSearch, setIsSearch] = useState(true);
  const [tap, setTap] = useState("class");
  const userInfo = getUser();

  useEffect(() => {
    // console.log("parmas => ",params)
    if (params["who"] == "teacher") {
      setIsTeacher(true);
    } else {
      setIsTeacher(false);
    }
    setTap(params["where"]);
  }, [params]);

  useEffect(() => {
    try {
      console.log("## ", tap);
      setIsSearch(true);
    } catch (e) {}
  }, [tap]);

  return (
    <Frame
      userInfo={userInfo}
      t_visible={false}
      isTap={true}
      tapCompo={
        <>
          {/* @Todo 각 학원에는 적용돼있지 않고 하이큐에만 있는 기능 불필요하다 판단해 임시 제거 추후 불필요한게 맞다면 주석 제거 요망 2024.04.02 */}
          {/* <div style={{
                    ...Message_Style.tap_text,
                    fontSize : "20px",
                    borderBottom : tap == "class" ? "2px solid" : "none",
                    borderBottomColor : tap == "class" ?
                    (isTeacher ? style.common.t_color : style.common.s_color)
                    :
                    "none"
                    ,
                    color :
                    tap == "class" ?
                    (isTeacher ? style.common.t_color : style.common.s_color)
                    :
                    style.common.fontColor_2

                }}
                    onClick={()=>{
                        navigate("/academy"+"/"+params["who"]+"/class")
                    }}
                >
                    내 수업
                </div> */}
          <div
            style={{
              ...Message_Style.tap_text,
              fontSize: "20px",
              borderBottom: tap == "academy" ? "2px solid" : "none",
              borderBottomColor:
                tap == "academy"
                  ? isTeacher
                    ? style.common.t_color
                    : style.common.s_color
                  : "none",
              color:
                tap == "academy"
                  ? isTeacher
                    ? style.common.t_color
                    : style.common.s_color
                  : style.common.fontColor_2,
            }}
            onClick={() => {
              navigate("/academy" + "/" + params["who"] + "/academy");
            }}
          >
            내 학원
          </div>
          {/* <div style={{
                    ...Message_Style.tap_text,
                    fontSize : "20px",
                    borderBottom : tap == "book" ? "2px solid" : "none",
                    borderBottomColor : tap == "book" ?
                    (isTeacher ? style.common.t_color : style.common.s_color)
                    :
                    "none"
                    ,
                    color :
                    tap == "book" ?
                    (isTeacher ? style.common.t_color : style.common.s_color)
                    :
                    style.common.fontColor_2

                }}
                    onClick={()=>{
                        navigate("/academy"+"/"+params["who"]+"/book")
                    }}
                >
                    내 교재
                </div> */}
          {/* {
                // params["who"] == "teacher" &&
                <div style={{
                    ...Message_Style.tap_text,
                    fontSize : "20px",
                    borderBottom : tap == "subs" ? "2px solid" : "none",
                    borderBottomColor : tap == "subs" ?
                    (isTeacher ? style.common.t_color : style.common.s_color)
                    :
                    "none"
                    ,
                    color :
                    tap == "subs" ?
                    (isTeacher ? style.common.t_color : style.common.s_color)
                    :
                    style.common.fontColor_2

                }}
                    onClick={()=>{
                        navigate("/academy"+"/"+params["who"]+"/subs")
                    }}
                >
                    교재 구독
                </div>
                } */}
        </>
      }
      // isSearch={isSearch}
      isSearch={false}
    >
      <div
        style={{
          ...Message_Style.c,
          // padding : style.common.padding,
        }}
      >
        {tap == "academy" ? (
          <div
            style={{
              padding: "15px 15px 0px 15px",
            }}
          >
            <Academy_main
              scrollKey={scrollKey}
              setScrolllKey={setScrolllKey}
              isTeacher={isTeacher}
              userInfo={userInfo}
            />
          </div>
        ) : tap == "class" ? (
          <Academy_class
            scrollKey={scrollKey}
            setScrolllKey={setScrolllKey}
            isTeacher={isTeacher}
            userInfo={userInfo}
          />
        ) : (
          tap == "subs" && (
            <div
              style={{
                // padding : style.common.padding,
                padding: "15px 15px 0px 15px",
              }}
            >
              <Academy_subs
                tap={tap}
                hiddenNav={hiddenNav}
                setHiddenUrl={setHiddenUrl}
                scrollKey={scrollKey}
                setScrolllKey={setScrolllKey}
                setIsSearch={setIsSearch}
                isTeacher={isTeacher}
                userInfo={userInfo}
                s_bookList={s_bookList}
                setS_BookList={setS_BookList}
                setCreateObj={setCreateObj}
              />
            </div>
          )
        )}
      </div>
    </Frame>
  );
};

export default Academy;
