import { useEffect } from "react";
import { useNavigate } from "react-router";
import { CONFIG } from "@config";
import { ACADEMY_RELATION_ENUM } from "../../domain/enum/academy";
import { IAcademyInfo, IAccount, ILectureWithStatus } from "@entities";
import { useGetAcademyInfo, useSetAcademyRequest } from "@hooks/queries/academy/useAcadmey";
import { useGetProfile } from "@hooks/queries/account/useProfile";
import { useAcademyLecturesRelated } from "@hooks/queries/academy/useAcademyLectures";
import { ACCOUNT_TYPE_ENUM } from "@enum";

enum StatusEnum {
  OK = "ok",
  NOT_FOUND = "notfound",
  ERROR = "error",
  CONFLICT = "conflict",
}

export default function AuthPage(): null {
  const navigate = useNavigate();
  const academyCode = CONFIG.APP_ENV.APP_ACADEMY_CODE;
  const { refetch: refetchAcademyInfo } = useGetAcademyInfo(academyCode);
  const { refetch: refetchUserProfile } = useGetProfile();
  const { refetch: refetchAcademyRelated } = useAcademyLecturesRelated(academyCode);
  const { mutateAsync } = useSetAcademyRequest();
  const isAcademyApp = CONFIG.APP_ENV.IS_ACADEMY_APP;

  const handleGetAccount = async () => {
    return await refetchUserProfile();
  };

  const handleCheckAcademyLectures = (lectures: ILectureWithStatus[]) => {
    switch (lectures.length) {
      case 0:
        alert("신청한 수업이 없어요. 수업를 신청해주세요.");
        navigate("/lectures/select");
        break;
      default:
        alert(
          `학원 수업 ${lectures.length}개를 신청한 상태에요. 수업 선생님께 수락을 요청해 보세요.`
        );
        navigate("/", { replace: true });
    }
  };

  const handleGoMain = (accountInfo: IAccount) => {
    let whereToGo =
      accountInfo.type === ACCOUNT_TYPE_ENUM.TEACHER
        ? "/message/teacher/home"
        : "/message/student/home";
    navigate(whereToGo, { replace: true });
  };

  const handleAcademyStatus = async (academyInfo: IAcademyInfo, accountInfo?: IAccount) => {
    const { data: academyLecturesRelated } = await refetchAcademyRelated();

    switch (academyInfo.status) {
      case ACADEMY_RELATION_ENUM.NO_RELATION:
        alert("학원에 가입 신청을 하지 않았어요. 학원 가입 요청을 보냅니다.");
        await mutateAsync(academyCode);
        handleCheckAcademyLectures(academyLecturesRelated.lectures);
        break;
      case ACADEMY_RELATION_ENUM.REQUEST:
        alert("학원 가입 신청을 요청한 상태에요. 학원의 승인을 기다려주세요.");
        handleCheckAcademyLectures(academyLecturesRelated.lectures);
        break;
      case ACADEMY_RELATION_ENUM.JOINED:
        handleGoMain(accountInfo);
        break;
      default:
        alert("학원 가입 신청에 문제가 발생했어요. 다시 시도해주세요.");
    }
  };

  const handleEnterLogin = async (accountInfo: IAccount) => {
    if (isAcademyApp) {
      try {
        const { data: academyInfo } = await refetchAcademyInfo();
        if (academyInfo) await handleAcademyStatus(academyInfo, accountInfo);
      } catch (e) {
        console.log(e);
        navigate("/");
      }
    } else {
      handleGoMain(accountInfo);
    }
  };

  const navigateByStatus = async (queryParams: URLSearchParams) => {
    const status = queryParams.get("res");
    const oauthSessionKey = queryParams.get("oauth_session_key");
    const hint = queryParams.get("hint");
    const notMyAccount = queryParams.get("not_my_account");

    switch (status) {
      case StatusEnum.OK:
        const { data } = await handleGetAccount();
        await handleEnterLogin(data);
        break;
      case StatusEnum.NOT_FOUND:
        navigate("/sign-up/type", { state: { oauthSessionKey, notMyAccount }, replace: true });
        break;
      case StatusEnum.ERROR:
        alert("문제가 발생했어요. 관리자에게 문의해주세요");
        navigate("/error-page");
        break;
      case StatusEnum.CONFLICT:
        alert("인증 시스템이 변경되었어요. 계정 연결 페이지로 이동합니다.");
        navigate("/auth/link-existing-account", { state: { hint, oauthSessionKey } });
        break;
      default:
        alert("문제가 발생했어요. 관리자에게 문의해주세요");
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.size !== 0) {
      navigateByStatus(queryParams);
    } else {
      navigate("/");
    }
  }, []);

  return null;
}
