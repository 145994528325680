import React from "react";
import LectureItem from "@components/pages/lecture/LectureItem";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { AcademyListResponseDTO } from "@api/academy/types";
import { Typography } from "@presentation/components/common/atoms";

const LectureListStyled = styled.ul`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm};
  overflow: scroll;
`;

type LectureData = {
  pages: AcademyListResponseDTO[];
  pageParams: Array<number | unknown>;
};

type SelectedLectures = Map<number, { lectureName: string; lectureId: number }>;

type LectureListProps = {
  lectureData: LectureData | null;
  selectedLectures: SelectedLectures;
  searchText: string;
  onToggle: (lecture: { lectureName: string; lectureId: number }) => void;
  isFetching: boolean;
};

const LectureList = React.forwardRef<HTMLDivElement, LectureListProps>(
  ({ lectureData, selectedLectures, searchText, onToggle, isFetching }: LectureListProps, ref) => {
    if (!lectureData)
      return (
        <Typography fontSize={"xs"} color={"natural.30"} textAlign={"center"}>
          검색으로 수업을 찾아보세요.
        </Typography>
      );
    if (lectureData.pages[0].lectures.length === 0)
      return (
        <Typography fontSize={"xs"} color={"natural.30"} textAlign={"center"}>
          검색 결과가 없습니다.
        </Typography>
      );

    return (
      <LectureListStyled>
        {lectureData.pages.map((page) =>
          page.lectures.map((lecture) => (
            <LectureItem
              key={lecture.lectureID}
              lecture={lecture}
              active={selectedLectures.has(lecture.lectureID)}
              searchText={searchText}
              onClickItem={() =>
                onToggle({
                  lectureName: lecture.lectureName,
                  lectureId: lecture.lectureID,
                })
              }
            />
          ))
        )}
        {isFetching ? <Skeleton height={60} /> : <div ref={ref} />}
        {lectureData.pageParams.length === lectureData.pages[0].maxPage && (
          <Typography fontSize={"xs"} color={"natural.30"} textAlign={"center"}>
            마지막 데이터입니다
          </Typography>
        )}
      </LectureListStyled>
    );
  }
);

export default LectureList;
