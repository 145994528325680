import { useMutation } from "@apollo/client";
import { CircularProgress } from "@material-ui/core";
import { Add, CheckCircleOutlined, HighlightOff } from "@material-ui/icons";
import { TextareaAutosize } from "@mui/base";
import { DriveFileRenameOutline } from "@mui/icons-material";
import Switch from "@mui/material/Switch";
import { PaymentContext } from "asset/context";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { usePageVisibility } from "react-page-visibility";
import { useNavigate, useParams } from "react-router";
import Slider from "react-slick";
import add_image from "../../asset/Img/add_image.png";
import add_link from "../../asset/Img/add_link.png";
import add_movie from "../../asset/Img/add_movie.png";
import add_qna from "../../asset/Img/add_qna.png";
import icon_movebook from "../../asset/Img/icon_movebook.png";
import image_48 from "../../asset/Img/image_48.png";
import noBook from "../../asset/Img/noBook.png";
import apiFn from "../../asset/apiClass";
import constants from "../../asset/constants";
import store from "../../asset/store";
import style from "../../asset/style";
import util from "../../asset/util";
import { AskSerialQuestionDocument } from "../../types/graphql/graphql";
import { UiInput } from "../../uiComponents/UiInput/UiInput";
import UiLoading from "../../uiComponents/UiLoading/UiLoading";
import UiTextBtn from "../../uiComponents/UiTextBtn/UiTextBtn";
import "./Message_talk.css";
import Message_talk_header_type_b from "./Message_talk_header_type_b";
import Message_talk_header_type_qna from "./Message_talk_header_type_qna";
import Message_talk_side from "./Message_talk_side";
import Message_talk_style from "./Message_talk_style";
import Modal from "./Modal";
import Modal_book from "./Modal_book";
import Modal_invite_msg from "./Modal_invite_msg";
import Modal_pick from "./Modal_pick";
import Talk from "./Talk";
import VideoQuestionThumbnail from "./VideoQuestionThumbnail";
import "./rainbow.css";
import {
  resolveQuestionApi,
  useCancelSolveQuestion,
  useSetEvaluateCalculationsQuestion,
  useSetEvaluateLevel,
  useSetEvaluateQuestion,
  useSetMosaicQuestion,
  useSetPublicQuestion,
  useSetQuestionTitle,
  useSetQuestionUnderstand,
} from "../../api/question";
import { useRecoilState, useSetRecoilState } from "recoil";
import { LoadingView, PreAnswerView } from "./components/talk";
import { questionContentAtom } from "../../application/recoils/question/atoms";
import { Button } from "@mui/material";
import IAi from "../../components/atoms/icons/ai";
import { EditQuestionModal } from "./components/editModal";
import { EditModalEnum } from "../../application/recoils/modal/types";
import { answerContentAtom } from "../../application/recoils/answer/atom";
import { useSocket } from "../../providers/socket";
import { useGetSemohaeAnswer, useSaveSemohaeAnswer } from "../../api/semohae";
import { showMessageLoadingAtom } from "../../application/recoils/ui/atoms";
import EditAnswerModal from "./components/editModal/Answer";
import { editShowModalAtom } from "../../application/recoils/modal/atoms";
import CaptureSizeSheet from "./components/captureSizeSheet";
import CaptureStateModal from "./components/captureStateModal";
import { useGetIsSemohaeFolded, useSetSemohaeFoldMetaData } from "../../api/account/stat_store";
import { QUESTION_META_KEY } from "../../api/account/stat_store/metadata_key";
import { getUser } from "@asset/storage";

const Message_talk = ({
  clipData,
  setClipData,
  isDetail,
  setIsDetail,
  isSide,
  setIsSide,
  messageList,
  senderInfo,
  setSenderInfo,
  setQnaData_app,
  setFileObj,
  setHiddenUrl,
  hiddenUrl,
  oriQnaRoom,
  youtube_url,
  setYoutube_url,
  setOriQnaRoom,
  videoInfo,
  setVideoInfo,
  solutionList,
  setSolutionList,
  websocket,
}) => {
  let params = useParams();
  let navigate = useNavigate();
  const userInfo = getUser();

  // 결제 관련
  const { requestPickTicket, pickResult, setPickResult, setForceUpdate, academyId } =
    useContext(PaymentContext);
  const [isModal, setIsModal] = useState(false);
  const [isModal_invite, setIsModal_invite] = useState(false);
  const [isModal_pick, setIsModal_pick] = useState(false);
  const [isModal_book, setIsModal_book] = useState(false);

  const [isTitle, setIsTitle] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const [isText, setIsText] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDel, setIsDel] = useState(false);

  const [isComplete, setIsComplete] = useState(false);
  const [isReconnect, setIsReconnect] = useState(false);
  const [message_page, setMessage_page] = useState(1);

  const [isPick, setIsPick] = useState(false);
  const [isMobum, setIsMobum] = useState(false);

  const [message_c, setMessage_c] = useState({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    position: "relative",
    height: window.innerHeight,
    width: "100%",
    maxWidth: "100%",
  });

  const [addQnaData, setAddQnaData] = useState({});
  const [talk, setTalk] = useState("");

  const [isChk, setIsChk] = useState(false);
  const [isMosaic, setIsMosaic] = useState(false);

  const [talkList, setTalkList] = useState([]);

  const [qnaData, setQnaData] = useState({
    invite: [],
  });

  const [selectData, setSelectData] = useState({});

  const [clipText, setClipText] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [isAttach, setIsAttach] = useState(false);

  const [isFocus, setIsFocus] = useState(false);

  const [headerInfo, setHeaderInfo] = useState({});

  const [isSubs, setIsSubs] = useState(false);
  const [isBookAdmin, setIsBookAdmin] = useState(false);
  const [title, setTitle] = useState("");
  const [askQuestionArgs, setAskQuestionArgs] = useState();
  const [askSerialQuestion] = useMutation(AskSerialQuestionDocument, { errorPolicy: "all" });
  const [questionContent, setQuestionContent] = useRecoilState(questionContentAtom);
  const [semohaeAnswerContent, setSemohaeAnswerContent] = useRecoilState(answerContentAtom);
  const setEditModalData = useSetRecoilState(editShowModalAtom);
  const [showSemohaePreAnswerView, setShowSemohaePreAnswerView] = useState(false);
  const semohaeFoldMetaData = useGetIsSemohaeFolded(params.qid);
  const setSemohaeFoldMetaData = useSetSemohaeFoldMetaData();
  const studentAskQuestion = useCallback(
    async (args) => {
      try {
        const variables = { input: args };
        const { errors, data } = await askSerialQuestion({ variables });
        const errorMessage = errors?.[0]?.extensions?.message;
        if (!!errorMessage) {
          throw new Error(errorMessage);
        }
        const questionId = data.askSerialQuestion.id;
        const studentId = args.studentId;
        navigate(`/message/${params["who"]}/home`);
        navigate(`/talk/${params["who"]}/0/${questionId}/${studentId}/${params["tid"]}`);
      } catch (e) {
        const errorMsg = e?.message || "등록하는 도중 오류가 발생했습니다. 다시 한번 확인해주세요.";
        alert(errorMsg);
      }
    },
    [params, navigate]
  );

  const startPickTicketType = useCallback(
    (args) => {
      setAskQuestionArgs(args);
      requestPickTicket();
    },
    [requestPickTicket, setAskQuestionArgs]
  );

  const onPickTicketType = useCallback(async () => {
    await studentAskQuestion({ ...askQuestionArgs, ticketType: pickResult });
    setAskQuestionArgs(null);
    setPickResult(false);
    setForceUpdate(new Date().getTime());
  }, [pickResult, askQuestionArgs, setForceUpdate, setPickResult, setPickResult]);

  // 티켓 종류가 선택되었을 때
  useEffect(async () => {
    if (pickResult && askQuestionArgs) {
      await onPickTicketType();
    }
  }, [pickResult, askQuestionArgs]);

  const apiPro_modiTitle = async () => {
    try {
      await useSetQuestionTitle(params?.qid, title);
      setQnaData({
        ...qnaData,
        title: title,
      });
      setIsTitle(false);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const emptySemohaeMetaData = Object.keys(semohaeFoldMetaData).length === 0;
    const isSemohaeFolded =
      semohaeFoldMetaData[`${QUESTION_META_KEY.QUESTION_SEMOHAE_FOLD}_${params.qid}`] === "unfold";

    if (talkList.length > 0) {
      const filteredTalks = talkList.filter((talk) => [70, 71].includes(talk.ctype));
      const talksOfType70 = filteredTalks.filter((talk) => talk.ctype === 70);
      const talksOfType71 = filteredTalks.filter((talk) => talk.ctype === 71);

      if (talksOfType70.length > 0) {
        const sortedTalks = talksOfType70.sort((a, b) => a.id - b.id);
        setSemohaeAnswerContent(sortedTalks[sortedTalks.length - 1]?.content);
        if (emptySemohaeMetaData) {
          setShowSemohaePreAnswerView(talksOfType71.length < 1 && talksOfType70.length > 0);
        } else {
          setShowSemohaePreAnswerView(isSemohaeFolded);
        }
      } else {
        setSemohaeAnswerContent("");
        setShowSemohaePreAnswerView(false);
      }
    } else {
      setSemohaeAnswerContent("");
      setShowSemohaePreAnswerView(false);
    }
    setLoadingMessage(false);
  }, [talkList, semohaeFoldMetaData]);

  var apiPro_send_qna = async (ctype, base64, videoFile, isOri, time, semohaeContent) => {
    try {
      await Promise.all([
        apiFn.comApi({
          state: {
            table: "online_qna_log",
            set: [
              {
                key: `wid:${userInfo["id"]}' and qid='${params.qid}`,
                value: {
                  reg_date: 0,
                },
              },
            ],
          },
        }),
        apiPro_read_intro(qnaData),
      ]);
      if (isOri) {
        if (ctype == 8) {
          await apiFn.onlineQnaMsgSet({
            state: {
              wtype: params["who"] == "student" ? 1 : 2,
              wid: userInfo["id"],
              qid: oriQnaRoom,
              ctype: ctype, // 1 | 10 not source, 11 source
              content: params["qid"],
            },
          });
        } else {
          let ori_id = `${isOri["room"]}&${isOri["msg_id"]}`;
          delete isOri["room"];
          delete isOri["msg_id"];
          if (ctype == 71) {
            isOri["content"] = isOri.content.replace(/\\/g, "\\\\");
          }
          await apiFn.onlineQnaMsgSet({
            state: {
              wtype: params["who"] == "student" ? 1 : 2,
              wid: userInfo["id"],
              qid: oriQnaRoom,
              ctype: ctype, // 1 | 10 not source, 11 source
              ori_id: ori_id,
              ...isOri,
            },
          });
        }
        setIsLoading(false);
        navigate(`/message/${params["who"]}/home`);
        navigate(`/talk/${params["who"]}/0/${oriQnaRoom}/${params["sid"]}/${userInfo["id"]}`);
        return;
      } else {
        let name_add = talk;
        let name = "";
        if (ctype == 1) {
          name_add =
            new Date().getTime() + "_" + util.getRandomInt(100, 999) + "_" + videoFile.name;
          if (params["qid"] == 0) {
            name = `general/${params["tid"]}___${params["sid"]}/${name_add}`;
          } else {
            name = `qna/${params["qid"]}/${name_add}`;
          }
          await apiFn.uploadFile(name, videoFile);
        }
        if (ctype == 2) {
          name_add = talk;
        }
        if (ctype == 3) {
          name_add =
            new Date().getTime() + "_" + util.getRandomInt(100, 999) + "_" + videoFile.name;
          if (params["qid"] == 0) {
            name = `general/${params["tid"]}___${params["sid"]}/${util.replace_mp4(name_add)}`;
          } else {
            name = `qna/${params["qid"]}/${util.replace_mp4(name_add)}`;
          }
          await util.putVideo(videoFile, {}, "", name);
        }

        if (ctype === 71) {
          name_add = semohaeContent.replace(/\\/g, "\\\\");
        }

        if (talk.includes("link&") || talk.includes(`${constants.redirect_url}talk`)) {
          ctype = 4;
          name_add = talk;
        }
        if (params["qid"] === 0) {
          await apiPro_send(ctype, name_add, time);
        } else {
          await apiFn.onlineQnaMsgSet({
            state: {
              wtype: params["who"] == "student" ? 1 : 2,
              wid: userInfo["id"],
              qid: params["qid"],
              ctype: ctype, // 1 | 10 not source, 11 source
              content: name_add,
              play_time: time ? Math.round(time["duration"]) : null,
              file_size: time ? time["size"] : null,
            },
          });
          let msg_data = await apiPro_read();
          setTalkList([...msg_data]);
        }

        setIsVisible(true);
        setIsLoading(false);
        setIsAttach(false);
      }
    } catch (e) {
      setIsVisible(true);
      setIsLoading(false);
      setIsAttach(false);
      console.error("error >", e);
    }
  };

  var apiPro_send = async (ctype, content, time) => {
    try {
      var post = {
        cat: "hiq",
        cmd: "messageSet",
        wtype: params["who"] == "student" ? 1 : 2,
        sid: params["sid"],
        tid: params["tid"],
        content: content ? content : talk,
        ctype: ctype,
        play_time: time ? Math.round(time["duration"]) : null,
        file_size: time ? time["size"] : null,
      };
      if (talkList.length > 0) {
        if (talkList[0]["s_hide_date"] != 0) {
          post["s_hide_date"] = 0;
        }
        if (talkList[0]["t_hide_date"] != 0) {
          post["t_hide_date"] = 0;
        }
      }
      let data = await store.axiosPost(post);

      let msg_data = await apiPro_read();
      setTalkList([...msg_data]);
    } catch (e) {
      console.error(e);
    }
  };

  const apiPro_read_header = async () => {
    try {
      let data = await apiFn.friendInfo({
        state: {
          id: userInfo["id"],
          targetId: params["who"] == "teacher" ? params["sid"] : params["tid"],
        },
      });
      return data;
    } catch (e) {
      console.error(e);
    }
  };

  const apiPro_addQnaList = async (qid) => {
    try {
      let _qid = qid;
      if (qid == 0) {
        _qid = params["qid"];
      }

      let data = await apiFn.qnaList({
        state: {
          id: params["tid"],
          qid: _qid,
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
        },
      });
      let oriQnaData;

      if (data["list"].length > 0) {
        oriQnaData = await apiFn.comApi({
          state: {
            sort: "reg_date desc",
            page: 1,
            amount: 9999,
            table: "online_qna",
            where: {
              "HIQDB_online_qna.id": `= '${data["list"][0]["original"] !== 0 ? data["list"][0]["original"] : data["list"][0].id}'`,
            },
          },
        });
        if (oriQnaData["list"].length > 0) {
          if (oriQnaData["list"][0]["mosaic"] == "y") {
            setIsMosaic(true);
          }
        }
      }

      if (data["list"][0]) {
        setAddQnaData({
          ...data["list"][0],
        });

        setAddQnaArr([data["list"][0]]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const apiPro_chk_subs = async (qData) => {
    try {
      let isChk = await apiFn.comApi({
        state: {
          table: "book_link",
          page: 1,
          amount: 9999,
          sort: "bid desc",
          where: {
            "HIQDB_book_link.bid": `= '${qData["bid"]}'`,
            "HIQDB_book_link.mid": `= '${qData["tid"]}'`,
          },
        },
      });
      if (isChk["list"].length > 0) {
        if (isChk["list"][0]["type"] == 8 || isChk["list"][0]["type"] == 4) {
          setIsSubs(true);
        } else {
          setIsSubs(false);
        }
      } else {
        setIsSubs(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const apiPro_book_authority = async (qData) => {
    try {
      const books = await (await fetch(`${constants.apiUrl}/user/${userInfo.id}/book`)).json();
      const bookList = books.reduce((prev, cur) => {
        prev[cur.bid] = cur.type;
        return prev;
      }, {});

      setIsBookAdmin(Object.keys(bookList).includes(String(qData.bid)));
    } catch (e) {
      console.error(e);
    }
  };

  const apiPro_read_qna = async (isSet, isNoIntro) => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "book_link",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          ref: [
            "HIQDB_online_qna.img|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.title|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.answered_by|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.tid|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.sid|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.public|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.original|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.original_img|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.mosaic|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.understand|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.ans_date|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.mobum_date|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.reg_date|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.qna_date|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.level|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.cal_amount|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.member_cnt|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.is_answer|HIQDB_book_link.qid=HIQDB_online_qna.id",
            "HIQDB_online_qna.content|HIQDB_book_link.qid=HIQDB_online_qna.id",
          ],
          left: [
            "HIQDB_book.id|HIQDB_book_link.bid=HIQDB_book.id",
            "HIQDB_book.name|HIQDB_book_link.bid=HIQDB_book.id",
            "HIQDB_book.book_type|HIQDB_book_link.bid=HIQDB_book.id",
            "HIQDB_book.reg_date as book_reg_date|HIQDB_book_link.bid=HIQDB_book.id",
            "HIQDB_book.reg_id|HIQDB_book_link.bid=HIQDB_book.id",
            "HIQDB_book.img as b_img|HIQDB_book_link.bid=HIQDB_book.id",
          ],
          where: {
            "HIQDB_book_link.qid": `= '${params["qid"]}'`,
          },
          avg: ["HIQDB_online_qna_log.rating:qid:qid|and avgTable.ltype='3'|starAvg"],
          count: [
            "HIQDB_online_qna_log.qid:qid|and countTable.ltype ='3'|starCnt",
            "HIQDB_online_qna_log.qid:qid|and countTable.ltype ='4'|metooCnt",
            "HIQDB_online_qna_log.qid:qid|and countTable.ltype ='1'|readCnt",
          ],
        },
      });
      let data_invite = await apiFn.comApi({
        state: {
          table: "online_qna_log",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            "HIQDB_online_qna_log.qid": `= '${params["qid"]}'`,
          },
        },
      });

      if (data_invite["list"].length > 0) {
        let invite = data_invite["list"].reduce((a, v, i) => {
          return [...a, v["wid"]];
        }, []);
        data["list"][0]["invite"] = invite;
      }
      setIsComplete(false);

      if (params.where !== "post") {
        setQnaData_app({
          ...data["list"][0],
        });
      }

      if (isSet) {
        if (data["list"].length > 0) {
          setQnaData({
            ...data["list"][0],
          });
          if (data["list"][0]["mobum_date"] && data["list"][0]["ans_date"] !== 0) {
            setIsChk(true);
          } else {
            if (data["list"][0]["public"] == "y") {
              setIsChk(true);
            } else {
              setIsChk(false);
            }
          }
          if (data["list"][0]["mosaic"] == "y") {
            setIsMosaic(true);
          } else {
            setIsMosaic(false);
          }
          apiPro_addQnaList(data["list"][0]["original"]);
        } else {
          setQnaData({});
        }
        setIsVisible(true);
      } else {
        return data["list"];
      }
    } catch (e) {
      setIsVisible(true);
      console.error(e);
    }
  };

  const isCallLog = useRef(false);
  useEffect(() => {
    if (qnaData?.invite?.includes(userInfo.id) || [params.sid, params.tid].includes(userInfo.id)) {
      if (isCallLog.current) {
        return;
      }
      isCallLog.current = true;
      apiFn.comApi({
        state: {
          table: "online_qna_log",
          set: [
            {
              key: `wid:${userInfo["id"]}' and qid='${params.qid}`,
              value: {
                reg_date: 0,
              },
            },
          ],
        },
      });
      apiPro_read_intro(qnaData);
    }
  }, [qnaData, userInfo]);

  const apiPro_read_intro = async (qData) => {
    try {
      let state = {
        table: "app_msg",
        sort: "reg_date desc",
        read: {
          sid: params["sid"],
          tid: params["tid"],
          wid: userInfo["id"],
          first: "y",
        },
        where: {
          "HIQDB_app_msg.sid": `='${params["sid"]}'`,
          "HIQDB_app_msg.tid": `='${params["tid"]}'`,
          "HIQDB_app_msg.dell": `='0'`,
          "HIQDB_app_msg.wtype": `in ('1','2')`,
        },
        ref: [
          "A.name|HIQDB_user A on A.user_type = 1 and A.id=HIQDB_app_msg.sid",
          "B.nick|HIQDB_user B on B.user_type = 2 and B.id=HIQDB_app_msg.tid",
        ],
      };

      if (params["sid"] == "hiqsum_admin" || params["tid"] == "hiqsum_admin") {
        state["ref"] = [];
      }

      if (params["qid"] != 0) {
        state["table"] = "online_qna_msg";
        state["read"] = {
          wid: userInfo["id"],
          qid: params["qid"],
          first: "y",
          new: !qData || qData?.ans_date ? "n" : "y",
        };
        state["where"] = {
          "HIQDB_online_qna_msg.qid": `= '${params["qid"]}'`,
          "HIQDB_online_qna_msg.dell": `= '0'`,
        };
        state["left"] = [
          "A.name|HIQDB_user_credential X1 ON HIQDB_online_qna_msg.wid = X1.name LEFT OUTER JOIN HIQDB_user A on A.index=X1.user_id and A.user_type = 1",
          "B.nick|HIQDB_user_credential X2 ON HIQDB_online_qna_msg.wid = X2.name LEFT OUTER JOIN HIQDB_user B on B.index=X2.user_id and B.user_type = 2",
        ];
        delete state["ref"];
      }

      let data = await apiFn.comApi({
        state: {
          page: 1,
          amount: 9999,
          ...state,
        },
      });
    } catch (e) {
      console.error(e);
    }
  };

  const apiPro_read = async (isSet, page, isNext) => {
    try {
      if (isSet) {
        if (page) {
          setMessage_page(page + 1);
        } else {
          setMessage_page((prevState) => prevState + 1);
        }
      }

      let state = {
        table: "app_msg",
        sort: "reg_date desc",
        read: {
          sid: params["sid"],
          tid: params["tid"],
          wid: userInfo["id"],
        },
        where: {
          "HIQDB_app_msg.sid": `='${params["sid"]}'`,
          "HIQDB_app_msg.tid": `='${params["tid"]}'`,
          "HIQDB_app_msg.dell": `='0'`,
          "HIQDB_app_msg.wtype": `in ('1','2')`,
        },
        ref: [
          "A.nick, A.img as s_img|HIQDB_user A on A.user_type = 1 and A.id=HIQDB_app_msg.sid",
          "B.name, B.img as t_img|HIQDB_user B on B.user_type = 2 and B.id=HIQDB_app_msg.tid",
        ],
      };

      if (params["sid"] == "hiqsum_admin" || params["tid"] == "hiqsum_admin") {
        state["ref"] = [];
      }

      if (params["qid"] != 0) {
        state["table"] = "online_qna_msg";
        state["read"] = {
          wid: userInfo["id"],
          qid: params["qid"],
        };
        state["where"] = {
          "HIQDB_online_qna_msg.qid": `= '${params["qid"]}'`,
          "HIQDB_online_qna_msg.dell": `= '0'`,
        };
        state["left"] = [
          "A.nick, A.img as s_img|HIQDB_user_credential X1 ON HIQDB_online_qna_msg.wid = X1.name LEFT OUTER JOIN HIQDB_user A on A.index=X1.user_id and A.user_type = 1",
          "B.name, B.img as t_img|HIQDB_user_credential X2 ON HIQDB_online_qna_msg.wid = X2.name LEFT OUTER JOIN HIQDB_user B on B.index=X2.user_id and B.user_type = 2",
        ];
        delete state["ref"];
      }
      let data = await apiFn.comApi({
        state: {
          // ...ts
          page: 1,
          amount: 9999,
          ...state,
        },
      });

      let isChk = await apiFn.comApi({
        state: {
          table: "folder_link",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            "HIQDB_folder_link.qid": `='${params["qid"]}'`,
            "HIQDB_folder_link.mid": `='${userInfo["id"]}'`,
          },
        },
      });

      if (isChk["list"].length > 0) {
        setIsPick(true);
      } else {
        setIsPick(false);
      }

      if (isSet) {
        // 질문 완료 체크
        data["list"].map((v, i) => {
          if (v["ctype"] == 10) {
            setIsComplete(true);
          }
        });

        setTalkList([...data["list"]]);

        setIsVisible(true);
      } else {
        return data["list"];
      }
    } catch (e) {
      setIsVisible(true);
      console.error(e);
    }
  };

  //이해됐어요 처리
  const apiPro_understant = async () => {
    try {
      await useSetQuestionUnderstand(params?.qid, "y");
      await apiPro_read_qna(true);
      await apiPro_read(true);
      setIsVisible(true);
    } catch (e) {
      console.error(e);
    }
  };

  const apiPro_understant_del = async () => {
    try {
      await useSetQuestionUnderstand(params?.qid, "n");

      await apiPro_read_qna(true);
      await apiPro_read(true);
      setIsVisible(true);
    } catch (e) {
      console.error(e);
    }
  };

  // 답변완료 취소 처리 *mobum_date, ans_date del
  const apiPro_complete_del = async () => {
    try {
      await useCancelSolveQuestion(params?.qid);
      await apiFn.comApi({
        state: {
          table: "online_qna_msg",

          del: [
            {
              qid: params["qid"],
              ctype: 10,
            },
          ],
          delAll: "Y",
        },
      });

      await apiFn.comApi({
        state: {
          table: "app_msg",
          set: [
            {
              key: `qid:${params["qid"]}`,
              value: {
                content: "ctype=11&content=답변완료가 취소 되었어요.",
              },
            },
          ],
        },
      });
      await apiPro_read_qna(true);

      await apiPro_read(true);
      setIsVisible(true);
    } catch (e) {
      console.error(e);
    }
  };

  const apiPro_cancle = async () => {
    try {
      let isOk = await apiFn.deleteQna({
        state: {
          qid: params["qid"],
          orderMid: userInfo["id"],
          sys: "y",
        },
      });
      setIsVisible(true);
      navigate("/message" + "/" + params["who"] + "/home");
    } catch (e) {
      console.error(e);
    }
  };

  const onCancelSuccess = useCallback(() => {
    setIsVisible(true);
    navigate("/message" + "/" + params["who"] + "/home");
  }, [params]);

  const apiPro_complete = async () => {
    try {
      let value = {
        ans_date: 0,
        answered_by: userInfo.id,
      };
      if (params["sid"] != "null") {
        value["mobum_date"] = 0;
      }
      const qnaJoinMember = () => {
        return apiFn.comApi({
          state: {
            table: "online_qna_msg",
            read: {
              wid: userInfo.id,
              qid: params.qid,
              first: "y",
              new: "y",
            },
            where: {
              "HIQDB_online_qna_msg.qid": `= '${params.qid}'`,
              "HIQDB_online_qna_msg.dell": `= '0'`,
            },
            left: [
              "A.nick|HIQDB_user_credential X1 ON HIQDB_online_qna_msg.wid = X1.name INNER JOIN HIQDB_user A on A.index=X1.user_id and A.user_type = 1",
              "B.name|HIQDB_user_credential X2 ON HIQDB_online_qna_msg.wid = X2.name INNER JOIN HIQDB_user B on B.index=X2.user_id and B.user_type = 2",
            ],
            page: 1,
            amount: 9999,
            sort: "reg_date desc",
          },
        });
      };
      const setOnlineQnaMsg = () => {
        return apiFn.onlineQnaMsgSet({
          state: {
            qid: params["qid"],
            wtype: 2,
            wid: userInfo["id"],
            content: "답변이 완료되었습니다.",
            ctype: 10,
            channel: constants.channelId,
          },
        });
      };

      await Promise.all([
        resolveQuestionApi({ questionId: params.qid, userId: userInfo.id }),
        qnaJoinMember(),
        setOnlineQnaMsg(),
      ]);
      await apiPro_evaluate();
    } catch (e) {
      console.error(e);
      alert("오류가 발생했습니다.\n" + e);
    }
  };

  const fileOnChange = async (e, key) => {
    try {
      setIsAttach(false);
      let reader = new FileReader();
      let file = e.target.files[0];
      // video 시간 관련 코드
      let myVideos = [];
      window.URL = window.URL || window.webkitURL;
      myVideos.push(file);
      let video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = function () {
        window.URL.revokeObjectURL(video.src);
        let duration = video.duration;
        myVideos[myVideos.length - 1].duration = duration;
      };

      video.src = URL.createObjectURL(file);

      if (file["type"].includes("image")) {
        reader.readAsDataURL(file);
        reader.onload = async (e) => {
          apiPro_send_qna(1, reader.result, file);
        };
        reader.onerror = async (event) => {
          let msg;
          switch (event.target.error.code) {
            case error.NOT_FOUND_ERR:
              msg = "파일을 찾을 수 없습니다.";
              break;
            case error.SECURITY_ERR:
              msg = "파일에 변경이 있습니다.";
              break;
            case error.ABORT_ERR:
              msg = "파일 읽기가 중지되었습니다.";
              break;
            case error.NOT_READABLE_ERR:
              msg = "파일 읽을 권한이 없습니다.";
              break;
            case error.ENCODING_ERR:
              msg = "파일 허용 용량을 초과하였습니다.";
              break;
            default:
              msg = e.target.error.code;
              break;
          }

          setIsLoading(false);
          setIsAttach(false);
        };
      } else {
        if (params["who"] == "student") {
          alert("이미지 파일만 등록할 수 있어요.");
          setIsLoading(false);
        } else {
          setFileObj({
            isMsg: true,
            videoFile: file,
            myVideos: myVideos[0],
            duration: myVideos[myVideos.length - 1].duration,
            qid: params["qid"],
            tid: params["tid"],
            sid: params["sid"],
          });
          setTimeout(() => {
            navigate(`/video/${params["who"]}/exlocal`);
            setIsLoading(false);
          }, 1000);
        }
      }
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  };

  const apiPro_del = async () => {
    try {
      let _talkList = [];
      console.log("talkList", talkList);
      talkList.map((v) => {
        if (selectData[v["id"]] == "Y") {
          console.log(v);
          return;
        } else {
          _talkList.push(v);
        }
      });

      let update_regDate = 0;
      if (_talkList[0]) {
        update_regDate = 0;
      }

      console.log("update_regDate", update_regDate, _talkList);
      let state = {};
      if (params["qid"] == 0) {
        state["table"] = "app_msg";
      } else {
        state["table"] = "online_qna_msg";
      }

      state["set"] = Object.keys(selectData).reduce((a, v, i) => {
        return [
          ...a,
          {
            key: `id:${v}`,
            value: { dell: 1 },
          },
        ];
      }, []);

      let isOk = await apiFn.comApi({
        state: state,
      });

      if (params["qid"] != 0) {
        await apiFn.comApi({
          state: {
            table: "app_msg",
            set: [
              {
                key: `qid:${params["qid"]}`,
                value: { reg_date: update_regDate },
              },
            ],
          },
        });
      }
      setIsDel(false);
      setSelectData({});
      let data_msg = await apiPro_read();

      setTalkList([...data_msg]);
    } catch (e) {
      console.error("MSG DEL ERROR : ", e);
    }
  };

  const apiPro_public = async (chk) => {
    if (!qnaData?.bid && !isChk) {
      alert("미지정 교재에 속한 문제는 공개할 수 없습니다\n공개할 교재에 이동 후 시도해주세요.");
      return;
    }

    try {
      await useSetPublicQuestion(params?.qid, chk ? "n" : "y");
      setIsChk((prev) => !prev);
    } catch (e) {
      console.error(e);
    }
  };

  const apiPro_mosaic = async (chk, ori) => {
    try {
      await useSetMosaicQuestion(ori ? ori : params["qid"], !isMosaic ? "y" : "n");
      setIsMosaic((prev) => !prev);
    } catch (e) {
      console.error(e);
    }
  };

  // 평가하기
  const apiPro_evaluate = async () => {
    try {
      await useSetEvaluateQuestion(params.qid);
      await apiPro_read_qna(true);

      await apiPro_read(true);

      setIsVisible(true);
    } catch (e) {
      console.error(e);
    }
  };

  const apiPro_evaluate_lv = async (num) => {
    try {
      await useSetEvaluateLevel(params?.qid, num);
      setQnaData((prev) => ({
        ...prev,
        level: num,
      }));
    } catch (e) {
      console.error(e);
    }
  };

  const apiPro_evaluate_cal = async (num) => {
    try {
      await useSetEvaluateCalculationsQuestion(params?.qid, num);
      setQnaData((prev) => ({
        ...prev,
        cal_amount: num,
      }));
    } catch (e) {
      console.error(e);
    }
  };

  let ws = useRef(null);
  var webSocketUrl = useRef(null);
  const isVisible_talk = usePageVisibility();

  const connectWs = () => {
    try {
      if (!userInfo["id"]) return;
      if (!webSocketUrl.current) webSocketUrl.current = constants.wsurl + "/" + userInfo["id"];
      if (ws.current && ws.current.readyState < 3) return;
      ws.current = new WebSocket(webSocketUrl.current);
      ws.current.onopen = () => {
        if (params["qid"] != 0) {
          let qid = params["qid"];
          ws.current.send(JSON.stringify({ qid: qid }));
        }
      };
      ws.current.onclose = (error) => {
        if (isReconnect) {
          connectWs();
        }
        if (!userInfo["id"]) {
          return;
        }
        if (error.code == 1006) {
          setTimeout(() => connectWs(), 1000);
        }
        setIsReconnect(false);
      };
      ws.current.onerror = (error) => {
        setIsReconnect(false);
        connectWs();
      };
      ws.current.onmessage = util.debounce((evt) => {
        if (params["qid"] != 0) {
          apiPro_read_qna(true, true);
        }
        apiPro_read(true);
      }, 3000);
    } catch (e) {
      console.error("메세지방 connectFail", e);
    }
  };

  const qna_pro = async () => {
    try {
      let [data1, data2, data3] = await Promise.all([
        apiPro_read_qna(false),
        apiPro_read(),
        apiPro_read_header(),
      ]);
      if (data1.length > 0) {
        setQnaData({
          ...data1[0],
        });
        apiPro_book_authority(data1[0]);
        apiPro_chk_subs(data1[0]);
        if (data1[0]["mobum_date"] && data1[0]["ans_date"]) {
          setIsChk(true);
        } else {
          if (data1[0]["public"] == "y") {
            setIsChk(true);
          } else {
            setIsChk(false);
          }
        }
        if (data1[0]["mosaic"] == "y") {
          setIsMosaic(true);
        } else {
          setIsMosaic(false);
        }
        apiPro_addQnaList(data1[0]["original"]);
      } else {
        setQnaData({});
      }

      // // 질문 완료 체크
      data2.map((v, i) => {
        if (v["ctype"] == 10) {
          setIsComplete(true);
        }
      });

      setTalkList([...data2]);

      setHeaderInfo({
        ...data3,
      });

      setIsVisible(true);
    } catch (e) {
      console.error("qna_pro Error => ", e);
      setIsVisible(true);
    }
  };

  const general_pro = async () => {
    try {
      let [data1, data2] = await Promise.all([apiPro_read(), apiPro_read_header()]);
      // // 질문 완료 체크
      data1.map((v, i) => {
        if (v["ctype"] == 10) {
          setIsComplete(true);
        }
      });

      setTalkList([...data1]);
      setHeaderInfo({
        ...data2,
      });
      setIsVisible(true);
    } catch (e) {
      setIsVisible(true);
    }
  };
  const endWs = () => {
    try {
      setIsReconnect(false);
      ws.current.close();
      ws.current = null;
      ws = useRef(null);
    } catch (e) {
      console.error(e);
    }
  };

  const scrollRef = useRef(null);

  // 과거 내 질문 검색
  const post_search = (isAnswer) => {
    try {
      if (isAnswer) {
        window.localStorage.setItem("isAnswer", true);
        window.localStorage.setItem("talk_bookId", qnaData?.bid);
      } else {
        window.localStorage.removeItem("isAnswer");
      }
      if (addQnaData["id"]) {
        if (addQnaData["original"] == 0) {
          navigate(`/postsearchresult/${params["who"]}/${addQnaData["id"]}`);
          setOriQnaRoom(params["qid"]);
        } else {
          navigate(`/postsearchresult/${params["who"]}/${addQnaData["original"]}`);
          setOriQnaRoom(params["qid"]);
        }
      } else {
        navigate(`/postsearchresult/${params["who"]}/${params["qid"]}&${qnaData["tid"]}`);
        setOriQnaRoom(params["qid"]);
      }
    } catch (e) {
      console.error(e);
    }
  };

  // 답변 완료하기
  const ans_complete = async () => {
    let isOk = window.confirm("해당 질문을 완료처리 하시겠어요?");
    if (isLoading) return;
    if (isOk) {
      setIsVisible(false);
      await apiPro_complete();
    }
    setIsVisible(true);
  };

  // 답변 완료 취소하기
  const ans_cancel = async () => {
    try {
      if (!(qnaData.answered_by === userInfo.id || qnaData.tid === userInfo.id)) {
        alert("질문을 받은 선생님이나 답변 완료 당사자만 취소 할 수 있습니다.");
        return;
      }
      let isOk = window.confirm("해당 질문을 답변 완료 취소처리 하시겠어요?");
      if (isLoading) return;
      if (isOk) {
        setIsVisible(true);
        apiPro_complete_del();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleFilePaste = (event) => {
    setIsLoading(true);
    for (const item of event.clipboardData.items) {
      const { kind, type } = item;
      if (kind === "string" && type === "text/html") {
        item.getAsString(async (data) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(data, "text/html");
          const image = doc.querySelector("img");
          if (image) {
            try {
              const imageResponse = await fetch(image.src, { mode: "no-cors" });
              if (imageResponse?.url) {
                const imageBlob = await imageResponse.blob();
                const fileBlob = new File([imageBlob], `image.${imageBlob.type.split("/")[1]}`, {
                  type: imageBlob.type,
                });
                const reader = new FileReader();
                reader.readAsDataURL(fileBlob);
                reader.onload = () => {
                  apiPro_send_qna(1, reader.result, fileBlob);
                };
                reader.onerror = (error) => {
                  console.error(error);
                  alert("파일을 읽는도중 에러가 발생했습니다.");
                };
              } else {
                alert("이미지 붙여넣기에 실패했습니다.");
              }
            } catch (err) {
              console.error(err);
              alert("이미지 붙여넣기에 실패했습니다.");
            }
          }
        });
      } else if (type.includes("image")) {
        const blob = item.getAsFile();
        if (blob) {
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = () => {
            apiPro_send_qna(1, reader.result, blob);
          };
          reader.onerror = (error) => {
            console.error(error.target.error.code);
            alert("파일을 전송도중 에러가 발생했습니다.");
          };
        } else {
          alert("파일을 읽을 수 없습니다.");
        }
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isDetail) return;
    try {
      if (isVisible_talk) {
        connectWs();
      } else {
        setIsReconnect(false);
        ws.close();
        ws = null;
        ws.current = null;
        ws.current.onmessage = null;
        webSocketUrl.close();
        webSocketUrl = useRef(null);
        webSocketUrl.current.close();
        webSocketUrl.current = null;
      }
    } catch (e) {}
  }, [isVisible_talk]);

  useEffect(() => {
    if (isLoading) {
      scrollRef.current.scrollIntoView();
    }
  }, [isLoading]);

  useEffect(() => {
    window.scroll(0, 2000);

    if (params["where"] !== "post") {
      if (oriQnaRoom) {
        if (oriQnaRoom === params["qid"]) {
          setOriQnaRoom("");
        }
      } else {
        setOriQnaRoom("");
      }
    }
    try {
      window.addEventListener("resize", function () {
        if (window.matchMedia("(orientation: portrait)").matches) {
          // Portrait 모드일 때 실행할 스크립트
          // 폭과 높이가 같으면 Portrait 모드로 인식돼요

          let obj = {
            width: window.screen.width,
            height: window.innerHeight,
          };

          setMessage_c({
            ...message_c,
            ...obj,
          });
        } else {
          // Landscape 모드일 때 실행할 스크립트

          let obj = {
            width: window.screen.width,
            height: window.innerHeight,
          };
          setMessage_c({
            ...message_c,
            ...obj,
          });
        }
      });
      if (params["qid"] !== "0") {
        qna_pro();
      } else {
        general_pro();
      }
      if (
        hiddenUrl.includes("people/student/home") ||
        hiddenUrl.includes("people/teacher/home") ||
        hiddenUrl.includes("searchresult")
      ) {
      } else {
        setHiddenUrl("");
      }
    } catch (e) {
      console.error(e);
    }
    return () => {
      endWs();
    };
  }, [params["qid"]]);

  useEffect(() => {
    const handlingMessageTalk = (evt) => {
      const newMessage = JSON.parse(evt.data);
      if (String(newMessage.qid) === String(params.qid) && newMessage.wid !== userInfo.id)
        setTalkList((prev) => [
          {
            ...newMessage,
            name: userInfo.type === 1 ? newMessage.tname : newMessage.sname,
            nick: userInfo.type === 1 ? newMessage.tnick : newMessage.snick,
          },
          ...prev,
        ]);
    };

    websocket?.addEventListener("message", handlingMessageTalk);

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage("isVideo_off");
      document.addEventListener("message", async (e) => {
        if (e.data == "isModal") {
          setIsEdit(false);
          setIsDel(false);
        }
      });
    }
    if (
      params["where"] != "searchresult" &&
      window.localStorage.getItem("isImgSearch") !== "true"
    ) {
      setFileObj({});
    }
    return () => {
      try {
        setIsSide(false);
        endWs();
        setQuestionContent("");
        setEditModalData(EditModalEnum.EMPTY);
        websocket?.removeEventListener("messsage", handlingMessageTalk);
        document.removeEventListener("message");
      } catch (e) {
        console.error(e);
      }
    };
  }, []);

  const [addQnaArr, setAddQnaArr] = useState([]);

  let input_ref = useRef(null);
  let talk_container = useRef(null);

  var msg_complete = false; // 답변 완료 중복 체크
  const { mutateAsync: semohaeAnswerMutateAsync, isLoading: semohaeAnswerIsLoading } =
    useGetSemohaeAnswer();
  const mutation = useSaveSemohaeAnswer();
  const handleCallSemohaeAnswer = async () => {
    try {
      const response = await semohaeAnswerMutateAsync();
      setSemohaeAnswerContent(response.message);
      setShowSemohaePreAnswerView(true);
      mutation.mutate({
        questionId: params.qid,
        ownerId: params.tid,
        semohaeAnswer: response.message,
      });
    } catch (e) {
      console.error(e);
    }
  };
  const handleTogglePreAnswerView = () => {
    if (semohaeAnswerContent) {
      setShowSemohaePreAnswerView((prev) => {
        setSemohaeFoldMetaData({
          value: !prev ? "unfold" : "fold",
          key: params.qid,
        });
        return !prev;
      });
    } else {
      if (qnaData?.content && semohaeSocket) {
        handleCallSemohaeAnswer();
      }
    }
  };

  useEffect(() => {
    if (qnaData.content) {
      setQuestionContent(qnaData.content);
    }
  }, [qnaData.content]);

  const { semohaeSocket } = useSocket();

  const setLoadingMessage = useSetRecoilState(showMessageLoadingAtom);

  return (
    <div
      onMouseDown={(e) => {
        if (e.button == 2) {
        }
      }}
      className="message_talk"
      style={message_c}
    >
      <Helmet>
        <meta
          property="og:image"
          content={
            (qnaData?.["img"] ?? qnaData?.original_img)
              ? `${constants.s3_url}/qna/${qnaData?.["img"] ?? qnaData?.original_img}.jpg`
              : `${constants.apiUrl}/qna/${params["qid"]}.jpg`
          }
          data-rh="true"
        />
        <meta property="og:title" content={qnaData["title"] || "출처 없음"} data-rh="true" />
      </Helmet>
      {!isVisible ? (
        <UiLoading isText={false} text="" />
      ) : (
        <>
          <div
            style={{
              ...Message_talk_style.s_1_c,
              // 0112 주석 처음 메세지 방 들어왔을 때 질문 이미지 위 쪽에 공간 생기는 코드 (이유 모름)
              width: isFocus && "100%",
              zIndex: 130000,
            }}
          >
            {params["qid"] == 0 ? (
              <Message_talk_header_type_b
                talkList={talkList}
                userInfo={userInfo}
                headerData={qnaData}
                headerInfo={headerInfo}
                setQnaData_app={setQnaData_app}
                setFileObj={setFileObj}
                setHiddenUrl={setHiddenUrl}
                endWs={endWs}
              />
            ) : (
              <Message_talk_header_type_qna
                selectData={selectData}
                setSelectData={setSelectData}
                isDel={isDel}
                setIsDel={setIsDel}
                apiPro_cancle={apiPro_cancle}
                onCancelSuccess={onCancelSuccess}
                clipData={clipData}
                setIsLoading={setIsLoading}
                apiPro_send_qna={apiPro_send_qna}
                isMobum={isMobum}
                setIsMobum={setIsMobum}
                apiPro_understant={apiPro_understant}
                apiPro_understant_del={apiPro_understant_del}
                isModal_pick={isModal_pick}
                setIsModal_pick={setIsModal_pick}
                setIsSide={setIsSide}
                ans_cancel={ans_cancel}
                post_search={(isAnswer) => post_search(isAnswer)}
                ans_complete={ans_complete}
                talkList={talkList}
                setHiddenUrl={setHiddenUrl}
                headerData={qnaData}
                userInfo={userInfo}
                isPick={isPick}
                hiddenUrl={hiddenUrl}
                apiPro_read={apiPro_read}
                endWs={endWs}
                setSolutionList={setSolutionList}
                solutionList={solutionList}
              />
            )}
            {params["qid"] != 0 && (
              <>
                {
                  params["who"] != "student" && !qnaData["ans_date"] && <></>
                  // 0125 메세지방 디자인 수정에 따른 주석
                }
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0px 20px",
                    backgroundColor: "white",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  ></div>
                </div>

                {params["who"] == "student" &&
                qnaData["ans_date"] &&
                qnaData["understand"] == "n" &&
                params["sid"] == userInfo["id"] ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0px 20px",
                      backgroundColor: "white",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    ></div>
                  </div>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    width: "100%",
                    backgroundColor: "white",
                  }}
                >
                  <div
                    style={{
                      padding: "5px 10px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        padding: "6px 0px 2px 0px",
                      }}
                    >
                      {params["who"] == "teacher" &&
                        (params["where"] == "post" ? (
                          <div
                            style={{
                              padding: "8px 10px",
                              fontSize: "13px",
                              color: "#464241",
                            }}
                            onClick={() => {
                              if (window.localStorage.getItem("isImgSearch")) {
                                navigate("/searchresult/teacher/de/de");
                                return;
                              }
                              if (qnaData["mobum_date"] && !qnaData["ans_date"]) {
                                alert(
                                  "모범답안 등록 대기중인 답안은 링크하실 수 없어요. 해당 답변을 완료하신후 사용해주세요."
                                );
                              } else {
                                let oriData = {
                                  room: clipData["qid"],
                                  msg_id: clipData["id"],
                                  content: clipData["content"],
                                };
                                setIsLoading(true);
                                apiPro_send_qna(8, null, null, oriData);
                              }
                            }}
                          >
                            원 질문방에 방 공유
                          </div>
                        ) : params["where"] === "searchresult" && qnaData.is_answer === 1 ? (
                          <>
                            <div
                              style={{
                                padding: "8px 10px",
                                fontSize: "13px",
                                color: "#464241",
                              }}
                              onClick={() => {
                                if (qnaData["mobum_date"] && !qnaData["ans_date"]) {
                                  alert(
                                    "모범답안 등록 대기중인 답안은 링크하실 수 없어요. 해당 답변을 완료하신후 사용해주세요."
                                  );
                                } else {
                                  let oriData = {
                                    room: clipData["qid"],
                                    msg_id: clipData["id"],
                                    content: clipData["content"],
                                  };
                                  setIsLoading(true);
                                  apiPro_send_qna(8, null, null, oriData);
                                }
                              }}
                            >
                              원 질문방에 방 공유
                            </div>
                          </>
                        ) : (
                          <></>
                        ))}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div
            style={{
              ...Message_talk_style.s_2_c,
              paddingBottom: "50px",
            }}
          >
            {params["qid"] != 0 &&
              (qnaData["original"] != 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      fontSize: "14px",
                      width: "100%",
                      justifyContent: "flex-end",
                    }}
                  >
                    {params["who"] == "teacher" && qnaData["tid"] == userInfo["id"] && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <UiTextBtn
                          text={"문제 이미지 모자이크"}
                          btnStyle={{
                            color: "#464241",
                            fontSize: "10px",
                          }}
                        />
                        <Switch
                          checked={isMosaic}
                          onClick={(e) => {
                            apiPro_mosaic(isChk, qnaData["original_img"]);
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      marginTop:
                        params["who"] == "teacher" && qnaData["tid"] == userInfo["id"]
                          ? "0px"
                          : "15px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    onClick={() => {
                      navigate(
                        `/view/${params["who"]}/${params["qid"]}/${params.where === "searchresult" ? "searchresult" : "de"}/${isMosaic ? "y" : "n"}/${params["tid"]}`
                      );
                    }}
                  >
                    <img
                      className={isMosaic ? "mosaic" : null}
                      style={{
                        border: "1px solid",
                        borderColor: style.common.borderColor4,
                        width: "96%",
                        maxHeight: "60vh",
                        objectFit: "cover",
                        objectPosition: "top",
                      }}
                      onError={(e) => {
                        e.currentTarget.src = noBook;
                      }}
                      src={`${constants.s3_url}/qna/${qnaData.original !== 0 ? qnaData.original_img : qnaData.img}.jpg?${util.getRandomInt(1000, 9999)}`}
                    />
                  </div>

                  {isSubs ? (
                    <div
                      style={{
                        borderTop: "2px solid",
                        borderBottom: "2px solid",
                        borderColor: style.common.borderColor2,
                        display: "flex",
                        flexDirection: "column-reverse",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "14px",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            margin: "20px 10px 0px 10px",
                            paddingBottom: "10px",
                            fontWeight: 500,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "center",
                            }}
                          >
                            <UiTextBtn
                              fnClick={() => {
                                if (params["who"] == "teacher") {
                                  navigate(
                                    "/talk/" +
                                      params["who"] +
                                      "/" +
                                      qnaData["original"] +
                                      "/" +
                                      qnaData["original"] +
                                      "/" +
                                      qnaData["sid"] +
                                      "/" +
                                      userInfo["id"]
                                  );
                                } else {
                                  navigate(
                                    "/talk/" +
                                      params["who"] +
                                      "/" +
                                      qnaData["original"] +
                                      "/" +
                                      qnaData["original"] +
                                      "/" +
                                      userInfo["id"] +
                                      "/" +
                                      qnaData["tid"]
                                  );
                                }
                              }}
                              btnStyle={{
                                fontSize: "16px",
                                fontWeight: 700,
                                color: style.common.fontColor_3,
                              }}
                              text={"이전질문방링크"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      style={{
                        borderTop: "2px solid",
                        borderBottom: "2px solid",
                        borderColor: style.common.borderColor2,
                        display: "flex",
                        flexDirection: "column-reverse",
                      }}
                    >
                      {addQnaArr.map((v, i) => (
                        <AddSection
                          setHiddenUrl={setHiddenUrl}
                          senderInfo={senderInfo}
                          setSenderInfo={setSenderInfo}
                          videoInfo={videoInfo}
                          setVideoInfo={setVideoInfo}
                          youtube_url={youtube_url}
                          setYoutube_url={setYoutube_url}
                          addQnaArr={addQnaArr}
                          setAddQnaArr={setAddQnaArr}
                          addQnaData={v}
                          qid={v["id"]}
                          userInfo={userInfo}
                        />
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <div
                  style={{
                    borderTop: "2px solid",
                    borderBottom: "2px solid",
                    borderColor: style.common.borderColor2,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      fontSize: "14px",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "5px 0px 5px 0px",
                        flex: 1,
                      }}
                    >
                      <div
                        style={{
                          padding: "5px 10px",
                          display: "flex",
                          fontFamily: "Noto Sans",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "14px",
                          color: "#3D3A39",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            width: "36px",
                          }}
                        >
                          교재
                        </div>
                        {
                          <div
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              wordWrap: "break-word",
                              lineHeight: "1.6em",
                              maxHeight: "1.6em",
                              minHeight: "1.6em",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontFamily: "Noto Sans",
                              fontStyle: "normal",
                              fontWeight: 300,
                              fontSize: "12px",
                              color: "#808080",
                              marginLeft: "2px",
                            }}
                            onClick={() => {}}
                          >
                            {qnaData["name"] ? qnaData["name"] : "미지정"}
                            {params["who"] == "teacher" && params["tid"] == userInfo["id"] && (
                              <img
                                src={icon_movebook}
                                style={{
                                  width: "20px",
                                  height: "16px",
                                  marginLeft: "8px",
                                }}
                                onClick={() => {
                                  setIsModal_book(true);
                                }}
                              />
                            )}
                          </div>
                        }
                      </div>
                      <div
                        style={{
                          padding: "5px 10px",
                          display: "flex",
                          fontFamily: "Noto Sans",
                          alignItems: "center",
                          fontStyle: "normal",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "14px",
                          color: "#3D3A39",
                        }}
                      >
                        <div
                          style={{
                            width: "36px",
                          }}
                        >
                          출처
                        </div>
                        {isTitle ? (
                          <div
                            style={{
                              display: "flex",
                              minWidth: "40px",
                              margin: "0px 3px",
                            }}
                          >
                            <UiInput
                              basic_container_style={{
                                height: "100%",
                              }}
                              placeholder={qnaData["title"]}
                              fnChange={(e) => {
                                setTitle(e.target.value);
                              }}
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "-webkit-box",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              wordWrap: "break-word",
                              lineHeight: "1.6em",
                              maxHeight: "1.6em",
                              minHeight: "1.6em",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              fontFamily: "Noto Sans",
                              fontStyle: "normal",
                              fontWeight: 300,
                              fontSize: "14px",
                              color: "#808080",
                              marginLeft: "2px",
                              marginRight: "5px",
                            }}
                          >
                            {qnaData["title"] ? qnaData["title"] : ""}
                          </div>
                        )}
                        {(params["tid"] == userInfo["id"] || params["sid"] == userInfo["id"]) &&
                          (!isTitle ? (
                            <DriveFileRenameOutline
                              style={{
                                fontSize: "19px",
                                color: "#4496F0",
                              }}
                              onClick={() => {
                                setIsTitle(true);
                              }}
                            />
                          ) : (
                            <div>
                              <CheckCircleOutlined
                                style={{
                                  fontSize: "20px",
                                  color: "#4496F0",
                                }}
                                onClick={() => {
                                  apiPro_modiTitle();
                                }}
                              />
                              <HighlightOff
                                style={{
                                  fontSize: "20px",
                                  color: style.common.fontColor_3,
                                }}
                                onClick={() => {
                                  setIsTitle(false);
                                }}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>

                  {!(qnaData.img && qnaData.original_img) ? (
                    <VideoQuestionThumbnail
                      videoQuestion={talkList.filter((talk) => talk.ctype === 91)?.[0]}
                      onClick={(videoQuestion) => {
                        setQnaData_app((prev) => ({
                          ...prev,
                          isVideoQuestion: talkList.filter((talk) => talk.ctype === 91).length > 0,
                        }));
                        setVideoInfo({
                          video_start: videoQuestion?.video_start,
                          video_end: videoQuestion?.video_end,
                          video_link: videoQuestion?.content,
                        });
                        navigate(
                          `/view/${params.who}/${qnaData.qid}/video&${encodeURIComponent(videoQuestion?.content)}/n/${params.tid}`
                        );
                      }}
                    />
                  ) : (
                    <Slider
                      infinite={false}
                      slidesToShow={1}
                      adaptiveHeight={true}
                      slidesToScroll={1}
                      dots={false}
                      arrows={false}
                    >
                      <div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={() => {
                            if ([1, 2].includes(qnaData?.book_type) && params?.who === "teacher") {
                              navigate(
                                `/pdfView/${qnaData?.bid}?questionId=${qnaData?.qid}&prevStep=talk&type=${qnaData.is_answer === 1 ? "answer" : "question"}&prevQuestion=${params?.qid}`
                              );
                            } else {
                              navigate(
                                `/view/${params["who"]}/${params["qid"]}/${params.where === "searchresult" ? "searchresult" : "de"}/${isMosaic ? "y" : "n"}/${params["tid"]}`
                              );
                            }
                          }}
                        >
                          <img
                            className={isMosaic ? "mosaic" : null}
                            style={{
                              border: "1px solid",
                              borderColor:
                                params["who"] == "student"
                                  ? style.common.s_color
                                  : style.common.t_color,
                              borderRadius: 10,
                              width: "96%",
                              maxHeight: "60vh",
                              objectFit: "cover",
                              objectPosition: "top",
                            }}
                            onError={(e) => {
                              e.currentTarget.src = noBook;
                            }}
                            src={
                              qnaData["img"]
                                ? `${constants.s3_url}/qna/${qnaData["img"]}.jpg?${util.getRandomInt(1000, 9999)}`
                                : `${constants.apiUrl}/qna/${params["qid"]}.jpg?${util.getRandomInt(1000, 9999)}`
                            }
                          />
                        </div>
                      </div>

                      {talkList
                        .filter((talk) => talk.ctype === 90)
                        .map((talk) => {
                          return (
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                                onClick={() => {
                                  navigate(
                                    `/view/${params["who"]}/${params["qid"]}/${talk.content}/${isMosaic ? "y" : "n"}/${params["tid"]}`
                                  );
                                }}
                              >
                                <img
                                  className={isMosaic ? "mosaic" : null}
                                  style={{
                                    border: "1px solid",
                                    borderColor:
                                      params["who"] == "student"
                                        ? style.common.s_color
                                        : style.common.t_color,
                                    borderRadius: 10,
                                    width: "96%",
                                    maxHeight: "60vh",
                                    objectFit: "cover",
                                    objectPosition: "top",
                                  }}
                                  onError={(e) => {
                                    e.currentTarget.src = noBook;
                                  }}
                                  src={`${constants.apiUrl}/qna/${params.qid}/${talk.content}`}
                                />
                              </div>
                            </div>
                          );
                        })}
                    </Slider>
                  )}
                </div>
              ))}
            {params["qid"] != 0 ? (
              <div
                style={{
                  color: "#515151",
                  padding: "5px 10px 5px 10px",

                  fontSize: "12px",
                  fontWeight: 400,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxSizing: "border-box",
                    width: "150px",
                    height: "24px",
                    background: "rgb(240,240,240)",
                    borderRadius: "10px",
                    color: "#515151",
                  }}
                >
                  {`
                            ${
                              qnaData["qna_date"]
                                ? util.convertToDateTime_ymdhm_dot(qnaData["reg_date"])
                                : qnaData["reg_date"]
                                  ? util.convertToDateTime_ymdhm_dot(qnaData["reg_date"])
                                  : ""
                            } 
                  `}
                </div>
              </div>
            ) : (
              <></>
            )}

            <div
              style={{
                display: "flex",
                flexDirection: "column-reverse",
              }}
              ref={talk_container}
            >
              {talkList
                .filter((talk) => ![90, 70].includes(talk.ctype))
                .map((v, i) => {
                  let isRef = false;
                  if (i == 0) {
                    isRef = true;
                  }

                  if (v["ctype"] == 10) {
                  }
                  if (v["ctype"] == 6) {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          padding: "10px 10px",
                          fontSize: "12px",
                          color: style.common.fontColor_3,
                          fontWeight: "bold",
                        }}
                      >
                        - {v["content"]} 님에게 질문이 이관되었어요. -
                      </div>
                    );
                  }
                  if (v["ctype"] == 7) {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          padding: "10px 10px",
                          fontSize: "12px",
                          color: style.common.fontColor_3,
                          fontWeight: "bold",
                        }}
                      >
                        - {v["content"]} -
                      </div>
                    );
                  } else if (v["ctype"] == 9) {
                  } else if (v["ctype"] != 10) {
                    return (
                      <Talk
                        index={i}
                        setHiddenUrl={setHiddenUrl}
                        senderInfo={senderInfo}
                        setSenderInfo={setSenderInfo}
                        videoInfo={videoInfo}
                        setVideoInfo={setVideoInfo}
                        youtube_url={youtube_url}
                        setYoutube_url={setYoutube_url}
                        isRef={isRef}
                        key={"talk_" + v["id"]}
                        data={v}
                        userInfo={userInfo}
                        isEdit={isDel}
                        fnClick={(data) => {
                          setClipText(data["content"]);
                          if (clipData["room"]) {
                          } else {
                            setClipData({ ...data });
                          }
                        }}
                        fnLongClick={(data) => {
                          let pass = true;
                          if (params["qid"] != 0 && data["wid"] != userInfo["id"]) pass = false;
                          if (params["tid"] == qnaData["tid"]) pass = true;
                          if (!pass) return;
                          if (clipData["room"]) {
                          } else {
                            setClipData({ ...data });
                          }
                          setClipText(data["content"]);
                          setIsEdit(true);
                          util.postWeb("isModal");
                        }}
                        fnCheck={(e) => {
                          if (selectData[e.target.id] == "Y") {
                            delete selectData[e.target.id];
                            setSelectData({
                              ...selectData,
                            });
                          } else {
                            setSelectData({
                              ...selectData,
                              [e.target.id]: "Y",
                            });
                          }
                        }}
                      />
                    );
                  } else if (parseInt(Number(v.ctype) / 10) === 9) {
                    return <div>추가 문제 자료</div>;
                  } else {
                    if (!msg_complete) {
                      msg_complete = true;
                      return;
                      // (
                      // <div
                      //   style={{
                      //     display: "flex",
                      //     alignItems: "center",
                      //     flexDirection: "column",
                      //     width: "100%",
                      //     padding: "40px 0px 50px 0px",
                      //   }}
                      // >
                      //   <div
                      //     style={{
                      //       boxSizing: "border-box",
                      //       width: "140px",
                      //       height: "22px",
                      //       background: "#E29092",
                      //       borderRadius: "10px",
                      //       fontFamily: "Noto Sans",
                      //       fontStyle: "normal",
                      //       fontWeight: 400,
                      //       fontSize: "10px",
                      //       display: "flex",
                      //       color: "white",
                      //       alignItems: "center",
                      //       justifyContent: "center",
                      //     }}
                      //   >
                      //     답변이 완료되었습니다.
                      //   </div>
                      // </div>
                      // );
                    }
                  }
                })}
            </div>
            {/*선생님일 때만, 시스템 메시지 방에서는 세모해 컴포넌트가 보이지 않도록*/}
            {params.who === "teacher" &&
              qnaData?.invite?.includes(userInfo.id) &&
              params.sid !== "hiqsum_admin" && (
                <>
                  {questionContent === "문제 OCR 추출에 실패했습니다." &&
                  !semohaeAnswerIsLoading ? (
                    <div
                      style={{
                        background: "#FFF",
                        borderRadius: "10px",
                        textAlign: "center",
                        width: "90%",
                        maxWidth: "400px",
                        margin: "0 auto",
                        padding: "8px",
                        lineHeight: "1.2",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        OCR 인식에 실패한 문제입니다.
                      </p>
                      <p>
                        <span style={{ color: "red" }}>과거 내 질문 검색</span>,
                        <span style={{ color: "red" }}>세모해 답변 받기</span> 기능이
                        비활성화되었습니다. <br />
                        기능을 활성화하려면 OCR 재인식하기 버튼을 클릭해 주세요.
                      </p>

                      <button
                        style={{
                          padding: "8px 16px",
                          background: "#ff595c",
                          color: "#FFF",
                          fontSize: "14px",
                          borderRadius: "8px",
                        }}
                        onClick={() => setEditModalData(EditModalEnum.QUESTION)}
                      >
                        OCR 재인식
                      </button>
                    </div>
                  ) : (
                    <>
                      {!talkList.some((talk) => talk.ctype === 91) && (
                        <>
                          {semohaeAnswerIsLoading ? (
                            <LoadingView />
                          ) : (
                            <>
                              {showSemohaePreAnswerView ? (
                                <PreAnswerView
                                  onSendAnswer={async (answerContent) =>
                                    apiPro_send_qna(
                                      71,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      answerContent
                                    )
                                  }
                                  onRefreshAnswer={handleCallSemohaeAnswer}
                                  onHidePreAnswerView={handleTogglePreAnswerView}
                                />
                              ) : (
                                <div
                                  style={{
                                    position: "fixed",
                                    right: "20px",
                                    bottom: "85px",
                                    background: "transparent",
                                  }}
                                >
                                  <Button
                                    variant="contained"
                                    sx={{ background: "#1F1F1F", borderRadius: "99px", py: "20px" }}
                                    onClick={handleTogglePreAnswerView}
                                  >
                                    <IAi />
                                    Ai답변 받기
                                  </Button>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}

            {isLoading && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  zIndex: 9999999999999999,
                }}
                ref={scrollRef}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignIsemohaeAnswertems: "center",
                    marginRight: "10px",
                    width: "150px",
                    padding: "10px",
                    borderRadius: 10,
                    color: style.common.t_color,
                    backgroundColor: "white",
                  }}
                >
                  <CircularProgress
                    style={{
                      width: "30px",
                      color: style.common.t_color,
                    }}
                  />
                  파일 업로드 중입니다...
                </div>
              </div>
            )}
          </div>

          <div
            style={{
              ...Message_talk_style.s_3_c,
              display:
                isComplete &&
                params["who"] == "teacher" &&
                qnaData?.tid !== userInfo?.id &&
                !qnaData?.invite?.includes(userInfo?.id) &&
                !isDel &&
                "none",
            }}
          >
            {isComplete &&
            qnaData?.tid !== userInfo?.id &&
            qnaData.sid !== userInfo?.id &&
            !qnaData?.invite?.includes(userInfo?.id) ? (
              params["who"] != "teacher" ? (
                <div style={{ ...Message_talk_style.s_3_type_1_c, padding: "10px 0px" }}>
                  {
                    <div
                      style={Message_talk_style.s_3_type_1_btn_w}
                      onClick={async () => {
                        let isOk = window.confirm("이 질문에 대하여 이어 질문 하시겠어요?");
                        if (!isOk) return;
                        if (qnaData["bid"] != 0 && params["sid"] != userInfo["id"]) {
                          navigate(
                            `/messageteacher/${params["who"]}/${qnaData["bid"]}/${params["qid"]}/${params["sid"]}/${params["tid"]}`
                          );
                        } else {
                          let studentId = userInfo["id"],
                            teacherId = qnaData["tid"];
                          startPickTicketType({
                            bookId: qnaData?.bid ? parseInt(qnaData.bid) : 0,
                            studentId,
                            teacherId,
                            originalQid: parseInt(params["qid"]),
                            academyId,
                          });
                        }
                      }}
                    >
                      <img
                        src={add_qna}
                        style={{
                          ...Message_talk_style.s_3_type_1_btn_1,
                          margin: "0px 5px 0px 10px",
                        }}
                      />
                      <div
                        style={{
                          fontSize: "13px",
                          margin: "0px 0px",
                          color: "#828282",
                        }}
                      >
                        이어 질문하기
                      </div>
                    </div>
                  }
                  {/*{qnaData["member_cnt"] <= 2 && (*/}
                  {/*  <div*/}
                  {/*    style={Message_talk_style.s_3_type_1_btn_w}*/}
                  {/*    onClick={() => {*/}
                  {/*      navigate(*/}
                  {/*        `/review/${params["who"]}/${params["where"]}/${params["qid"]}/${params["sid"]}/${params["tid"]}`*/}
                  {/*      );*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    <img src={answer} style={Message_talk_style.s_3_type_1_btn_2} />*/}
                  {/*    <div*/}
                  {/*      style={{*/}
                  {/*        fontSize: "13px",*/}
                  {/*        margin: "0px 5px",*/}
                  {/*        color: "#828282",*/}
                  {/*      }}*/}
                  {/*    >*/}
                  {/*      평가 등록하기*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*)}*/}
                </div>
              ) : (
                isDel &&
                (params["where"] == "post" ? (
                  <></>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "50px",
                      color: style.common.fontColor_3,
                    }}
                    onClick={() => {
                      apiPro_del();
                    }}
                  >
                    삭제하기
                  </div>
                ))
              )
            ) : (
              <div
                className="bottom_box"
                style={{
                  zIndex: 400,
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  justifyContent: "space-between",
                }}
              >
                {params["where"] != "post" &&
                  params["sid"] != "hiqsum_admin" &&
                  params["tid"] != "hiqsum_admin" &&
                  !isDel &&
                  (params["who"] == "teacher"
                    ? params["qid"] == 0
                      ? true
                      : qnaData["sid"] == userInfo["id"] ||
                        qnaData["tid"] == userInfo["id"] ||
                        qnaData["invite"]?.includes(userInfo["id"]) ||
                        isBookAdmin
                    : params["qid"] == 0
                      ? true
                      : false) && (
                    <div
                      style={{
                        ...Message_talk_style.s_3_type_2_c,
                        display: "flex",
                        padding: "12px 4px 12px 8px",
                      }}
                      onContextMenu={(e) => e.preventDefault()}
                    >
                      <UiTextBtn
                        text={
                          <Add
                            style={{
                              fontSize: "30px",
                            }}
                          />
                        }
                        isUnderline={false}
                        accept={params["who"] == "student" ? "image/*" : "*"}
                        btnStyle={{
                          color: "black",
                        }}
                        fnClick={(e) => {
                          setIsAttach(true);
                          return;
                        }}
                        isTeacher={params["who"] == "teacher" ? true : false}
                      />
                      <TextareaAutosize
                        onKeyDown={(e) => {
                          if (
                            e.code === "Enter" &&
                            !e.shiftKey &&
                            e.nativeEvent.isComposing === false
                          ) {
                            e.preventDefault();
                            if (talk == "") return;
                            if (params["qid"] == 0) {
                              apiPro_send();
                            } else {
                              if (
                                talk.includes("://www.youtube.com/") ||
                                talk.includes("://youtu.be/")
                              ) {
                                apiPro_send_qna(2);
                              } else {
                                apiPro_send_qna(0);
                              }
                            }
                            window.scrollTo(0, 3000);
                            setTalk("");
                            setIsText(false);
                          }
                        }}
                        onPaste={(e) => handleFilePaste(e)}
                        ref={input_ref}
                        onFocus={() => {
                          setIsFocus(true);
                          setTimeout(() => {
                            if (talk_container?.current?.children?.length > 0) {
                              talk_container.current.children[0].scrollIntoView({
                                block: "center",
                              });
                            }
                          }, 300);
                        }}
                        onBlur={() => {
                          setIsFocus(false);
                        }}
                        value={talk.replace(/<br>/gi, "\n")}
                        aria-label="maximum height"
                        placeholder=""
                        style={{
                          ...Message_talk_style.s_3_type_2_desc,
                          maxHeight: "60px",
                          overflowY: "scroll",
                          padding: "10px 10px 10px 10px",
                        }}
                        onChange={(e) => {
                          if (e.target.value == "") {
                            setIsText(false);
                            setTalk("");
                          } else {
                            setTalk(`${e.target.value.replace(/\n/g, `<br>`)}`);
                            setIsText(true);
                          }
                        }}
                      />
                      {
                        <SendBtn
                          params={params}
                          fnClick={() => {
                            if (talk == "") return;
                            if (params["qid"] == 0) {
                              apiPro_send();
                            } else {
                              if (
                                talk.includes("://www.youtube.com/") ||
                                talk.includes("://youtu.be/")
                              ) {
                                apiPro_send_qna(2);
                              } else {
                                apiPro_send_qna(0);
                              }
                            }
                            window.scrollTo(0, 3000);
                            setTalk("");
                            input_ref.current.focus();
                            setIsText(false);
                          }}
                        />
                      }
                    </div>
                  )}
                {isDel ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "50px",
                      color: style.common.fontColor_3,
                    }}
                    onClick={() => {
                      apiPro_del();
                    }}
                  >
                    삭제하기
                  </div>
                ) : (
                  params["who"] == "student" &&
                  (params["qid"] == 0
                    ? !isText && <></>
                    : qnaData["sid"] == userInfo["id"] ||
                        (qnaData["invite"] || []).includes(userInfo["id"])
                      ? params["where"] != "post" && (
                          <div
                            style={{
                              ...Message_talk_style.s_3_type_2_c,
                              display: "flex",
                              padding: "12px 4px 12px 8px",
                            }}
                            onContextMenu={(e) => e.preventDefault()}
                          >
                            <UiTextBtn
                              text={
                                <Add
                                  style={{
                                    fontSize: "30px",
                                  }}
                                />
                              }
                              isUnderline={false}
                              accept={params["who"] == "student" ? "image/*" : "*"}
                              btnStyle={{
                                color: "black",
                              }}
                              fnClick={(e) => {
                                setIsAttach(true);
                                return;
                              }}
                              isTeacher={params["who"] == "teacher" ? true : false}
                            />
                            <TextareaAutosize
                              onKeyDown={(e) => {
                                if (
                                  e.code === "Enter" &&
                                  !e.shiftKey &&
                                  e.nativeEvent.isComposing === false
                                ) {
                                  e.preventDefault();
                                  if (talk == "") return;
                                  if (params["qid"] == 0) {
                                    apiPro_send();
                                  } else {
                                    if (
                                      talk.includes("://www.youtube.com/") ||
                                      talk.includes("://youtu.be/")
                                    ) {
                                      apiPro_send_qna(2);
                                    } else {
                                      apiPro_send_qna(0);
                                    }
                                  }
                                  window.scrollTo(0, 3000);
                                  setTalk("");
                                  setIsText(false);
                                }
                              }}
                              onPaste={(e) => handleFilePaste(e)}
                              ref={input_ref}
                              onKeyUp={(e) => {
                                if (e.keyCode == "13") {
                                }
                              }}
                              onFocus={() => {
                                setIsFocus(true);
                                setTimeout(() => {
                                  if (talk_container.current.children.length > 0) {
                                    talk_container.current.children[0].scrollIntoView({
                                      block: "center",
                                    });
                                  }
                                }, 300);
                              }}
                              onBlur={() => {
                                setIsFocus(false);
                              }}
                              value={talk.replace(/<br>/gi, "\n")}
                              aria-label="maximum height"
                              placeholder=""
                              style={{
                                ...Message_talk_style.s_3_type_2_desc,
                                maxHeight: "60px",
                                overflowY: "scroll",
                                padding: "10px 10px 10px 10px",
                              }}
                              onChange={(e) => {
                                if (e.target.value == "") {
                                  setIsText(false);
                                  setTalk("");
                                } else {
                                  setTalk(`${e.target.value.replace(/\n/g, `<br>`)}`);
                                  setIsText(true);
                                }
                              }}
                            />
                            {
                              <SendBtn
                                params={params}
                                fnClick={() => {
                                  if (talk == "") return;
                                  if (params["qid"] == 0) {
                                    setIsText(false);
                                    apiPro_send();
                                  } else {
                                    setIsText(false);
                                    if (
                                      talk.includes("://www.youtube.com/") ||
                                      talk.includes("://youtu.be/")
                                    ) {
                                      apiPro_send_qna(2);
                                    } else {
                                      apiPro_send_qna(0);
                                    }
                                  }
                                  setTalk("");
                                  window.scrollTo(0, 1500);
                                  input_ref.current.focus();
                                }}
                              />
                            }
                          </div>
                        )
                      : !isText && <></>)
                )}
              </div>
            )}
          </div>
          {isEdit && (
            <div
              style={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                top: 0,
                backgroundColor: "rgba(200,200,200,0.5)",
                maxWidth: style.common.maxWidth,
              }}
              onClick={() => {
                if (isEdit) {
                  setIsEdit(false);
                  util.postWeb("isModal_off");
                }
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  minWidth: "100px",
                  backgroundColor: "white",
                  padding: "10px",
                  borderRadius: 5,
                  border: "1px solid",
                  borderColor: style.common.borderColor2,
                }}
              >
                <div
                  style={{
                    padding: "5px 0px 10px 0px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    border: "none",
                  }}
                  onClick={async (e) => {
                    await util.copyClip(clipText);
                    setIsEdit(false);
                    util.postWeb("isModal_off");
                  }}
                >
                  복사
                </div>
                {params["who"] == "teacher" && (
                  <div
                    style={{
                      borderTop: "1px solid",
                      borderColor: style.common.borderColor2,
                      paddingTop: "10px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    onClick={(e) => {
                      setIsDel(true);
                      setIsEdit(false);
                      e.stopPropagation();
                    }}
                  >
                    삭제
                  </div>
                )}
                {params["who"] == "teacher" && params["where"] == "post" && (
                  <div
                    style={{
                      borderTop: "1px solid",
                      borderColor: style.common.borderColor2,
                      paddingTop: "10px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    onClick={(e) => {
                      let oriData = {
                        room: clipData["qid"],
                        msg_id: clipData["id"],
                        content: clipData["content"],
                        play_time: clipData["play_time"],
                        video_end: clipData["video_end"],
                        video_start: clipData["video_start"],
                      };

                      if (clipData["ori_id"]) {
                        if (clipData["ori_id"].includes("&")) {
                          oriData["room"] = clipData["ori_id"].split("&")[0];
                          oriData["msg_id"] = clipData["ori_id"].split("&")[1];
                        }
                      }
                      setIsLoading(true);
                      apiPro_send_qna(clipData["ctype"], null, null, oriData);
                      setIsEdit(false);
                      util.postWeb("isModal_off");
                      e.stopPropagation();
                    }}
                  >
                    원 질문방에 전송
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
      {isAttach && (
        <div
          style={{
            position: "fixed",
            top: 0,
            display: "flex",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(200,200,200,0.5)",
            zIndex: 99999998,
            maxWidth: style.common.maxWidth,
          }}
          onClick={(e) => {
            setIsAttach(false);
          }}
        >
          <div
            style={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              height: "130px",
              padding: "10px 0px",
              borderTop: "1px solid",
              borderTopColor: style.common.borderColor,
              backgroundColor: "white",
              zIndex: 99999999,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              style={{
                zIndex: 99999999,
                padding: "5px 0px 20px 15px",
                fontSize: "15px",
              }}
            >
              파일 공유
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              <div style={Message_talk_style.attach_img_wrapper}>
                <input
                  type={"file"}
                  accept={"image/*"}
                  id="img_file"
                  onChange={(e) => {
                    setIsLoading(true);
                    fileOnChange(e);
                  }}
                  style={{
                    display: "none",
                  }}
                />
                <label
                  htmlFor="img_file"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <img src={add_image} style={Message_talk_style.attach_img} />
                  이미지
                </label>
              </div>
              {params["who"] == "teacher" && (
                <>
                  <div style={Message_talk_style.attach_img_wrapper}>
                    <input
                      type={"file"}
                      accept={"video/*"}
                      id="video_file"
                      onChange={(e) => {
                        setIsLoading(true);
                        fileOnChange(e);
                      }}
                      style={{
                        display: "none",
                      }}
                    />
                    <label
                      htmlFor="video_file"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <img src={add_movie} style={Message_talk_style.attach_img} />
                      로컬 영상
                    </label>
                  </div>
                  <div
                    style={Message_talk_style.attach_img_wrapper}
                    onClick={() => {
                      if (params["qid"] == 0) {
                        setFileObj({
                          tid: params["tid"],
                          sid: params["sid"],
                        });
                      }
                      navigate(`/video/${params["who"]}/ms&${params["qid"]}`);
                    }}
                  >
                    <img
                      src={add_link}
                      style={{
                        ...Message_talk_style.attach_img,
                        width: "48px",
                      }}
                    />
                    영상 링크
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {isSide && (
        <Message_talk_side
          setQnaData={setQnaData}
          isMosaic={isMosaic}
          isBookAdmin={isBookAdmin}
          apiPro_mosaic={apiPro_mosaic}
          setIsModal_book={setIsModal_book}
          isPick={isPick}
          setIsPick={setIsPick}
          setIsModal={setIsModal}
          setIsModal_invite={setIsModal_invite}
          setIsModal_pick={setIsModal_pick}
          isDetail={isDetail}
          setIsDetail={setIsDetail}
          userInfo={userInfo}
          setIsSide={setIsSide}
          apiPro_evaluate_lv={apiPro_evaluate_lv}
          apiPro_evaluate_cal={apiPro_evaluate_cal}
          isChk={isChk}
          apiPro_public={apiPro_public}
          qnaData={qnaData}
          isModal_invite={isModal_invite}
        />
      )}
      {isModal && (
        <Modal qnaData={qnaData} userInfo={userInfo} setIsModal={setIsModal} isModal={isModal} />
      )}
      {isModal_invite && (
        <Modal_invite_msg
          setQnaData={setQnaData}
          qnaData={qnaData}
          userInfo={userInfo}
          setIsModal={setIsModal_invite}
          isModal={isModal_invite}
        />
      )}
      {isModal_pick && (
        <Modal_pick
          setIsPick={setIsPick}
          qnaData={qnaData}
          userInfo={userInfo}
          setIsModal={setIsModal}
          setIsModal_pick={setIsModal_pick}
          isModal_pick={isModal_pick}
          isModal={isModal}
        />
      )}
      {isModal_book && (
        <Modal_book
          apiPro_read_qna={apiPro_read_qna}
          qnaData={qnaData}
          userInfo={userInfo}
          setIsModal={setIsModal_book}
          isModal={isModal_book}
        />
      )}
      <CaptureSizeSheet />
      <CaptureStateModal />
      <EditAnswerModal
        requestEditAnswer={(editAnswerContent) => {
          mutation.mutate({
            questionId: params.qid,
            ownerId: userInfo.id,
            semohaeAnswer: editAnswerContent,
          });
        }}
      />
      <EditQuestionModal onFinishEditQuestion={handleCallSemohaeAnswer} />
    </div>
  );
};

const AddSection = ({
  addQnaArr,
  setAddQnaArr,
  addQnaData,
  userInfo,
  qid,
  setHiddenUrl,
  senderInfo,
  setSenderInfo,
  videoInfo,
  setVideoInfo,
  youtube_url,
  setYoutube_url,
}) => {
  const [isAdd, setIsAdd] = useState(false);

  return (
    <div
      style={{
        fontSize: "14px",
        width: "100%",
      }}
    >
      <div
        style={{
          margin: "0px 10px 0px 10px",
          paddingBottom: "10px",
          fontWeight: 500,
        }}
      >
        {/* 원문항 */}
        {isAdd && (
          <AddMsg
            setHiddenUrl={setHiddenUrl}
            senderInfo={senderInfo}
            setSenderInfo={setSenderInfo}
            videoInfo={videoInfo}
            setVideoInfo={setVideoInfo}
            youtube_url={youtube_url}
            setYoutube_url={setYoutube_url}
            addQnaArr={addQnaArr}
            setAddQnaArr={setAddQnaArr}
            qid={qid}
            qData={addQnaData}
            userInfo={userInfo}
          />
        )}
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            padding: "10px 0px",
          }}
        >
          <UiTextBtn
            fnClick={() => setIsAdd(!isAdd)}
            btnStyle={{
              fontSize: "16px",
              fontWeight: 700,
              color: style.common.fontColor_3,
            }}
            text={
              addQnaData["original"] == 0
                ? isAdd
                  ? "원대화 접기"
                  : "원대화 펴기"
                : isAdd
                  ? "이전 대화 접기"
                  : "이전 대화 펴기"
            }
          />
        </div>
        {isAdd && (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "4px",
              backgroundColor: "#b5b5b5",
              border: "1px dashed white",
              marginTop: "10px",
            }}
          ></div>
        )}
      </div>
    </div>
  );
};

const AddMsg = ({
  qid,
  qData,
  userInfo,
  addQnaArr,
  setAddQnaArr,
  setHiddenUrl,
  senderInfo,
  setSenderInfo,
  videoInfo,
  setVideoInfo,
  youtube_url,
  setYoutube_url,
}) => {
  let params = useParams();

  const [talkList, setTalkList] = useState([]);

  const apiPro_read_addMsg = async () => {
    try {
      let state = {
        table: "app_msg",
        sort: "reg_date desc",
        read: {
          sid: params["sid"],
          tid: params["tid"],
          wid: userInfo["id"],
        },
        where: {
          "HIQDB_app_msg.sid": `='${params["sid"]}'`,
          "HIQDB_app_msg.tid": `='${params["tid"]}'`,
          "HIQDB_app_msg.dell": `='0'`,
          "HIQDB_app_msg.wtype": `in ('1','2')`,
        },
        ref: [
          "A.nick, A.img as s_img|HIQDB_user A on A.index=HIQDB_user_credential.user_id and A.user_type = 1 and HIQDB_online_qna_msg.wid = HIQDB_user_credential.name",
          "B.name, B.img as t_img|HIQDB_user B on B.index=HIQDB_user_credential.user_id and B.user_type = 2 and HIQDB_online_qna_msg.wid = HIQDB_user_credential.name",
        ],
      };

      if (params["sid"] == "hiqsum_admin" || params["tid"] == "hiqsum_admin") {
        state["ref"] = [];
      }

      if (params["qid"] != 0) {
        state["table"] = "online_qna_msg";
        state["read"] = {
          wid: userInfo["id"],
          qid: qData["id"],
        };
        state["where"] = {
          "HIQDB_online_qna_msg.qid": `= '${qData["id"]}'`,
          "HIQDB_online_qna_msg.dell": `= '0'`,
        };
        state["left"] = [
          "A.nick, A.img as s_img|HIQDB_user_credential X1 ON HIQDB_online_qna_msg.wid = X1.name LEFT OUTER JOIN HIQDB_user A on A.index=X1.user_id and A.user_type = 1",
          "B.name, B.img as t_img|HIQDB_user_credential X2 ON HIQDB_online_qna_msg.wid = X2.name LEFT OUTER JOIN HIQDB_user B on B.index=X2.user_id and B.user_type = 2",
        ];
        delete state["ref"];
      }

      let data = await apiFn.comApi({
        state: {
          page: 1,
          amount: 9999,
          ...state,
        },
      });

      setTalkList([...data["list"]]);
    } catch (e) {
      console.error("e e : ", e);
    }
  };

  useEffect(() => {
    apiPro_read_addMsg();
  }, [params.qid]);

  let msg_complete = false; // 답변 완료 중복 체크

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column-reverse",
      }}
    >
      {talkList
        .filter((talk) => ![90].includes(talk.ctype))
        .map((v, i) => {
          let isRef = false;
          if (i == 0) {
          }

          if (v["ctype"] == 10) {
          } else if (v["ctype"] == 9) {
          } else if (v["ctype"] != 10) {
            return (
              <Talk
                index={i}
                setHiddenUrl={setHiddenUrl}
                senderInfo={senderInfo}
                setSenderInfo={setSenderInfo}
                videoInfo={videoInfo}
                setVideoInfo={setVideoInfo}
                youtube_url={youtube_url}
                setYoutube_url={setYoutube_url}
                key={"talk_" + v["id"]}
                data={v}
                userInfo={userInfo}
                fnClick={(data) => {}}
                fnLongClick={(data) => {}}
                fnCheck={(e) => {}}
              />
            );
          } else {
            if (!msg_complete) {
              msg_complete = true;
            }
          }
        })}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: "5px",
        }}
      >
        <div
          style={{
            color: params["who"] == "teacher" ? style.common.t_color : style.common.s_color,
            width: "96%",
            padding: "10px 0px",
          }}
        >
          {/* 질문 일자 질문자(닉네임) */}
          {`${util.convertToDateTime_full(qData["reg_date"])} ${qData["nick"] === undefined ? qData.teacherName : qData.nick}`}
        </div>
      </div>
    </div>
  );
};

const SendBtn = ({ fnClick, params }) => {
  return (
    <div style={Message_talk_style.s_3_type_2_btn_w}>
      <div
        style={{
          padding: "2px",
          width: "34px",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: params["who"] == "teacher" ? "#5EA3C6" : "#FFC133",
          borderRadius: 13,
        }}
        onClick={fnClick}
      >
        <img
          src={image_48}
          style={{
            width: "16px",
            height: "12px",
          }}
        />
      </div>
    </div>
  );
};

export default Message_talk;
