import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import apiFn from "../../asset/apiClass";
import style from "../../asset/style";
import UiPeople_subs from "../../uiComponents/UiPeople/UiPeople_subs";
import UiSorting from "../../uiComponents/UiSorting/UiSorting";
import UiTextBtn from "../../uiComponents/UiTextBtn/UiTextBtn";
import UiTopPopTextBtn from "../../uiComponents/UiTopPopTextBtn/UiTopPopTextBtn";
import Lecture_book_Style from "../Profile/Lecture_book_Style";

const Book_subs = ({ userInfo, isTeacher, linkData, hiddenNav, setHiddenNav }) => {
  let params = useParams();
  let navigate = useNavigate();

  var [selectData, setSelectData] = useState({});
  var [selectData_l, setSelectData_l] = useState(0);

  var [selectData_two, setSelectData_two] = useState({});
  var [selectData_l_two, setSelectData_l_two] = useState(0);

  const [isEdit, setIsEdit] = useState(false);
  const [isEdit_two, setIsEdit_two] = useState(false);

  var [pre_data, setPre_data] = useState([]);
  var [data, setData] = useState([]);

  const apiPro_read = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "user_credential",
          page: 1,
          sort: "name desc",
          amount: 9999,
          ref: [
            "HIQDB_book_link.bid|HIQDB_book_link.mid=HIQDB_user_credential.name",
            "HIQDB_book_link.type|HIQDB_book_link.mid=HIQDB_user_credential.name",
            "HIQDB_book_link.status as bstatus|HIQDB_book_link.mid=HIQDB_user_credential.name",
            "A.* |HIQDB_user A on A.index=HIQDB_user_credential.user_id and A.user_type = 2",
          ],
          where: {
            "HIQDB_book_link.bid": `='${params["id"]}'`,
            "HIQDB_book_link.status": `!='9'`,
            "HIQDB_book_link.type": `in ('4','8')`,
          },
        },
      });

      console.log("구독 리스트 : ", data["list"]);

      let pre_array = [];
      let array = [];
      data["list"].map((v, i) => {
        // // console.log("# ->",v);
        if (v["id"] == userInfo["id"]) return;
        if (v["bstatus"] == 0) {
          pre_array.push(v);
        } else {
          array.push(v);
        }
      });

      setPre_data([...pre_array]);

      setData([...array]);
    } catch (e) {}
  };

  const apiPro_subs_approve = async (data, isDel, isMulti) => {
    try {
      let state = {
        table: "book_link",
      };

      if (isDel) {
        if (isMulti) {
          let del = [];
          Object.keys(data).map((v, i) => {
            let _obj = { mid: v, bid: params["id"] };
            del.push(_obj);
          });
          state["del"] = del;
        } else {
          state["del"] = [{ mid: data["id"], bid: params["id"] }];
        }
      } else {
        let type = 8;
        let status = 1;

        let sendMsg = {
          type: "book_subsup",
          orderMid: userInfo["id"],
          targetMid: data["id"],
          boid: params["id"],
        };

        state["sendMsg"] = sendMsg;
        state["set"] = [
          {
            key: `mid:${data["id"]}' and bid='${params["id"]}`,
            value: {
              type: type,
              status: status,
            },
          },
        ];
      }

      let isOk = await apiFn.comApi({
        state: state,
      });
      if (isMulti) {
        setIsEdit(false);
        setIsEdit_two(false);
        setSelectData({});
        setSelectData_two({});
      }
      await apiPro_read();
    } catch (e) {}
  };

  const apiPro_set = async (data, isDel, isDown, isMulti) => {
    try {
      let state = {
        table: "book_link",
      };

      if (isDel) {
        if (isMulti) {
          let del = [];
          Object.keys(data).map((v, i) => {
            let _obj = { mid: v, bid: params["id"] };
            del.push(_obj);
          });
          state["del"] = del;
        } else {
          state["del"] = [{ mid: data["id"], bid: params["id"] }];
        }
      } else {
        let type = 5;
        let sendMsg = {
          type: "book_adminup",
          orderMid: userInfo["id"],
          targetMid: data["id"],
          boid: params["id"],
        };
        if (isDown) {
          sendMsg["type"] = "book_admindown";
          type = 6;
        }
        state["sendMsg"] = sendMsg;
        state["set"] = [
          {
            key: `mid:${data["id"]}' and bid='${params["id"]}`,
            value: { type: type },
          },
        ];
      }
      let isOk = await apiFn.comApi({
        state: state,
      });
      if (isMulti) {
        setIsEdit(false);
        setIsEdit_two(false);
        setSelectData({});
        setSelectData_two({});
      }
      await apiPro_read();
    } catch (e) {
      // console.log("## ",e)
    }
  };

  const apiPro_del = async (data) => {
    try {
      let state = {
        table: "book_link",
      };
      // let sendMsg = {
      //     type : "book_subsup",
      //     orderMid : userInfo["id"],
      //     targetMid : data["id"],
      //     boid : params["id"]
      // }

      // state["sendMsg"] = sendMsg

      state["del"] = [
        {
          mid: data["id"],
          bid: params["id"],
          // key: `mid:${data["id"]}' and bid='${params["id"]}`,
        },
      ];

      let isOk = await apiFn.comApi({
        state: state,
      });

      await apiPro_read();
    } catch (e) {
      console.log("ASDFASDF ? ? ? ", e);
    }
  };

  const apiPro_del_array = async (data) => {
    try {
      let id_key = Object.keys(data);
      // console.log(id_key)
      // return;
      let promiseArray = id_key.map((v) =>
        apiFn.exitBook({
          state: {
            orderMid: userInfo["id"],
            targetMid: v,
            boid: params["id"],
          },
        })
      );
      await Promise.all([...promiseArray]);

      setIsEdit(false);
      setIsEdit_two(false);
      setSelectData({});
      setSelectData_two({});

      await apiPro_read();
    } catch (e) {
      // console.log("@@ ",e)
    }
  };
  useEffect(() => {
    apiPro_read();
  }, []);

  useEffect(() => {
    try {
      selectData_l = 0;
      // // console.log("selectData => ",selectData)
      Object.keys(selectData).map((v, i) => {
        if (selectData[v] == "Y") {
          selectData_l = selectData_l + 1;
        } else if (selectData[v] == "N") {
          if (selectData_l == 0) return;
          selectData_l = selectData_l - 1;
        }
      });
      setSelectData_l(selectData_l);
    } catch (e) {
      // console.log(e)
    }
  }, [selectData]);

  return (
    <div
      style={{
        padding: "0px 10px 15px 10px",
      }}
    >
      {(isEdit || isEdit_two) && (
        <UiTopPopTextBtn
          title="편집 취소"
          isTeacher={isTeacher}
          fnClick={() => {
            setIsEdit(false);
            setIsEdit_two(false);
            setSelectData({});
            setSelectData_two({});
          }}
        />
      )}
      {pre_data.length > 0 && linkData["mid"] && linkData["type"] == 5 && (
        <div
          style={{
            fontSize: "14px",
            marginTop: "15px",
            fontWeight: "bold",
          }}
        >
          {`초대 승낙 대기 ${pre_data.length}명`}
        </div>
      )}
      {isEdit && selectData_l > 0 && (
        <div
          style={{
            ...Lecture_book_Style.edit_section_w,
            borderTop: isEdit ? "none" : "1px solid",
            borderColor: style.common.borderColor,
          }}
        >
          선택된 선생님을
          <div style={{}}>
            {isTeacher && (
              <div
                style={{
                  display: "flex",
                }}
              >
                <UiTextBtn
                  text={"초대 취소하기"}
                  btnStyle={{
                    ...Lecture_book_Style.textBtn,
                    color: style.common.fontColor_3,
                    marginRight: "7.5px",
                  }}
                  fnClick={() => {
                    let isOk = window.confirm("선택하신 선생님들의 초대신청을 취소하시겠어요?");
                    if (isOk) {
                      apiPro_del_array(selectData);

                      // apiPro_set(selectData,true,false,true)
                      // apiPro_del(selectData,true);
                      // apiPro_set(selectData,true,false,true)
                      // apiPro_del_array(selectData)
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {linkData["mid"] && linkData["type"] == 5 && (
        <UiPeople_subs
          isBlank={false}
          isInfo={true}
          isExpel={linkData["type"] == 5 ? true : false}
          isManager={false}
          data={pre_data}
          isOnly={linkData["mid"] && (linkData["type"] == 5 ? false : true)}
          isSearch={true}
          userInfo={userInfo}
          isTeacher={isTeacher}
          isBookManager={true}
          isManager={true}
          isTeacherPage={false}
          isEdit={isEdit}
          setSelectData={setSelectData}
          selectData={selectData}
          fnBookUp={(d) => {
            let isOk = window.confirm("선생님의 초대신청을 수락하시겠어요?");
            if (!isOk) return;
            apiPro_subs_approve(d);
          }}
          fnLongClick={(id) => {
            setIsEdit(true);
            setSelectData({
              ...selectData,
              [id]: "Y",
            });
          }}
          fnCheck={(e) => {
            if (selectData[e.target.id] == "Y") {
              delete selectData[e.target.id];
              setSelectData({
                ...selectData,
              });
            } else {
              setSelectData({
                ...selectData,
                [e.target.id]: "Y",
              });
            }
          }}
          // fnDown={(d)=>{

          // }}
          fnExpel={(d) => {
            let isOk = window.confirm("선생님의 초대신청을 취소하시겠어요?");
            if (isOk) {
              apiPro_del(d);
            }
            // apiPro_set(d,true);
          }}
          // fnExpel={fnExpel}
          fnClick={() => {}}
        />
      )}
      <UiSorting
        title={`전체 강사진 ${data.length}명`}
        data={
          [
            // { name : "출처순" , value : "B01"},
            // { name : "평점순" , value : "B02"},
            // { name : "저도요순" , value : "B03"},
            // { name : "날짜순" , value : "B04"},
          ]
        }
        isTeacher={params["who"] == "teacher" ? true : false}
      />
      <UiPeople_subs
        isBlank={false}
        isInfo={true}
        isExpel={linkData["type"] == 5 ? true : false}
        isManager={false}
        data={data}
        isOnly={linkData["mid"] && (linkData["type"] == 5 ? false : true)}
        isSearch={true}
        userInfo={userInfo}
        isTeacher={isTeacher}
        isBookManager={true}
        isManager={true}
        isTeacherPage={false}
        fnBookUp={(d) => {
          apiPro_set(d);
        }}
        fnDown={(d) => {
          apiPro_set(d, false, true);
        }}
        fnExpel={(d) => {
          let isOk = window.confirm("선생님을 구독 취소 시키겠습니까?");
          if (isOk) {
            // apiPro_set(d,true);
            apiPro_del(d);
          }
        }}
        // fnExpel={fnExpel}
        fnClick={() => {}}
      />
    </div>
  );
};

export default Book_subs;
