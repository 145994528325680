import React from "react";
import { HelmetProvider } from "react-helmet-async";
import ApolloClientProvider from "./ApolloClientProvider";
import ConfirmProvider from "./ConfirmProvider";
import ErrorBoundary from "./ErrorBoundary";
import ReactQueryClientProvider from "./ReactQueryClientProvider";
import ReduxProvider from "./ReduxProvider";
import RootSuspense from "./RootSuspense";
import RootThemeProvider from "./RootThemeProvider";
import HotToastProvider from "./ToastProvider";
import { RecoilRoot } from "recoil";
import { ToastProvider } from "./toast";
import ThemeProviders from "./theme";
import JotaiProvider from "@providers/jotai";
import ProviderContainer from "../shared/provider";

const Providers = ({ children }) => {
  return (
    <ReduxProvider>
      <JotaiProvider>
        <RecoilRoot>
          <ToastProvider>
            <HelmetProvider>
              <ProviderContainer>
                <ErrorBoundary>
                  <ReactQueryClientProvider>
                    <ApolloClientProvider>
                      <HotToastProvider>
                        <RootSuspense>
                          <ThemeProviders>
                            <RootThemeProvider>
                              <ConfirmProvider>{children}</ConfirmProvider>
                            </RootThemeProvider>
                          </ThemeProviders>
                        </RootSuspense>
                      </HotToastProvider>
                    </ApolloClientProvider>
                  </ReactQueryClientProvider>
                </ErrorBoundary>
              </ProviderContainer>
            </HelmetProvider>
          </ToastProvider>
        </RecoilRoot>
      </JotaiProvider>
    </ReduxProvider>
  );
};

export default Providers;
