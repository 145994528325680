import { React } from "react";
import { useParams } from "react-router";
import "./Myinfo_memo.css";
import UiMemo from "../../uiComponents/UiMemo/UiMemo";

const Myinfo_memo = ({ isTeacher, myInfo, userInfo, setHiddenNav }) => {
  let params = useParams();

  return <UiMemo setHiddenNav={setHiddenNav} lid={params["id"]} userInfo={userInfo} />;
};

export default Myinfo_memo;
