import { IAccountRepository } from "@interfaces";
import { IAccount } from "@entities";
import { axiosInstanceWithAuth, axiosInstanceWithOutAuth } from "@infrastructure/api";
import { CONFIG } from "@config";
import { ExistingAccountLinkDto } from "@infrastructure/repositories/account/dto/link.dto";

class AccountRepositoryImpl implements IAccountRepository {
  constructor() {}

  async getProfile(): Promise<IAccount> {
    try {
      const response = await axiosInstanceWithAuth.get(
        CONFIG.API.API_ENDPOINTS.ACCOUNT.GET_PROFILE
      );
      return response.data;
    } catch (e: any) {
      throw new Error(`Error in getProfile: ${e.message}`);
    }
  }
  async setExistingAccountLink(data: ExistingAccountLinkDto): Promise<void> {
    try {
      const response = await axiosInstanceWithOutAuth.post(
        CONFIG.API.API_ENDPOINTS.ACCOUNT.SET_EXISTING_ACCOUNT_LINK,
        data,
        { withCredentials: true }
      );
      return response.data;
    } catch (e: any) {
      throw new Error(`Error in setExistingAccountLink: ${e.message}`);
    }
  }
}

export const accountRepository = new AccountRepositoryImpl();
