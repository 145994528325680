// hooks/useNicknameCheck.ts
import { useEffect, useRef } from "react";
import { FieldValues, Path, useFormContext } from "react-hook-form";
import { getCheckNicknameUsable } from "@api/account/check";

export function useNicknameCheck<T extends FieldValues>() {
  const { setError } = useFormContext<T>();
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const handleInputChange = async (value: string, fieldName: Path<T>) => {
    if (timerRef.current) clearTimeout(timerRef.current);

    if (value) {
      timerRef.current = setTimeout(async () => {
        try {
          const response = await getCheckNicknameUsable(value);
          if (!response.usable) {
            setError(fieldName, { message: "이미 사용중인 닉네임입니다." });
          }
        } catch (e) {
          setError(fieldName, {
            message: "중복 체크에 문제가 생겼습니다. 잠시후 다시 시도해 주세요.",
          });
        }
      }, 500);
    }
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    };
  }, []);

  return { handleInputChange, timerRef };
}
