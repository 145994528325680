import React, { forwardRef, InputHTMLAttributes } from "react";
import styled from "styled-components";

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
}

const Label = styled.label`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xs};
`;

const Input = styled.input`
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.natural[30]};
  padding: ${({ theme }) => theme.spacing.sm};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
`;
const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.danger[70]};
  font-size: 12px;
  margin-top: 4px;
`;

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ label, error, ...props }, ref) => {
    return (
      <Label>
        {label}
        <Input ref={ref} {...props} />
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Label>
    );
  }
);

TextInput.displayName = "TextInput";
