import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { SignUpContext, SignUpStepEnum } from "@providers/pages/signUp";
import styled from "styled-components";
import Appbar from "@components/pages/signUp/appbar";
import TypeSelectButton from "@components/pages/signUp/typeSelectButton";
import PageContainer from "@components/templates/PageContainer";
import { AccountEnum } from "../../../shared/enum/account";

export const ContentSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1em;
  flex: 1;
`;

export default function SignUpTypePage(): JSX.Element {
  let navigate = useNavigate();
  const location = useLocation();
  const { updateFormData, updateStep, myNotAccount } = useContext(SignUpContext);

  const handleNext = (type: AccountEnum) => {
    updateFormData({ type });
    updateStep(SignUpStepEnum.TERMS);
    navigate(`/sign-up/terms`);
  };

  useEffect(() => {
    if (location.state) {
      updateFormData({ oauth_session_key: location.state.oauthSessionKey });
      myNotAccount.current = Boolean(location.state.notMyAccount);
    }
  }, [location]);

  return (
    <PageContainer>
      <Appbar />
      <ContentSection>
        <TypeSelectButton
          type={AccountEnum.TEACHER_LOWER}
          onSelectType={() => handleNext(AccountEnum.TEACHER_LOWER)}
        />
        <TypeSelectButton
          type={AccountEnum.STUDENT_LOWER}
          onSelectType={() => handleNext(AccountEnum.STUDENT_LOWER)}
        />
      </ContentSection>
    </PageContainer>
  );
}
