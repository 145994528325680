import { AccountRepository } from "../../repository/account.repository";
import { tokenManager } from "../../../../../shared/api/api-gateway/token/token.manager";
import { IAccountRepository } from "../../interface/account-repository.interface";
import { ITokenStorageRepository } from "../../../../../shared/api/api-gateway/token/storage/token-storage.interface";

class LogoutUseCase {
  constructor(
    private readonly accountRepository: IAccountRepository,
    private tokenRepository: ITokenStorageRepository
  ) {}

  async execute(): Promise<void> {
    await this.accountRepository.logout();
    this.tokenRepository.clearToken();
  }

  private naverLogout(): void {}
  private kakaoLogout(): void {}
  private appleLogout(): void {}
}

export const logoutUseCase = new LogoutUseCase(new AccountRepository(), tokenManager);
