import { useMutation } from "@tanstack/react-query";
import { LocalAuthRepository } from "../repository/localAuth.repository";
import { LocalAuthToResponseUseCase } from "../usecase/localAuthToResponseUseCase";
import { useNavigate } from "react-router";

export const useLocalAuthToResponse = () => {
  const localAuthRepository = new LocalAuthRepository();
  const localAuthToResponseUseCase = new LocalAuthToResponseUseCase(localAuthRepository);
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (localAuthData: any) => {
      return localAuthToResponseUseCase.execute(localAuthData);
    },
    onSuccess: (response: string) => {
      navigate(`/auth?${response}`);
    },
  });
};
