import { DefaultTheme, ThemeProvider } from "styled-components";
import React, { ReactNode } from "react";
import { theme } from "@design-system";

const themes: DefaultTheme = theme;

type ThemeProvidersProps = {
  children: ReactNode;
};

export default function ThemeProviders({ children }: ThemeProvidersProps) {
  return <ThemeProvider theme={themes}>{children}</ThemeProvider>;
}
