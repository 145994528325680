import { React } from "react";
import { useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router";
import { Frame } from "../page_index";
import UiSorting from "../../uiComponents/UiSorting/UiSorting";
import UiTicketLog from "../../uiComponents/UiTicketLog/UiTicketLog";
import UiLoading from "../../uiComponents/UiLoading/UiLoading";
import { UserTicketLogsDocument } from "types/graphql/graphql";
import { TeacherTicketLogsDocument } from "types/graphql/graphql";

const MyInfo_ticketlog = ({ userInfo }) => {
  let params = useParams();
  let navigate = useNavigate();
  const userId = params["id"];
  const isTeacher = params["who"] == "teacher";
  const { loading, error, data, refetch } = useQuery(
    isTeacher ? TeacherTicketLogsDocument : UserTicketLogsDocument,
    {
      fetchPolicy: "network-only",
      variables: {
        userId,
        actions: ["TICKET_USE", "TICKET_USE_CANCEL"],
      },
    }
  );
  if (loading) {
    return <UiLoading text="로딩 중입니다." isText={false} />;
  }
  const logs = (isTeacher ? data?.teacherTicketLogs?.logs : data?.userTicketLogs?.logs) || [];

  return (
    <Frame
      title={"질문권 사용내역"}
      userInfo={userInfo}
      // isPadding={false}
      isSearch={false}
      // isTap={true}
    >
      <div
        style={{
          width: "100%",
        }}
      >
        <div
          style={{
            padding: "5px 12px",
          }}
        >
          <UiSorting title={`사용내역 ${logs.length}개`} />
        </div>
        <div
          style={{
            padding: "10px 12px",
          }}
        >
          {logs.map((v, i) => (
            <UiTicketLog data={v} isTeacher={isTeacher} />
          ))}
        </div>
      </div>
    </Frame>
  );
};

export default MyInfo_ticketlog;
