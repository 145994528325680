import React, { createContext, ReactNode, useRef, useState } from "react";
import { AccountEnum } from "../../../shared/enum/account";

export enum SignUpStepEnum {
  TYPE = 0,
  TERMS = 1,
  ACCOUNT_INFO = 2,
}

type FormData = {
  name?: string;
  email?: string;
  oauth_session_key?: string;
  type?: AccountEnum;
  marketingNotify?: boolean;
  [key: string]: any;
};

type SignUpContextProps = {
  signUpFormData: FormData;
  updateFormData: (newData: Partial<FormData>) => void;
  updateStep: (newStep: SignUpStepEnum) => void;
  currentStep: SignUpStepEnum;
  onResetSignUpState: () => void;
  myNotAccount?: React.MutableRefObject<boolean>;
};

export const SignUpContext = createContext<SignUpContextProps | undefined>(undefined);

export const SignUpProvider = ({ children }: { children: ReactNode }) => {
  const [formData, setFormData] = useState<FormData>({});
  const [step, setStep] = useState<SignUpStepEnum>(SignUpStepEnum.TYPE);
  const myNotAccount = useRef(false);

  const updateFormData = (newData: Partial<FormData>) => {
    setFormData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  const updateStep = (newStep: SignUpStepEnum) => {
    setStep(newStep);
  };

  const handleResetSignUpState = () => {
    setFormData({});
    setStep(SignUpStepEnum.TYPE);
  };

  return (
    <SignUpContext.Provider
      value={{
        signUpFormData: formData,
        updateFormData,
        updateStep,
        currentStep: step,
        onResetSignUpState: handleResetSignUpState,
        myNotAccount,
      }}
    >
      {children}
    </SignUpContext.Provider>
  );
};
