import { IAcademyRepository } from "@interfaces";
import { academyRepository } from "@infrastructure/repositories/academy";

class AcademyUseCase {
  private academyRepository: IAcademyRepository;

  constructor(academyRepository: IAcademyRepository) {
    this.academyRepository = academyRepository;
  }

  getAcademyInfo(academyCode: number | string) {
    return this.academyRepository.getAcademyInfo(academyCode);
  }
  setAcademyRequest(academyCode: number | string) {
    return this.academyRepository.setAcademyRequest(academyCode);
  }
  getAcademyLectureRelated(academyCode: number | string) {
    return this.academyRepository.getAcademyLectureRelated(academyCode);
  }
}

export const academyUseCase = new AcademyUseCase(academyRepository);
