import { AccountFieldConfigType } from "@constants/accountForm/types";

export const ACCOUNT_FORM_CONFIG: { [key: string]: AccountFieldConfigType } = {
  nickname: {
    label: "닉네임",
    placeholder: "닉네임을 입력해주세요.",
    type: "text",
    defaultValue: "",
    rules: {
      required: "새로운 닉네임을 입력해주세요.",
      maxLength: { value: 20, message: "닉네임은 최대 20자까지 가능합니다." },
      validate: (value) => {
        const regex = /^[a-zA-Z0-9가-힣ㄱ-ㅎ\s]+$/;
        if (!regex.test(value)) {
          return "글자, 숫자 혹은 한글만 입력해주세요.";
        }
        return true;
      },
    },
    changeEvent: true,
  },
  userName: {
    label: "이름",
    placeholder: "이름을 입력해주세요.",
    type: "text",
    defaultValue: "",
    rules: {
      required: "본명을 입력해주세요.",
      maxLength: { value: 20, message: "이름은 최대 20자까지 가능합니다." },
      validate: (value) => {
        const regex = /^[가-힣a-zA-Z\s]+$/;
        if (!regex.test(value)) {
          return "이름은 한글 또는 영문으로만 입력 가능합니다.";
        }
        return true;
      },
    },
  },
  phoneNumber: {
    label: "전화번호",
    placeholder: "전화 번호를 입력해주세요.",
    type: "tel",
    defaultValue: "",
    rules: {
      required: "전화번호를 입력해주세요.",
      maxLength: { value: 20, message: "전화번호는 최대 20자까지 가능합니다." },
      pattern: {
        value: /^[0-9]{10,11}$/,
        message: "올바른 전화번호 형식을 입력해주세요. (예: 01012345678)",
      },
    },
  },
  email: {
    label: "이메일",
    placeholder: "이메일을 입력해주세요.",
    type: "email",
    defaultValue: "",
    rules: {
      required: "이메일을 입력해주세요.",
      maxLength: { value: 30, message: "이메일은 최대 30자까지 가능합니다." },
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        message: "유효한 이메일 주소를 입력해주세요.",
      },
    },
  },
  identity: {
    label: "oauth provider에서 제공하는 식별자 (개인 DB에서 유일 한 키 입력)",
    placeholder: "식별자를 입력해주세요.",
    type: "text",
    defaultValue: "",
    rules: {
      required: "식별자를 입력해주세요.",
      maxLength: { value: 50, message: "식별자는 최대 50자까지 가능합니다." },
    },
  },
};
