import { React, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import apiFn from "../../asset/apiClass";
import UiBook from "../../uiComponents/UiBook/UiBook";

var fontStyle = {
  fontFamily: "Noto Sans",
  fontStyle: "normal",
};

const Modal_book = ({ apiPro_read_qna, setIsModal, isModal, userInfo, qnaData, setIsPick }) => {
  let params = useParams();
  let navigate = useNavigate();

  var [list, setList] = useState([]);

  // 교재 리스트
  const apiPro_read_book = async () => {
    try {
      if (params["who"] == "teacher") {
        let isOk = await apiFn.comApi({
          state: {
            table: "book",
            page: 1,
            amount: 999,
            u_id: params["tid"],
            sort: "reg_date desc",
            ref: ["HIQDB_book_link.cid|HIQDB_book_link.bid=HIQDB_book.id"],
            where: {
              "HIQDB_book_link.mid": `= '${params["tid"]}'`,
              "HIQDB_book.status": `!= '9'`,
              // "HIQDB_book_link.fid" : `= 0`,
              // "HIQDB_book_link.status" : `= '1'`,
              "HIQDB_book_link.status": "!= 0",
              "HIQDB_book_link.type": "!= 9",
              // "HIQDB_book_link.cid" : `= 0`,
            },
          },
        });

        // console.log("teacher Book Data ",isOk["list"])
        setList([
          ...isOk.list.reduce((list, book) => {
            if (!list.map(({ id }) => id).includes(book.id)) list.push(book);
            return list;
          }, []),
        ]);
      }
    } catch (e) {}
  };

  const apiPro_set = async (data) => {
    try {
      let isOk = await apiFn.comApi({
        state: {
          table: "book_link",
          set: [
            {
              // key : `bid:${data["id"]} and qid='${params["qid"]}`,
              key: `qid:${params["qid"]}`,
              value: { bid: data["id"] },
            },
          ],
        },
        // key : `bid:${v}' and mid='${userInfo["id"]}`,
        // set: [ { key: 'prKey:15', value: {a:1,b:2}  } ]
      });

      apiPro_read_qna(true, true);
      // navigate(-1)
      setIsModal(false);
    } catch (e) {}
  };

  useEffect(() => {
    apiPro_read_book();
  }, []);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        zIndex: 130002,
        backgroundColor: "rgba(150, 150, 150,0.5)",
        // backgroundColor:"red",
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={() => {
        setIsModal(false);
      }}
    >
      <div
        style={{
          width: "75%",
          minHeight: "55%",
          maxHeight: "60%",
          backgroundColor: "white",
          padding: "15px 20px 30px 20px",
          borderRadius: 10,
          border: "1px solid rgb(200, 200, 200)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.37))",
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div
          style={{
            fontWeight: 500,
          }}
        >
          다른 교재로 이동
        </div>
        <div
          style={{
            marginTop: "10px",
            // paddingTop:"5px",
            overflowY: "scroll",
            display: "flex",
            flexWrap: "wrap",
            // flexDirection:"column",
            flex: 1,
            justifyContent: "center",
          }}
        >
          {list.map((v) => (
            <UiBook
              isTeacher={true}
              data={v}
              isShare={false}
              navigate={() => {}}
              fnClick={(data) => {
                // console.log("data => ",data)
                apiPro_set(data);
              }}
            />
          ))}
        </div>
        {/* <div style={{
                    height:"40px",
                    display:"flex",
                    justifyContent:"center",
                }}>
                    <UiBtn
                        btnStyle={{
                            width:"90px",
                            height:"36px",
                            backgroundColor:style.common.t_color,
                            color : "white",
                            fontStyle:"12px",
                        }}
                        title={"닫기"}
                        fnClick={()=>{
                            setIsModal_pick(false)
                        }}
                    />
                </div> */}
      </div>
    </div>
  );
};

export default Modal_book;
