import { React, useEffect, useState } from "react";
import DaumPostcode from "react-daum-postcode";
import { useNavigate, useParams } from "react-router";
import apiFn from "../../asset/apiClass";
import style from "../../asset/style";
import util from "../../asset/util";
import Basic from "../../common/frame/Basic";
import UiBtn from "../../uiComponents/UiBtn/UiBtn";
import UiConfirm from "../../uiComponents/UiConfirm/UiConfirm";
import { UiInput_title } from "../../uiComponents/UiInput/UiInput";
import { getUser } from "@asset/storage";

const Profile_regi = () => {
  let navigate = useNavigate();
  let params = useParams();
  const userInfo = getUser();

  var [academyData, setAcademyData] = useState([]);
  const [mapData, setMapData] = useState({});

  const [isTeacher, setIsTeacher] = useState(true);

  // 클래스룸 정보
  const apiPro_read = async () => {
    try {
      let data = await apiFn.comApi({
        state: {
          table: "class",
          page: 1,
          amount: 9999,
          sort: "reg_date desc",
          where: {
            "HIQDB_class.id": `='${params["id"]}'`, // 클래스룸 ID
            // 'HIQDB_member_class.mid':`='${userInfo["id"]}'`,
          },
        },
      });

      let academy = {
        name: data["list"][0]["name"],
        owner: data["list"][0]["owner"],
        intro: data["list"][0]["intro"],
        address: data["list"][0]["address"],
        tel: data["list"][0]["tel"],
        email: data["list"][0]["email"],
        id: data["list"][0]["id"],
      };
      setAcademyData({
        ...academy,
      });
    } catch (e) {}
  };

  // 클래스룸 등록
  const apiPro_regi = async () => {
    try {
      let regiObj = Object.assign(academyData);

      delete regiObj["tcnt"];
      delete regiObj["tseq"];
      // delete regiObj["mid"]
      // delete regiObj["reg_date"]

      const isOk = await apiFn.comApi({
        state: {
          table: "class",
          set: [
            {
              key: `id:${academyData["id"]}`,
              value: regiObj,
            },
          ],
        },
      });
      if (isOk) {
        navigate(`/profile/teacher/${params["id"]}/de`);
      }
    } catch (e) {
      util.c_err_log(`apiPro_regi => ${e}`);
    }
  };

  const apiPro_del = async () => {
    try {
      // let isOk = apiFn.comApi({
      //     state : {
      //         table : 'class',
      //         set : [
      //             {
      //                 key : `id:${academyData["id"]}`,
      //                 value : {
      //                     status : 9
      //                 }
      //             }
      //         ]
      //     }
      // })

      let isOk = await apiFn.deleteClass({
        state: {
          cid: params["id"],
          orderMid: userInfo["id"],
        },
      });

      navigate(`/academy/${params["who"]}/academy`);
    } catch (e) {}
  };

  useEffect(() => {
    // console.log("parmas => ",params)
    if (params["who"] == "teacher") {
      setIsTeacher(true);
    } else {
      setIsTeacher(false);
    }
  }, [params]);

  useEffect(() => {
    apiPro_read();
  }, []);

  useEffect(() => {
    // // console.log("academyData => ",academyData);
  }, [academyData]);

  return (
    <Basic title="정보변경">
      {params["form"] == "default" ? (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "absolute",
              height: "50px",
              right: 10,
              top: 14,
              fontSize: "14px",
              color: style.common.fontColor_3,
              fontWeight: "bold",
            }}
            onClick={() => {
              let isOk = `${academyData["name"]} 클래스룸을 삭제하시겠어요?\n\(클래스룸 소속 하위 수업이 전부 삭제되며 \n복구할 수 없어요.)`;
              if (isOk) {
                navigate("/profileregi/" + params["who"] + "/" + params["id"] + "/confirm");
              }
            }}
          >
            클래스룸 삭제
          </div>
          <UiInput_title
            title="클래스룸 이름"
            placeholder={academyData["name"]}
            inputValue={academyData["name"]}
            type="basic"
            inputClass={isTeacher ? "input_t" : "input_s"}
            fnChange={(e) => {
              setAcademyData({
                ...academyData,
                name: e.target.value,
              });
            }}
          />
          <UiInput_title
            title="대표자 이름"
            placeholder={academyData["owner"]}
            inputValue={academyData["owner"]}
            type="basic"
            inputClass={isTeacher ? "input_t" : "input_s"}
            fnChange={(e) => {
              setAcademyData({
                ...academyData,
                owner: e.target.value,
              });
            }}
          />
          <UiInput_title
            title="클래스룸을 소개하는 글"
            placeholder={academyData["intro"]}
            inputValue={academyData["intro"]}
            dsecStyle={{
              height: "70px",
            }}
            type="desc"
            maxLength={80}
            inputClass={isTeacher ? "input_t" : "input_s"}
            fnChange={(e) => {
              setAcademyData({
                ...academyData,
                intro: e.target.value,
              });
            }}
          />
          {/* <UiInput_title 
            title="클래스룸 주소"
            // placeholder={mapData["address"] ? mapData["address"] : "클래스룸의 이름을 입력해주세요."}
            inputValue={mapData["address"] ? mapData["address"] : academyData["address"]}
            type="basic"
            fnClick={()=>{
                 navigate("/profileregi/"+params["who"]+"/"+params["id"]+"/map")
            }}
            inputClass={isTeacher ? "input_t" : "input_s"}
        />
        <UiInput_title 
            title="상세주소"
            placeholder="나머지 주소를 입력하세요."
            type="basic"
            inputClass={isTeacher ? "input_t" : "input_s"}
            fnChange={(e)=>{
                setAcademyData({
                    ...academyData,
                    address : `${mapData["address"]} ${e.target.value}`
                })
            }}
        /> */}
          <UiInput_title
            title="전화번호"
            placeholder={academyData["tel"]}
            inputValue={academyData["tel"]}
            type="basic"
            inputClass={isTeacher ? "input_t" : "input_s"}
            fnChange={(e) => {
              setAcademyData({
                ...academyData,
                tel: e.target.value,
              });
            }}
          />
          <UiInput_title
            title="이메일"
            placeholder={academyData["email"]}
            inputValue={academyData["email"]}
            type="basic"
            inputClass={isTeacher ? "input_t" : "input_s"}
            fnChange={(e) => {
              setAcademyData({
                ...academyData,
                email: e.target.value,
              });
            }}
          />
          <UiBtn
            title={"확인"}
            btnStyle={{
              marginTop: "30px",
              backgroundColor: style.common.t_color,
              color: "white",
            }}
            fnClick={() => {
              apiPro_regi();
            }}
          />
        </>
      ) : params["form"] == "map" ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100vh",
            // backgroundColor : "red",
          }}
        >
          <DaumPostcode
            style={{
              width: "100%",
              height: "100%",
            }}
            autoClose={false}
            onComplete={(data) => {
              // console.log(data)
              setMapData({
                ...data,
              });
              setAcademyData({
                ...academyData,
                address: mapData["address"],
              });

              navigate("/profileregi/" + params["who"] + "/" + params["id"] + "/default");
            }}
          />
        </div>
      ) : (
        params["form"] == "confirm" && (
          <UiConfirm
            isTeacher={isTeacher}
            navi={"/academy/teacher/academy"}
            fnClick={() => {
              apiPro_del();
            }}
          />
        )
      )}
    </Basic>
  );
};

export default Profile_regi;
