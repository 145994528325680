import { useInfiniteQuery } from "@tanstack/react-query";
import { academyRepository } from "../repository/academy.repository";

export const useGetLecturesRelatedToAcademy = (academyCode: string) => {
  return useInfiniteQuery({
    queryKey: ["academy", "lectures", academyCode],
    queryFn: ({ pageParam = 1 }) =>
      academyRepository.getLecturesRelatedToAcademy(academyCode, pageParam),
    getNextPageParam: (lastPage, allPages) => {
      return allPages.length < lastPage?.maxPage ? allPages.length + 1 : undefined;
    },
    enabled: false,
    suspense: false,
  });
};
