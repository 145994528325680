import { FolderItemInterface } from "api/messageMark";
import folderGreen from "asset/Img/folderGreen.svg";
import folderOrange from "asset/Img/folderOrange.svg";
import folderPink from "asset/Img/folderPink.svg";
import folderRed from "asset/Img/folderRed.svg";
import folderSkyblue from "asset/Img/folderSkyblue.svg";
import folderYellow from "asset/Img/folderYellow.svg";
import CustomCheckbox from "components/atoms/CustomCheckbox";
import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router";
import styled, { css } from "styled-components";
import { useLongPress } from "use-long-press";
import { MessageMarkContext } from "../context/MessageMarkContextProvider";
import FolderModifyMenu from "./FolderModifyMenu";

const COLOR_LIST = [folderSkyblue, folderPink, folderGreen, folderOrange, folderYellow, folderRed];

const FolderItem = ({ index, ...folder }: FolderItemInterface & { index: number }) => {
  const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(null);
  const {
    setVisibleMenuId,
    visibleMenuId,
    addCheckedFolder,
    removeCheckedFolder,
    checkedFolderList,
    isDeleteMode,
  } = useContext(MessageMarkContext);

  const navigate = useNavigate();
  const longPress = useLongPress(() => {
    isDeleteMode || handleShowMenu();
  }, {});

  const handleShowMenu = () => {
    if (visibleMenuId === folder.id) {
      return;
    }
    setAnchorElement(itemRef.current);
    setVisibleMenuId(folder.id);
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      addCheckedFolder(folder);
    } else {
      removeCheckedFolder(folder.id);
    }
  };
  const itemRef = useRef<HTMLLabelElement>(null);
  return (
    <>
      <FolderItemContainer
        {...longPress()}
        onContextMenu={(event) => {
          event.preventDefault();
          isDeleteMode || handleShowMenu();
        }}
        isVisibleMenu={visibleMenuId === folder.id}
        onClick={(event) => {
          if (visibleMenuId || isDeleteMode) {
            return;
          }
          event.stopPropagation();
          navigate(`/oxNote/${folder.id}`);
        }}
        htmlFor={`folder-checkbox-${folder.id}`}
        ref={itemRef}
      >
        <img src={COLOR_LIST[index % 6]} alt="folder" />
        <span>{folder.name}</span>
        {isDeleteMode && (
          <CheckboxContainer>
            <CustomCheckbox
              id={`folder-checkbox-${folder.id}`}
              color="primary"
              onChange={handleCheck}
              onClick={(event) => event.stopPropagation()}
              checked={!!checkedFolderList.find((item) => item.id === folder.id)}
            />
          </CheckboxContainer>
        )}
      </FolderItemContainer>
      <FolderModifyMenu
        folderId={folder.id}
        anchorElement={anchorElement}
        onClose={() => {
          setVisibleMenuId(null);
          setAnchorElement(null);
        }}
      />
    </>
  );
};

export const RecentFolderItem = () => {
  const navigate = useNavigate();
  return (
    <FolderItemContainer onClick={() => navigate(`/oxNote/recent`)}>
      <img src={COLOR_LIST[0]} alt="folder" />
      <span>{"최근본 질문"}</span>
    </FolderItemContainer>
  );
};

export const LazyFolderItem = () => {
  const navigate = useNavigate();
  return (
    <FolderItemContainer onClick={() => navigate(`/oxNote/1`)}>
      <img src={COLOR_LIST[1]} alt="folder" />
      <span>{"나중에 볼 질문"}</span>
    </FolderItemContainer>
  );
};

export const FolderItemContainer = styled.label<{ isVisibleMenu?: boolean }>`
  min-width: 100px;
  max-width: 250px;
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  display: flex;
  gap: 10px;
  flex-direction: column;
  position: relative;
  ${({ isVisibleMenu }) =>
    isVisibleMenu &&
    css`
      z-index: 2;
    `}
  > span {
    padding-bottom: 16px;
  }
  > img {
    box-shadow: 4px 4px 6px 0px #0000001a;
    border-radius: 10px;
  }
`;

const CheckboxContainer = styled.div`
  position: absolute;
  padding: 0px;
  top: 8px;
  left: 8px;
`;

export default FolderItem;
