import styled from "styled-components";
import React from "react";
import { useTypography } from "@application/hooks/theme/useTypography";

export interface TypographyProps {
  variant?: string;
  fontSize?: string;
  fontWeight?: string;
  color?: string;
  as?: keyof JSX.IntrinsicElements;
  className?: string;
  children?: React.ReactNode;
  textAlign?: string;
}

const StyledTypography = styled.p<TypographyProps>`
  margin: 0;
  padding: 0;
  ${({ variant, fontSize, fontWeight, color, textAlign, theme }) => {
    const { getStyle } = useTypography();
    const style = getStyle({ variant, fontSize, fontWeight, color });

    return `
      font-size: ${style.fontSize};
      font-weight: ${style.fontWeight};
      color: ${style.color};
      text-align: ${textAlign};
    `;
  }}
`;

export const Typography = ({ as, children, ...props }: TypographyProps): JSX.Element => {
  return (
    <StyledTypography as={as} {...props}>
      {children}
    </StyledTypography>
  );
};

export const H1 = styled(Typography).attrs((props) => ({
  as: "h1",
  fontWeight: props.fontWeight || "bold",
  fontSize: "6xl",
}))``;
