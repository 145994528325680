import { React } from "react";
import aquery from "../../../asset/Img/aquery.png";
import answer from "../../../asset/Img/answer.png";
// import Register_Intro_style from './Register_Intro_Style';
import Register_One_style from "./Register_One_style";
import style from "../../../asset/style";
import Register_Intro_style from "../../signUp/type/Register_Intro_Style";

const DeactivateForm = ({
  title,
  addTitle,
  isTeacher,
  num = null,
  isTopTitle = true,
  dotCnt = 5,
}) => {
  return (
    <>
      {isTopTitle && (
        <div
          style={{
            ...Register_Intro_style.s_c,
            height: "50px",
            margin: "5px 0px 20px 0px",
          }}
        >
          <div style={Register_Intro_style.s_w}>
            <div
              style={{
                color: isTeacher ? style.common.t_color : style.common.s_color,
                fontWeight: "bold",
              }}
            >
              {isTeacher ? "회원 탈퇴" : "회원 탈퇴"}
            </div>
            <div
              style={{
                ...Register_Intro_style.i,
                // overflow:'hidden'
                // overflow : 'scroll',
              }}
            >
              <img
                src={isTeacher ? aquery : answer}
                style={{
                  // width : "100%",
                  height: "100%",
                }}
              />
            </div>
          </div>
        </div>
      )}
      <div style={Register_One_style.a_c}>
        <div style={Register_One_style.a_t}>
          {/* 계정정보 입력 */}
          {title}
          <div
            style={{
              marginLeft: "3px",
              color: isTeacher ? style.common.t_color : style.common.s_color,
            }}
          >
            {addTitle}
          </div>
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          {num !== null &&
            [1, 2, 3, 4, 5].splice(0, dotCnt).map((v, i) => {
              if (num == v) {
                return (
                  <div
                    style={{
                      ...Register_One_style.dot,
                      backgroundColor: isTeacher ? style.common.t_color : style.common.s_color,
                    }}
                  />
                );
              } else {
                return <div style={Register_One_style.dot} />;
              }
            })}
        </div>
      </div>
    </>
  );
};

export default DeactivateForm;
