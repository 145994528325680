import { ACADEMY_METADATA, APP_METADATA } from "../../../common/constants";

export const APP_ENV = {
  IS_PRODUCTION: APP_METADATA.IS_PRODUCTION,
  IS_DEVELOPMENT: APP_METADATA.IS_DEVELOPMENT,
  IS_ACADEMY_APP: APP_METADATA.IS_ACADEMY_APP,
  APP_NAME: ACADEMY_METADATA.APP_NAME,
  APP_ACADEMY_CODE: ACADEMY_METADATA.ACADEMY_CODE,

  APPLE_CLIENT_ID: process.env.REACT_APP_APPLE_CLIENT_ID,
  NAVER_CLIENT_ID: process.env.REACT_APP_NAVER_CLIENT_ID,

  get IS_NOT_ACADEMY() {
    return !this.IS_ACADEMY_APP;
  },
};
