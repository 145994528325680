import { IColorRepository } from "../../domain/interfaces/theme/color-repository.interface";
import { ColorValue } from "../../domain/entities/theme/color.entity";

export class ThemeColorRepository implements IColorRepository {
  constructor(private theme: any) {}

  getDefaultColor(): ColorValue {
    return ColorValue.create(this.theme.colors.natural[70])!;
  }

  getColorByPalette(paletteName: string): ColorValue | null {
    const palette = this.theme.colors[paletteName];
    if (!palette) return null;

    if (typeof palette === "object") {
      const defaultShade = this.theme.colors.defaultShades?.[paletteName];
      if (!defaultShade) return null;
      return ColorValue.create(palette[defaultShade]);
    }

    return ColorValue.create(palette);
  }

  getColorByPaletteAndShade(paletteName: string, shade: string): ColorValue | null {
    const color = this.theme.colors[paletteName]?.[shade];
    if (!color) return null;
    return ColorValue.create(color);
  }
}
