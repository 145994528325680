import TextInput, { TextInputProps } from "@components/atoms/textInput";
import React, { forwardRef } from "react";
import styled from "styled-components";
import { Typography } from "@presentation/components/common/atoms";

export interface TextInputWithLabelProps extends TextInputProps {
  label?: string;
}

const StyledLabel = styled.label`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.xs};
`;

const TextInputWithLabel = forwardRef<HTMLInputElement, TextInputWithLabelProps>(
  ({ label, ...props }, ref) => {
    return (
      <StyledLabel>
        {label && <Typography>{label}</Typography>}
        <TextInput ref={ref} {...props} />
      </StyledLabel>
    );
  }
);

export default TextInputWithLabel;
