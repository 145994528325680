import { BackButton } from "@components/molecules/backButton";
import React from "react";
import styled from "styled-components";

export const AppBarSection = styled.header`
  height: 50px;
  display: flex;
  align-items: center;
`;

export default function Appbar(): JSX.Element {
  return (
    <AppBarSection>
      <BackButton />
    </AppBarSection>
  );
}
