export type FontWeightValue = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900;

export class FontWeight {
  private constructor(private value: FontWeightValue) {}

  static create(weight: string | number): FontWeight | null {
    const numWeight = Number(weight);
    if (numWeight >= 100 && numWeight <= 900 && numWeight % 100 === 0) {
      return new FontWeight(numWeight as FontWeightValue);
    }
    return null;
  }

  getValue(): FontWeightValue {
    return this.value;
  }
}
