import { useMutation } from "@tanstack/react-query";
import { ILocalAuthBody } from "@entities";
import { useNavigate } from "react-router";
import { authRepository } from "@infrastructure/repositories/auth";

export const useLocalLogin = () => {
  const navigate = useNavigate();
  return useMutation({
    mutationFn: (data: ILocalAuthBody) => {
      return authRepository.setLoginLocalAuth(data);
    },
    onSuccess: (data) => {
      if ("retryCode" in data) {
        navigate(`/auth/?res=notfound&oauth_session_key=${data.retryCode}`);
      } else if ("tokenIssued" in data) {
        navigate(`/auth/?res=ok`);
      } else {
        console.log("error", data);
      }
    },
  });
};
