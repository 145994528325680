import React from "react";
import styled from "styled-components";
import { themeUtil } from "@utils/theme";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  onClick?: () => void;
  color?: string;
  disabled?: boolean;
}

const StyledButton = styled.button<ButtonProps>`
  border: none;
  cursor: pointer;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  font-size: ${({ theme }) => theme.typography.fontSizes.sm};
  background-color: transparent;
  color: ${({ color }) => themeUtil.getColor(color)};
  &:disabled {
    background: ${({ theme }) => theme.colors.natural[20]};
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

const GhostButton: React.FC<ButtonProps> = ({
  children,
  onClick,
  color = "white",
  disabled = false,
}) => {
  return (
    <StyledButton onClick={onClick} color={color} disabled={disabled}>
      {children}
    </StyledButton>
  );
};

export default GhostButton;
