import constants from "asset/constants";
import { ReactComponent as MoreIcon } from "asset/images/icon/more.svg";
import BookBlue from "asset/images/studybook-blue.svg";
import BookGreen from "asset/images/studybook-green.svg";
import BookOrange from "asset/images/studybook-orange.svg";
import BookPurple from "asset/images/studybook-pink.svg";
import BookRed from "asset/images/studybook-red.svg";
import BookYellow from "asset/images/studybook-yellow.svg";
import { getUser } from "asset/storage";
import CustomCheckbox from "components/atoms/CustomCheckbox";
import { FontStyleMap } from "components/atoms/Font";
import React, {
  DragEvent,
  MouseEvent,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { WhoIs, WhoType } from "types/enum";
import { CommonStudyBookItem, StudyBookContext } from "../context/StudyBookContext";
import BookSubMenu from "./StudyBookSubMenu";
import { ReactComponent as PdfIcon } from "asset/images/icon/pdf-icon.svg";

export const COLOR_LIST = [BookRed, BookYellow, BookGreen, BookBlue, BookPurple, BookOrange];

type StudyBookItemProps = {
  index: number;
  hiddenDragImages?: HTMLDivElement;
  isDragMode?: boolean;
  isConfigable?: boolean;
} & CommonStudyBookItem;

const StudyBookItem = ({
  index,
  hiddenDragImages,
  isDragMode,
  isConfigable,
  ...book
}: StudyBookItemProps) => {
  const { isConfigMode, checkedBookList, addCheckedBook, removeCheckedBook, setIsDragging } =
    useContext(StudyBookContext);

  const isImutable = useMemo(() => book.type === 11 || book.id === 0, [book]);
  const navigate = useNavigate();

  const ref = useRef<HTMLDivElement>(null);

  const handleCheck = useCallback((event) => {
    if (event.target.checked) {
      addCheckedBook(book);
    } else {
      removeCheckedBook(book.id);
    }
  }, []);

  const isChecked = useMemo(
    () => !!checkedBookList.find((item) => item.id === book.id),
    [checkedBookList]
  );
  const imageUrl = useMemo(() => {
    const imageName = book.img ?? book.id;
    const imageUrl = `${constants.s3_url}/bookCover/${imageName}.jpg`;
    return imageUrl;
  }, [book]);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLLabelElement, globalThis.MouseEvent>) => {
      if (!isConfigMode && !isSubmenuOpen) {
        const userInfo = getUser();
        event.stopPropagation();
        const normalLink =
          userInfo.type === WhoType.teacher
            ? `/book/${WhoIs.teacher}/${book.id}/0/de`
            : `/book/${WhoIs.student}/${book.id}/${book["classID"] ? book["classID"] : "subs"}/de`;
        const pdfLink = `/book/${book.id}`;
        const link = [1, 2].includes(book?.book_type) ? pdfLink : normalLink;

        navigate(link);
      }
    },
    [isConfigMode, navigate, book]
  );
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  const dragActions = useMemo(() => {
    const handleDragStart = (event: DragEvent<HTMLImageElement>) => {
      if (isConfigMode) {
        event.dataTransfer.setDragImage(hiddenDragImages, 0, 0);
      }
      setIsDragging(true);
      const transferData = checkedBookList.map((item) => item.id).join(",");
      event.dataTransfer.setData("bookIds", transferData || `${book.id}`);
    };
    return isDragMode
      ? {
          onDragStart: handleDragStart,
          onDragEnd() {
            setIsDragging(false);
          },
          draggable: !isImutable,
        }
      : {};
  }, []);

  return (
    <>
      <BookItemContainer onClick={handleClick}>
        <ImageContainer ref={ref}>
          <img
            {...dragActions}
            src={imageUrl}
            onError={(event) => (event.currentTarget.src = COLOR_LIST[index % 6])}
          />
          {[1, 2].includes(book?.book_type) && (
            <PdfIconBox>
              <PdfIcon />
            </PdfIconBox>
          )}
        </ImageContainer>
        {isConfigable && (
          <ConfigContainer>
            {!isImutable &&
              (isConfigMode ? (
                <CustomCheckbox checked={isChecked} onChange={handleCheck} />
              ) : (
                <a
                  onClick={(event) => {
                    event.stopPropagation();
                    setIsSubmenuOpen(true);
                  }}
                >
                  <MoreIcon />
                </a>
              ))}
            <BookSubMenu
              anchorElement={ref.current}
              bookData={book}
              open={isSubmenuOpen}
              onClose={() => setIsSubmenuOpen(false)}
            />
          </ConfigContainer>
        )}
        <TitleFont>{book.name}</TitleFont>
      </BookItemContainer>
    </>
  );
};

const BookItemContainer = styled.label`
  cursor: pointer;
  width: 156px;
  padding: 24px 24px 16px;
  flex-direction: column;
  gap: 16px;
  border-radius: 10px;
  background: white;
  position: relative;
`;

const ImageContainer = styled.div`
  height: auto;
  width: 110px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  position: relative;
  img {
    width: 100%;
    aspect-ratio: 110/142;
    object-fit: cover;
  }
`;

const TitleFont = styled.div`
  color: var(--neutral-color-natural-60, #434343);
  ${FontStyleMap["KR/medium/$KR.BS.MD.12"]}
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  white-space: nowrap;
  text-align: center;
  margin: 16px -10px 0;
`;

const ConfigContainer = styled.div`
  display: flex;
  position: absolute;
  width: 16px;
  height: 16px;
  right: 32px;
  top: 32px;
  svg {
    width: 16px;
    height: 16px;
  }
`;

const PdfIconBox = styled.div`
  position: absolute;
  bottom: 5px;
  left: 6px;
`;

export default StudyBookItem;
