import React from "react";
import AccountForm from "@presentation/components/common/molecules/form/account";
import { AccountFormFiledType } from "@presentation/components/common/molecules/form/account/account-form.types";
import { Button } from "@presentation/components/common/atoms";

type LocalOauthViewProps = {
  onSubmit?: (data: AccountFormFiledType) => void;
  defaultValues?: AccountFormFiledType;
  onRandomOauth?: () => void;
  onLocalAuthCallback?: () => void;
};

function LocalOauthView({
  onSubmit,
  defaultValues,
  onRandomOauth,
  onLocalAuthCallback,
}: LocalOauthViewProps): JSX.Element {
  return (
    <div>
      <h1>로컬 oauth를 로그인</h1>
      <AccountForm
        fields={["identity", "userName", "nickname", "email", "phoneNumber"]}
        onSubmit={onSubmit}
        defaultValues={defaultValues}
      />
      <Button type={"button"} onClick={onRandomOauth} backgroundColor={"info.70"}>
        랜덤 계정 생성
      </Button>
      <Button type={"button"} onClick={onLocalAuthCallback} backgroundColor={"warning.70"}>
        로컬 콜백
      </Button>
    </div>
  );
}

export default LocalOauthView;
