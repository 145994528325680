import styled, { css } from "styled-components";
import { StyledButtonProps } from "@presentation/components/common/atoms/button/button.types";
import { getDefaultColors } from "@presentation/components/common/atoms/button/button.util";

export const variantStyles = {
  filled: css<StyledButtonProps>`
    background-color: ${(props) =>
      props.$processedBackgroundColor || getDefaultColors("filled", props.theme).background};
    color: ${(props) => props.$processedColor || getDefaultColors("filled", props.theme).color};
    border: none;

    &:hover {
      background-color: ${(props) => {
        if (props.$processedBackgroundColor) {
          return `${props.$processedBackgroundColor}dd`;
        }
        return props.theme.colors.primary[60];
      }};
    }
    &:active {
      background-color: ${(props) => {
        if (props.$processedBackgroundColor) {
          return `${props.$processedBackgroundColor}bb`;
        }
        return props.theme.colors.primary[80];
      }};
    }
    &:disabled {
      background-color: ${({ theme }) => theme.colors.natural[30]};
      color: white;
      cursor: not-allowed;
    }
  `,

  outlined: css<StyledButtonProps>`
    background-color: ${(props) =>
      props.$processedBackgroundColor || getDefaultColors("outlined", props.theme).background};
    color: ${(props) => props.$processedColor || getDefaultColors("outlined", props.theme).color};
    border-width: 1px;
    border-style: solid;
    border-color: ${({ theme }) => theme.colors.natural[20]};

    &:hover {
      border-color: ${(props) => props.$processedColor || props.theme.colors.primary[70]};
      color: ${(props) => props.$processedColor || props.theme.colors.primary[70]};
    }
    &:active {
      border-color: ${(props) => props.$processedColor || props.theme.colors.primary[80]};
      color: ${(props) => props.$processedColor || props.theme.colors.primary[80]};
    }
    &:disabled {
      border-color: ${({ theme }) => theme.colors.natural[30]};
      color: ${({ theme }) => theme.colors.natural[30]};
      cursor: not-allowed;
    }
  `,

  ghost: css<StyledButtonProps>`
    background-color: ${(props) =>
      props.$processedBackgroundColor || getDefaultColors("ghost", props.theme).background};
    color: ${(props) => props.$processedColor || getDefaultColors("ghost", props.theme).color};
    border: none;

    &:hover {
      background-color: ${(props) =>
        props.$processedBackgroundColor || props.theme.colors.natural[20]};
    }
    &:active {
      background-color: ${(props) => {
        if (props.$processedBackgroundColor) {
          return `${props.$processedBackgroundColor}33`;
        }
        return props.theme.colors.natural[40];
      }};
    }
    &:disabled {
      color: ${({ theme }) => theme.colors.natural[30]};
      cursor: not-allowed;
    }
  `,

  link: css<StyledButtonProps>`
    background-color: ${(props) =>
      props.$processedBackgroundColor || getDefaultColors("link", props.theme).background};
    color: ${(props) => props.$processedColor || getDefaultColors("link", props.theme).color};
    border: none;

    &:hover {
      background-color: ${(props) =>
        props.$processedBackgroundColor || props.theme.colors.primaryLight};
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.disabled};
      cursor: not-allowed;
    }
  `,
};

export const sizeStyles = {
  small: css`
    padding: 6px 12px;
    font-size: 14px;
  `,
  medium: css`
    padding: 8px 16px;
    font-size: 16px;
  `,
  large: css`
    padding: 10px 20px;
    font-size: 18px;
  `,
};

export const StyledButton = styled.button<StyledButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};

  ${(props) => props.variant && variantStyles[props.variant]}
  ${(props) => props.size && sizeStyles[props.size]}
  
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.primaryLight};
  }
`;
