import { ArrowBackIos, Search } from "@material-ui/icons";
import React, { CSSProperties, ReactNode, Suspense } from "react";
import { useNavigate, useParams } from "react-router";
import store from "../../asset/store";
import style from "../../asset/style";
import Frame_Style from "./Frame_Style";

import Footer from "components/molecules/Footer";
import UiLoading from "uiComponents/UiLoading/UiLoading";

type FrampProps = {
  f_style?: CSSProperties;
  t_visible?: boolean;
  isSearch?: boolean;
  fnSearch?: () => void;
  title?: string | ReactNode;
  page?: string;
  children?: ReactNode;
  isFooter?: boolean;
  isTap?: boolean;
  tapCompo?: ReactNode;
  navi?: number;
  backEvent?: () => void;
  setHiddenUrl?: (url: string) => void;
  isEx?: boolean;
  subHeader?: ReactNode;
  tapStyle?: CSSProperties;
  f_c_style?: CSSProperties;
  t_c_style?: CSSProperties;
  isDubleTap?: boolean;
  type?: string;
  isBack?: boolean;
};
const Frame = ({
  f_style,
  t_visible = true,
  isSearch = true,
  fnSearch,
  title = "검색결과",
  page,
  children,
  isFooter = true,
  isTap = false,
  tapCompo,
  navi = -1,
  backEvent = () => {},
  setHiddenUrl,
  isEx = false,
  subHeader,
  tapStyle = {},
  f_c_style,
  t_c_style,
  isDubleTap = false,
  type,
  isBack = true,
}: FrampProps) => {
  let navigate = useNavigate();
  let params = useParams();

  return (
    <div
      style={{
        ...Frame_Style.c,
        position: "relative",
        flexDirection: isEx ? "column" : "row",
        boxSizing: "border-box",
        ...f_c_style,
      }}
    >
      {t_visible && (
        <div
          style={{
            top: 0,
            left: 0,
            display: "flex",
            flexDirection: "column",
            position: isEx ? "static" : "fixed",
            minHeight: "50px",
            width: "100%",
            justifyContent: "center",
            ...f_style,
          }}
        >
          {type == "2" ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#FBFBFB",
                height: "50px",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                paddingTop: "20px",
              }}
            >
              <div
                style={{
                  ...Frame_Style.t_c,
                  maxWidth: style.common.maxWidth,
                  alignItems: "flex-end",
                }}
              >
                <div
                  style={{
                    ...Frame_Style.t_l_w,
                    width: "100%",
                  }}
                >
                  {isBack ? (
                    <ArrowBackIos
                      onClick={() => {
                        backEvent();
                        window.scroll(0, 0);
                        if (setHiddenUrl) {
                          setHiddenUrl("");
                        }

                        if (navi < 0 && store.get("stack").length <= 0) {
                          navigate("/");
                        } else {
                          navigate(navi);
                        }
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  {typeof title == "string" ? (
                    <div style={{ ...style.common.line_text, fontSize: "21px" } as CSSProperties}>
                      {title}
                    </div>
                  ) : (
                    title
                  )}
                </div>
                {isSearch && (
                  <Search
                    onClick={fnSearch}
                    style={{
                      fontSize: "32px",
                      color: style.common.fontColor_6,
                    }}
                  />
                )}
              </div>
            </div>
          ) : type == "3" ? (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#FBFBFB",
                height: "75px",
                borderBottom: "1px solid rgba(189, 189, 189, 0.1)",
              }}
            >
              <div
                style={{
                  ...Frame_Style.t_c,
                  maxWidth: style.common.maxWidth,
                  alignItems: "flex-end",
                }}
              >
                <div
                  style={{
                    ...Frame_Style.t_l_w,
                    width: "100%",
                  }}
                >
                  {isBack ? (
                    <ArrowBackIos
                      onClick={() => {
                        backEvent();
                        window.scroll(0, 0);
                        if (setHiddenUrl) {
                          setHiddenUrl("");
                        }
                        if (navi < 0 && store.get("stack").length <= 0) {
                          navigate("/");
                        } else {
                          navigate(navi);
                        }
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  {typeof title == "string" ? (
                    <div style={{ ...style.common.line_text, fontSize: "21px" } as CSSProperties}>
                      {title}
                    </div>
                  ) : (
                    title
                  )}
                </div>
                {isSearch && (
                  <Search
                    onClick={fnSearch}
                    style={{
                      fontSize: "32px",
                      color: style.common.fontColor_6,
                    }}
                  />
                )}
              </div>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "white",
              }}
            >
              <div
                style={{
                  ...Frame_Style.t_c,
                  maxWidth: style.common.maxWidth,
                }}
              >
                <div
                  style={{
                    ...Frame_Style.t_l_w,
                    width: "100%",
                  }}
                >
                  <ArrowBackIos
                    onClick={() => {
                      backEvent();
                      window.scroll(0, 0);
                      if (setHiddenUrl) {
                        setHiddenUrl("");
                      }
                      if (navi < 0 && store.get("stack").length <= 0) {
                        navigate("/");
                      } else {
                        navigate(navi);
                      }
                    }}
                  />
                  {typeof title == "string" ? (
                    <div style={{ ...style.common.line_text, fontSize: "21px" } as CSSProperties}>
                      {title}
                    </div>
                  ) : (
                    title
                  )}
                </div>
                {isSearch && (
                  <Search
                    onClick={fnSearch}
                    style={{
                      fontSize: "32px",
                      color: style.common.fontColor_6,
                    }}
                  />
                )}
              </div>
            </div>
          )}
          {subHeader && subHeader}
        </div>
      )}
      {isTap && (
        <div
          id={"tap_container"}
          style={
            {
              top: 0,
              ...style.common.block,
              backgroundColor: "white",
              maxWidth: style.common.maxWidth,
              ...t_c_style,
            } as CSSProperties
          }
        >
          <div
            style={{
              ...Frame_Style.t_c,
              ...tapStyle,
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                // backgroundColor:"purple",
              }}
            >
              {tapCompo}
            </div>
            {isSearch && (
              <Search
                style={{
                  fontSize: "32px",
                  color: style.common.fontColor_6,
                }}
              />
            )}
          </div>
        </div>
      )}
      <div
        style={{
          ...Frame_Style.children_c,
          marginTop:
            type == "2"
              ? "75px"
              : type == "3"
                ? "80px"
                : !isEx && (isTap ? (isDubleTap ? "130px" : "70px") : "50px"),
          height:
            type == "2"
              ? "calc(100vh - 147px)"
              : type == "3"
                ? "calc(100vh - 152px)"
                : isTap
                  ? "calc(100vh - 138.5px)"
                  : subHeader
                    ? "calc(100vh - 158px)"
                    : "calc(100vh - 118px)",
          boxSizing: "border-box",
          overflow: page && page === "message" ? "hidden" : "auto",
        }}
      >
        <Suspense
          fallback={
            <UiLoading isTransparent={true} isText={false} isCircle={false} text="로딩중" />
          }
        >
          {children}
        </Suspense>
      </div>
      {isFooter && <Footer />}
    </div>
  );
};

export default Frame;
