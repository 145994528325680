import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { QueryKeys } from "@api/constants";
import { getAcademyLectureList } from "@api/academy/index";
import { APP_METADATA } from "@constants";
import { AcademyListResponseDTO } from "@api/academy/types";

type UseAcademyLectureListProps = {
  limit?: number;
};

type UseAcademyLectureListReturnType = {
  academyLectureListIsError: true | false;
  academyLectureListHasNextPage: boolean;
  academyLectureListIsLoading: false | true;
  academyLectureListIsFetchingNextPage: boolean;
  academyLectureListData: InfiniteData<AcademyListResponseDTO>;
  academyLectureListFetchNextPage: (
    options?: FetchNextPageOptions
  ) => Promise<InfiniteQueryObserverResult<AcademyListResponseDTO, unknown>>;
};

export const useAcademyLectureList = ({
  limit = 1,
}: UseAcademyLectureListProps): UseAcademyLectureListReturnType => {
  const { data, isLoading, isError, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: [QueryKeys.ACADEMY_LECTURE_LIST],
      queryFn: async ({ pageParam = 1 }): Promise<AcademyListResponseDTO> => {
        return await getAcademyLectureList(pageParam, limit);
      },
      getNextPageParam: (lastPage, allPages) => {
        return allPages.length < lastPage?.maxPage ? allPages.length + 1 : undefined;
      },
      enabled: !!APP_METADATA.ACADEMY_CODE && !!APP_METADATA.IS_ACADEMY,
    });

  return {
    academyLectureListData: data,
    academyLectureListIsLoading: isLoading,
    academyLectureListIsError: isError,
    academyLectureListFetchNextPage: fetchNextPage,
    academyLectureListHasNextPage: hasNextPage,
    academyLectureListIsFetchingNextPage: isFetchingNextPage,
  };
};
