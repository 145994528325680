import styled from "styled-components";

export const AccountContextSection = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: ${({ theme }) => theme.spacing.md};
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

export const AccountFormContainer = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const AccountFormFieldBox = styled.div`
  flex: 1;
  gap: ${({ theme }) => theme.spacing.md};
  display: flex;
  flex-direction: column;
`;
