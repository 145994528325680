import { ArrowBackIos } from "@material-ui/icons";
import face_teacher from "asset/Img/defaultpic_teacher.png";
import constants from "asset/constants";
import store from "asset/store";
import util from "asset/util";
import React, { useContext, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { useLocation, useNavigate, useParams } from "react-router";
import "./UiReactPlayer.css";

import { useMutation } from "@apollo/client";
import apiFn from "asset/apiClass";
import { CommonContext, PaymentContext } from "asset/context";
import VideoPlayer from "components/molecules/VideoPlayer";
import { AskVideoQuestionDocument } from "types/graphql/graphql";
import UiLoading from "../UiLoading/UiLoading";
import UploadQuestionModal from "./UploadQuestionModal";
import { useResolveQuestion } from "../../api/question";

interface UiReactPlayerProps {
  msgPop?: boolean;
  data: any;
  isPlayer?: boolean;
  isMsg?: boolean;
  noQuestion?: boolean;
  containerStyle?: React.CSSProperties;
  setIsHeader?: any;
  senderInfo?: any;
  isSubs?: boolean;
  userInfo?: any;
  setQnaData?: any;
  qnaData?: any;
  setFileObj?: any;
  fileObj?: any;
}
const UiReactPlayer = ({
  msgPop = false,
  data,
  isPlayer = false,
  isMsg = false,
  noQuestion = false,
  containerStyle,
  setIsHeader,
  senderInfo,
  isSubs = false,
  userInfo = null,
  setQnaData,
  qnaData,
  setFileObj,
  fileObj,
}: UiReactPlayerProps) => {
  const { isFull, setIsFull } = useContext(CommonContext);
  let navigate = useNavigate();
  let params = useParams();

  const { academyId } = useContext(PaymentContext);
  var [isOn, setIsOn] = useState(true);

  var [isLoad, setIsLoad] = useState(false);
  var [isPop, setIsPop] = useState(false);
  var [isPotrait, setIsPortrait] = useState(false);

  var [duration, setDruration] = useState({
    current: 0,
    total: 0,
  });

  const [showUploadPictureModal, setShowUploadPictureModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [embedPlayerRef, setEmbedPlayerRef] = useState<any>();
  const [videoPlayerRef, setVideoPlayerRef] = useState<any>();
  const [askVideoQuestion] = useMutation(AskVideoQuestionDocument, { errorPolicy: "all" });

  var video;

  // Check the volume
  var checkVolume = function (dir) {
    if (dir) {
      var currentVolume = Math.floor(video.volume * 10) / 10;
      if (dir === "+") {
        if (currentVolume < 1) video.volume += 0.1;
      } else if (dir === "-") {
        if (currentVolume > 0) video.volume -= 0.1;
      }
      if (currentVolume <= 0) video.muted = true;
      else video.muted = false;
    }
  };

  // Change the volume
  var alterVolume = function (dir) {
    checkVolume(dir);
  };

  // Set the video container's fullscreen state
  var setFullscreenData = function (state) {
    // console.log("CALL FUllSCreen Event")
    if (!isPlayer) {
      if (isFull) {
        setIsFull(false);

        const stack = store.get("stack");
        if (stack.length > 0) navigate(-1);
        else navigate("/");
      } else {
        if (isMsg) {
          navigate(`/view/${params["who"]}/${params["qid"]}/${params["type"]}/full/0`);
        } else {
          navigate(`/columndetail/${params["who"]}/${params["id"]}/full/0`);
        }
        setIsFull(true);
      }
      return;
    }
  };

  // Fullscreen
  var handleFullscreen = function () {
    if (isPlayer) return;
    if (isFull) {
      setIsFull(false);

      const stack = store.get("stack");
      if (stack.length > 0) navigate(-1);
      else navigate("/");
    } else {
      if (isMsg) {
        navigate(`/view/${params["who"]}/${params["qid"]}/${params["type"]}/full`);
      } else {
        navigate(`/columndetail/${params["who"]}/${params["id"]}/full`);
      }
      setIsFull(true);
    }
    return;
  };

  const [windowSize, setWindowSize] = useState({});

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      // cleanup
      window.removeEventListener("resize", handleResize);
      setIsPop(false);
    };
  }, [params]);

  useEffect(() => {
    if (windowSize["width"]) {
      if (windowSize["width"] < windowSize["height"]) {
        setIsPortrait(true);
      } else {
        setIsPortrait(false);
      }
    } else {
      setIsPortrait(true);
    }
  }, [windowSize]);

  var useLoca = useLocation();
  useEffect(() => {
    try {
      // // console.log("--------- ",params)
      if (params["type"]) {
        // // console.log("#######################")
        if (params["type"] != "full") {
          // // console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@")
          // alert("pop?")
          setIsFull(false);
          setIsPop(false);
        }
      }
    } catch (e) {
      alert(e);
    }
  }, [useLoca]);

  useEffect(() => {
    return () => {
      const documentAny = document as any;
      try {
        // // console.log("REMOVE ! ! !!  !! ! !")
        document.removeEventListener("fullscreenchange", function (e) {
          setFullscreenData(!!(documentAny.fullScreen || document.fullscreenElement));
          // // console.log("Full Screen Button");
          // setIsFull(true)
        });
        // // console.log("1")
        document.removeEventListener("webkitfullscreenchange", function () {
          setFullscreenData(!!documentAny.webkitIsFullScreen);
          // // console.log("Full Screen Button");
          // setIsFull(true)
        });
        // // console.log("2")
        document.removeEventListener("mozfullscreenchange", function () {
          setFullscreenData(!!documentAny.mozFullScreen);
          // // console.log("Full Screen Button");
          // setIsFull(true)
        });
        // // console.log("3")
        document.removeEventListener("msfullscreenchange", function () {
          setFullscreenData(!!documentAny.msFullscreenElement);
          // // console.log("Full Screen Button");
          // setIsFull(true)
        });
        // // console.log("4")
        document.getElementById("fs").removeEventListener("click", function (e) {
          handleFullscreen();
        });
      } catch (e) {
        // console.log("REMOVE ERROR => ",e);
      }
    };
  }, []);

  useEffect(() => {
    if (fileObj && Object.keys(fileObj).length > 0) {
      setShowUploadPictureModal(true);
    }
  }, [fileObj]);

  async function handleSubmitQuestion(file = undefined) {
    try {
      setIsLoading(true);
      const defaultBookResponse = await fetch(`${constants.apiUrl}/class/${data.cid}/default`);
      if (defaultBookResponse.status !== 200) {
        alert("기본 교재가 없어서 질문을 등록할 수 없습니다");
        return;
      }
      const defaultBook = await defaultBookResponse.json();

      const writerId = userInfo.id;
      let teacherId = null,
        studentId = null;
      const videoUrl = !isPlayer
        ? params.qid
          ? params["qid"].includes("memo")
            ? `/memo/${params["qid"].split("&")[1]}/${
                data["video_link"].split("/")[data["video_link"].split("/").length - 1]
              }`
            : isMsg
              ? params["qid"] === "0"
                ? `general/${senderInfo["general"]}/${
                    data["video_link"].split("/")[data["video_link"].split("/").length - 1]
                  }`
                : +`/qna/${params["qid"]}/${
                    data["video_link"].split("/")[data["video_link"].split("/").length - 1]
                  }`
              : `/board/${data["id"]}` + ".mp4"
          : data["video"]
            ? `/subs_info/${params["id"]}/${
                data["video"].split("/")[data["video"].split("/").length - 1]
              }`
            : `/board/${data.id}.mp4`
        : data.video_link;
      let checkpoint: null;
      if (!isPlayer) {
        // const videoEl = document.getElementById("VideoPlayerHiQ");
        // videoEl?.pause();
        videoPlayerRef?.pause();
        checkpoint = videoPlayerRef?.currentTime();
      } else {
        try {
          embedPlayerRef?.getInternalPlayer().pauseVideo();
        } catch (e) {
          embedPlayerRef?.getInternalPlayer().pause();
        }
        checkpoint = embedPlayerRef?.getCurrentTime();
      }

      if (userInfo.type === 2 && userInfo.id === data.mid) {
        // if there is no question picture, ask for it by showing modal
        if (!file || Object.keys(file).length <= 0) {
          let checkpoint: null;
          if (!isPlayer) {
            // st videoEl = document.getElementById("VideoPlayerHiQ");
            // videoEl?.pause();
            // checkpoint = videoEl.currentTime;
            videoPlayerRef?.pause();
            checkpoint = videoPlayerRef?.currentTime();
          } else {
            try {
              embedPlayerRef.getInternalPlayer().pauseVideo();
            } catch (e) {
              embedPlayerRef.getInternalPlayer().pause();
            }
            checkpoint = embedPlayerRef.getCurrentTime();
          }

          setQnaData({ video_start: checkpoint });
          setShowUploadPictureModal(true);
          return;
        }

        teacherId = userInfo.id;

        let filepath = `qna/temp-${teacherId}-${new Date().getMilliseconds()}.jpg`;
        const img = await apiFn.uploadFile(filepath, util.dataURLtoFile(file.base64));

        const { qid } = await apiFn.onlineQnaSet({
          state: {
            title: data.title + "." + qnaData.video_start,
            tid: teacherId,
            bid: defaultBook.bid,
            qna_date: "y",
            ans_date: "y",
            mobum_date: "y",
            new: "y",
            mobum: "y",
            img,
          },
        });
        await store.putBucket(util.dataURLtoFile(file.base64), `qna/${qid}.jpg`, false, false);
        const videoStart = Number(qnaData.video_start).toString();
        await apiFn.onlineQnaMsgSet({
          state: {
            qid: qid,
            wtype: 2,
            wid: teacherId,
            content: videoUrl,
            video_start: parseInt(videoStart),
            ctype: 12,
          },
        });

        await Promise.all([
          useResolveQuestion(qid, userInfo.id),
          apiFn.onlineQnaMsgSet({
            state: {
              qid: qid,
              wtype: 2,
              wid: teacherId,
              content: "답변이 완료되었습니다.",
              ctype: 10,
            },
          }),
        ]);

        navigate(`/talk/${params.who}/0/${qid}/${studentId}/${teacherId}`);
      } else if (userInfo.type === 1) {
        try {
          const studentId = userInfo.id;
          const teacherId = data.mid;
          const bookId = parseInt(defaultBook.bid);
          const variables = {
            input: {
              bookId,
              studentId,
              teacherId,
              videoUrl,
              videoCheckPoint: checkpoint,
              title: data.title,
              academyId,
            },
          };
          const { data: askVideoData, errors } = await askVideoQuestion({ variables });
          const errorMessage = errors?.[0]?.extensions?.message;
          if (!!errorMessage) {
            throw new Error(errorMessage as string);
          }
          const questionId = askVideoData.askVideoQuestion.id;
          navigate(`/talk/${params.who}/0/${questionId}/${studentId}/${teacherId}`);
        } catch (e) {
          const error = e as Error;
          alert(error?.message || "질문 등록에 실패했습니다.");
        }
      } else {
        alert("유효하지 않은 사용자입니다");
        return;
      }
    } catch (e) {
      alert("네트워크 오류가 발생했습니다");
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  console.log("data", data, params);
  return (
    <>
      {!isPlayer ? (
        <>
          {/* {
        isMsg &&
            <div style={{
                // fontSize : "14px",
                width:"200px",
                height:"200px",
                backgroundColor:"red",
                paddingRight:"30px",
                color : 'white',
            }}
                onClick={()=>{
                    // console.log("@@@ ",isPop);
                    setIsPop(!isPop)
                }}
            >
                {isPop ? "문제 닫기" : "문제 보기"}
            </div>
        } */}
          <div
            style={
              isMsg
                ? params["mo"] == "full"
                  ? {
                      zIndex: 99999,
                      position: "fixed",
                      top: 0,
                      left: 0,
                      width: isPotrait ? "100vw" : window.innerWidth,
                      height: isPotrait ? "100vh" : window.innerHeight,
                      backgroundColor: "rgba(0,0,0,1)",
                      // backgroundColor : 'black'
                    }
                  : {
                      position: "relative",
                      width: "100%",
                      height: "100%",
                      // width: isPotrait ? "100%" : window.innerWidth,
                      // height: isPotrait ? "100%" : window.innerHeight,
                    }
                : params["type"] == "full"
                  ? {
                      zIndex: 99999,
                      position: "fixed",
                      top: 0,
                      left: 0,
                      width: "100vw",
                      height: "100vh",
                      backgroundColor: "rgba(0,0,0,1)",
                    }
                  : {
                      position: "relative",
                      display: "flex",
                      minHeight: "30vh",
                      flex: 1,
                    }
            }
            className="DDDDDDDDDD"
            onClick={() => {
              if (isOn) {
                setIsOn(false);
                if (setIsHeader) {
                  setIsHeader(false);
                }
              } else {
                setIsOn(true);
                if (setIsHeader) {
                  setIsHeader(true);
                }
              }
            }}
          >
            <VideoPlayer
              startTime={data["video_start"]}
              endTime={data["video_end"]}
              handleFullScreen={isMsg ? null : handleFullscreen}
              fullscreenable={isMsg ? false : true}
              poster={
                params["qid"]
                  ? params["qid"].includes("memo")
                    ? constants.s3_url +
                      `/memo/${params["qid"].split("&")[1]}/${data["video_link"]
                        .split("/")
                        [data["video_link"].split("/").length - 1].replace(".MP4", ".jpg")
                        .replace(".mp4", ".jpg")}`
                    : isMsg
                      ? // constants.apiUrl
                        params["qid"] === "0"
                        ? constants.s3_url +
                          // '/public'
                          // +
                          `/general/${senderInfo["general"]}/${data["video_link"]
                            .split("/")
                            [data["video_link"].split("/").length - 1].replace(".MP4", ".jpg")
                            .replace(".mp4", ".jpg")}`
                        : data["video_end"]
                          ? constants.s3_url +
                            `/qna/${params["qid"]}/${data["video_link"]
                              .split("/")
                              [data["video_link"].split("/").length - 1].replace(".MP4", ".jpg")
                              .replace(".mp4", ".jpg")}`
                          : constants.s3_url +
                            `/board/${data["video_link"]
                              .split("/")
                              [data["video_link"].split("/").length - 1].replace(".MP4", ".jpg")
                              .replace(".mp4", ".jpg")}`
                      : constants.s3_url +
                        `/board/${data["id"]}` +
                        ".jpg?" +
                        util.getRandomInt(0, 9999)
                  : data["video"] &&
                    constants.s3_url +
                      `/subs_info/${params["id"]}/${data["video"]
                        .split("/")
                        [data["video"].split("/").length - 1].replace(".MP4", ".jpg")
                        .replace(".mp4", ".jpg")}`
              }
              src={
                isMsg
                  ? data["video_link"]
                  : isSubs
                    ? constants.s3_url + `/subs_info/${params["id"]}/${data["video"]}`
                    : `${constants.video_bucket}/board/${data?.id}.mp4`
              }
              onErrorSrc={!isMsg && !isSubs && `${constants.s3_url}/board/${data["id"]}.mp4`}
              setPlayerRef={setVideoPlayerRef}
            />
          </div>
          <div
            style={{
              zIndex: 999999999999,
              position: "absolute",
              top: 0,
              // display :"flex",
              left: 0,
              display: isFull ? (isOn ? "flex" : "none") : "none",
              flexDirection: "column",
              width: "100%",
              flex: 1,
              padding: "5px 0px",
              backgroundColor: "rgba(0,0,0,0.6)",
              alignItems: "center",
              ...containerStyle,
            }}
          >
            <div
              style={{
                position: "fixed",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                // backgroundColor : "rgba(0,0,0,0.6)",
                width: "100%",
                padding: "10px 0px",
                // flex:1,
                // padding:"5px 50px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "10px",
                }}
              >
                <ArrowBackIos
                  onClick={() => {
                    window.scroll(0, 0);
                    setIsFull(false);

                    const stack = store.get("stack");
                    if (stack.length > 0) navigate(-1);
                    else navigate("/");
                  }}
                  style={{
                    color: "white",
                    paddingLeft: "10px",
                  }}
                />
                <img
                  onError={(e) => {
                    e.currentTarget.src = face_teacher;
                  }}
                  src={
                    isMsg
                      ? `${constants.apiUrl}/bookCover/${
                          data["bid"]
                        }.jpg?${util.getRandomInt(1000, 9999)}`
                      : isSubs
                        ? ""
                        : data["mid"] &&
                          `${constants.apiUrl}/teacher/${data["mid"].normalize("NFD")}.jpg`
                  }
                  style={{
                    // width : "30px",
                    height: "36px",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    navigate(`/myinfo/teacher/${data["mid"]}`);
                  }}
                />
                {!isSubs && (
                  <div>
                    <div
                      style={{
                        color: "white",
                        fontSize: "18px",
                        padding: "3px 0px",
                        fontWeight: 600,
                      }}
                    >
                      {data["title"]}
                    </div>
                    <div>
                      <div
                        style={{
                          color: "white",
                          fontSize: "10px",
                        }}
                      >
                        {isMsg
                          ? `${data["name"]}`
                          : `${data["writer"] && data["writer"]["name"]} | ${data["name"]}`}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {!noQuestion && (
                <div
                  style={{
                    // fontSize : "14px",
                    paddingRight: "30px",
                    color: "white",
                  }}
                  onClick={() => handleSubmitQuestion()}
                >
                  질문 등록
                </div>
              )}
              {!noQuestion && data.cat_id !== "158" && (
                <div
                  style={{
                    // fontSize : "14px",
                    paddingRight: "30px",
                    color: "white",
                  }}
                  onClick={() => {
                    // // console.log("@@@ ",isPop);
                    setIsPop(!isPop);
                  }}
                >
                  {isPop ? "문제 닫기" : "문제 보기"}
                </div>
              )}
            </div>
          </div>
          {!noQuestion && (
            <div
              style={{
                textAlign: "center",
                position: "absolute",
                left: "calc(50% - 60px)",
                width: 120,
                zIndex: 10,
                display: "inline-block",
              }}
            >
              <button
                onClick={() => handleSubmitQuestion()}
                style={{
                  width: 120,
                  borderRadius: 4,
                  backgroundColor: "rgb(247, 184, 101)",
                  border: "none",
                  color: "white",
                }}
              >
                질문 등록
              </button>
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            ...containerStyle,
            width: "100%",
          }}
        >
          <ReactPlayer
            onProgress={(e) => {
              if (
                e.playedSeconds < data["video_start"] ||
                e.playedSeconds > (data.video_end ?? embedPlayerRef.getDuration())
              ) {
                embedPlayerRef.seekTo(data["video_start"], "seconds");
                // embedPlayerRef.seekTo(60,'seconds')
              }
            }}
            onSeek={(seconds) => {
              console.log(seconds);
            }}
            // style={{
            //     minHeight : "600px",
            // }}
            url={data["video_link"]}
            // url="https://www.youtube.com/watch?v=rF3dt0DTLJs"
            width="100%"
            // height={"100%"}
            playing={false}
            controls={true}
            // config={{
            //     youtube : {
            //         embedOptions : {
            //             fullScreenEnabled : "allowfullscreen",
            //             fullscreen : "allowfullscreen"
            //         }
            //     },
            //     vimeo : {
            //         fullscreen : 1
            //     }
            // }}
            pip={false}
            ref={(r) => {
              setEmbedPlayerRef(r);
            }}
          />
          {/* <VideoPlayer
            startTime={data["video_start"]}
            endTime={data["video_end"]}
            src={data["video_link"]}
            width="100%"
            ref={(r) => {
              setEmbedPlayerRef(r);
            }}
            poster={
              params["qid"]
                ? params["qid"].includes("memo")
                  ? constants.s3_url +
                    `/memo/${params["qid"].split("&")[1]}/${data["video_link"]
                      .split("/")
                      [data["video_link"].split("/").length - 1].replace(
                        ".MP4",
                        ".jpg"
                      )
                      .replace(".mp4", ".jpg")}`
                  : isMsg
                  ? // constants.apiUrl
                    params["qid"] === "0"
                    ? constants.s3_url +
                      // '/public'
                      // +
                      `/general/${senderInfo["general"]}/${data["video_link"]
                        .split("/")
                        [data["video_link"].split("/").length - 1].replace(
                          ".MP4",
                          ".jpg"
                        )
                        .replace(".mp4", ".jpg")}`
                    : constants.s3_url +
                      `/qna/${params["qid"]}/${data["video_link"]
                        .split("/")
                        [data["video_link"].split("/").length - 1].replace(
                          ".MP4",
                          ".jpg"
                        )
                        .replace(".mp4", ".jpg")}`
                  : constants.s3_url +
                    `/board/${data["id"]}` +
                    ".jpg?" +
                    util.getRandomInt(0, 9999)
                : data["video"] &&
                  constants.s3_url +
                    `/subs_info/${params["id"]}/${data["video"]
                      .split("/")
                      [data["video"].split("/").length - 1].replace(
                        ".MP4",
                        ".jpg"
                      )
                      .replace(".mp4", ".jpg")}`
            }
          /> */}
          {!noQuestion && (
            <div style={{ textAlign: "center" }}>
              <button
                onClick={() => handleSubmitQuestion()}
                style={{
                  width: 120,
                  borderRadius: 4,
                  backgroundColor: "rgb(247, 184, 101)",
                  border: "none",
                  color: "white",
                }}
              >
                질문 등록
              </button>
            </div>
          )}
        </div>
      )}
      {(isPop || msgPop) &&
        (isPotrait ? (
          <div
            style={{
              position: "fixed",
              bottom: 0,
              display: "flex",
              alignItems: "flex-end",
              right: 2,
              // width:"50vw",
              height: "calc(100vh - 100px)",
              zIndex: 100002,
            }}
          >
            <div
              style={{
                overflowY: "scroll",
                maxHeight: "50vh",
                backgroundColor: "white",
              }}
            >
              <img
                className={data["mosaic"] == "y" ? "mosaic" : null}
                src={
                  isMsg
                    ? data.img
                      ? `${constants.s3_url}/qna/${data["img"]}.jpg`
                      : `${constants.s3_url}/qna/${data["original_img"]}.jpg`
                    : // `${constants.apiUrl}/public/qna/${data["qid"]}.jpg?${util.getRandomInt(1000,9999)}`
                      // `${constants.s3_url}/public/qna/${data["qid"]}.jpg?${util.getRandomInt(1000,9999)}`
                      // data["original_img"] ?
                      // `${constants.apiUrl}/public/qna/${data["original_img"]}.jpg?${util.getRandomInt(1000,9999)}`
                      // :
                      // // `${constants.apiUrl}/public/qna/${params["qid"]}/${params["type"]}?${util.getRandomInt(1000,9999)}`
                      data["id"]
                      ? data["cat_id"] !== 158
                        ? constants.s3_url +
                          // '/public'
                          // +
                          `/board/${data["id"]}_t` +
                          ".jpg"
                        : // +
                          // util.getRandomInt(1000,9999)
                          constants.s3_url + `/board/${data["id"]}` + ".jpg"
                      : // +
                        // util.getRandomInt(1000,9999)
                        ""
                }
                // src={
                // constants.apiUrl
                // +
                // '/public'
                // +
                // `/board/${data["id"]}`
                // +
                // '.jpg'
                // }

                style={{
                  width: "100vw",
                }}
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              position: "fixed",
              right: 15,
              top: 50,
              height: "calc(100vh - 60px)",
              // backgroundColor:"white",
              overflowY: "scroll",
              zIndex: 100002,
            }}
          >
            <div
              style={{
                backgroundColor: "white",
              }}
            >
              <img
                className={data["mosaic"] == "y" ? "mosaic" : null}
                src={
                  isMsg
                    ? `${constants.s3_url}/qna/${data["img"]}.jpg`
                    : // `${constants.apiUrl}/public/qna/${data["qid"]}.jpg?${util.getRandomInt(1000,9999)}`
                      // data["original_img"] ?
                      // `${constants.apiUrl}/public/qna/${data["original_img"]}.jpg?${util.getRandomInt(1000,9999)}`
                      // :
                      // // `${constants.apiUrl}/public/qna/${params["qid"]}/${params["type"]}?${util.getRandomInt(1000,9999)}`
                      data["id"]
                      ? data["cat_id"] != 158
                        ? constants.apiUrl +
                          `/board/${data["id"]}_t` +
                          ".jpg?" +
                          util.getRandomInt(1000, 9999)
                        : constants.apiUrl +
                          `/board/${data["id"]}` +
                          ".jpg?" +
                          util.getRandomInt(1000, 9999)
                      : ""
                }
                style={{
                  width: "50vw",
                }}
              />
            </div>
          </div>
        ))}
      {showUploadPictureModal && (
        <UploadQuestionModal
          fileObj={fileObj}
          onChange={(e) => {
            setFileObj({
              name: ``,
              file: e,
            });
            navigate(`/crop/teacher/set`);
          }}
          onSubmit={async (fileObj) => {
            try {
              await handleSubmitQuestion(fileObj);
              setFileObj({});
              setShowUploadPictureModal(false);
            } catch (e) {
              console.error(e);
              alert("문제 등록을 실패했습니다");
            } finally {
              setIsLoading(false);
            }
          }}
          onCancel={() => {
            setShowUploadPictureModal(false);
            setFileObj({});
          }}
        />
      )}

      {isLoading && <UiLoading isFull isTransparent={true} />}
    </>
  );
};

export default UiReactPlayer;
