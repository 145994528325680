import React from "react";
import styled from "styled-components";
import SignInButton from "@presentation/components/views/signIn/loginButtonList/signInButton";
import appleLogo from "@assets/images/apple_logo.png";
import naverLogo from "@assets/images/naver_logo.png";
import kakaoLogo from "@assets/images/kakao_logo.png";
import { CONFIG } from "@config";

const ButtonListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.sm};
`;

const SignInButtonList: {
  buttonName: string;
  buttonLogo?: string;
  type: string;
  background?: string;
  color?: string;
}[] = [
  { buttonName: "네이버 로그인", buttonLogo: naverLogo, type: "naver", background: "#03c75a" },
  {
    buttonName: "카카오 로그인",
    buttonLogo: kakaoLogo,
    type: "kakao",
    background: "#fee500",
    color: "#444",
  },
  { buttonName: "Apple 로그인", buttonLogo: appleLogo, background: "#000", type: "apple" },
  ...(CONFIG.APP_ENV.IS_DEVELOPMENT
    ? [
        { buttonName: "테스트 Oauth 인증 버튼", type: "localOauth" },
        ...CONFIG.CONSTANTS.TEST_ACCOUNT.map((account) => ({
          buttonName: account.name,
          type: JSON.stringify(account),
        })),
      ]
    : []),
];

type LoginButtonListProps = {
  onLogin: (id: string) => void;
};

function LoginButtonList({ onLogin }: LoginButtonListProps): JSX.Element {
  return (
    <ButtonListContainer>
      {SignInButtonList.map((button, index) => (
        <SignInButton
          key={index}
          buttonName={button.buttonName}
          buttonLogo={button?.buttonLogo}
          background={button?.background}
          color={button?.color}
          onLogin={() => onLogin(button.type)}
        />
      ))}
    </ButtonListContainer>
  );
}

export default LoginButtonList;
