import React from "react";
import BrowserRouterProvider from "./browserRouter/ui";
import NavigationInitProvider from "./navigationInit/ui";

export default function ProviderContainer({ children }) {
  return (
    <>
      <BrowserRouterProvider>
        <NavigationInitProvider>{children}</NavigationInitProvider>
      </BrowserRouterProvider>
    </>
  );
}
