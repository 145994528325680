import React from "react";
import UiRadioListStyle from "./UiRadioListStyle ";

// import UiStyle from '../UiStyle'

const UiRadioList = ({
  list,
  inputName,
  initValue,
  label,
  isDisable = false,
  isLabel = false,
  fnClick,
  isColumn,
  containerStyle,
  labelStyle,
}) => {
  return (
    <div
      style={{
        ...UiRadioListStyle.container,
        ...containerStyle,
        flexDirection: isColumn ? "column" : "row",
      }}
    >
      {/* {createRadioList()} */}
      {list.map((v, i) => (
        <div
          key={i}
          style={{
            display: "flex",
            // flex:1,
            // marginTop:25,
            marginLeft: i != 0 && "10px",
            alignItems: "center",
          }}
        >
          <input
            type="radio"
            disabled={isDisable}
            name={inputName}
            id={v.value}
            onClick={fnClick}
            style={{
              marginBottom: "2.5px",
            }}
            checked={initValue && v["value"] == initValue && "checked"}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              // width:"100%",
              justifyContent: "flex-end",
              marginLeft: "6px",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                // backgroundColor:"red",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <label htmlFor={v.value} style={{ ...UiRadioListStyle.label_style, ...labelStyle }}>
                {v.name}
              </label>
            </div>
            {v.value == "acc" && (
              <div
                style={{
                  ...UiRadioListStyle.label_style,
                  color: "#8F8F8F",
                  fontSize: "14px",
                  // ...UiStyle.TextStyle.a9b9b9b_small,
                  marginLeft: 12,
                }}
              >
                {label}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default UiRadioList;
