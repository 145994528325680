import { useQuery } from "@tanstack/react-query";
import { AccountQueryKeys } from "@application/account/queryKeys";
import { accountUseCase } from "@application/account";
import { useSetAtom } from "jotai";
import { accountAtom } from "@atoms/account/atom";
import { IAccount } from "@entities";
import { ACCOUNT_TYPE_ENUM } from "@enum";

export const useGetProfile = () => {
  const setStateProfile = useSetAtom(accountAtom);

  return useQuery<IAccount, Error>({
    queryKey: [AccountQueryKeys.ACCOUNT_PROFILE],
    queryFn: (): Promise<IAccount> => {
      return accountUseCase.getProfile();
    },
    onSuccess: (data) => {
      setStateProfile(data);
      window.localStorage.setItem(
        "user",
        JSON.stringify({ id: data.userID, type: data.type === ACCOUNT_TYPE_ENUM.STUDENT ? 1 : 2 })
      );
    },

    onError: (error) => {
      console.error(error.message);
    },
    enabled: false,
    useErrorBoundary: false,
    suspense: false,
    staleTime: 5 * 60 * 1000,
    cacheTime: 30 * 60 * 1000,
  });
};
