import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { getLectureSearchList } from "@api/lecture/index";
import { QueryKeys } from "@api/constants";
import { LectureSearchResponseDTO } from "@api/lecture/types";
import { APP_METADATA } from "@constants";
import { getAcademyLectureSearchList } from "@api/academy";

type UseLectureSearchProps = {
  searchText: string;
  limit?: number;
  enable?: boolean;
};

type UseLectureSearchReturnType = {
  lectureSearchIsError: true | false;
  lectureSearchHasNextPage: boolean;
  lectureSearchIsLoading: false | true;
  lectureSearchIsFetchingNextPage: boolean;
  lectureSearchData: InfiniteData<LectureSearchResponseDTO>;
  lectureSearchFetchNextPage: (
    options?: FetchNextPageOptions
  ) => Promise<InfiniteQueryObserverResult<LectureSearchResponseDTO, unknown>>;
};

export const useLectureSearch = ({
  limit,
  searchText,
  enable,
}: UseLectureSearchProps): UseLectureSearchReturnType => {
  const { data, isLoading, isError, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery({
      queryKey: [QueryKeys.LECTURE_SEARCH_LIST, searchText],
      queryFn: async ({ pageParam = 1 }): Promise<LectureSearchResponseDTO> => {
        if (APP_METADATA.IS_ACADEMY) {
          return await getAcademyLectureSearchList({ page: pageParam, limit, searchText });
        } else {
          return await getLectureSearchList({ page: pageParam, limit, searchText });
        }
      },
      getNextPageParam: (lastPage, allPages) => {
        return allPages.length < lastPage?.maxPage ? allPages.length + 1 : undefined;
      },
      enabled: enable,
      suspense: false,
    });

  return {
    lectureSearchData: data,
    lectureSearchIsLoading: isLoading,
    lectureSearchIsError: isError,
    lectureSearchFetchNextPage: fetchNextPage,
    lectureSearchHasNextPage: hasNextPage,
    lectureSearchIsFetchingNextPage: isFetchingNextPage,
  };
};
