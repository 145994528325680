import React from "react";
import {
  AccountInfoArea,
  ActionSection,
  Button,
  ButtonArea,
  ContentSection,
  DescriptionArea,
  HeaderContainer,
  MainContentContainer,
} from "@presentation/views/authViews/linkExistingAccountView/linkExistingAccount.styles";
import { Typography } from "@presentation/components/common/atoms/typography";
import AccountInfoItem from "@presentation/components/views/auth/accountInfoItem";
import { BaseLayout } from "../../../../common/layout";

type LinkExistingAccountProps = {
  existingAccount: {
    username?: string;
    email?: string;
    phoneNumber?: string;
    type?: string;
  };
  onLinkExistingAccount: () => void;
  onNotMyAccount: () => void;
};

const labelMap = {
  username: "이름",
  nickname: "닉네임",
  email: "이메일",
  phoneNumber: "전화번호",
  type: "회원 정보",
};

function LinkExistingAccountView({
  existingAccount,
  onLinkExistingAccount,
  onNotMyAccount,
}: LinkExistingAccountProps): JSX.Element {
  return (
    <BaseLayout>
      <HeaderContainer>
        <Typography variant={"medium.lg"}>계정 확인</Typography>
      </HeaderContainer>
      <MainContentContainer>
        <ContentSection>
          <DescriptionArea>
            <Typography fontSize={"xl"} as={"h2"} fontWeight={"semiBold"}>
              더 나은 서비스를 위해 인증 시스템을 새롭게 업그레이드했어요!
            </Typography>
            기존 계정을 안전하게 이어가기 위해 연결이 필요해요. 아래 계정 정보가 본인이 맞는지
            확인해주세요!
            <Typography color={"info.70"} fontSize={"xs"}>
              새로운 인증 시스템으로 더 빠르고 안전하게 서비스를 이용하실 수 있어요.
            </Typography>
          </DescriptionArea>
          <AccountInfoArea>
            <Typography fontWeight={"medium"} as={"h3"}>
              기존 계정 정보
            </Typography>
            {Object.keys(existingAccount).map((key) => {
              return (
                <AccountInfoItem key={key} label={labelMap[key]} value={existingAccount[key]} />
              );
            })}
          </AccountInfoArea>
        </ContentSection>
        <ActionSection>
          <ButtonArea>
            <Button onClick={onLinkExistingAccount}>네, 제 계정이 맞습니다.</Button>
            <Button
              backgroundColor={"white"}
              color={"black"}
              border={"1px solid black"}
              onClick={onNotMyAccount}
            >
              아니요, 제 계정이 아닙니다.
            </Button>
          </ButtonArea>
        </ActionSection>
      </MainContentContainer>
    </BaseLayout>
  );
}

export default LinkExistingAccountView;
