import React from "react";

export function PrivacyPolicy(): JSX.Element {
  return (
    <>
      1. 총칙
      <br />
      <br />
      주식회사 BNZ[이하 “회사”]는 회원의 개인정보보호를 소중하게 생각하고, 회원의 개인정보를
      보호하기 위하여 항상 최선을 다해 노력하고 있습니다.
      <br />
      <br />
      1) 회사는 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』을 비롯한 모든 개인정보보호 관련
      법률규정을 준수하고 있으며, 관련 법령에 의거한 개인정보취급정책을 정하여 이용자 권익 보호에
      최선을 다하고 있습니다.
      <br />
      2) 회사는 『개인정보취급정책』을 제정하여 이를 준수하고 있으며, 이를 인터넷사이트 및 모바일
      어플리케이션에 공개하여 이용자가 언제나 용이하게 열람할 수 있도록 하고 있습니다.
      <br />
      <br />
      <br />
      2. 개인정보의 수집방법 및 항목
      <br />
      <br />
      회사는 회원가입, 원활한 상담, 각종 서비스의 제공 등을 위해 아래와 같은 최소한의 개인정보를
      수집하고 있습니다.
      <br />
      <br />
      1) 회원가입 시점에서 수집하는 항목
      <br />- 이메일 주소, 이름, 휴대폰 번호 및 비밀번호, 닉네임, 성별, 생년월일, 학원개인번호,
      학원소속반등 (본인의 동의하에 학원에 제공된 개인정보)
      <br />
      <br />
      2) 서비스 이용과정에서 수집될 수 있는 개인정보의 범위
      <br />- 서비스 이용에 필요한 사진, 이름, 직업, 학력, 지역명, SNS정보(페이스북, 인스타그램,
      유튜브), 관심사
      <br />- 서비스 이용과정에서 이용자가 입력한 정보 및 작성한 게시글
      <br />- 상담기능을 이용하는 경우 휴대폰 번호
      <br />- 학생관리기능을 이용하는 경우 학생의 사진, 이름, 학생의 휴대폰 번호, 부모의 휴대폰
      번호, 주소, 생년월일, 이메일 주소, 학교정보
      <br />- 접속 로그, 쿠키, 접속IP정보, 서비스 이용 기록, 불량 이용 기록
      <br />
      <br />
      3) 개인정보의 수집방법
      <br />- 모바일애플리케이션, 웹페이지, 서면양식, 팩스, 고객센터를 통한 전화와 온라인 상담,
      이벤트 응모
      <br />
      <br />
      <br />
      3. 개인정보의 수집 및 이용목적
      <br />
      <br />
      회사는 다음의 목적을 위하여 개인정보를 수집합니다. 개인정보는 다음의 목적 이외의 용도로는
      이용되지 않으며, 이용목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의 동의를 받는
      등 필요한 조치를 이행할 예정입니다.
      <br />
      <br />
      1) 서비스의 원활한 제공 및 운영
      <br />
      <br />
      2) 회원관리
      <br />
      회원제 서비스 이용, 개인식별, 불량회원의 부정 이용 방지와 비인가 사용 방지, 가입의사 확인,
      가입 및 가입횟수 제한, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달
      <br />
      <br />
      3) 신규 서비스 개발
      <br />
      신규 서비스 개발, 이용자별 맞춤 서비스 제공, 서비스 유효성 확인
      <br />
      <br />
      4) 마케팅 및 광고에의 활용
      <br />
      통계학적 특성에 따른 서비스 제공 및 광고 게재, 정기 간행물 발송, 서비스의 유효성 확인, 이벤트
      및 광고성 정보 제공 및 참여기회 제공, 접속 번호 파악, 회원의 서비스 이용에 대한 통계
      <br />
      <br />
      4. 수집하는 개인정보의 항목
      <br />
      <br />
      회원구분에 따라 다음의 목적을 위해 개인정보를 수집합니다.
      <br />
      <br />
      1) 서비스의 원활한 제공 및 운영
      <br />
      i. 회원 공통
      <br />
      (필수) 이메일 주소, 이름, 휴대폰번호 및 비밀번호, 닉네임, 성별, 생년월일 등 (본인의 동의하에
      학원에 제공된 개인정보)
      <br />
      (기타) 서비스 이용내역, 접속 로그, 쿠키, 접속IP정보, 서비스 이용 기록, 불량 이용 기록
      <br />
      ii. 선생님
      <br />
      (선택) 사진, 이름, 직업, 학력, 지역명, SNS정보(페이스북, 인스타그램, 유튜브)
      <br />
      iii. 학생
      <br />
      (선택) 사진, 이름, 학력, 지역명, SNS정보(페이스북, 인스타그램, 유튜브), 관심사
      <br />
      iv. 학부모
      <br />
      (선택) 사진, 자녀 성별, 자녀 학력, 지역명, SNS정보(페이스북, 인스타그램, 유튜브) <br />
      <br />
      2) 마케팅 및 광고에 활용
      <br />
      닉네임, 성별, 휴대폰번호, 이메일 주소, 지역명
      <br />
      <br />
      <br />
      5. 개인정보 수집, 이용, 제공에 대한 동의
      <br />
      <br />
      회사는 이용자가 최초 회원가입 시 또는 서비스 이용과 관련해 개인정보를 입력할 시
      개인정보취급정책 및 이용약관 등의 내용에 대해 동의 여부를 체크할 수 있는 절차를 마련하여, 이를
      체크하면 개인정보수집에 대해 동의한 것으로 취급하며, 미동의시 서비스 이용이 불가능 합니다.
      아울러 회사는 마케팅 및 광고에 활용하기 위하여 개인정보를 수집할 시 이용자로부터 별도 동의를
      받습니다.
      <br />
      <br />
      <br />
      6. 개인정보의 목적 외 이용 및 제 3자 제공
      <br />
      <br />
      1) 회사는 이용자의 개인정보를 “3.개인정보의 수집 및 이용목적”에서 고지한 범위 내에서 사용하며
      동 범위를 초과하여 이용하지 않고 원칙적으로 제3자에게 제공하지 않습니다.
      <br />
      <br />
      2) 다만, 다음의 경우 예외적으로 이용자의 동의를 받지 않습니다.
      <br />
      <br />
      i. 서비스 제공에 따른 요금정산을 위하여 필요한 경우
      <br />
      ii. 이용자들이 사전에 동의한 경우
      <br />
      iii. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의
      요구가 있는 경우
      <br />
      <br />
      <br />
      7. 개인정보의 취급위탁
      <br />
      회사는 원활한 업무처리와 회원에게 보다 나은 서비스 제공을 위하여 회원의 개인정보를 위탁하여
      처리할 수 있으며, 이 경우 반드시 사전에 위탁처리 업체명과 목적, 위탁처리 되는 개인정보의 범위,
      위탁기간 등에 대해 상세하게 고지합니다.
      <br />
      <br />
      8. 개인정보의 보유기간 및 파기
      <br />
      <br />
      1) 회사가 회원의 개인정보를 수집하는 경우 그 보유기간은 원칙적으로 회원가입 후 약관에 의한
      “계약기간 및 이용계약의 종료시”까지며, 이용계약 종료의 경우 회사는 회원의 개인정보를 즉시
      파기합니다. (단, 재가입유예기간 동안의 재가입 방지 등을 위해 이용계약이 종료한 날로부터 1개월
      경과 후에 파기하도록 합니다.) 또한 다음의 경우 각각 명시한 기간 동안 개인정보를 보유합니다.
      <br />
      <br />
      i. 상법 등의 법령의 규정에 의하여 보존할 필요성이 있는 경우 관계 법령 『전자상거래 등에서의
      소비자보호에 관한 법률』 제6조(거래기록의 보존 등) 에서 규정한 보존기간 동안 거래내역과
      최소한의 기본정보를 보유
      <br />
      <br />
      ii. 다음의 경우 명시한 기간 동안 개인정보를 보유
      <br />- 소비자의 불만 또는 분쟁처리에 관한 기록: 3년
      <br />- 부정이용 등에 관한 기록: 5년
      <br />- 방문 기록: 3개월
      <br />- 서비스 내 사용자 특정에 필요한 기록(쿠키, 세션): 로그인 기간 내<br />
      <br />
      2) 회사는 고객의 개인정보를 보호하여 개인정보 유출로 인한 피해가 발생하지 않도록 하기 위하여
      다음과 같은 방법을 통하여 개인정보를 파기합니다.
      <br />- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
      <br />- 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여
      삭제합니다.
      <br />- 상기 파기과정은 회사가 파기 사유가 발생한 개인정보를 선정하고 개인정보 보호책임자의
      승인을 받은 후에 진행합니다.
      <br />
      <br />
      3) 회원의 동의를 받아 보유하고 있는 개인정보에 대한 당해 회원의 열람요구가 있을 경우 회사는
      지체 없이 이를 열람, 확인할 수 있도록 조치합니다.
      <br />
      <br />
      4) 회사는 유용한 서비스 개발을 위해 개인정보 파기 사유 발생시 암호화, 등의 정보 보호 절차를
      거친 뒤 보유할 수 있습니다. 단, 이렇게 보유한 정보는 법률에 의한 경우를 제외하고는 서비스 외
      다른 목적으로 이용되지 않습니다.
      <br />
      <br />
      9. 이용자 및 법정대리인의 권리와 그 행사방법
      <br />
      <br />
      1) 이용자 및 법정대리인은 언제든지 등록되어 있는 자신 또는 대리자의 개인정보를 조회하거나
      수정, 파기 할 수 있으며 가입해지를 요청할 수도 있습니다.
      <br />
      2) 이용자 혹은 대리인의 개인정보 조회,수정을 위해서는 ‘내 정보’를, 가입 해지(동의철회)를
      위해서는 고객센터를 통해 본인확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴 요청이
      가능합니다.
      <br />
      3) 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하여 개인정보의 조회, 수정 및 가입 해지
      요청이 가능합니다.
      <br />
      4) 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용
      또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를
      제3자에게 통지합니다.
      <br />
      5) 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 7조 “개인정보의
      보유기간 및 파기”에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록
      처리하고 있습니다.
      <br />
      6) 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여
      정보주체의 권리가 제한될 수 있습니다.
      <br />
      7) 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는
      경우에는 그 삭제를 요구할 수 없습니다.
      <br />
      8) 회사는 이용자의 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등
      요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.
      <br />
      <br />
      <br />
      10. 위치기반정보의 수집에 관한 사항
      <br />
      <br />
      회사는 원활한 서비스의 제공을 위해 위치기반정보를 수집할 수 있으며 이에 관하여는
      위치기반서비스 이용약관을 참고하시기 바랍니다.
      <br />
      <br />
      <br />
      11. 개인정보의 기술적/관리적 보호 대책
      <br />
      <br />
      회사는 이용자들의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지
      않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 강구하고 있습니다. 단 이용자
      본인의 부주의나 인터넷 또는 통신상의 문제로 ID, 비밀번호 등 개인정보가 유출되어 발생한 문제에
      대해 회사는 일체의 책임을 지지 않습니다.
      <br />
      <br />
      1) 개인정보 암호화
      <br />
      <br />
      이용자의 개인정보는 암호화되어 저장 및 관리되고 있어 본인만이 알고 있으며, 개인정보의 확인 및
      변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.
      <br />
      <br />
      i. 암호화 대상 개인정보: 이메일, 비밀번호, 닉네임, 휴대폰 번호, 주소, SNS인증키
      <br />
      <br />
      2) 해킹 등에 대비한 대책
      <br />
      <br />
      회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해
      최선을 다하고 있습니다. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신
      백신프로그램을 이용하여 이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고
      있으며, 암호화통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고
      있습니다. 그리고 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타
      시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.
      <br />
      <br />
      3) 취급 직원의 최소화 및 교육
      <br />
      <br />
      회사의 개인정보관련 취급 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여
      정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 회사의 개인정보취급정책의 준수를
      항상 강조하고 있습니다.
      <br />
      <br />
      취급 직원은 『개인정보 보호법』 제59조(금지행위) 에 따라 다음 각호의 행위를 하지 않습니다.
      <br />
      <br />
      i. 거짓이나 그 밖의 부정한 수단이나 방법으로 개인정보를 취득하거나 처리에 관한 동의를 받는
      행위
      <br />
      ii. 업무상 알게 된 개인정보를 누설하거나 권한 없이 다른 사람이 이용하도록 제공하는 행위
      <br />
      iii. 정당한 권한 없이 또는 허용된 권한을 초과하여 다른 사람의 개인정보를 훼손, 멸실, 변경,
      위조 또는 유출하는 행위
      <br />
      <br />
      <br />
      12. 이용자의 의무
      <br />
      <br />
      1) 이용자는 적법한 개인정보를 항상 최신으로 입력, 유지해야 합니다. 부정확한 정보의 입력에 의한
      문제의 책임은 이용자 본인에게 있으며, 특히 타인의 정보 도용으로 인한 문제 발생시에는 회원자격
      상실 및 『주민등록법』에 의한 처벌 대상이 될 수 있습니다.
      <br />
      <br />
      2) 이용자는 서비스 이용에 필요한 개인정보가 유출되지 않도록 해야 하며, 유출에 따른 문제 발생시
      책임은 본인에게 있으며, 회사는 책임지지 않습니다.
      <br />
      <br />
      3) 이용자는 『개인정보 보호법』, 주민등록법』, 『정보통신망 이용촉진 및 정보보호 등에 관한
      법률』, 등 관계법령을 준수해야 합니다.
      <br />
      <br />
      <br />
      13. 의견수렴 및 불만처리
      <br />
      <br />
      1) 회사는 개인정보와 관련한 이용자의 의견을 소중하게 생각하며, 이용자는 개인정보 관련 문의에
      대하여 성실하게 답변을 받을 권리가 있습니다.
      <br />
      <br />
      2) 회사는 이용자와의 원활한 의사소통을 위해 고객센터를 운영하고 있으며 다음의 이메일 주소를
      통해 문의하실 수 있습니다.
      <br />
      <br />
      [고객센터] e-mail: hiqbank@gmail.com <br />
      <br />
      3) 기타 개인정보에 관한 상담이 필요한 경우에는 개인정보침해신고센터, 대검찰청
      인터넷범죄수사센터, 경찰청 사이버테러대응센터 등으로 문의하실 수 있습니다.
      <br />
      <br />
      (아래의 기관은 회사와는 별개의 기관으로서 회사의 자체적인 개인정보 의견수렴, 불만처리 및
      피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하시기 바랍니다.)
      <br />
      <br />- 개인정보침해신고센터 전화: (국번없이)1301/휴대전화:지역번호+1301
      <br />
      <br />- URL : http://www.cyberprivacy.or.kr
      <br />
      <br />- 대검찰청 인터넷범죄수사센터 전화: 02-3480-3600
      <br />
      <br />- URL : http://www.spo.go.kr
      <br />
      <br />- 경찰청 사이버테러대응센터 전화: 02-393-9112
      <br />
      <br />- URL : http://www.netan.go.kr
      <br />
      <br />
      <br />
      14. 개인정보관리책임자 및 담당자
      <br />
      <br />
      1) 개인정보관리책임자는 고객의 개인정보를 보호하고 유출을 방지하는 책임자로서 이용자가
      안심하고 회사가 제공하는 서비스를 이용할 수 있도록 도와드리며, 개인정보를 보호하는데 있어
      이용자에게 고지한 사항들에 반하여 사고가 발생할 시에는 이에 관한 책임을 집니다.
      <br />
      <br />
      2) 기술적인 보완조치를 취하였음에도 불구하고 해킹 등 기본적인 네트워크상의 위험성에 의해
      발생하는 예기치 못한 사고로 인한 정보의 훼손 및 멸실, 이용자가 작성한 게시물에 의한 각종 분쟁
      등에 관해서는 책임이 없습니다.
      <br />
      <br />
      3) 회사는 정보통신망 이용촉진 및 정보보호 등에 관한 법률에서 규정한 관리책임자를 다음과 같이
      지정합니다.
      <br />
      <br />
      [개인정보관리 책임자]
      <br />
      <br />
      성명: 박주호 (CEO)
      <br />
      <br />
      e-mail : hiqbank@gmail.com
      <br />
      <br />
      <br />
      기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.
      <br />
      <br />- 개인정보침해신고센터(www.118.or.kr / 118)
      <br />
      <br />- 정보보호마크인증위원회 (www.eprivacy.or.kr / 02-580-0533~4)
      <br />
      <br />- 대검찰청 첨단범죄수사과 (www.spo.go.kr / 02-3480-2000)
      <br />
      <br />- 경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02-392-0330)
      <br />
      <br />
      <br />
      15. 고지의 의무
      <br />
      <br />본 개인정보 취급정책은 정부 및 회사의 정책 또는 보안기술의 변경에 따라 내용의 추가, 삭제
      및 수정이 있을 경우에는 개정 최소 7일 전부터 서비스의 ‘공지사항’란을 통해 고지하며, 본 정책은
      공지한 날로부터 시행됩니다.
      <br />
      <br />
      단, 사전에 공지가 없었을 경우에는 공지일자 기준 7일 이후부터 효력이 발생합니다.
      <br />
      <br />
    </>
  );
}
