import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import InfiniteScroll from "react-infinite-scroll-component";

import { Frame } from "../../page_index";
import { UiCheckBox } from "../../../uiComponents/UiCheckBox/UiCheckBox";

import styles from "./style.module.scss";
import constants from "../../../asset/constants";
import QuestionItem from "./QuestionItem";
import { CircularProgress } from "@mui/material";
import style from "../../../asset/style";
import { getUser } from "@asset/storage";

const Myinfo_Question = () => {
  const [questionList, setQuestionList] = useState([]);
  const [tcnt, setTcnt] = useState(null);
  const [bookList, setBookList] = useState(null);
  const userInfo = getUser();

  const [page, setPage] = useState(0);
  const amount = 12;

  const [showPublicOnly, setShowPublicOnly] = useState(false);

  const navigate = useNavigate();

  const hasMore = useMemo(() => {
    return (page + 1) * amount < tcnt;
  }, [tcnt, page]);

  const getQuestionList = async () => {
    const {
      books,
      questions,
      tcnt: newTcnt,
    } = await (
      await fetch(`${constants.apiUrl}/user/${userInfo.id}/question/?page=${page}&amount=${amount}`)
    ).json();
    setTcnt(newTcnt);
    setQuestionList((prev) => [...prev, ...(questions || [])]);
    setBookList(
      books?.reduce((prev, cur) => {
        prev[cur.bid] = cur.type;
        return prev;
      }, {})
    );

    setPage((prev) => prev + 1);
  };

  useEffect(() => {
    getQuestionList();
  }, []);

  return (
    <Frame title="내 질문 답변 관리" userInfo={userInfo} isSearch={false} isPremium>
      <div className={styles.MyQuestionContainer}>
        <div className={styles.FilterSection}>
          <UiCheckBox checked={showPublicOnly} fnClick={() => setShowPublicOnly((prev) => !prev)} />
          <div style={{ marginLeft: 10 }}>공유 질문만 보기</div>
        </div>
        <div id="ScrollTarget" className={styles.QuestionListSection}>
          <InfiniteScroll
            hasMore={hasMore}
            dataLength={questionList?.length}
            next={getQuestionList}
            scrollableTarget="ScrollTarget"
            loader={
              hasMore && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    padding: "0px 0px 10px 0px",
                  }}
                >
                  <CircularProgress
                    style={{
                      fontSize: "12px",
                      color: userInfo.user_type === 2 ? style.common.t_color : style.common.s_color,
                    }}
                  />
                </div>
              )
            }
          >
            {questionList?.map((question) => {
              if (userInfo.type === 1) {
                return (
                  <QuestionItem.StudentQuestionItem
                    id={question.id}
                    questionImage={`${constants.apiUrl}/qna/${question.img}.jpg`}
                    title={question.title}
                    isAnswered={question.ans_date !== null}
                    isOwn={question.sid === userInfo.id}
                  />
                );
              } else if (userInfo.type === 2) {
                const isSharing =
                  question.HIQDB_book_links?.[0] &&
                  bookList?.[question.HIQDB_book_links?.[0]?.bid] === 5; // 관리자인 교재
                const isShared =
                  question.HIQDB_book_links?.[0] &&
                  [4, 6, 9, 11].includes(bookList?.[question.HIQDB_book_links?.[0]?.bid]); // 열람 교재
                if (question.tid !== userInfo.id && !isSharing && !isShared)
                  console.log(question, bookList?.[question.HIQDB_book_links?.[0]?.bid]);

                return (
                  <QuestionItem.TeacherQuestionItem
                    key={`${question.id}/${question.sid_HIQDB_user_credential?.user?.id}/${question.tid_HIQDB_user_credential?.user?.id}`}
                    id={question.id}
                    questionImage={`${constants.apiUrl}/qna/${question.img}.jpg`}
                    title={question.title}
                    isAnswered={question.ans_date !== null}
                    isSharing={isSharing}
                    isShared={isShared}
                    isOwn={question.tid === userInfo.id}
                    student={question.sid_HIQDB_user_credential?.user}
                    reg_date={question.reg_date}
                    teacher={question.tid_HIQDB_user_credential?.user}
                  />
                );
              } else {
                throw new Error("Invalid User Type");
              }
            }) ?? null}
          </InfiniteScroll>
        </div>
      </div>
    </Frame>
  );
};

export default Myinfo_Question;
