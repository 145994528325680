import { useParams } from "react-router";
import { React, useEffect, useState, useRef } from "react";
import { Check, Edit, RemoveCircle } from "@material-ui/icons";
import { UiInput } from "../../../../../uiComponents/UiInput/UiInput";
import style from "../../../../../asset/style";
import styles from "./style.module.scss";

const AnswerItem = ({
  objKey,
  data,
  fileList,
  setFileList,
  onDragStart,
  onDrag,
  onDragEnd,
  deleteAnswerFromQuestionList,
}) => {
  let params = useParams();

  const [isModi, setIsModi] = useState(false);
  const [name, setName] = useState(data?.["fileName"]);

  const [isModi_gi, setIsModi_gi] = useState(false);
  const [rate, setRate] = useState(0);

  const ref = useRef(null);
  const imgRef = useRef(null);

  useEffect(() => {
    if (data.file) {
      const fileUrl = URL.createObjectURL(data.file);
      if (imgRef.current) {
        imgRef.current.src = fileUrl;
      }

      return () => {
        URL.revokeObjectURL(fileUrl);
      };
    }
  }, [data.file]);

  useEffect(() => {
    setName(data["fileName"]);
  }, [data]);

  useEffect(() => {
    setName(fileList[objKey]?.fileName);
  }, [fileList, objKey]);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener("touchstart", (e) => onDragStart(e, ref.current));
      ref.current.addEventListener("touchmove", (e) => onDrag(e, ref.current), { passive: false });
      ref.current.addEventListener("touchend", (e) => onDragEnd(e, ref.current));
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener("touchmove", onDrag);
        ref.current.removeEventListener("touchend", onDragEnd);
      }
    };
  }, [ref]);

  return (
    <div
      draggable={true}
      className={styles.AnswerItemContainer}
      onDragStart={onDragStart}
      onDrag={onDrag}
      onDragEnd={onDragEnd}
      ref={ref}
      id={"A:" + objKey}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            position: "relative",
            width: "150px",
            // height:"150px",
            margin: "10px",
          }}
        >
          <img
            ref={imgRef}
            alt={`Preview ${objKey}`}
            style={{
              width: "100%",
              objectFit: "cover",
              objectPosition: "top",
              // height:"100%",
            }}
          />
          <RemoveCircle
            style={{
              position: "absolute",
              color: "#F09294",
              fontSize: "28px",
              top: -10,
              right: -10,
              backgroundColor: "white",
              borderRadius: 20,
            }}
            onClick={() => {
              deleteAnswerFromQuestionList(objKey);
              delete fileList[objKey];
              setFileList({
                ...fileList,
              });
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            style={{
              maxWidth: "120px",
              marginRight: "6px",
            }}
          >
            {isModi ? (
              <UiInput
                inputValue={name}
                inputClass={"input_t"}
                type="basic"
                fnChange={(e) => {
                  setName(e.target.value);
                }}
              />
            ) : (
              <div style={style.common.line_text}>
                {data["fileName"]}
                {/* util.textTrim(data["fileName"],20) */}
              </div>
            )}
          </div>
          {isModi ? (
            <Check
              style={{
                fontSize: "22px",
                fontWeight: "bold",
                color: style.common.t_color,
              }}
              onClick={() => {
                if (isModi) {
                  fileList[objKey]["fileName"] = name;
                  setFileList({
                    ...fileList,
                  });
                }
                setIsModi(!isModi);
              }}
            />
          ) : (
            <Edit
              style={{
                fontSize: "18px",
                color: style.common.fontColor_3,
              }}
              onClick={() => {
                if (isModi) {
                  fileList[objKey]["fileName"] = name;
                  setFileList({
                    ...fileList,
                  });
                }
                setIsModi(!isModi);
              }}
            />
          )}
        </div>
        {params["type"] == "gi" && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                maxWidth: "120px",
                marginRight: "6px",
              }}
            >
              {isModi_gi ? (
                <UiInput
                  inputValue={rate}
                  inputClass={"input_t"}
                  type="basic"
                  fnChange={(e) => {
                    setRate(e.target.value);
                  }}
                />
              ) : (
                <div style={style.common.line_text}>
                  {rate}
                  {/* util.textTrim(data["fileName"],20) */}
                </div>
              )}
            </div>
            <Edit
              style={{
                fontSize: "18px",
              }}
              onClick={() => {
                if (isModi_gi) {
                  fileList[objKey]["rate"] = rate;
                  setFileList({
                    ...fileList,
                  });
                }
                setIsModi_gi(!isModi_gi);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AnswerItem;
